<template>
  <div class="wrap">
    <div class="title-view">
      <div class="title-text">充值方式</div>
      <img src="../assets/alipayIcon.png" class="title-pay-icon" />
      <div class="title-payType">支付宝</div>
    </div>

    <div class="input-card">
      <div class="title-text" style="margin-top: 8px; height: 21px">
        充值金额
      </div>

      <div class="input-view">
        <div class="text-yuan">¥</div>
        <van-field class="input" v-model="money" type="digit" :border="false"></van-field>
        <div class="btn" :style="money == '' ? 'background: #DADADA;' : ''" @click="doPay">
          充 值
        </div>
      </div>

      <div class="tips">充值的货款将进入货款账户,可直接用于商城消费</div>
    </div>

    <div></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { formatPrice } from "../utils/money";

export default {
  name: "Pay",
  components: {},
  data() {
    return {
      money: "",
      goodslist: [],
      token: this.$route.query.token,
      canClick: true
    };
  },
  created() {
    document.title = "货款充值";
    console.log("token", this.$route.query.token);
    // this.getlevelgoodslist();
  },
  methods: {
    pay(postData) {
      console.log("??", postData, ap);
      this.$axios({
        method: "get",
        url: "https://openapi.alipay.com/gateway.do?" + postData
      }).then(res => {
        console.log("pay", res);
        if (res.status == 200) {
          // window.location.href = res.config.url;

          // this.$axios({
          //   methods: "get",
          //   url: res.config.url,
          // });

          var doc = document.open("text/html", "replace");
          var txt = res.data;
          doc.write(txt);
          doc.close();

          //   var win = window.open("", "运行窗口");

          //   win.document.open();
          //   win.document.close();
          //   setTimeout(() => {
          //       win.document.write(res.data);
          //   }, 200);
        }
      });
    },
    doPay() {
      this.$toast("该功能暂未开放");
      // if (Number(this.money) >= 2000) {
      //   this.$dialog
      //     .confirm({
      //       title: "提示",
      //       message:
      //         "此充值仅用于消费充值，不支持会员升级充值，如需升级充值，请到KA,KB的升级通道进行付款。",
      //     })
      //     .then(() => {
      //       this.commitPaymentOrder();
      //     });
      // } else {
      //   this.commitPaymentOrder();
      // }
    },
    commitPaymentOrder() {
      if (this.canClick == false) {
        return;
      }
      if (this.money == "") {
        return;
      }
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 5000);
      this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: "1", Authorization: this.token },
        url: "/api/dist/user/commitPaymentOrder",
        data: {
          remark: "", //类型：String  必有字段  备注：订单备注
          source: 0, //类型：Number  必有字段  备注：0：直接购买
          clientType: 9, //类型：Number  必有字段  备注：客户端（0：APP；1：WEB；2：小程序；9：其他）
          rechargeAmount: Number(this.money) * 100 //类型：Number  必有字段  备注：充值金额（分）
          // rechargeAmount: 1, //类型：Number  必有字段  备注：充值金额（分）
        }
      }).then(e => {
        console.log("commitPaymentOrder", e);
        if (e.data.success) {
          this.$axios({
            method: "post",
            headers: {
              mac: "mac",
              clientSource: "1",
              Authorization: this.token
            },
            url: "/api/dist/user/paySeniorGrade",
            data: {
              orderId: e.data.data.orderId, //类型：String  必有字段  备注：订单ID
              payChannelType: 4, //类型：Number  必有字段  备注：支付平台（0-支付宝APP 1-微信APP 2-微信小程序 ）
              appIdType: 4 //类型：String  必有字段  备注：（0-支付宝APP 1-微信APP 2-微信小程序 3-微信小程序B端 4-支付宝APP B端 ）
            }
          }).then(e => {
            console.log("paySeniorGrade", e);
            if (e.data.success) {
              this.pay(e.data.data.aliPaySignResultDTO.aliPayResponseBody);
            } else {
              this.$toast(e.data.message);
            }
          });
        } else {
          this.$toast(e.data.message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  min-height: 750px;
  background-color: #f5f5f5;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 12px;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.title-view {
  height: 24px;
  margin-top: 20px;
  display: flex;
}
.title-text {
  position: absolute;
  left: 28px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 21px;
}
.title-pay-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 81px;
}
.title-payType {
  text-align: right;
  position: absolute;
  right: 28px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #232323;
  line-height: 22px;
}

.input-card {
  margin-top: 28px;
  width: 340px;
  height: 148px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 4px;
}
.input-view {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 57px;
  height: 56px;
  width: 316px;
  border-bottom: 1px solid #e5e5e5;
}
.text-yuan {
  margin-top: 16px;
  width: 16px;
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0a0a0a;
  line-height: 37px;
}

.input {
  width: 210px;
  height: 56px;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0a0a0a;
  line-height: 56px;

  // /deep/ .van-field__body {
  //   height: 100%;
  //   input {
  //     height: 100%;
  //   }
  // }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
  position: absolute;
  right: 29px;
  width: 76px;
  height: 36px;
  background: #323cb3;
  border-radius: 4px;

  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
}
.tips {
  margin: auto;
  margin-top: 9px;
  width: auto;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #919191;
  line-height: 17px;
}
.alipay {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 750px;
  background-color: #f5f5f5;
}
</style>
