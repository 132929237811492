<template>
  <div class="withdraw_container">
    <div class="big_box">
      <div class="head" @click="handleClickSelectBankCard">
        <p class="head_title">到账银行卡</p>
        <div class="head_right_mask">
          <img v-if="isHaveBankCardInfo" class="logo"
            :src="require(`../../../assets/BranCardLogo/${bankCardInfo.bankCode}.png`)" alt="" />
          <p class="name">
            {{
              isHaveBankCardInfo
              ? `${bankCardInfo.bankName}(${bankCardInfo.cardNumber.slice(-4)})`
              : "请添加银行卡"
            }}
          </p>
          <img class="arrow" src="../../../assets/right_arrow_black_icon.png" alt="" />
        </div>
      </div>
      <p class="big_title">提现金额</p>
      <div class="withdraw_mask">
        <div class="withdraw_box van-hairline--bottom">
          <span class="withdraw_box_title">¥</span>
          <van-field v-model.number="money" type="number" clearable placeholder="请输入金额" />
        </div>
        <div class="withdraw_button" @click="handleClickWithdraw">提现</div>
      </div>
      <p class="money_tip">
        当前可提现总额¥{{ withdrawMoney }}元&nbsp;<span @click="handleClickAllMoney">全部提现</span>
      </p>
      <p class="tip_title">提现说明</p>
      <p class="tip_info">
      <pre>{{ withdrawTipData.remark }}</pre>
      </p>
    </div>
    <!-- 提现弹框 -->
    <van-popup v-model="isShowWithDrawPop" :close-on-click-overlay="false">
      <div class="withdraw_pop">
        <div class="head">
          <img class="head_close" src="../../../assets/close_black.png" alt="" @click="handleClickClosePop" />
          <p class="head_title">请输入短信验证码</p>
        </div>
        <p class="tip_title">提现</p>
        <p class="money_value"><span>¥</span>&nbsp;{{ money }}</p>
        <div class="line"></div>
        <div class="taxes_mask">
          <p class="taxes_title">手续费</p>
          <p class="taxes_money">¥{{ formatPrice(currentPriceTax.handlingFee) }} ({{
            Math.floor(withdrawTipData.handlingFee * 100) }}%)</p>
        </div>
        <div class="taxes_mask">
          <p class="taxes_title">税费</p>
          <p class="taxes_money">¥{{ formatPrice(currentPriceTax.taxes) }} ({{ Math.floor(withdrawTipData.taxes *
            100) }}%)</p>
        </div>
        <van-password-input :value="value" :mask="false" :focused="showKeyboard" :gutter="6"
          @focus="showKeyboard = true" />
        <p :class="['countdown_time', isReget ? 'countdown_time_h' : '']" @click="getCode">{{ isReget ? '重新获取' :
          `(${count}秒后重新获取)` }}</p>
        <p class="phone_tip">验证码已发送至尾号{{ userInfoData.mobile && userInfoData.mobile.slice(-4) }}的手机中</p>
      </div>
    </van-popup>
    <!-- 签约弹框 -->
    <van-dialog v-model="isShowSignPop" title="签约个税平台账户绑定" show-cancel-button confirm-button-color="#333333"
      @confirm="handleClickSignSureAction" @cancel="handleClickSignCancleAction">
      <van-field v-model="bindMobile" maxlength="11" label="手机号" clearable type="digit" placeholder="请输入注册个税平台手机号"
        label-width="50px" />
      <van-field v-model="bindCode" maxlength="6" center clearable label="验证码" type="digit" placeholder="请输入短信验证码"
        label-width="50px">
        <template #button>
          <van-button size="small" :style="{ opacity: bindIsReget ? 0.5 : 1 }" color="#323CB3"
            @click="handleClickBindCode">{{ bindIsReget ? `重新获取
            ${bindCount}s` : '发送验证码' }}</van-button>
        </template>
      </van-field>
      <van-field v-model="bindName" label="姓名" clearable placeholder="请输入注册个税平台姓名" label-width="50px" />
      <van-field v-model="bindCard" maxlength="18" label="身份证" clearable placeholder="请输入注册个税平台身份证号码"
        label-width="50px" />
    </van-dialog>
    <!-- 选择银行卡弹框 -->
    <van-popup v-model="isShowBankCardPop" :close-on-click-overlay="false">
      <div class="bankcard_pop">
        <div class="head">
          <img class="head_close" src="../../../assets/close_black.png" alt="" @click="handleClickCloseBankCardPop" />
          <p class="head_title">请选择银行卡</p>
        </div>
        <div v-for="(item, index) in bankCardList" :key="index" class="list" @click="handleClickCurrentBankCard(item)">
          <img class="list_logo" :src="require(`../../../assets/BranCardLogo/${item.bankCode}.png`)" alt="" />
          <p class="list_name van-ellipsis">
            {{ item.bankName }}({{ item.cardNumber && item.cardNumber.slice(-4) }})
          </p>
          <div v-show="false" class="list_button">修改</div>
          <img class="list_arrow" src="../../../assets/right_arrow_black_icon.png" alt="" />
        </div>
      </div>
    </van-popup>
    <!-- 数字键盘 -->
    <van-number-keyboard v-model="value" :show="showKeyboard" @blur="showKeyboard = false" />
  </div>
</template>

<script>
import { formatPrice } from "../../../utils/money";

export default {
  created() {
    if (this.$route.query.type === "0") {
      document.title = "余额提现";
    } else {
      document.title = "货款提现";
    }
  },
  data() {
    return {
      // 输入金额
      money: "",
      isShowWithDrawPop: false,
      isShowBankCardPop: false,
      value: "",
      showKeyboard: false,
      isShowSignPop: false,
      token: "",
      type: "",
      withdrawMoney: "",
      isHaveBankCardInfo: false,
      bankCardInfo: {},
      bankCardList: [],
      withdrawTipData: {},
      userInfoData: {},
      // 提现定时器
      timer: null,
      count: 60,
      isReget: false,
      // 签约绑定数据源
      bindMobile: "",
      bindCode: "",
      bindName: "",
      bindCard: "",
      bindTimer: null,
      bindCount: 60,
      bindIsReget: false,
      currentPriceTax: {},
    };
  },
  mounted() {
    uni.webView.getEnv((res) => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.type = this.$route.query.type;
        this.withdrawMoney = this.$route.query.withdrawMoney;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index",
          });
          return;
        }
        this.getFirstBankCard();
        this.getWithdrawTip();
        this.getUserInfo();
      } else {
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getFirstBankCard();
          this.getWithdrawTip();
          this.getUserInfo();
        });
      }
    });
  },
  watch: {
    count(val) {
      if (val == 0) {
        this.isReget = true;
        clearInterval(this.timer);
      }
    },
    bindCount(val) {
      if (val == 0) {
        this.bindIsReget = false;
        this.bindCount = "60";
        clearInterval(this.bindTimer);
      }
    },
    value(value) {
      if (value.length === 6) {
        // 调用提现接口
        const params = {
          captcha: value,
          idCard: this.bankCardInfo.idCard,
          bankName: this.bankCardInfo.bankName,
          withdrawType: this.type,
          handlingFee: this.currentPriceTax.handlingFee,
          accountMobile: this.userInfoData.mobile,
          taxes: this.currentPriceTax.taxes,
          cardNumber: this.bankCardInfo.cardNumber,
          accountName: this.bankCardInfo.accountName,
          amount: this.money * 100,
        };
        this.startWithdrawRequest(params);
      }
    },
  },
  methods: {
    formatPrice,
    // 获取绑定的第一张银行卡
    async getFirstBankCard() {
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token,
          },
          url: "/api/bankcard/queryBankCardList",
          data: {},
        });
        if (data.success) {
          if (data.data && data.data.length > 0) {
            this.isHaveBankCardInfo = true;
            this.bankCardInfo = { ...data.data[0] };
            this.bankCardList = [...data.data];
          } else {
            this.isHaveBankCardInfo = false;
            this.bankCardInfo = {};
          }
        } else {
          this.$toast(data.message);
          this.isHaveBankCardInfo = false;
          this.bankCardInfo = {};
        }
      } catch (error) {
        this.$toast(data.message);
        this.isHaveBankCardInfo = false;
        this.bankCardInfo = {};
      }
    },
    // 获取提现说明
    async getWithdrawTip() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/withdraw/queryWithdrawBasisConfig",
        data: {},
      });
      if (data.success) {
        this.withdrawTipData = { ...data.data };
      } else {
        this.$toast(data.message);
      }
    },
    // 获取用户信息
    async getUserInfo() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/user/info/queryUserInfoById",
        data: {},
      });
      if (data.success) {
        this.userInfoData = { ...data.data };
      } else {
        this.$toast(data.message);
      }
    },
    // 获取提现短信验证码
    async getWithdrawVerifyCode() {
      this.$toast.loading({
        message: "获取验证码中...",
        forbidClick: true,
      });
      const milliStamp = new Date().getTime();
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token,
          },
          url: "/api/user/verify/sendWithdrawVerifyCode",
          data: {
            mobile: this.userInfoData.mobile,
            currentTime: milliStamp,
            sign: this.$md5(
              `${this.userInfoData.mobile}weiphd%ue8@r4manqu@Qwdl1kj92${milliStamp}`.toLocaleLowerCase()
            ),
          },
        });
        this.$toast.clear();
        if (data.success) {
          this.$toast("获取验证码成功");
          this.getTimer();
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    // 根据金额获取税费
    async getCurrentTaxData(amount) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/withdraw/computeTaxAndHandlingFee",
        data: { amount: amount * 100 },
      });
      if (data.success) {
        this.currentPriceTax = { ...data.data };
      } else {
        this.currentPriceTax = {};
        this.$toast(data.message);
      }
    },
    // 提现接口
    async startWithdrawRequest(params) {
      this.$toast.loading({
        message: "提现中...",
        forbidClick: true,
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token,
          },
          url: "/api/withdraw/initiateWithdraw",
          data: params,
        });
        this.$toast.clear();
        this.isShowWithDrawPop = false;
        this.showKeyboard = false;
        this.value = "";
        if (data.success) {
          this.$router.push(
            `/addol/withdrawSuccess?token=${this.token}&type=${this.$route.query.type}`
          );
        } else {
          if (data.code === 300 && this.type === "0") {
            // 儒风伟签约功能仅针对余额提现
            // 用户未签约
            this.$dialog
              .alert({
                title: "签约提示",
                message:
                  "当前提现账户未查询到个税平台签约记录，无法正常提现。\n-若您已签约，请点击绑定；\n-若您未签约，请点击签约；",
                messageAlign: "left",
                showCancelButton: "true",
                confirmButtonText: "签约",
                cancelButtonText: "绑定",
                confirmButtonColor: "#333333",
                cancelButtonColor: "#333333",
              })
              .then(() => {
                //签约
                this.handleSignContract();
              })
              .catch(() => {
                //绑定
                this.handleBind();
              });
          } else {
            this.$toast(data.message);
          }
        }
      } catch (error) {
        this.value = "";
        this.isShowWithDrawPop = false;
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    // 签约
    async handleSignContract() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/sign/signed",
        data: {},
      });
      if (data.success) {
        if (data.data && data.data.length > 0) {
          this.handleGoThirdSign(data.data);
        } else {
          this.$toast("未获取到第三方平台签约信息");
        }
      } else {
        this.$toast(data.message);
      }
    },
    // 跳转第三方签约
    handleGoThirdSign(url) {
      uni.webView.getEnv((res) => {
        if (
          res.miniprogram ||
          process.env.VUE_APP_ENV == "development" ||
          process.env.VUE_APP_ENV == "test"
        ) {
          uni.webView.reLaunch({
            url: `/pages/h5/testWeb?complete=1&pathH5=${url}`,
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 37,
              param: url,
            })
            .then((e) => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },
    // 绑定
    handleBind() {
      this.isShowSignPop = true;
    },
    handleClickCurrentBankCard(item) {
      this.isShowBankCardPop = false;
      this.bankCardInfo = { ...item };
    },
    handleClickSelectBankCard() {
      if (this.isHaveBankCardInfo) {
        this.isShowBankCardPop = true;
      } else {
        this.$router.push(`/addol/bankCardList?token=${this.token}`);
      }
    },
    handleClickAllMoney() {
      this.money = this.withdrawMoney;
    },
    // 提现按钮事件
    handleClickWithdraw() {
      if (Object.keys(this.withdrawTipData).length === 0) {
        this.$toast("获取配置信息失败，请稍后再试！");
        return;
      }
      if (Object.keys(this.bankCardInfo).length === 0) {
        this.$toast("请添加银行卡");
        return;
      }
      if (this.money.length === 0) {
        this.$toast("请输入提现金额");
        return;
      }
      if (
        Number(this.money) < Number(this.withdrawTipData.miniWithdrawAmount)
      ) {
        this.$toast(`最小提现金额为${this.withdrawTipData.miniWithdrawAmount}`);
        return;
      }
      if (Number(this.withdrawMoney) < Number(this.money)) {
        this.$toast("账号余额不足");
        return;
      }
      this.isShowWithDrawPop = true;
      this.showKeyboard = true;
      this.getCurrentTaxData(this.money);
      // 获取提现短信验证码
      this.getWithdrawVerifyCode();
    },
    // 提现定时器
    getTimer() {
      this.timer = setInterval(() => {
        if (this.count == 0) {
          clearInterval(this.timer);
          return;
        }
        this.count--;
      }, 1000);
    },
    // 绑定签约定时器
    getBindSignTimer() {
      this.bindIsReget = true;
      this.bindTimer = setInterval(() => {
        if (this.bindCount == 0) {
          this.bindIsReget = false;
          this.bindCount = "60";
          clearInterval(this.bindTimer);
          return;
        }
        this.bindCount--;
      }, 1000);
    },
    // 获取提现验证码
    getCode() {
      if (!this.isReget) {
        return;
      }
      if (this.count == 0) {
        this.count = 60;
        this.isReget = false;
        this.getWithdrawVerifyCode();
      }
    },
    handleClickClosePop() {
      this.isShowWithDrawPop = false;
    },
    handleClickCloseBankCardPop() {
      this.isShowBankCardPop = false;
    },
    // 获取绑定签约验证码
    handleClickBindCode() {
      if (this.bindIsReget) {
        return;
      }
      if (this.bindMobile.length === 0) {
        this.$toast("请输入注册个税平台手机号");
        return;
      }
      if (this.bindMobile.length < 11) {
        this.$toast("手机号不正确");
        return;
      }
      this.getBindVerifyCode();
    },
    // 获取绑定签约短信验证码
    async getBindVerifyCode() {
      this.$toast.loading({
        message: "获取验证码中...",
        forbidClick: true,
      });
      const milliStamp = new Date().getTime();
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token,
          },
          url: "/api/user/verify/sendSignBindingVerifyCode",
          data: {
            mobile: this.bindMobile,
            currentTime: milliStamp,
            sign: this.$md5(
              `${this.bindMobile}weiphd%ue8@r4manqu@Qwdl1kj92${milliStamp}`.toLocaleLowerCase()
            ),
          },
        });
        this.$toast.clear();
        if (data.success) {
          this.$toast("获取验证码成功");
          this.getBindSignTimer();
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    async handleClickSignSureAction() {
      if (this.bindMobile.length === 0) {
        this.$toast("请输入注册个税平台手机号");
        return;
      }
      if (this.bindMobile.length < 11) {
        this.$toast("手机号不正确");
        return;
      }
      if (this.bindCode.length === 0) {
        this.$toast("请输入验证码");
        return;
      }
      if (this.bindCode.length < 6) {
        this.$toast("验证码不正确");
        return;
      }
      if (this.bindName.length === 0) {
        this.$toast("请输入注册个税平台姓名");
        return;
      }
      if (this.bindCard.length === 0) {
        this.$toast("请输入身份证号");
        return;
      }
      if (this.bindCard.length < 18) {
        this.$toast("请输入正确身份证号");
        return;
      }
      this.$toast.loading({
        message: "绑定中...",
        forbidClick: true,
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token,
          },
          url: "/api/dist/sign/binding",
          data: {
            accountMobile: this.bindMobile,
            realName: this.bindName,
            idCard: this.bindCard,
            code: this.bindCode,
            userId: this.userInfoData.userId,
          },
        });
        this.$toast.clear();
        if (data.success) {
          this.isShowSignPop = false;
          this.$toast("绑定成功,可继续提现");
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    handleClickSignCancleAction() {
      console.log("取消");
    },
    beforeDestroy() {
      clearInterval(this.timer);
      clearInterval(this.bindTimer);
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f5f5f5;

  .big_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: white;

    .head {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }

      .head_right_mask {
        flex: 1;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: right;

        .logo {
          width: 18px;
          height: 18px;
        }

        .name {
          padding: 0;
          margin: 0;
          margin-right: 5px;
          margin-left: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
        }

        .arrow {
          height: 11px;
        }
      }
    }

    .big_title {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 50px 20px 5px 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }

    .withdraw_mask {
      width: 100%;
      box-sizing: border-box;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .withdraw_box {
        flex: 1;
        width: 0;
        background-color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-right: 5px;

        .withdraw_box_title {
          padding: 0;
          margin: 0;
          font-size: 25px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
        }

        /deep/ .van-cell {
          padding: 10px 16px 10px 3px;
        }

        /deep/ .van-field__control {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
        }
      }

      .withdraw_button {
        width: 100px;
        height: 50px;
        background: #141414;
        border-radius: 2px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }

    .money_tip {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      padding: 15px 20px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: left;

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }
    }

    .tip_title {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      padding: 15px 20px 5px 20px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }

    .tip_info {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      padding: 5px 20px 15px 20px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;

      pre {
        margin: 0;
        padding: 0;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }

  /deep/ .van-popup--center {
    border-radius: 16px;
    top: 40%;
  }

  .withdraw_pop {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 277px;
    height: 290px;

    .head {
      width: 100%;
      box-sizing: border-box;
      padding: 15px 15px 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head_close {
        width: 13px;
      }

      .head_title {
        flex: 1;
        width: 0;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }
    }

    .tip_title {
      width: 100%;
      padding: 0;
      margin: 15px 0 2px 0;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #424242;
      line-height: 22px;
      text-align: center;
    }

    .money_value {
      width: 100%;
      padding: 0;
      margin: 0;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: center;

      span {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
    }

    .line {
      margin: 10px 0 0;
      background-color: #f3f3f3;
      width: 245px;
      height: 1px;
    }

    .taxes_mask {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      .taxes_title {
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b2b2b2;
      }

      .taxes_money {
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
      }
    }

    /deep/ .van-password-input {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      margin-top: 10px;
      box-sizing: border-box;
    }

    /deep/ .van-password-input__security li {
      height: 34px;
      background-color: #f0f0f0;
      border-radius: 4px;
    }

    .countdown_time {
      width: 100%;
      padding: 0;
      margin: 5px 0;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #afacac;
      text-align: center;
    }

    .countdown_time_h {
      color: #232323 !important;
    }

    .phone_tip {
      width: 100%;
      padding: 0;
      margin: 5px 0;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      text-align: center;
    }
  }

  .bankcard_pop {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 277px;
    height: 300px;

    .head {
      width: 100%;
      box-sizing: border-box;
      padding: 15px 15px 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head_close {
        width: 13px;
      }

      .head_title {
        flex: 1;
        width: 0;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }
    }

    .list {
      margin-top: 10px;
      width: 100%;
      height: 35px;
      background: #ffffff;
      border-radius: 3px;
      display: flex;
      align-items: center;

      .list_logo {
        width: 20px;
        height: 20px;
        margin-left: 15px;
        margin-right: 4px;
      }

      .list_name {
        flex: 1;
        width: 0;
        padding: 0;
        margin: 0;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }

      .list_button {
        width: 52px;
        height: 25px;
        border-radius: 13px;
        border: 1px solid #404040;
        font-size: 13px;
        font-family: PingFangSC;
        color: #404040;
        line-height: 25px;
        text-align: center;
        margin-left: 10px;
        margin-right: 7px;
      }

      .list_arrow {
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  /deep/ .van-field__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  /deep/ .van-number-keyboard {
    z-index: 90000000;
  }
}
</style>
