<template>
    <div v-if="recordList.length" class="ver-message-wrap">

        <!-- 当前组件 -->
        <div :style="{ top: tabFix ? '4px' : (statusBarHeight + 'px') }" :class="['li', animateNext ? 'nextAnimate' : '']">
            <div class="li__content">
                {{ getShowRecord(recordList[current]) }}
            </div>
        </div>

        <!-- 下个组件 -->
        <div :style="{ top: tabFix ? '40px' : (statusBarHeight + 36 + 'px') }"
            :class="['li pre-li', animateNext ? 'preAnimate' : '']">
            <div class="li__content">
                {{ getShowRecord(recordList[preCurrent + 1 > (recordList.length - 1) ? 0 : (preCurrent + 1)]) }}
            </div>
        </div>
    </div>
</template>

<script>
// 定时器
let animateNextTimer = null;
export default {
    name: 'prizeRecord',
    props: {
        statusBarHeight: {
            type: Number,
            default: 20
        },
        tabFix: {
            type: Boolean,
            default: false
        },
        recordList: {
            type: Array,
            default: []
        }
    },
    computed: {
        // recordList() {
        //     if (!this.recordList.length) {

        //         return [];
        //     }

        //     const arr = this.recordList.map(item => {

        //         let name = this.emojiReplace(item.buyerName).slice(0, 1) + '** ';
        //         // 时间
        //         let timeName = '';
        //         const durTime = parseInt((new Date().getTime() - item.payTime) / 1000);

        //         if (durTime < 60) {
        //             // 1分钟内
        //             timeName = durTime + '秒钟前';
        //         } else if (durTime < 60 * 60) {
        //             // 1小时内
        //             timeName = parseInt(durTime / 60) + '分钟前';
        //         } else {
        //             // 1小时外
        //             timeName = '59分钟前';
        //         }

        //         // 商品
        //         const goodName = '购买了' + item?.subOrders[0].itemName;

        //         // 图片
        //         let path = item.extension?.USER_HEAD_URL || '';

        //         // webp
        //         if (path.indexOf('?') > -1 || path.indexOf('gif') > -1) {

        //         } else if (this.$webpShow !== 'webpImg') {
        //             path = path + '?x-oss-process=img/resize,w_50,m_lfit';
        //         } else {

        //             path = path + '?x-oss-process=img/format,webp/resize,w_50,m_lfit';
        //         }

        //         return {
        //             itemId: item?.subOrders[0].itemId || '',
        //             headSrc: path,
        //             textContent: name + timeName + goodName
        //         }
        //     })

        //     return arr;
        // }
    },
    data() {
        return {
            current: 0,
            preCurrent: 0,
            // recordList: [],
            count: 0,
            // 动画控制
            animateNext: false,
            loading: false,
            lockBtn: false
        }
    },
    // 销毁定时器
    destroyed() {
        // console.log('定时器销毁')
        // animateNextTimer = null;
        // clearInterval(animateNextTimer);
    },
    methods: {
        getShowRecord(item) {
            return this.noPassByName(item.nikeName) + '获得' + item.prizeName;
        },
        noPassByName(str) {
            if (null != str && str != undefined) {
                if (str.length <= 3) {
                    return "*" + str.substring(1, str.length);
                } else if (str.length > 3 && str.length <= 6) {
                    return "**" + str.substring(2, str.length);
                } else if (str.length > 6) {
                    return str.substring(0, 2) + "****" + str.substring(6, str.length);
                }
            } else {
                return "";
            }
        },
        emojiReplace(text) {
            var regStr =
                /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;

            return text.replace(regStr, "")
        },
        destroyedTimer() {
            console.log('销毁计时器')
            animateNextTimer = null;
            clearInterval(animateNextTimer);
        },
        // 初始化
        async init() {
            // 阻断
            if (this.loading) {
                return
            }
            this.loading = true;
            this.lockBtn = true;

            this.count++;

            // 重置
            // this.recordList = [];
            setTimeout(() => {
                this.current = 0;
                this.preCurrent = 0;
                // 动画控制
                this.animateNext = false;
            }, 10)

            // 动画执行
            setTimeout(() => {
                this.loading = false;

                // 只执行一次
                console.log('执行', this.count)
                if (this.count === 1 || !animateNextTimer) {
                    console.log('执行计时器')
                    this.lockBtn = false;
                    this.setAnimateStart();
                }
            }, 500)

        },
        // 消息
        getOrderMsgData() {
            let params = {};
            params['pageSize'] = 50;
            params['currentPage'] = 1;
            // this.$request(ORDER_BROAD_CAST)
            // 	.then(res => {
            // 		this.recordList = res.data;

            // 		// 动画执行
            // 		setTimeout(() => {
            // 			this.loading = false;

            // 			// 只执行一次
            // 			console.log('执行', this.count)
            // 			if(this.count === 1 || !animateNextTimer) {
            // 				console.log('执行计时器')
            // 				this.lockBtn = false;
            // 				this.setAnimateStart();
            // 			}
            // 		}, 500)
            // 	})
            // 	.catch(() => {
            // 		this.loading = false;
            // 	})
        },
        // 动画执行
        setAnimateStart() {
            animateNextTimer = setInterval(() => {
                if (this.lockBtn) {
                    // 遇到阻断等待当前循环作废
                    this.lockBtn = false;
                    return;
                } else {
                    // 1s执行时间
                    this.animateNext = true;
                }

                setTimeout(() => {
                    // 下标递增
                    this.current++;
                    if (this.current > this.recordList.length - 1) {
                        // 循环
                        this.current = 0;
                    }
                    // 状态回归__递次变化
                    // 第一条
                    this.animateNext = false;


                    if (this.lockBtn) {
                        this.current = 0;
                        // 遇到阻断等待当前循环作废
                        this.lockBtn = false;
                        return;
                    }

                    // 第二条
                    setTimeout(() => {
                        this.preCurrent = this.current;
                    }, 500)

                }, 1500)
            }, (this.lockBtn ? 500 : 3000))
        },
    }

}
</script>

<style lang="scss" scoped>
.ver-message-wrap {
    margin-left: 11px;

    .li {
        position: absolute;
        top: 0;
        left: 12rpx;
        z-index: 2;
        display: inline-flex;
        align-items: center;
        height: 22px;
        padding: 4rpx 12rpx 4rpx 4rpx;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.65);


        &.pre-li {
            opacity: 0;
        }

        &__img {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            margin-right: 6rpx;
            border-radius: 50%;
            background: #eeeeee;
        }

        &__content {
            max-width: 340px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 4px;
            padding-right: 4px;
        }

        &.nextAnimate {
            animation: upAnimate 0.6s linear 1 forwards;
        }

        &.preAnimate {

            animation: preAnimate 0.6s linear 1 forwards;
        }

        // 上滑动画
        @keyframes upAnimate {
            0% {
                transform: translateY(0);
                opacity: 1;
            }

            100% {
                transform: translateY(-36px);
                opacity: 0;
            }
        }

        @keyframes preAnimate {
            0% {
                transform: translateY(0);
                opacity: 0;
            }

            100% {
                transform: translateY(-36px);
                opacity: 1;
            }
        }

    }
}
</style>