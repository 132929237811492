<template>
  <div class="">
    <div :class="['ck-wheel', 'background-1']">
      <div class="share" @click="onShare"></div>
      <!-- 规则 -->
      <div :class="['label', 'label-1']" @click="goPage(0)">活动规则</div>
      <!-- 记录 -->
      <div :class="['label', 'label-2']" @click="goPage(1)">中奖记录</div>
      <!-- 通知栏 -->
      <div class="msgView">
        <van-notice-bar class="notice" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :duration="1000"
            :show-indicators="false"
            :touchable="false"
          >
            <van-swipe-item v-for="(item, index) in prizeTurnTableDTO" :key="index">{{
              item
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>

      <!-- 次数 -->
      <div class="harmerBG">
        <div class="txt1">
          {{ counts_1 }}
          <div class="ge">个</div>
        </div>
        <div class="txt2">
          {{ counts_2 }}
          <div class="ge">个</div>
        </div>
      </div>

      <!-- choose -->
      <div class="chooseBg">
        <div class="left" @click="goGame(4)"></div>
        <div class="right" @click="goGame(3)"></div>
      </div>

      <div class="leftMoney">
        {{ formatPrice(gapAmount) }}
        <div class="left-yuan">元</div>
      </div>

      <div class="curMoney">
        {{ formatPrice(totalAmount) }}
        <div class="curMoney-yuan">元</div>
      </div>

      <div class="drawed">
        已抽
        <div class="drawcounts">{{ timesPlayed }}</div>
        次
      </div>

      <div class="progressMoney">{{ formatPrice(thresholdAmount) }}元</div>

      <!-- 进度条 -->
      <div class="progressBg">
        <div class="progess-left"></div>
        <div class="progress" :style="{ width: progressWidth + 'px' }"></div>
        <div class="progess-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "../utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      token: "",
      type: 1,
      progressWidth: 0,
      counts_1: 0,
      dayCounts_1: 0,
      counts_2: 0,
      dayCounts_2: 0,
      gapAmount: 0,
      progressAmount: 0,
      thresholdAmount: 0,
      totalAmount: 0,
      timesPlayed: 0,
      prizeTurnTableDTO: [],
      goGameType: 0,
      timeId: 0
    };
  },
  created() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
    console.log("tokesn", this.token, this.$route.query, this.curIntervalId);

    this.type = Number(this.$route.query.type);
    document.title = "kiddol出虎意料“敲”有礼";

    if (this.type == 3) {
      this.$router.replace(
        "/turnTableRecord" + "?token=" + this.token + "&type=2" + "&timeStamp=" + Date.now()
      );
    }
  },
  destroyed() {
    clearInterval(this.timeId);
  },
  mounted() {
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          this.token = this.$route.query.token;
          this.getDate();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            this.getDate();
          });
        }
      });
    }, 1000);
  },
  watch: {
    $route(to, from) {
      console.log("wa");
      // document.documentElement.scrollTop = 0;
      // this.$router.go(0);
    }
  },
  methods: {
    formatPrice,
    goGame(type) {
      // console.log("goGame", goGame);
      this.goGameType = type;
      if (type === 4) {
        this.getCountsG(() => {
          if (this.counts_1 <= 0) {
            this.$toast("您还没有金锤子，累计消费满1680即可获得1个~");
            // return;
          } else if (this.dayCounts_1 >= 5) {
            this.$toast("今天金锤子使用次数达到上限啦，明天再来吧~");
            // return;
          } else {
            window.location.href =
              this.$httpUrl + `/bean/?token=${this.token}&type=${this.goGameType}`;
          }
        });
      } else if (type === 3) {
        this.getCountsS(() => {
          if (this.counts_2 <= 0) {
            this.$toast("您还没有银锤子，邀请好友注册或单笔实付满168即可获得1个~");
            // return;
          } else if (this.dayCounts_2 >= 10) {
            this.$toast("今天银锤子使用次数达到上限啦，明天再来吧~");
            // return;
          } else {
            window.location.href =
              this.$httpUrl + `/bean/?token=${this.token}&type=${this.goGameType}`;
          }
        });
      }
    },
    async getCountsG(func = null) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/queryLotteryTimes",
        data: {
          type: 4
        }
      });
      if (data.success) {
        this.counts_1 = data.data.remainTimes;
        this.dayCounts_1 = data.data.timesPlayedToday;
        if (func) {
          func && func();
        }
      } else {
        this.$toast(data.message);
      }
    },
    async getCountsS(func = null) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/queryLotteryTimes",
        data: {
          type: 3
        }
      });
      if (data.success) {
        this.counts_2 = data.data.remainTimes;
        this.dayCounts_2 = data.data.timesPlayedToday;
        if (func) {
          func && func();
        }
      } else {
        this.$toast(data.message);
      }
    },
    async getDrawCounts() {
      if (true) {
        await this.getCountsG();
      }
      if (true) {
        await this.getCountsS();
      }
    },
    async getDate() {
      //获取抽奖次数
      this.getDrawCounts();
      this.timeId = setInterval(() => {
        this.getDrawCounts();
      }, 1000);

      //累计金额
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/lottery/queryLotteryAmountProgress",
          data: { type: 4 }
        });
        if (data.success) {
          this.gapAmount = data.data.gapAmount;
          this.progressAmount = data.data.progressAmount;
          this.thresholdAmount = data.data.thresholdAmount;
          this.totalAmount = data.data.totalAmount;
          this.timesPlayed = data.data.timesPlayed;

          let width = (300 / 375) * document.documentElement.clientWidth;
          this.progressWidth =
            (this.progressAmount / this.thresholdAmount) * width > width
              ? width
              : (this.progressAmount / this.thresholdAmount) * width;
          console.log(data);
        } else {
          this.$toast(data.message);
        }
      }
      //中奖记录播报
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/prizeRecord/queryLimit",
          data: {
            typeList: [3, 4]
          }
        });
        if (data.success) {
          this.prizeTurnTableDTO = [];
          if (data.data && data.data.length > 0) {
            data.data.map((item, index) => {
              this.prizeTurnTableDTO.push(
                `恭喜${this.noPassByName(
                  item.extension.userName ? item.extension.userName : ""
                )}获得${item.turntableDTO.prizeName}`
              );
            });
          }
        } else {
          this.$toast(data.message);
        }
      }
    },
    noPassByName(str) {
      if (null != str && str != undefined) {
        if (str.length <= 3) {
          return "*" + str.substring(1, str.length);
        } else if (str.length > 3 && str.length <= 6) {
          return "**" + str.substring(2, str.length);
        } else if (str.length > 6) {
          return str.substring(0, 2) + "****" + str.substring(6, str.length);
        }
      } else {
        return "";
      }
    },
    stopClick() {},
    hasToken() {
      if (!this.$route.query.token || this.$route.query.token == "undefined") {
        this.$router.push("/downloadApp?timeStamp=" + Date.now());
        return false;
      }
      if (!this.canClick) return false;
      clearInterval(this.curIntervalId);
      return true;
    },
    toAddress(id) {
      this.$router.push(`/turnTableAddress?id=${id}&token=${this.token}&timeStamp=${Date.now()}`);
    },
    goPage(index) {
      if (this.token == "") {
        return;
      }
      var path = ["/mouseRules", "/mouseRecord"];
      this.$router.push(
        path[index] + "?token=" + this.token + "&type=" + this.type + "&timeStamp=" + Date.now()
      );
    },

    onShare(){
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: "/otherPackage/h5Game/share?type=mouse"
          });
        } else {
          
        }
      });
    },

    jumpToMain() {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  padding: 0px;
  max-width: 100vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-1 {
  height: 2340px;
  background: url(https://oss-kiddo.manqu88.com/mouse/bg.png) no-repeat;
  background-size: 100% 100%;
}

.share {
  position: absolute;
  top: 60px;
  right: 13px;
  width: 32px;
  height: 47px;
  background: url(https://oss-kiddo.manqu88.com/h5/share_mouse.png) no-repeat;
  background-size: 100% 100%;
}

.label {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0px;
  width: 80px;
  height: 30px;
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 17px 0 0 17px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}
.label-1 {
  position: absolute;
  top: 287px;
}
.label-2 {
  position: absolute;
  top: 328px;
}

.msgView {
  position: absolute;
  // margin-bottom: 18px;
  // margin-top: 237px;
  top: 209px;
  width: 309px;
  height: 56px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/msgBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .notice {
    width: 260px;
    height: 24px;
    position: absolute;
    bottom: 2px;
    left: 20px;
    font-size: 12px;
    color: #2d2d2d;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }
  /deep/ .van-notice-bar {
    background-color: rgba($color: #ffffff, $alpha: 0) !important;
  }
}
.harmerBG {
  position: absolute;
  width: 151px;
  height: 73px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/harmerBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  top: 287px;
  left: 13px;

  font-size: 12px;
  font-family: KeepCalm-Medium, KeepCalm;
  font-weight: 500;
  color: #ff4c1c;
  line-height: 22px;

  .ge {
    font-size: 12px;
  }

  .txt1 {
    display: flex;
    position: absolute;
    top: 4px;
    right: 8px;
    height: 22px;
    font-size: 15px;
    font-family: KeepCalm-Medium, KeepCalm;
    font-weight: 500;
    color: #ff4c1c;
    line-height: 22px;
    text-align: right;
  }

  .txt2 {
    display: flex;
    position: absolute;
    bottom: 6px;
    right: 8px;
    height: 22px;
    font-size: 15px;
    font-family: KeepCalm-Medium, KeepCalm;
    font-weight: 500;
    color: #ff4c1c;
    line-height: 22px;
    text-align: right;
  }
}
.chooseBg {
  position: absolute;
  width: 311px;
  height: 183px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/choose.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  top: 382px;
  .left {
    width: 155px;
    height: 183px;
  }
  .right {
    width: 155px;
    height: 183px;
  }
}
.leftMoney {
  display: flex;
  position: absolute;
  top: 852px;
  left: 168px;
  height: 14px;
  font-size: 14px;
  font-family: KeepCalm-Medium, KeepCalm;
  font-weight: 500;
  color: #ff4c1c;
  line-height: 14px;
  .left-yuan {
    font-size: 12px;
  }
}

.curMoney {
  display: flex;
  position: absolute;
  top: 949px;
  left: 131px;
  height: 14px;
  font-size: 14px;
  font-family: KeepCalm-Medium, KeepCalm;
  font-weight: 500;
  color: #ff4c1c;
  line-height: 14px;
  .curMoney-yuan {
    color: #000000;
    font-size: 12px;
  }
}
.drawed {
  display: flex;
  position: absolute;
  top: 949px;
  right: 34px;
  height: 20px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  text-align: right;
  .drawcounts {
    font-size: 14px;
    color: #ff4c1c;
  }
}

.progressMoney {
  position: absolute;
  top: 908px;
  right: 33px;
  height: 18px;
  font-size: 12px;
  font-family: KeepCalm-Medium, KeepCalm;
  font-weight: 500;
  color: #ff4c1c;
  line-height: 18px;
  text-align: right;
}

.progressBg {
  position: absolute;
  top: 891px;
  // margin-top: 6px;
  width: 310px;
  height: 17px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/progress_bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .progess-left {
    margin-left: 1.5px;
    width: 4px;
    height: 13px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/progress_left.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .progress {
    width: 18.5px;
    height: 13px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/progress_mid.png) repeat;
    background-size: 100% 100%;
  }
  .progess-right {
    width: 4px;
    height: 13px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/mouse/progress_right.png)
      no-repeat;
    background-size: 100% 100%;
  }
}
</style>
