<template>
  <div class="back">
    <div :class="['ck-wheels']">
      <div class="Q">{{ list[index1].subList[index2].Q }}</div>
      <div class="A">{{ list[index1].subList[index2].A }}</div>
    </div>
  </div>
</template>

<script>
import { formatList } from "@/utils/utils";
export default {
  name: "Help",
  components: {},
  created() {
    document.title = "问题详情";
  },
  data() {
    return {
      list: formatList(),
      index1: this.$route.query.index1,
      index2: this.$route.query.index2
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.back {
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ck-wheels {
    margin-top: 10px;
    width: 355px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .Q {
      margin-left: 11px;
      margin-top: 17px;
      width: 335px;
      margin-bottom: 8px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323cb0;
      line-height: 23px;
    }

    .A {
      margin-left: 11px;
      margin-bottom: 17px;
      width: 335px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
    }
  }
}
</style>
