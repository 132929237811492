<template>
  <div class="wrap">
    <img src="https://oss-kiddo.manqu88.com/activity/recruit/rule1.png" alt="" style="width:100%">
  </div>
</template>

<script>
export default {
  mounted(){
    document.title = '招募规则'
  }
}
</script>


<style scoped>
.wrap {
  width: 100vw;
  min-height: 100vh;
  background: rgb(217, 217, 217)
}
</style>