<template>
  <div class="back">
    <video
      id="myVideo"
      class="video"
      playsinline="true"
      webkit-playsinline="true"
      :src="src"
      preload="auto"
      :poster="'https://oss-kiddo.manqu88.com/klgd/video/videoPoster.png?1'"
      @onplay="onEvent"
      @oncanplaythrough="onEvent"
    ></video>
    <div class="btnBg" v-if="showBtn" @click="btn">
      <div class="btn"></div>
    </div>
  </div>
</template>

<script>
import { formatList } from "@/utils/utils";
export default {
  name: "Help",
  components: {},
  created() {
    document.title = "广告";
  },
  mounted() {
    document.getElementById("myVideo").addEventListener("play", () => {
      console.log("play");
      parent.postMessage("start", "*");
      this.showBtn = false;
    });

    // document.getElementById("myVideo").addEventListener("canplaythrough", () => {
    //   console.log("canplaythrough");
    //   this.showBtn = true;
    // });

    
  },
  destroyed() {
    console.log('destroyed');
    document.getElementById("myVideo").pause();
  },
  data() {
    return {
      list: formatList(),
      src: this.$route.query.src,
      showBtn: true
    };
  },
  methods: {
    onEvent(e) {
      console.log(e);
    },
    btn() {
      // this.showBtn = false;
      // parent.postMessage("start", "*");
      document.getElementById("myVideo").play();
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .video {
    width: 100vw;
    height: 100%;
    object-fit: scale-down;
  }

  .btnBg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 42px;
      height: 42px;
      background: url(https://oss-kiddo.manqu88.com/h5/btn/btn_play.png?11) no-repeat;
      background-size: 100% 100%;
    }
  }
  .ck-wheels {
    margin-top: 10px;
    width: 355px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .Q {
      margin-left: 11px;
      margin-top: 17px;
      width: 335px;
      margin-bottom: 8px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323cb0;
      line-height: 23px;
    }

    .A {
      margin-left: 11px;
      margin-bottom: 17px;
      width: 335px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
    }
  }
}
</style>
