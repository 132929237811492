<template>
  <div class="super_value_container">
    <div class="universal_head">
      <div class="universal_head_mask">
        <div class="universal_head_line"></div>
        <p class="universal_head_title">超享团销售业绩</p>
        <p v-show="isShowTip" class="universal_head_sub">（不包含本人销量业绩）</p>
      </div>
      <div class="universal_head_right">
        <img class="universal_head_right_eye" :src="isShowAllData
                    ? require('../../../assets/manage_home_eyeOpe.png')
                    : require('../../../assets/manage_home_eyeClo.png')
                  " @click="handleClickShowHide" alt="" />
        <van-tabs type="card" title-inactive-color="#666666" color="#323CB3" @click="onClickTab">
          <van-tab title="本人"></van-tab>
          <van-tab title="直属"></van-tab>
          <van-tab title="团队"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="universal_mask">
      <div class="universal_mask_left">
        <p v-for="(item, index) in salesDataToday" :key="index">
          {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                      }}</span>
        </p>
      </div>
      <div class="universal_mask_right">
        <p v-for="(item, index) in salesDataMonth" :key="index">
          {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                      }}</span>
        </p>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="universal_per">
      <p class="universal_per_title">本月超享团预估销售收益</p>
      <div class="universal_per_mask">
        <div class="universal_per_mask_left">
          <p v-for="(item, index) in superValuePreSaleData.leftList" :key="index">
            {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                          }}</span>
          </p>
        </div>
        <div class="universal_per_mask_right">
          <p v-for="(item, index) in superValuePreSaleData.rightList" :key="index">
            {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                          }}</span>
          </p>
        </div>
      </div>
    </div>
    <van-empty v-if="isShowEmpty" description="暂无数据" />
  </div>
</template>

<script>
export default {
  props: {
    token: {
      default: "",
      type: String,
      require: true
    },
    distUserId: {
      default: "",
      type: String,
      require: true
    }
  },
  data() {
    return {
      superValueSaleData: {},
      superValuePreSaleData: {},
      isShowEmpty: false,
      isShowAllData: true,
      isShowTip: false
    };
  },
  mounted() {
    if (this.token.length > 0) {
      const params = { saleType: 1, distUserId: this.distUserId, type: 0 };
      this.getSuperValueSaleData(params);
      this.getSuperValueMonthPreData();
    }
  },
  computed: {
    // 月数据
    salesDataMonth() {
      if (
        this.superValueSaleData.salesDataMonth &&
        this.superValueSaleData.salesDataMonth.length > 0
      ) {
        if (this.isShowAllData) {
          return [...this.superValueSaleData.salesDataMonth];
        } else {
          return [...this.superValueSaleData.salesDataMonth.filter(item => !item.needHide)];
        }
      } else {
        return [];
      }
    },
    // 今日数据
    salesDataToday() {
      if (
        this.superValueSaleData.salesDataToday &&
        this.superValueSaleData.salesDataToday.length > 0
      ) {
        if (this.isShowAllData) {
          return [...this.superValueSaleData.salesDataToday];
        } else {
          return [...this.superValueSaleData.salesDataToday.filter(item => !item.needHide)];
        }
      } else {
        return [];
      }
    }
  },
  methods: {
    // 获取超享团业绩
    async getSuperValueSaleData(params) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/salesPerformance",
        data: params
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.superValueSaleData = { ...data.data };
          this.isShowEmpty = false;
        } else {
          this.isShowEmpty = true;
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    async getSuperValueMonthPreData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/distGrouponPerformance",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.isShowEmpty = false;
          this.superValuePreSaleData = { ...data.data };
        } else {
          this.isShowEmpty = true;
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    handleClickShowHide() {
      this.isShowAllData = !this.isShowAllData;
    },
    onClickTab(index) {
      this.isShowTip = index === 2 ? true : false;
      let params = {};
      if (index === 0) {
        params = { saleType: 1, distUserId: this.distUserId, type: 0 };
      } else if (index === 1) {
        params = { saleType: 1, distUserId: this.distUserId, type: 3 };
      } else {
        params = { saleType: 1, distUserId: this.distUserId, type: 1 };
      }
      this.getSuperValueSaleData(params);
    }
  }
};
</script>

<style lang="scss" scoped>
.super_value_container {
  padding: 12px 12px;
  box-sizing: border-box;
  width: 100%;

  .universal_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .universal_head_mask {
      flex: 1;
      width: 0;
      display: flex;
      justify-content: left;
      align-items: center;

      .universal_head_line {
        width: 5px;
        height: 14px;
        background: #f9550a;
        margin-right: 5px;
      }

      .universal_head_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
      }

      .universal_head_sub {
        padding: 0;
        margin: 0;
        font-size: 10px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #949494;
      }
    }

    .universal_head_right {
      display: flex;
      justify-content: right;
      align-items: center;

      .universal_head_right_eye {
        height: 15px;
        margin-right: 6px;
      }

      /deep/ .van-tabs__wrap {
        height: 20px;
        border-radius: 2px;
        min-width: 100px;

        .van-tabs__nav--card {
          height: 20px;
          border: none;
          margin: 0;
          border-radius: 2px;

          .van-tab {
            border: none;

            .van-tab__text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .universal_mask {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
    border-radius: 4px;
    display: flex;
    margin-top: 10px;

    .universal_mask_left {
      width: calc(100% / 2);
      padding-left: 15px;

      p {
        margin: 8px 0px;
        padding: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        .money {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #666666;
        }

        .unit {
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .universal_mask_right {
      width: calc(100% / 2);
      padding-left: 15px;

      p {
        margin: 8px 0px;
        padding: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        .money {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #666666;
        }

        .unit {
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  .universal_per {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .universal_per_title {
      padding: 0;
      margin: 9px 13px 3px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #404040;
    }

    .universal_per_mask {
      width: 100%;
      display: flex;

      .universal_per_mask_left {
        width: calc(100% / 2);
        padding-left: 15px;

        p {
          margin: 8px 0px;
          padding: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;

          .money {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #666666;
          }

          .unit {
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }

      .universal_per_mask_right {
        width: calc(100% / 2);
        padding-left: 15px;

        p {
          margin: 8px 0px;
          padding: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;

          .money {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #666666;
          }

          .unit {
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
