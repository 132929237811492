<template>
  <div class="wrap">
    <div class="specBox">
      <div class="specItem" v-for="item in skuShowDTOS" :key="item.id">
        <div class="specTitle">{{ item.specificationName }}</div>
        <div class="specVal">
          <div
            class="item"
            :class="{ selected: selectedSku[item.specificationName] === ele }"
            v-for="ele in item.specificationValues"
            :key="ele"
            @click="selectSpec(item.specificationName, ele)"
          >
            {{ ele }}
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="descBox">
      <table>
        <tr>
          <th>收益类型</th>
          <th>场景</th>
          <th>金额</th>
        </tr>
        <tr v-if="gradeCode === 3">
          <td rowspan="3" class="rowspan">佣金</td>
          <td>会员下单</td>
          <td>
            ￥{{ commissionData.commonSell | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>
        <tr v-if="gradeCode === 3">
          <td>实习/店主/首席下单</td>
          <td>
            ￥{{ commissionData.leaderSell | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>
        <tr v-if="gradeCode === 3">
          <td>实习/店主/首席下级会员下单</td>
          <td>
            ￥{{ commissionData.leaderSell | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>

        <tr v-if="gradeCode !== 3">
          <td class="rowspan">佣金</td>
          <td>会员下单</td>
          <td>
            ￥{{ commissionData.commonSell | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td class="rowspan">自购立减</td>
          <td>自购</td>
          <td>
            ￥{{ commissionData.selfAmount | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>
      </table>

      <table>
        <tr v-for="(item, index) in commissionData.ladderAwardDTOS" :key="index">
          <td
            :rowspan="commissionData.ladderAwardDTOS.length"
            class="rowspan"
            v-if="index === 0"
          >
            超享团{{ gradeCode === 3 ? "阶梯" : "额外" }}奖励
            <br />
            <span>(需确认收货)</span>
          </td>
          <td>{{ item.ladderAwardDesc }}</td>
          <td>
            ￥{{ item.ladderAwardAmount | formatPrice }}
            <span v-if="!skuId" class="tip">起</span>
          </td>
        </tr>
      </table>
    </div>

    <div class="divider"></div>

    <div class="bottom">
      <div class="row">
        <div class="left">销售总件数</div>
        <div class="right">{{ commissionData.orderPieceNum }}</div>
      </div>
      <div class="row">
        <div class="left">已退款件数</div>
        <div class="right">{{ commissionData.refundPieceNum }}</div>
      </div>
      <div class="row">
        <div class="left">未确认件数</div>
        <div class="right">{{ commissionData.waitCompletePieceNum }}</div>
      </div>
      <div class="row">
        <div class="left">已确认件数</div>
        <div class="right">{{ commissionData.completePieceNum }}</div>
      </div>
    </div>

    <div class="tip">
      注：当前商品全部规格销售件数都会计入超享团额外奖励门槛计算，奖励金额会在订单确认收货时逐笔发放至余额账户
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "../utils/money";

export default {
  data() {
    return {
      itemId: null,
      skuId: null,
      skuDTOS: [],
      skuShowDTOS: [],
      selectedSku: {},
      commissionData: {
        selfAmount: 0,
        leaderSell: 0,
        commonSell: 0,
        ladderAwardDTOS: [],
        completePieceNum: 0,
        orderPieceNum: 0,
        refundPieceNum: 0,
        waitCompletePieceNum: 0,
      },
      token: "",
      gradeCode: 3, // KA 3, KB 2
    };
  },
  filters: {
    formatPrice,
  },
  mounted() {
    document.title = "收益说明";
    this.itemId = this.$route.query.itemId;
    this.templateId = this.$route.query.templateId;

    setTimeout(async () => {
      uni.webView.getEnv(async (res) => {
        console.log("当前环境：", res, JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          this.token = this.$route.query.token;
          this.queryUserLevel();
          this.queryItemDetail();
        } else {
          this.$bridge
            .call("getToken", {})
            .then((e) => {
              console.log("getToken客户端返回: ", e, JSON.stringify(e));
              this.token = e;
              if (!e || JSON.stringify(e) == "{}") {
                this.$bridge.call("openPage", { type: 26 });
                return;
              }
              this.queryUserLevel();
              this.queryItemDetail();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }, 500);

    // this.token = this.$route.query.token;
    // this.queryUserLevel();
    // this.queryItemDetail();
  },

  methods: {
    // 查询会员等级
    async queryUserLevel() {
      const {
        data: { data, success, message },
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          // clientSource: 2,
          version: "mini_1.2.4",
          Authorization: this.token,
        },
        url: "/api/member/queryMemberByUserId",
      });
      if (success) {
        this.gradeCode = data.gradeCode;
      } else {
        this.$toast(message);
      }
    },

    // 查询商品详情规格信息
    async queryItemDetail() {
      const {
        data: { data, success, message },
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          // clientSource: 2,
          version: "mini_1.2.4",
          Authorization: this.token,
        },
        url: "/api/item/queryItemById",
        data: {
          id: this.itemId,
        },
      });
      if (success) {
        console.log("商品数据---", data);
        this.skuDTOS = [...data.skuDTOS];
        this.skuShowDTOS = [...data.skuShowDTOS];
        if (this.skuDTOS.length) {
          this.selectedSku = JSON.parse(this.skuDTOS[0].specification);
          this.skuId = this.skuDTOS[0].id;
          this.$forceUpdate();
          this.queryCommissionDetail();
        }
      } else {
        this.$toast(message);
      }
    },

    /**
     * 选择规格
     * param1 规格名
     * param2 规格值
     */
    selectSpec(specName, specVal) {
      const { skuDTOS } = this;
      if (this.selectedSku[specName] === specVal) {
        // delete this.selectedSku[specName];
        return;
      } else {
        this.selectedSku[specName] = specVal;
      }
      this.$forceUpdate();
      const currentSku = skuDTOS.filter(
        (item) => item.specification === JSON.stringify(this.selectedSku)
      );
      if (currentSku.length) {
        this.skuId = currentSku[0].id;
      } else {
        this.skuId = null;
      }
      console.log("已选数据---", this.selectedSku, this.skuId);

      // 防抖
      let timer;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        this.queryCommissionDetail();
      }, 300);
    },

    // 查询佣金数据
    async queryCommissionDetail() {
      const {
        data: { data, success, message },
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          // clientSource: 2,
          version: "mini_1.2.4",
          Authorization: this.token,
        },
        url: "api/distGrouponItem/queryCommissionDetail",
        data: {
          itemId: this.itemId,
          skuId: this.skuId,
          templateId: this.templateId, //类型：Number  必有字段  备注：超值团购活动id
        },
      });
      if (success) {
        console.log("佣金数据---", data);
        this.commissionData = {
          ...data,
        };
      } else {
        this.$toast(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 375px;
  background: #ffffff;

  .specBox {
    width: 100%;
    padding: 20px 10px 7px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .specItem {
      .specTitle {
        margin-bottom: 12px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        line-height: 20px;
      }

      .specVal {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-right: 10px;
          padding: 5px 25px;
          border-radius: 4px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646464;
          margin-bottom: 15px;
          background: #f7f7f7;
          border: 1px solid #f7f7f7;

          &.selected {
            background: #ffffff;
            border: 1px solid #141414;
          }
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 5px;
    background: #f7f7f7;
  }

  .descBox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    table {
      width: 100%;
      margin-bottom: 32px;
      border-collapse: collapse;

      tr {
        width: 100%;

        th {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #121212;
          padding-bottom: 15px;
          text-align: center;

          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 50%;
          }
          &:nth-child(3) {
            width: 25%;
          }
        }

        td {
          margin: 0;
          padding: 0;
          height: 30px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #121212;
          line-height: 30px;
          text-align: center;
          border: 1px solid #f7f7f7;

          &.rowspan {
            font-weight: bold;

            span {
              font-size: 10px;
              font-weight: normal;
            }
          }

          .tip {
            font-size: 9px;
          }

          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 50%;
          }
          &:nth-child(3) {
            width: 25%;
          }
        }
      }
    }
  }

  .bottom {
    background: #ffffff;
    padding: 0 22px;
    box-sizing: border-box;
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }

    .left {
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 18px;
    }

    .right {
      height: 16px;
      font-size: 13px;
      font-family: JDZhengHT-Regular, JDZhengHT;
      font-weight: 400;
      color: #130000;
      line-height: 16px;
    }
  }

  .tip {
    padding: 0 22px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    margin: 17px 0;
    height: 54px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
}
</style>
