<template>
    <div class="main">
        <div class="task-one" @click="clickTaskOne">
            <img v-if="auditStatus != null" style="height:29px;"
                :src="`https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/task1-btn${Number(auditStatus) + 1}.png`" />
        </div>
        <div class="task-two">
            <img @click="clickInvite"
                src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/task2-invite.png" />
            <div>
                <span>我的邀请首席人数：</span>
                <span class="two-number">{{ inviteKaNum }}</span>
            </div>
        </div>
        <rewardProgress class="task-two-progress" pageType="1" :rate="inviteKaNum">
        </rewardProgress>
        <div class="task-three-btn" @click="clickShare">
            <img style="height:29px;" src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/task3-share.png" />
        </div>
        <rewardProgress class="task-three-progress" pageType="2" :rate="saleAmount">
        </rewardProgress>
        <div class="task-three-data">
            <div class="three-data-align">
                <span>预估销售业绩：</span>
                <span>{{ formatPrice(saleAmount) }}</span>
            </div>
            <div class="three-data-align">
                <span>已确认销售业绩：</span>
                <span>{{ formatPrice(confirmAmount) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import rewardProgress from "@/views/recruit/components/progress.vue";
import { formatPrice } from "@/utils/money";

export default {
    components: { rewardProgress },
    data() {
        return {
            token: this.$route.query.token,
            auditStatus: null,    // 审核进度  0待提交 1待审核 2审核通过 3审核拒绝
            inviteKaNum: 0,     // 邀请人数
            saleAmount: 0,      // 预估销售业绩
            confirmAmount: 0,   // 已完成销售业绩
            isSupportKa: null, // 是否活动用户
            isShowKaToast: true,    // 是否toast提示是否活动用户
            timer: null,
        }
    },
    created() {
        document.title = "新首席扶持计划";
    },
    mounted() {
        var imgUrl = `https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/task1-btn${Number(this.auditStatus) + 1}.png`;
        console.log('---czm=--', imgUrl, this.auditStatus, Number(this.auditStatus));
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                    this.getData();
                } else {
                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        this.canClick = true;
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                        this.getData();
                    });
                }
            });
        }, 1000);
        // this.timer = setInterval(() => {
        //     this.getData();
        // }, 5000)
    },
    destroyed() {
        // clearInterval(this.timer);
    },
    methods: {
        formatPrice,
        async getData() {
            const {
                data: { data, success, message, code },
            } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token,
                },
                url: "/api/dist/recruitPracticeKa/querySupportStatistics",
                data: {},
            });
            if (success) {
                this.auditStatus = data.auditStatus;
                this.inviteKaNum = data.inviteKaNum;
                this.saleAmount = data.saleAmount;
                this.confirmAmount = data.confirmAmount;
                this.isSupportKa = data.isSupportKa;
                if (!this.isSupportKa && this.isShowKaToast) {
                    this.$toast('该活动仅限新首席参与');
                    this.isShowKaToast = false;
                }
            } else {
                // this.$toast(message);
            }
        },
        clickTaskOne() {
            console.log('----czm---点击填写资料');
            if (!this.isSupportKa) {
                this.$toast('该活动仅限新首席参与');
                return;
            }
            // 0待提交 1待审核 2审核通过 3审核拒绝
            if (this.auditStatus == 0 || this.auditStatus == 3) {
                uni.webView.getEnv((res) => {
                    // console.log("当前环境：" + JSON.stringify(res), browser().versions);
                    if (res.miniprogram) {
                        uni.webView.navigateTo({
                            url: "/otherPackage/recruit/writeInfo",
                        });
                    } else {
                        // this.$bridge.call("jumpToWriteInfo", { id: id }).then((e) => {
                        //     console.log("jumpToDetail客户端返回: ", e);
                        // });
                        this.$router.push(
                            `/recruit/writeInfo??token=${this.token}&timeStamp=${Date.now()}`
                        );
                    }
                });
            } else if (this.auditStatus == 1) {
                this.$toast('正在审核中，预计需要1个工作日，请耐性等待~');
            } else if (this.auditStatus == 2) {
                this.$toast('恭喜您，审核已通过，奖励已发放至您的账户，可至Addol APP-可提现余额查看');
            } else {

            }
        },
        clickInvite() {
            if (!this.isSupportKa) {
                this.$toast('该活动仅限新首席参与');
                return;
            }
            // console.log('----czm---点击立即邀请显示分享海报');
            uni.webView.getEnv((res) => {
                // console.log("当前环境：" + JSON.stringify(res), browser().versions);
                if (res.miniprogram) {
                    uni.webView.navigateTo({
                        url: "/otherPackage/recruit/share",
                    });
                } else {
                    this.$bridge.call("clickInvite", {}).then((e) => {
                        console.log("jumpToDetail客户端返回: ", e);
                    });
                }
            });
        },
        clickShare() {
            if (!this.isSupportKa) {
                this.$toast('该活动仅限新首席参与');
                return;
            }
            console.log('----czm---点击去分享调整到装修页');
            uni.webView.getEnv(res => {
                // console.log("当前环境：" + JSON.stringify(res));
                let id = 2760;
                if (res.miniprogram) {
                    uni.webView.navigateTo({
                        url: `/pages/index/sub-page?id=${id}`
                    });
                } else {
                    this.$bridge
                        .call("openPage", {
                            //跳转任意app页面
                            type: 0,
                            param: id
                        })
                        .then(e => {
                            console.log("openPage" + "客户端返回: ", e);
                        });
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: 1687px;
    background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/recruit_bg.png") no-repeat;
    background-size: 100% 100%;
}

.task-one {
    max-width: 140px;
    height: 29px;
    margin-top: 471px;
    margin-left: 26px;
}

.task-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 126px 26px;
    margin-bottom: 0;
    align-self: stretch;

    img:nth-child(1) {
        height: 29px;
    }

    div:nth-child(2) {
        display: flex;
        height: 22px;

        span:first-child {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #B80A04;
            text-align: right;
            line-height: 22px;
            height: 22px;
        }

        .two-number {
            font-size: 16px;
            height: 17px;
            line-height: 19px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #B80A04;
            text-align: right;
            border-bottom: 3px solid #B80A04;
        }
    }
}

.task-two-progress {
    align-self: stretch;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;

}

.task-three-btn {
    max-width: 140px;
    height: 29px;
    margin-top: 173px;
    margin-left: 26px;
}

.task-three-progress {
    align-self: stretch;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;

}

.task-three-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    align-self: stretch;
    margin-left: 27px;
    margin-right: 27px;
    height: 40px;

    .three-data-align {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;

        span:nth-child(1) {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #B80A04;
        }

        span:nth-child(2) {
            height: 28px;
            line-height: 22px;
            font-size: 19px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #B80A04;
            border-bottom: 3px solid #B80A04;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}
</style>