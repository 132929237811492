<template>
  <div class="ck-wheel">
    <!-- <div class="updateTime">
      <div class="icon"></div>
      <div class="time">列表数据更新于 2023-3-8 18:00</div>
    </div> -->

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="noList" v-if="list.length === 0"></div>
      <div class="card" v-for="(item, index) in list" :key="index" @click="itemDetail(item.cardNo)">
        <div class="title">
          <div>{{ item.templateName }}</div>
          <div class="arrow"></div>
        </div>

        <div class="cardNo">尊享卡卡号：{{ item.cardNo }}</div>

        <div class="msg-line">
          <div class="msg" style="width: 55%;">
            <!-- <div class="msg-title">购买用户</div>
            <div class="msg-des">{{ item.buyerUserName }}</div> -->
            <div class="msg-title">用户手机号</div>
            <div class="msg-des">
              {{ item.buyerMobile && item.buyerMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}
            </div>
          </div>
          <div class="msg" style="width: 45%;">
            <div class="msg-title">已提货数量</div>
            <div class="msg-des">{{ dataVisible ? item.alreadyTakeNum : "*****" }}</div>
          </div>
        </div>

        <div class="msg-line">
          <div class="msg" style="width: 55%;">
            <div class="msg-title">预计佣金收益</div>
            <div class="msg-des">
              {{ dataVisible ? formatPrice(item.predictCommission) : "*****" }}元
            </div>
          </div>
          <div class="msg" style="width: 45%;">
            <div class="msg-title">已解锁佣金</div>
            <div class="msg-des">
              {{ dataVisible ? formatPrice(item.settledCommission) : "*****" }}元
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <div :class="['data-fixed', dataVisible ? 'data-show' : 'data-hide']" @click="dataVisible = !dataVisible"></div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      buyerId: this.$route.query.buyerId,
      list: [],
      loading: false,
      finished: false,
      currentPage: 1,
      canLoad: false,
      dataVisible: true
    };
  },
  created() {
    document.title = "佣金明细";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetail();
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    async getDetail() {
      console.log("currentPage", this.currentPage);

      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardStatisticsList",
          data: {
            buyerId: this.buyerId || "",
            currentPage: this.currentPage,
            pageSize: 10
          }
        });

        // 加载状态结束
        this.loading = false;
        this.canLoad = true;

        if (data.success) {
          if (data.data.length === 0) {
            this.finished = true;
          }
          this.list = [...this.list, ...data.data];
        } else {
          this.$toast(data.message);
        }
      }
    },

    async onLoad() {
      if (!this.canLoad) {
        return;
      }
      this.currentPage++;
      this.getDetail();
    },

    itemDetail(cardNo) {
      uni.webView.getEnv(res => {
        if (
          res.miniprogram ||
          res.h5
        ) {
          this.$router.push(`/addol/exclusiveCardDetail?token=${this.token}&cardNo=${cardNo}`);
        } else {
          this.$bridge
            .call("openPage", {
              type: 48,
              param: cardNo
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;

  .data-fixed {
    position: fixed;
    top: 58px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .data-show {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/show.png?") no-repeat;
    background-size: 100% 100%;
  }

  .data-hide {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/hide.png?") no-repeat;
    background-size: 100% 100%;
  }

  .updateTime {
    margin-top: 15px;
    margin-bottom: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 13px;
      height: 13px;
      background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/icon.png?") no-repeat;
      background-size: 100% 100%;
      margin-right: 7px;
    }

    .time {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }

  .noList {
    margin: auto;
    margin-top: 53px;
    width: 100px;
    height: 133px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
    background-size: 100% 100%;
  }

  .card {
    margin-top: 10px;
    width: 355px;
    height: 115px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 13px;
      width: 329px;
      height: 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;

      .arrow {
        width: 6px;
        height: 11px;
        background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/arrow.png?") no-repeat;
        background-size: 100% 100%;
      }
    }

    .cardNo {
      margin-top: 3px;
      width: 329px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      margin-bottom: 12px;
    }

    .msg-line {
      margin-bottom: 6px;
      width: 329px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;

      .msg {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;

        .msg-title {
          font-weight: 400;
        }

        .msg-des {
          margin-left: 6px;
          font-weight: 500;
          max-width: 95px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
</style>
