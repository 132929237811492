import moment from 'moment';

export const PrefixInteger = (num, n) => {
  return (Array(n).join(0) + num).slice(-n);
}

export const countDown = (now, end, format) => {
  const duration = moment.duration(end - now, 'ms');
  const days = duration.get('day');
  const hours = duration.get('hours');
  const mins = duration.get('minutes');
  const ss = duration.get('seconds');
  if (format === 'day') {
    if( days <= 0 && hours <= 0 && mins <=0 && ss <= 0 ) {
      return "0天 00:00:00"
    } else {
      return days + '天 ' + PrefixInteger(hours, 2) + ':' + PrefixInteger(mins, 2) + ':' + PrefixInteger(ss, 2)
    }
  } else {
    if( hours <= 0 && mins <=0 && ss <= 0 ) {
      return "00:00:00"
    } else {
      return PrefixInteger(hours, 2) + ':' + PrefixInteger(mins, 2) + ':' + PrefixInteger(ss, 2)
    }
  }
}