<template>
  <div class="ck-wheel">
    <div class="block">
      <div class="back" v-if="showBack" @click="goBack"></div>
      <div class="title">
        <div class="dot"></div>
        本期考核截止时间：
      </div>
      <div class="detail">
        {{ datas.deadlineTime && moment(datas.deadlineTime).format("YYYY.MM.DD HH:mm:ss") }}
      </div>

      <div class="title">
        <div class="dot"></div>
        本期考核指标：
      </div>
      <div class="detail">
        已确认收货销售超享团订单实付总额：{{ formatPrice(datas.leaderAssessThreshold) }}元
      </div>

      <div class="title">
        <div class="dot"></div>
        当前完成情况：
      </div>
      <div class="detail">
        已确认收货销售超享团订单实付总额：{{ formatPrice(datas.confirmTotalAmount) }}元
      </div>
      <div class="detail">
        未确认收货销售超享团订单实付总额：{{ formatPrice(datas.notConfirmTotalAmount) }}元
      </div>

      <div class="title">
        <div class="dot"></div>
        实习推荐官考核规则说明：
      </div>
      <div class="detail">
        会员通过此链接可成为实习推荐官，成为实习推荐官后每30个自然日为保级考核期，保级考核期内销售/自购超享团商品已完成订单总金额≥2500元即考核成功，实习推荐官身份保留，若不满足则保级考核失败失去实习推荐官资格；
      </div>
      <div class="detail">
        实习推荐官最多可申请成功3次，已升级推荐官/首席推荐官用户无法再次升级；
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: "",
      datas: {
        showBack: false,
        deadlineTime: null,
        leaderAssessThreshold: 0,
        confirmTotalAmount: 0,
        notConfirmTotalAmount: 0
      }
    };
  },
  created() {
    document.title = "实习推荐官考核";
    this.showBack = this.$route.query.showBack ? true : false;
  },
  mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    goBack() {
      this.$router.go(-1);
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/distGrouponLeader/detail",
        data: {}
      });
      if (data.success) {
        this.datas = data.data;
      } else {
        this.$toast(data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  position: relative;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  padding-top: 8px;

  .back {
    position: relative;
    left: -10px;
    margin-top: 38px;
    width: 110px;
    height: 30px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/btn_backToMember.png?11")
      no-repeat;
    background-size: 100% 100%;
  }
  .block {
    width: 342px;
    height: auto;
    .title {
      margin-top: 19px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 20px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 45px;
        background: #323cb0;
        margin-right: 6px;
      }
    }
    .detail {
      margin-left: 15px;
      margin-top: 5px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      letter-spacing: 1px;
      word-break: break-all;
    }
  }
}
</style>
