<template>
	<div>
		<img class="kiddol-rule-bg" :src="'https://kiddo-bucket.manqu88.com/kiddol-rank-rule-2.jpg?timeStamp=' + timeStamp" alt="">
	</div>
</template>

<script>
	export default {
		data() {
			return {
				timeStamp: 0
			}
		},
		mounted() {
			this.timeStamp = new Date().getTime();
			console.log(this.timeStamp)
			setTimeout(() => {
				document.title = '活动规则';
			}, 500)
		}
	}
</script>

<style lang="scss" scoped >
	.kiddol-rule-bg{
		display: block;
		width: 100%;
	}
</style>