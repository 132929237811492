<template>
  <div class="ck-wheel">
    <div :class="['background-1']"></div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "@/utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {};
  },
  async created() {
    document.title = "活动规则";
  },
  destroyed() {},
  mounted() {},
  methods: {
    formatPrice
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  padding: 0px;
  max-width: 100vw;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-1 {
  width: 375px;
  height: 2322px;
  background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/chenckInRules.png?1) no-repeat;
  background-size: 100% 100%;
}
</style>
