<template>
  <div class="wrap">
    <div class="card">
      <div class="title">收益类型说明</div>
      <div class="detail">
        平台收益类型共分为三大类：佣金、K金、团队服务费；
      </div>
      <div class="detail">
        ● 佣金<br />
        包含客户订单的佣金收益；
      </div>
      <div class="detail">
        ● K金<br />
        包含客户订单的K金收益和邀请会员奖励；
      </div>
      <div class="detail">
        ● 团队服务费<br />
        指平台对KA会员所进行的服务费奖励；
      </div>

      <div class="title">收益统计</div>
      <div class="detail">
        收益统计中收益数据包含了已结算收益和未结算收益，故收益都为
        预估数值，会根据订单、客户等实际情况的变化而变化；
      </div>
      <div class="detail">
        ● 上月、本月、今日预估佣金<br />
        指定时间内支付成功订单可得佣金总和（会扣除退款金额）；
      </div>
      <div class="detail">
        ● 上月、本月、今日预估K金<br />
        指定时间内支付成功订单可得K金和邀请会员奖励之和（会扣除退款金额）；
      </div>

      <div class="title">销售业绩</div>
      <div class="detail">
        销售业绩中今日数据为今日订单状态变化所产生的交易数据，本月数据为本月支付订单状态变化所产生的交易数据；
      </div>
      <div class="detail">
        ● 销量<br />
        指定时间内支付订单包含商品件数总和；
      </div>
      <div class="detail">
        ● 退款件数<br />
        指定时间内退款成功订单包含商品件数总和；
      </div>
      <div class="detail">
        ● 销售额<br />
        指定时间内支付成功订单的支付金额总和；
      </div>
      <div class="detail">
        ● 退款<br />
        指定时间内退款成功订单的退款金额总和；
      </div>
      <div class="detail">
        ● 确认总额<br />
        指定时间内确认收货完成订单的支付金额总和（已扣除退款金额）；
      </div>

      <div class="title">用户概况</div>
      <div class="detail">
        用户概况中数据分为3个部分：意向用户、直邀会员和裂变会员；
      </div>
      <div class="detail">
        ● 意向用户<br />
        意向用户为绑定你邀请码且未消费满¥168的用户，分为已下单和未下单两个部分，根据用户是否有过下单记录进行区分；
      </div>
      <div class="detail">
        ● 直邀会员<br />
        指绑定你邀请码且消费满¥168的KC、KB、KA会员；
      </div>
      <div class="detail">
        ● 裂变会员<br />
        指你邀请的会员再邀请会员所裂变出来的会员用户；
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { formatPrice } from "../utils/money";

export default {
  name: "illustrate",
  components: {},

  data() {
    return {};
  },
  created() {
    document.title = "经营数据说明";
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 12px;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 0px auto;
  width: 355px;
  height: auto;
  background: #ffffff;
}
.title {
  margin: 14px auto;
  margin-bottom: -9px;
  width: 326px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 21px;
}
.detail {
  margin: 14px auto;
  width: 326px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
</style>
