<template>
  <div v-if="isRequest">
    <div class="wrap" v-if="!isJoin">
      <div class="rule" @click="toRule">规则</div>
      <div class="top"></div>
      <van-form validate-trigger="onSubmit" @submit="onSubmit" class="center">
        <div class="field">
          <van-field
            v-model="inviteCode"
            placeholder="请输入邀请人邀请码"
            maxlength="8"
            :border="false"
            :error="false"
            :rules="[
              {
                required: true,
                message: '请输入邀请人邀请码',
              },
              {
                validator,
                message: '请输入8位邀请人邀请码',
              },
            ]"
            @input="changeInviteCode"
          >
            <template #button>
              <div v-if="validator(inviteCode) && inviteInfo && inviteInfo.headUrl" class="parentInfo">
                <img :src="inviteInfo.headUrl" class="head" />
                <div class="nick">{{ inviteInfo.nick }}</div>
              </div>
              <!-- <van-button
                size="small"
                type="primary"
                class="confirm"
                :disabled="!validator(inviteCode)"
                @click="confirmInviteCode"
                native-type="button"
              >确认</van-button> -->
            </template>
          </van-field>
          <div class="field_tip">邀请码可咨询邀请人获取</div>
          <van-field
            v-model="distToken"
            placeholder="请输入15位平移口令"
            maxlength="15"
            :border="false"
            :error="false"
            :rules="[
              {
                required: true,
                message: '请输入15位平移口令',
              },
              {
                validator: validatorDistToken,
                message: '请输入15位平移口令',
              },
            ]"
          />
          <div class="field_tip">平移口令可请邀请人咨询官方团长获取</div>
          <van-field
            v-if="isBrowser"
            v-model="mobile"
            type="tel"
            label="+86"
            placeholder="请输入手机号"
            maxlength="11"
            :border="false"
            :error="false"
            :rules="[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                validator: validatorMobile,
                message: '请输入11位正确的手机号',
              },
            ]"
          />
          <van-field
            v-if="isBrowser"
            v-model="verifyCode"
            type="number"
            placeholder="请输入验证码"
            maxlength="6"
            :border="false"
            :error="false"
            :rules="[
              {
                required: true,
                message: '请输入验证码',
              },
              {
                validator: validatorVerifyCode,
                message: '请输入6位正确的验证码',
              },
            ]"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                :disabled="!validatorMobile(mobile) || count !== 0"
                native-type="button"
                @click="getVerifyCode"
              >
                {{ count === 0 ? '获取验证码' : `重新获取${count}s` }}
              </van-button>
            </template>
          </van-field>
        </div>
        <van-checkbox v-model="checked" class="checked" v-if="isBrowser">
          未注册的手机号验证后将自己创建kiddol账号<br/>
          登陆即代表您已同意
          <a href="https://static.manqu88.com/kiddo-privacy.html">《隐私政策》</a>和
          <a href="https://static.manqu88.com/addol-sale-agreement.html">《推广服务协议》</a>
        </van-checkbox>
        <van-button round block type="info" native-type="submit" class="submit">{{ isBrowser ? '注册并加入' : '立即加入'}}</van-button>
      </van-form>
      <div v-if="showInviteBox" class="mask" @click="showInviteBox = false"></div>
      <div v-if="showInviteBox" class="inviteInfoBox">
        <div class="info">
          <div class="title">邀请人</div>
          <img :src="inviteInfo.headUrl" class="head" />
          <div class="nick">{{ inviteInfo.nick }}</div>
        </div>
        <van-icon name="close" class="icon" @click="showInviteBox = false" />
      </div>
      <div class="bottom"></div>
    </div>

    <div class="wrap_result" v-if="isJoin && activityData.status === 1">
      <div class="rule" @click="toRule">规则</div>
      <div class="time">到期时间 {{ selfInfo.deadlineTime | formatDate }}</div>
      <div class="header">您的专属邀请码</div>
      <div class="inviteCode">{{ selfInfo.selfInviteCode }}</div>
      <div class="copy" @click="copy(selfInfo.selfInviteCode)"></div>
      <div class="tip" v-if="isBrowser">活动后续进度请到APP/小程序对应活动页查看</div>
      <div class="box" v-if="!isBrowser">
        <div class="title">邀请会员</div>
        <div class="list">
          <div class="item">
            <div class="data">{{ activityData.inviteForeverNum }}</div>
            <div class="name">已达标永久会员</div>
          </div>
          <div class="item">
            <div class="data">{{ activityData.inviteNum }}</div>
            <div class="name">已邀请会员</div>
          </div>
          <div class="item">
            <div class="data">{{ activityData.needInviteForeverNum }}</div>
            <div class="name">需达标永久会员</div>
          </div>
        </div>
      </div>
      <div class="box box1" v-if="!isBrowser">
        <div class="title">团队购买总额</div>
        <div class="tip">数据每日0点～2点更新</div>
        <div class="list">
          <div class="item">
            <div class="data money"><span>￥</span>{{ activityData.teamFinishAmount | formatPrice }}</div>
            <!-- <div class="data money"><span>￥</span>30000.00</div> -->
            <div class="name">已收货总额</div>
          </div>
          <div class="item">
            <div class="data money"><span>￥</span>{{ activityData.teamSaleAmount | formatPrice }}</div>
            <!-- <div class="data money"><span>￥</span>30000.00</div> -->
            <div class="name">下单总额</div>
          </div>
          <div class="item">
            <div class="data money"><span>￥</span>{{ activityData.needTeamFinishAmount | formatPrice }}</div>
            <!-- <div class="data money"><span>￥</span>30000.00</div> -->
            <div class="name">需达标总额</div>
          </div>
        </div>
      </div>
      <div class="downloadBtn" @click="downloadKiddol" v-if="isBrowser">下载Kiddol</div>
      <div class="downloadBtn margin" @click="downloadAddol" v-if="isBrowser">下载Addol</div>
    </div>

    <div class="wrap_success" v-if="isJoin && activityData.status === 2">
      <div class="h1">
        <span>恭喜</span>
        <span class="nickName">{{ activityData.nickName }}</span>
        <span>通过考核</span>
        <br/>
        成为[KA]用户
      </div>
    </div>

    <div class="wrap_fail" v-if="isJoin && activityData.status === 3">
      <div class="reJoin" @click="reJoin">重新参与</div>
    </div>

    <div v-if="showConfirmModal" class="confirmBox">
      <div class="mask"></div>
      <div class="main">
        <div class="btn" @click.stop="onConfirmModal"></div>
      </div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "../utils/money";
import { downloadIamge } from "../utils/saveImg";
import { formatDate } from "../utils/time";

export default {
  name: "eliteRecruit",
  components: {},
  data() {
    return {
      inviteCode: "",
      distToken: "",
      mobile: "",
      verifyCode: "",
      checked: false,
      inviteInfo: {
        nick: '',
        headUrl: '',
      },
      showInviteBox: false,
      count: 0,
      isJoin: false,
      selfInfo: {
        deadlineTime: '',
        selfInviteCode: '',
        isAlreadyBind: 0,
      },
      activityData: {
        isJoin: 0,
        status: 1,
        inviteForeverNum: 0,
        inviteNum: 0,
        needInviteForeverNum: 0,
        teamFinishAmount: 0,
        teamSaleAmount: 0,
        needTeamFinishAmount: 0,
      },
      isBrowser: false,
      token: '',
      isRequest: false,
      showConfirmModal: false,
    };
  },
  filters: {
    formatPrice,
    formatDate
  },
  mounted() {
    document.title = '精英招募令';
    setTimeout(async () => {
      uni.webView.getEnv(async (res) => {
        console.log("当前环境1111111111：", res, JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          this.isBrowser = false;
          this.token = this.$route.query.token;
          this.getActivityData();
          this.getSuperInfo();
        } else {
          if (browser().versions.isKiddolClient || browser().versions.isAddolClient) {
            this.$bridge.call("getToken", {}).then((e) => {
              console.log("getToken客户端返回: ", e, JSON.stringify(e));
              this.token = e;
              this.isBrowser = false;
              if (!e || JSON.stringify(e) == '{}') {
                this.$bridge.call("openPage", { type: 26 });
                return;
              }
              this.getActivityData();
              this.getSuperInfo();
            }).catch((error) => {
              console.log(error);
            });
          } else {
            this.isBrowser = true;
          }
        }
      });
      this.isRequest = true;
    }, 500);
  },
  methods: {
    downloadIamge,
    validator(val) {
      return val.length === 8;
    },
    validatorDistToken(val) {
      return val.length === 15;
    },
    validatorMobile(val) {
      return /^1\d{10}/.test(val);
    },
    validatorVerifyCode(val) {
      return /\d{6}/.test(val);
    },
    async getSuperInfo() {
      const { data: { data, success } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/user/querySuperUserInfo",
      });
      if (success && data && data.nickName) {
        this.inviteCode = data.invitationCode;
        this.inviteInfo = {
          nick: data.nickName.substr(0, 10),
          headUrl: data.headPicUrl
        }
      } else {
        this.inviteCode = "";
        this.inviteInfo = {
          nick: '',
          headUrl: '',
        }
      }
    },
    // 点击确定获取邀请码信息
    // async confirmInviteCode() {
    //   const { data: { data, success, message } } = await this.$axios({
    //     method: "post",
    //     headers: {
    //       mac: "mac",
    //       clientSource: this.$clientSource(),
    //       Authorization: this.token,
    //     },
    //     url: "/api/dist/user/getDistUserByCode",
    //     data: {
    //       code: this.inviteCode
    //     }
    //   });
    //   if (success) {
    //     if (data.gradeCode === -1) {
    //       this.$toast('邀请码不存在');
    //       return false;
    //     }
    //     this.inviteInfo = {
    //       nick: data.nickName,
    //       headUrl: data.headPicUrl,
    //     }
    //     this.showInviteBox = true;
    //   } else {
    //     this.$toast(message);
    //   }
    // },
    // 输入8位邀请码后查信息
    async changeInviteCode() {
      if (!this.inviteCode || this.inviteCode.length !== 8) {
        this.inviteInfo = {
          nick: '',
          headUrl: '',
        }
        return false;
      }
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/user/getDistUserByCode",
        data: {
          code: this.inviteCode
        }
      });
      if (success) {
        // if (data.gradeCode === -1) {
        //   this.$toast('邀请码不存在');
        //   this.inviteInfo = {
        //     nick: '',
        //     headUrl: '',
        //   }
        //   return false;
        // }
        this.inviteInfo = {
          nick: data.nickName.substr(0, 10),
          headUrl: data.headPicUrl,
        }
      } else {
        this.$toast(message);
      }
    },
    // 获取验证码
    async getVerifyCode() {
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/user/verify/sendLoginVerifyCode",
        data: {
          mobile: this.mobile
        }
      });
      if (success) {
        this.count = 59;
        const timer = setInterval(() => {
          if (this.count === 1) {
            clearInterval(timer);
          }
          this.count--;
        }, 1000);
      } else {
        this.$toast(message);
      }
    },
    // 提交
    async onSubmit() {
      if (!this.checked && this.isBrowser) {
        this.$toast('请勾选协议');
        return;
      }
      const { inviteCode, distToken, mobile, verifyCode } = this;

      try {
        let requestApi;
        if (this.isBrowser) {
          requestApi = this.$axios({
            method: "post",
            headers: {
              mac: "mac",
              clientSource: this.$clientSource(),
              Authorization: this.token,
            },
            url: "/api/dist/recruitPracticeKa/registerAndJoinPracticeKa",
            data: {
              inviteCode,
              distToken,
              mobile,
              verifyCode
            }
          })
        } else {
          requestApi = this.$axios({
            method: "post",
            headers: {
              mac: "mac",
              clientSource: this.$clientSource(),
              Authorization: this.token,
            },
            url: "/api/dist/recruitPracticeKa/directJoinPracticeKa",
            data: {
              inviteCode,
              distToken
            }
          })
        }
  
        const { data: { data, success, message } } = await requestApi;
        console.log(data);
        if (success) {
          this.selfInfo = { ...data, isAlreadyBind: data.isAlreadyBind || 0 };
          this.showConfirmModal = true;
        } else {
          this.$toast(message);
        }
      } catch(error) {
        console.log(error);
      }

    },
    async onConfirmModal() {
      if (!this.isBrowser) {
        this.getActivityData('submit');
      } else {
        this.isJoin = true;
      }
      this.showConfirmModal = false;
    },
    // 获取活动数据
    async getActivityData(from) {
      const { data: { data, code, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/recruitPracticeKa/getPracticeKaStatistics",
      });
      if (code === 200) {
        this.activityData = { ...data };
        this.selfInfo = { ...data, isAlreadyBind: this.selfInfo.isAlreadyBind || 0 };
        this.isJoin = true;
        console.log(98765, this.selfInfo, from);
        if (this.selfInfo.isAlreadyBind && from === 'submit') {
          this.$toast('您已绑定过邀请码，系统不再进行重新绑定');
        }
      } else if (code === 15000009) {
        this.activityData = {
          isJoin: 0,
          status: 1,
          inviteForeverNum: 0,
          inviteNum: 0,
          needInviteForeverNum: 0,
          teamFinishAmount: 0,
          teamSaleAmount: 0,
          needTeamFinishAmount: 0,
        };
        this.isJoin = false;
      } else {
        this.$toast(message);
        this.isJoin = false;
      }
    },
    downloadKiddol() {
      window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.mq.kiddo.mall');
    },
    downloadAddol() {
      window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.mq.kiddo.partner');
    },
    reJoin() {
      this.isJoin = false;
    },
    toRule() {
      this.$router.push("/eliteRecruitRule");
    },
    copy(value) {
      let input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', value);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.$toast('复制成功');
    }
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100vw;
  min-height: 100vh;
  background-color: #E5E5E5;
  background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/bg1.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;

  .top {
    width: 254px;
    height: 184px;
    background: url('https://oss-kiddo.manqu88.com/activity/recruit/title1.png') no-repeat;
    background-size: 100% 100%;
    margin: 80px auto 0;
  }

  .center {
    margin-top: 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .field {
      width: 306px;
      padding: 40px 38px 48px;
      // background: #fff;
      background: url('https://oss-kiddo.manqu88.com/activity/recruit/main1.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      z-index: 1;
    }

    .van-field {
      padding: 9px 0 4px;
      border-bottom: 1px solid #8f8f8f;
      // height: 48px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: transparent;

      /deep/ .van-field__label {
        width: 40px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404040;
      }

      /deep/ .van-field__error-message {
        height: 16px;
        line-height: 16px;
      }
    }

    /deep/ .van-button {
      z-index: 1;
      width: 80px;
      height: 30px;
      // background: linear-gradient(270deg, #fc7223 0%, #f9550a 100%);
      background: #3C64FC;
      border-radius: 8px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      padding: 0;
      border: 0;

      &.confirm {
        width: 70px;
        height: 25px;
        border-radius: 14px;
        font-size: 12px;
        line-height: 25px;
      }

      &.van-button--disabled {
        background: #d8d8d8;
      }

      &.submit {
        margin-top: 33px;
        width: 258px;
        height: 44px;
        background: #5248E8;
        border-radius: 22px;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 44px;
        // margin-bottom: 60px;
      }
    }

    .checked {
      z-index: 1;
      width: 375px;
      margin: 28px 0 0;
      align-items: flex-start;
      padding-left: 35px;
      box-sizing: border-box;

      /deep/ .van-checkbox__icon--round {
        background: #FFFFFF;
        border-radius: 50%;
      }

      /deep/ .van-checkbox__label {
        margin-left: 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1D1D;
        line-height: 17px;
        word-break: break-all;

        a {
          color: #1B88E1;
        }
      }
    }

    .field_tip {
      height: 14px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      // color: #EC2929;
      color: #666666;
      line-height: 14px;
      margin-top: 1px;
    }

    .parentInfo {
      display: flex;
      align-items: center;

      .head {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
      }

      .nick {
        margin-left: 3px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1D1D;
        // width: 80px;
        // overflow: hidden;
        // white-space: nowrap;
      }
    }
  }

  .mask {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }

  .inviteInfoBox {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .info {
      width: 174px;
      height: 174px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
      box-sizing: border-box;

      .title {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 21px;
      }

      .head {
        margin: 17px 0 10px;
        width: 66px;
        height: 66px;
        background: #EEEEEE;
        border-radius: 50%;
      }

      .nick {
        width: 150px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        text-align: center;
      }
    }

    .icon {
      margin-top: 17px;
      font-size: 36px;
      color: #fff;
    }
  }

  .bottom {
    width: 116px;
    height: 49px;
    margin: 23px auto 11px;
    // position: absolute;
    // left: 130px;
    // bottom: 11px;
    background: url('https://oss-kiddo.manqu88.com/activity/recruit/bottom1.png') no-repeat;
    background-size: 100% 100%;
  }
}

.wrap_result {
  width: 100vw;
  min-height: 100vh;
  background-color: #ECECEC;
  background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/result1.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 331px;
  box-sizing: border-box;

  .time {
    height: 37px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 39px;
    background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/time1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 15px;
  }

  .header {
    margin-top: 17px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 21px;
  }

  .inviteCode {
    margin-top: 9px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 45px;
    letter-spacing: 2px;
  }

  .copy {
    margin-top: 9px;
    width: 46px;
    height: 46px;
    background: url('https://oss-kiddo.manqu88.com/activity/recruit/copy1.png') no-repeat;
    background-size: 100% 100%;
  }

  .tip {
    margin-top: 30px;
    margin-bottom: 25px;
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #101010;
    line-height: 18px;
  }

  .box {
    width: 345px;
    height: 148px;
    padding: 21px 0 31px;
    box-sizing: border-box;
    margin-top: 16px;
    background: url('https://oss-kiddo.manqu88.com/activity/recruit/box1.png') no-repeat;
    background-size: 100% 100%;
    z-index: 1;

    .title {
      margin: 0 auto;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }

    &.box1 {
      margin-bottom: 33px;
      .list {
        // padding: 0 20px;
        margin-top: 3px;
        
        .item {
          .name {
            margin-top: 10px;
          }
        }

      }

      .tip {
        margin-top: 3px;
        margin-bottom: 0;
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 14px;
      }
    }

    .list {
      // padding: 0 20px;
      box-sizing: border-box;
      margin-top: 20px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .item {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .data {
          font-size: 29px;
          font-family: JDZhengHT-Regular, JDZhengHT;
          font-weight: 400;
          color: #000000;

          &.money {
            font-size: 20px;

            span {
              font-size: 13px;
            }
          }
        }

        .name {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-top: 5px;
        }
      }
    }
  }

  .downloadBtn {
    width: 276px;
    height: 44px;
    background: linear-gradient(270deg, #5C6BDC 0%, #2F39B7 100%);
    border-radius: 22px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 44px;
    text-align: center;
    margin-top: 18px;
    z-index: 1;

    &.margin {
      margin-bottom: 50px;
    }
  }
}

.rule {
  width: 31px;
  height: 64px;
  background: #000000;
  padding: 11px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 0.5;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
  text-align: center;
  position: absolute;
  top: 187px;
  right: 0;
}

.wrap_success {
  width: 100vw;
  min-height: 100vh;
  background-color: #E5E5E5;
  background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/success1.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  padding-top: 152px;
  box-sizing: border-box;

  .h1 {
    margin: 0 auto;
    width: 100%;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #5148FF;
    line-height: 33px;
    text-align: center;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .nickName {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.wrap_fail {
  width: 100vw;
  min-height: 100vh;
  background-color: #E5E5E5;
  background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/fail1.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  padding-top: 685px;
  padding-bottom: 83px;
  box-sizing: border-box;

  .reJoin {
    width: 258px;
    height: 44px;
    background: #5248E8;
    border-radius: 22px;
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 44px;
    text-align: center;
    margin: 0 auto;
    // position: absolute;
    // left: 59px;
    // bottom: 83px;
    // top: 685px;
  }
}

.confirmBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 300px;
    height: 355px;
    background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/confirm-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .btn {
      position: absolute;
      left: 50%;
      bottom: 17px;
      transform: translateX(-50%);
      width: 220px;
      height: 38px;
      background-image: url('https://oss-kiddo.manqu88.com/activity/recruit/confirm-btn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }  
}
</style>
