<template>
  <div class="exclusive_container">
    <div class="universal_head">
      <div class="universal_head_mask">
        <div class="universal_head_line"></div>
        <p class="universal_head_title">尊享卡业绩</p>
        <p v-show="isShowTip" class="universal_head_sub">（不包含本人销量业绩）</p>
      </div>
      <div class="universal_head_right">
        <img class="universal_head_right_eye" v-show="false" src="../../../assets/manage_home_eyeOpe.png" alt="" />
        <van-tabs type="card" title-inactive-color="#666666" color="#323CB3" @click="onClickTab">
          <van-tab title="本人"></van-tab>
          <van-tab title="直属"></van-tab>
          <van-tab title="团队"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="exclusive_sale">
      <div class="universal_mask">
        <div class="universal_mask_left">
          <p v-for="(item, index) in exclusiveData.leftList" :key="index">
            {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                          }}</span>
          </p>
        </div>
        <div class="universal_mask_right">
          <p v-for="(item, index) in exclusiveData.rightList" :key="index">
            {{ item.name }}&nbsp;&nbsp;<span class="money">{{ item.value }}</span>&nbsp;<span class="unit">{{ item.unit
                          }}</span>
          </p>
        </div>
      </div>
      <div class="detail" @click="handleClickDetail">详情 ></div>
    </div>
    <van-empty v-if="isShowEmpty" description="暂无数据" />
  </div>
</template>

<script>
export default {
  props: {
    token: {
      default: "",
      type: String,
      require: true
    },
    distUserId: {
      default: "",
      type: String,
      require: true
    }
  },
  data() {
    return {
      isShowEmpty: false,
      exclusiveData: {},
      isShowTip: false
    };
  },
  mounted() {
    if (this.token.length > 0) {
      this.getExclusiveData({ type: 2 });
    }
  },
  methods: {
    // 获取尊享卡业绩数据
    async getExclusiveData(params) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/cycleCardStatisticsNew",
        data: params
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.isShowEmpty = false;
          this.exclusiveData = { ...data.data };
        } else {
          this.isShowEmpty = true;
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    onClickTab(index) {
      this.isShowTip = index === 2 ? true : false;
      let params = {};
      if (index === 0) {
        params = { type: 2 };
      } else if (index === 1) {
        params = { type: 3 };
      } else {
        params = { type: 1 };
      }
      this.getExclusiveData(params);
    },
    handleClickDetail() {
      this.$router.push(`/cycleCard/customer?token=${this.token}&distUserId=${this.distUserId}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.exclusive_container {
  padding: 12px 12px;
  box-sizing: border-box;
  width: 100%;

  .universal_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .universal_head_mask {
      flex: 1;
      width: 0;
      display: flex;
      justify-content: left;
      align-items: center;

      .universal_head_line {
        width: 5px;
        height: 14px;
        background: #f9550a;
        margin-right: 5px;
      }

      .universal_head_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
      }

      .universal_head_sub {
        padding: 0;
        margin: 0;
        font-size: 10px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #949494;
      }
    }

    .universal_head_right {
      display: flex;
      justify-content: right;
      align-items: center;

      .universal_head_right_eye {
        height: 15px;
        margin-right: 6px;
      }

      /deep/ .van-tabs__wrap {
        min-width: 100px;
        height: 20px;
        border-radius: 2px;

        .van-tabs__nav--card {
          height: 20px;
          border: none;
          margin: 0;
          border-radius: 2px;

          .van-tab {
            border: none;

            .van-tab__text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .exclusive_sale {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
    border-radius: 4px;
    margin-top: 10px;

    .universal_mask {
      width: 100%;
      display: flex;

      .universal_mask_left {
        width: calc(100% / 2);
        padding-left: 15px;

        p {
          margin: 8px 0px;
          padding: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;

          .money {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #666666;
          }

          .unit {
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }

      .universal_mask_right {
        width: calc(100% / 2);
        padding-left: 15px;

        p {
          margin: 8px 0px;
          padding: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;

          .money {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #666666;
          }

          .unit {
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }

    .detail {
      box-sizing: border-box;
      margin-left: 10px;
      margin-right: 10px;
      border-top: 1px solid #f5f5f5;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f9550a;
      line-height: 30px;
      height: 30px;
      text-align: center;
    }
  }
}
</style>
