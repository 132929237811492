<template>
  <div class="ck-wheel">
    <div class="poster">
      <div class="rules" style="top: 87px" @click="goRules">活动规则</div>
    </div>
    <div class="bg">
      <!-- 复制 -->
      <div class="card">
        <div class="itemView">
          <div class="label">我的邀请码</div>
          <div class="copyView" @click="copy(1)">
            <div class="inviteCode">{{ inviteCode }}</div>
            <div class="copy">复制</div>
          </div>
        </div>

        <div class="itemView">
          <div class="label">APP下载链接</div>
          <div class="copyView" @click="copy(2)">
            <div class="url">{{ url }}</div>
            <div class="copy">复制</div>
          </div>
        </div>

        <div class="inviteBtn" @click="share">立即邀请</div>
      </div>

      <!-- 收益  -->
      <div class="card">
        <div class="itemView">
          <div class="title">我的收益</div>
          <div class="label" @click="goDetail">明细 ></div>
        </div>

        <div class="itemView">
          <div class="title">累计获得金币（含待结算）</div>
          <div class="gold_1">{{ parseGold(goldData.totalGold && goldData.totalGold) }}</div>
        </div>

        <!-- <div class="itemView">
          <div class="title">待结算金币</div>
          <div class="gold_1">
            {{ goldData.unsettlementGold && parseGold(goldData.unsettlementGold) }}
          </div>
        </div> -->
      </div>

      <!-- 排行榜 -->
      <div class="card" style="margin-bottom: 26px;">
        <div class="itemView">
          <div class="title">邀请金币奖励排行榜</div>
          <div class="label"></div>
        </div>
        <div class="noList" v-if="noList && rankList.length === 0"></div>

        <div class="itemView rankView" v-for="(item, index) in rankList" :key="index">
          <div class="rankView">
            <div
              class="rankIcon"
              v-if="index < 3"
              :style="{
                backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/gold/rank_${index +
                  1}.png?${Date.now()})`
              }"
            ></div>
            <div class="rankNum" v-else>{{ index + 1 }}</div>
            <div class="name">{{ formatNickname(item.nickName) }}</div>
          </div>
          <div class="gold_1" v-if="index < 3">
            {{ parseGold(item.totalGold) }}
          </div>
          <div class="gold_2" v-else>{{ parseGold(item.totalGold) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      inviteCode: "",
      goldData: {},
      url: "https://a.app.qq.com/o/simple.jsp?pkgname=com.mq.kiddo.mall",
      rankList: [],
      noList: false
    };
  },
  created() {
    document.title = "邀新赚金币";
  },
  mounted() {
    this.token = this.$route.query.token;
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.getInviteCode();
          this.getData();
          this.getList();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getInviteCode();
            this.getData();
            this.getList();
          });
        }
      });
    }, 1000);
  },

  methods: {
    formatPrice,
    formatNickname(str) {
      str += "";
      if (null != str && str != undefined) {
        if (str.length >= 1) {
          return str.substring(0, 1) + "****";
        } else {
          return "****";
        }
      } else {
        return "";
      }
    },
    async getInviteCode() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/invitation/queryUserInvitation",
        data: {}
      });
      if (data.success) {
        this.inviteCode = data.data;
      } else {
        this.$toast(data.message);
      }
    },
    parseGold(gold) {
      if (!gold) {
        return "0";
      }
      let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
      let arr = ret.split(".");
      if (arr[1] === "00") {
        return arr[0];
      } else {
        return ret;
      }
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/goldAccount/queryInviteGoldAccount",
        data: {
          source: 1
        }
      });
      if (data.success) {
        this.goldData = data.data || {};
      } else {
        this.$toast(data.message);
      }
    },

    async getList() {
      if (this.token) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/goldAccount/queryGoldTop",
          data: {}
        });
        if (data.success) {
          this.rankList = data.data || [];
        } else {
          this.$toast(data.message);
        }
        this.noList = true;
      }
    },

    copy(type) {
      var copyDom = document.createElement("div");
      copyDom.innerText = type === 1 ? this.inviteCode : this.url;
      copyDom.style.position = "absolute";
      copyDom.style.top = "0px";
      copyDom.style.right = "-9999px";
      document.body.appendChild(copyDom);
      //创建选中范围
      var range = document.createRange();
      range.selectNode(copyDom);
      //移除剪切板中内容
      window.getSelection().removeAllRanges();
      //添加新的内容到剪切板
      window.getSelection().addRange(range);
      //复制
      var successful = document.execCommand("copy");
      copyDom.parentNode.removeChild(copyDom);
      if (successful) {
        this.$toast("复制成功");
      } else {
        this.$toast("复制失败");
      }
    },
    goRules() {
      this.$router.push(`/invitationRules?time=${Date.now()}`);
    },
    goDetail() {
      this.$router.push(
        `/myGoldDetail?token=${this.token}&userId=${this.userId}&time=${Date.now()}`
      );
    },
    share() {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/assistPackage/activity/share?shareType=3`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 27,
              param: {
                shareType: 3
              }
            })
            .then(e => {
              console.log("openPage27客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster {
    position: relative;
    width: 100vw;
    height: 350px;
    background: url(https://oss-kiddo.manqu88.com/h5/gold/invitePoster.png?1) no-repeat;
    background-size: 100% 100%;

    .rules {
      display: flex;
      align-items: center;
      position: absolute;
      justify-content: center;
      right: 0px;
      width: 80px;
      height: 30px;
      background: rgba($color: #000000, $alpha: 0.7);
      border-radius: 17px 0 0 17px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .bg {
    width: 100vw;
    min-height: 470px;
    background: url(https://oss-kiddo.manqu88.com/h5/gold/inviteBg.png?1) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .card {
      margin-top: 15px;
      padding-top: 13px;
      width: 340px;
      background: #ffffff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .inviteBtn {
        width: 191px;
        height: 31px;
        background: linear-gradient(270deg, #fc7223 0%, #f9550a 100%);
        border-radius: 11px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 12px 0 19px 0;
      }
      .noList {
        margin: auto;
        margin-top: 22px;
        margin-bottom: 42px;
        width: 105px;
        height: 131px;
        background: url(https://oss-kiddo.manqu88.com/h5/gold/noRank.png) no-repeat;
        background-size: 100% 100%;
      }
      .rankView {
        height: 49px;
        border-bottom: 1px solid #f5f5f5;
      }
      .itemView {
        width: 300px;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 13px;
        align-items: center;

        .title {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #131313;
          line-height: 18px;
        }
        .label {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
          line-height: 17px;
        }
        .rankView {
          display: flex;
          align-items: center;
          .rankIcon {
            width: 18px;
            height: 23px;
            background: url(https://oss-kiddo.manqu88.com/h5/gold/inviteBg.png?1) no-repeat;
            background-size: 100% 100%;
            margin-right: 11px;
          }
          .rankNum {
            width: 18px;
            height: 23px;
            margin-right: 11px;
            font-size: 13px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #666666;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name {
            width: 146px;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #404040;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 1;
          }
        }
        .gold_1 {
          height: 17px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #fc7021;
          text-align: right;
          // line-height: 17px;
        }
        .gold_2 {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #404040;
          text-align: right;
          // line-height: 18px;
        }
        .copyView {
          padding: 0px 7px 0px 7px;
          max-width: 214px;
          height: 22px;
          background: #f7f7f7;
          border-radius: 9px;
          display: flex;
          align-items: center;
          font-size: 11px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff4141;
          .inviteCode {
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #404040;
            margin-right: 6px;
          }
          .url {
            margin-right: 6px;
            max-width: 168px;
            height: 16px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a0a0a0;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
}
</style>
