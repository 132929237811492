<template>
    <div class="line-content">
        <div class="line-top">
            <div class="reward-mark" v-for="(reward, index) in scaleArray.slice(1)"
                :style="{ marginLeft: `${(reward / baseValue) * 100}%` }">
                <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/ruler-money.png" />
                <div>{{ rewardArray[index] }}</div>
            </div>
        </div>
        <div class="line-rate-bg">
            <div class="line-rate"
                :style="{ width: `${(pageType == 1 ? (rate / baseValue) * 100 : (rate / (baseValue * 100)) * 100)}%`, textAlign: `${((pageType == 2 && Number(rate) == 0) ? 'center' : 'right' )}`}">
                {{ pageType == 1 ? (rate + unit) : (parseGold(rate)) }}&nbsp;
            </div>
        </div>
        <div class="line-bottom">
            <div class="ruler-mark" v-for="(scale, index) in scaleArray"
                :style="{ marginLeft: `${(scale / baseValue) * 100}%` }">
                <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/ruler-mark.png" />
                <div>{{ index == 0 ? 0 : (index == 3 ? (pageType == 1 ? 10 : 10000) : scaleArray[index]) }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { formatPrice } from "@/utils/money";

export default {
    name: "app",
    props: ["pageType", "rate"],
    data() {
        return {
            baseValue: null,    // 刻度基数
            scaleArray: [],     // 刻度
            rewardArray: [],    // 奖励
            unit: '', // 单位
        }
    },
    mounted() {
        if (this.pageType == 1) {
            this.baseValue = 10;
            this.scaleArray = [0.2, 3, 5, 9.6];
            this.rewardArray = [150, 500, 1500];
            this.unit = '人'
        } else {
            this.baseValue = 10000;
            this.rewardArray = [100, 200, 500];
            this.scaleArray = [200, 1500, 5000, 9600];
            this.unit = '元'
        }
    },
    methods: {
        formatPrice,
        parseGold(gold) {
            let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
            let arr = ret.split(".");
            console.log(ret, arr);
            if (arr[1] === "00") {
                return arr[0];
            } else {
                return ret;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.line-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 68px;
}

.line-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 321px;
    height: 24px;
    position: relative;

    .reward-mark {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        transform: translateX(-50%);

        :first-child {
            width: 14px;
            // height: 16px;
        }

        :last-child {
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #141414;
            margin-left: 2px;
        }
    }
}

.line-rate-bg {
    width: 321px;
    height: 18px;
    background: #E3E3E3;
    display: flex;
    align-items: flex-start;

    .line-rate {
        max-width: 100%;
        min-width: 31px;
        height: 18px;
        background: #B80A04;
        text-align: right;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.line-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 4px;
    width: 321px;
    position: relative;

    .ruler-mark {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        transform: translateX(-50%);

        :first-child {
            width: 8px;
            height: 7px;
        }

        :last-child {
            font-size: 9px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #141414;
            line-height: 20px;
        }
    }
}
</style>