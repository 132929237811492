<template>
  <div class="wheel">
    <div class="myScore">
      我的活力值：
      <div class="score">{{ score }}</div>
    </div>
    <div class="tabView">
      <div :class="['tab', tabIndex === 0 ? 'tab-choosed' : '']" @click="changeTab(0)">获得</div>
      <div :class="['tab', tabIndex === 1 ? 'tab-choosed' : '']" @click="changeTab(1)">消耗</div>
    </div>

    <div class="listView">
      <div class="card" v-if="recordList.length === 0">
        <div class="noData"></div>
      </div>
      <div class="card" v-for="(item, index) in recordList" :key="index">
        <div class="title">{{ item.recordDescribe }}</div>

        <div class="bottom">
          <div class="left">{{ formatDate(item.gmtModified) }}</div>
          <div class="right">{{ tabIndex === 0 ? "+" : "-" }}{{ formatPrice(item.score) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/time";
import { formatPrice } from "@/utils/money";
export default {
  name: "scoreDetail",
  components: {},
  created() {
    document.title = "活力值明细";
  },
  async mounted() {
    // 活力值
    const { data } = await this.$axios({
      method: "post",
      headers: {
        mac: "mac",
        clientSource: this.$clientSource(),
        Authorization: this.token
      },
      url: "/api/temp/score/query",
      data: {
        type: 50
      }
    });
    if (data.success) {
      this.score = formatPrice(data.data.score);
    } else {
      this.$toast(data.message);
    }
    this.getRecordData();
  },
  data() {
    return {
      token: this.$route.query.token,
      type: this.$route.query.type,
      recordList: [],
      tabIndex: 0,
      score: 0
    };
  },
  methods: {
    formatPrice,
    formatDate,

    async getRecordData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/temp/score/queryDetailList",
        data: {
          type: 50,
          changeType: this.tabIndex + 1
        }
      });
      if (data.success) {
        this.recordList = data.data;
      } else {
        this.$toast(data.message);
      }
    },
    changeTab(index) {
      this.recordList = [];
      this.tabIndex = index;
      this.getRecordData();
    }
  }
};
</script>
<style lang="scss" scoped>
.wheel {
  width: 100vw;
  // min-height: 100vh;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/bg.png?11) no-repeat;
  background-size: 100% 100%;

  .myScore {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 17px;
    line-height: 25px;
    .score {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #d20006;
      line-height: 25px;
    }
  }

  .tabView {
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .tab {
      width: 143px;
      height: 34px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 22px;
      margin-left: 7px;
      margin-right: 7px;
    }
    .tab-choosed {
      background: #d20006;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
    }
  }

  .listView {
    width: 100%;
    height: 698px;
    overflow-y: scroll;
    .card {
      width: 345px;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      margin-top: 0;
      margin-bottom: 10px;

      .noData {
        width: 57px;
        height: 82px;
        background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/nodata.png?11) no-repeat;
        background-size: 100% 100%;
        margin-top: 56px;
        margin-bottom: 59px;
      }

      .title {
        margin-top: 5px;
        width: 325px;
        height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 24px;
      }

      .bottom {
        margin-bottom: 5px;
        width: 325px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #888888;
          line-height: 24px;
        }
        .right {
          height: 24px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #d20006;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
