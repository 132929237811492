<template>
  <div class="status_container">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" @load="onLoad"
      v-if="!isShowEmpty">
      <div class="order-list" v-for="item in orderListData" :key="item.orderId">
        <!-- 订单编号头部 -->
        <div class="order-list-head van-hairline--bottom">
          <p class="order-list-num">订单编号：{{ item.orderId }}</p>
          <img class="order-list-copy" src="../../../assets/order_center_copy.png" alt=""
            @click="onCopyOrderNo(item.orderId)" />
          <p :class="['order-list-orderstatus', `orderstatus_color_${item.orderStatus}`]">
            {{ orderStatusContent(item.orderStatus) }}
          </p>
        </div>
        <!-- 商品列表 -->
        <div v-for="goodsItem in item.distSubOrderList" :key="goodsItem.skuId" class="order-list-goods"
          @click="handleLookDetail(item.orderId)">
          <img class="goods_cover" :src="goodsItem.resources" alt="" />
          <div class="goods_middle">
            <p class="goods_name van-multi-ellipsis--l2">
              {{ goodsItem.itemName }} {{ skuSpec(goodsItem.specification) }}
            </p>
            <p v-show="false" class="goods_income">
              佣金¥111
            </p>
            <div class="goods_right">
              <p class="goods_price">¥{{ formatPrice(goodsItem.distSubOrderExtension.price) }}</p>
              <p class="goods_num">x{{ goodsItem.amount }}</p>
            </div>
          </div>
        </div>
        <!-- 下单信息 -->
        <div class="order_list_info van-hairline--bottom" @click="handleLookDetail(item.orderId)">
          <div class="order_list_info_time">
            <p class="order_list_info_tip">下单时间:</p>
            <p class="order_list_info_value">
              {{ item.gmtCreate && moment(item.gmtCreate).format("YYYY.MM.DD HH:mm:ss") }}
            </p>
          </div>
          <div class="order_list_info_user">
            <p class="order_list_info_tip">下单用户:</p>
            <p class="order_list_info_name van-ellipsis">
              {{ dataVisible ? item.userInfo.nickName : "****" }}
            </p>
            <p class="order_list_info_phone">{{ parseMobile(item.userInfo.mobile) }}</p>
            <p v-show="item.userInfo.agentName"
              :class="['order_list_info_level', `level_color_${item.userInfo.gradeCode}`]">
              {{ item.userInfo.agentName }}
            </p>
          </div>
        </div>
        <!-- 订单金额相关信息 -->
        <div class="order-list-money-info" @click="handleLookDetail(item.orderId)">
          <p class="title">实付金额</p>
          <p class="money">
            {{
              item.distOrderExtension.deliveryFee &&
              `运费¥${formatPrice(item.distOrderExtension.deliveryFee)}`
            }}
            {{
              item.distOrderExtension.taxPrice &&
              `税费¥${formatPrice(item.distOrderExtension.taxPrice)}`
            }}
            {{ `¥${formatPrice(item.distOrderExtension.payPrice)}` }}
          </p>
        </div>
        <div v-show="item.hasOwnProperty('promotionCommission')" class="order-list-money-info"
          @click="handleLookDetail(item.orderId)">
          <p class="title">{{ item.orderStatus === 3 ? "佣金合计" : "预估佣金合计" }}</p>
          <p :class="['money', item.orderStatus === 4 && item.status === 0 ? 'middle_line' : '']">
            {{ dataVisible ? `¥${formatPrice(item.promotionCommission)}` : "****" }}
          </p>
        </div>
        <div v-show="item.hasOwnProperty('kCommission')" class="order-list-money-info"
          @click="handleLookDetail(item.orderId)">
          <p class="title">{{ item.orderStatus === 3 ? "佣金补贴" : "预估佣金补贴" }}</p>
          <p :class="['money', item.orderStatus === 4 && item.status === 0 ? 'middle_line' : '']">
            {{ dataVisible ? `¥${formatPrice(item.kCommission)}` : "****" }}
          </p>
        </div>
        <div v-show="item.hasOwnProperty('doubleCommission')" class="order-list-money-info"
          @click="handleLookDetail(item.orderId)">
          <p class="title">{{ item.orderStatus === 3 ? "翻倍佣金" : "预估翻倍佣金" }}</p>
          <p :class="['money', item.orderStatus === 4 && item.status === 0 ? 'middle_line' : '']">
            {{ dataVisible ? `¥${formatPrice(item.doubleCommission)}` : "****" }}
          </p>
        </div>
        <div v-show="item.hasOwnProperty('cycleCommission')" class="order-list-money-info"
          @click="handleLookDetail(item.orderId)">
          <p class="title">预计待解锁总佣金合计</p>
          <p :class="['money', item.orderStatus === 4 && item.status === 0 ? 'middle_line' : '']">
            {{ dataVisible ? `¥${formatPrice(item.cycleCommission)}` : "****" }}
          </p>
        </div>
        <div class="menu-mask" @click="handleLookDetail(item.orderId)">
          <div class="look-detail">
            查看详情
          </div>
        </div>
      </div>
    </van-list>
    <div :class="['data-fixed', dataVisible ? 'data-show' : 'data-hide']" v-if="!isShowEmpty"
      @click="dataVisible = !dataVisible"></div>
    <van-empty v-if="isShowEmpty" description="暂无订单" />
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "../../../utils/money";

export default {
  created() {
    if (this.$route.query.orderStatus === "0") {
      document.title = "待支付订单";
    } else if (this.$route.query.orderStatus === "1") {
      document.title = "待发货订单";
    } else if (this.$route.query.orderStatus === "4") {
      document.title = "24小时内取消支付订单";
    } else {
      document.title = "售后中订单";
    }
  },
  data() {
    return {
      token: "",
      isShowEmpty: false,
      orderListData: [],
      loading: false,
      finished: false,
      currentPage: 2,
      dataVisible: true
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        let params = {
          orderStatus: Number(this.$route.query.orderStatus),
          currentPage: 1,
          pageSize: 20
        };
        if (Number(this.$route.query.orderStatus) === 4) {
          const timestamp = Date.parse(new Date());
          params["startTime"] = timestamp / 1000 - 86400000 / 1000;
          params["endTime"] = timestamp / 1000;
        }
        this.getOrderListData(params);
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          let params = {
            orderStatus: Number(this.$route.query.orderStatus),
            currentPage: 1,
            pageSize: 20
          };
          if (Number(this.$route.query.orderStatus) === 4) {
            const timestamp = Date.parse(new Date());
            params["startTime"] = timestamp / 1000 - 86400000 / 1000;
            params["endTime"] = timestamp / 1000;
          }
          this.getOrderListData(params);
        });
      }
    });
  },
  computed: {
    orderStatusContent() {
      return orderStatus => {
        if (orderStatus === 0) {
          return "待付款";
        } else if (orderStatus === 1) {
          return "待发货";
        } else if (orderStatus === 2) {
          return "已发货";
        } else if (orderStatus === 3) {
          return "已完成";
        } else {
          return "已关闭";
        }
      };
    },
    skuSpec() {
      return specification => {
        const specArr = [];
        if (specification) {
          const specJSON = JSON.parse(specification);
          Object.keys(specJSON).map(ele => {
            specArr.push(specJSON[ele]);
          });
        }
        return specArr.join("，");
      };
    }
  },
  methods: {
    moment,
    formatPrice,
    parseMobile(phone) {
      phone = phone || "";
      var pho = /(\d{3})\d*(\d{4})/;
      var str = phone.replace(pho, "$1****$2");
      return str;
    },
    async getOrderListData(params) {
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/user/queryNewDistOrders",
          data: params
        });
        if (data.success) {
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.isShowEmpty = true;
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            }
            this.isShowEmpty = false;
            this.orderListData = [...data.data];
          }
        } else {
          this.isShowEmpty = true;
          this.loading = false;
          this.finished = true;
          this.$toast(data.message);
        }
      } catch (error) {
        this.finished = true;
        this.loading = false;
        this.isShowEmpty = true;
      }
    },
    async onLoad() {
      try {
        let params = {
          orderStatus: Number(this.$route.query.orderStatus),
          currentPage: this.currentPage,
          pageSize: 20
        };
        if (Number(this.$route.query.orderStatus) === 4) {
          const timestamp = Date.parse(new Date());
          params["startTime"] = timestamp / 1000 - 86400000 / 1000;
          params["endTime"] = timestamp / 1000;
        }
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/user/queryNewDistOrders",
          data: params
        });
        if (data.success) {
          this.currentPage++;
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            }
            this.orderListData = [...this.orderListData, ...data.data];
          }
        } else {
          this.finished = true;
          this.loading = false;
        }
      } catch (error) {
        this.finished = true;
        this.loading = false;
      }
    },
    onCopyOrderNo(orderId) {
      this.$copyText(orderId)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch(() => {
          this.$toast("复制失败");
        });
    },
    handleLookDetail(orderId) {
      this.$router.push(`/addol/orderDetail?token=${this.token}&orderId=${orderId}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.status_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  /deep/ .van-empty {
    width: 100vw;
    height: 90vh;
  }

  .order-list {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 10px;
    width: calc(100vw - 16px);
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding-bottom: 15px;

    .order-list-head {
      margin-left: 16px;
      margin-right: 16px;
      box-sizing: border-box;
      height: 47px;
      display: flex;
      align-items: center;

      .order-list-num {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #8d8c8c;
      }

      .order-list-copy {
        width: 12px;
        height: 12px;
        margin-left: 5px;
      }

      .order-list-orderstatus {
        flex: 1;
        width: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: right;
      }

      .orderstatus_color_0 {
        color: #fa550a;
      }

      .orderstatus_color_1 {
        color: #fa550a;
      }

      .orderstatus_color_2 {
        color: #fa550a;
      }

      .orderstatus_color_3 {
        color: #8d8c8c;
      }

      .orderstatus_color_4 {
        color: #fa550a;
      }
    }

    .order-list-goods {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;

      .goods_cover {
        width: 71px;
        height: 71px;
        background-color: #F5F5F5;
      }

      .goods_middle {
        flex: 1;
        width: 0;
        margin-left: 8px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .goods_name {
          padding: 0;
          margin: 0;
          /* margin-bottom: 5px; */
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
          word-break: break-all;
        }

        .goods_income {
          padding: 0px 5px;
          margin: 0;
          height: 19px;
          border-radius: 2px;
          border: 1px solid #de7d36;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #de7d36;
          line-height: 19px;
          text-align: center;
        }

        .goods_right {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 11px;

          .goods_price {
            padding: 0;
            margin: 0;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }

          .goods_num {
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #404040;
          }
        }
      }
    }

    .order_list_info {
      margin: 7px 16px;
      padding-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .order_list_info_time {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 2px;

        .order_list_info_tip {
          padding: 0;
          margin: 0;
          margin-right: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .order_list_info_value {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }
      }

      .order_list_info_user {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top: 2px;
        margin-bottom: 2px;

        .order_list_info_tip {
          padding: 0;
          margin: 0;
          margin-right: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .order_list_info_name {
          max-width: 120px;
          padding: 0;
          margin: 0px 1px 0px 0px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
          text-align: left;
        }

        .order_list_info_phone {
          padding: 0;
          margin: 0px 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .order_list_info_level {
          padding: 1px 3px;
          margin: 0;
          height: 15px;
          border-radius: 1px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 15px;
          text-align: center;
        }

        .level_color_1 {
          background: #b6c4ff;
        }

        .level_color_2 {
          background: #f06d32;
        }

        .level_color_3 {
          background: #323cb4;
        }

        .level_color_-1 {
          background: #c0c0c0;
        }
      }
    }

    .order-list-money-info {
      margin: 10px 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .title {
        margin: 0;
        padding: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }

      .money {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404040;
      }

      .middle_line {
        text-decoration: line-through;
      }
    }

    .menu-mask {
      margin-left: 16px;
      margin-right: 16px;
      display: flex;
      justify-content: flex-end;

      .look-detail {
        width: 85px;
        height: 28px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #b0b0b0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .data-fixed {
    position: fixed;
    top: 58px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .data-show {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/show.png?") no-repeat;
    background-size: 100% 100%;
  }

  .data-hide {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/hide.png?") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
