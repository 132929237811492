var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{class:['ck-wheel', _vm.type === 2 ? 'background-2' : 'background-1']},[_c('div',{staticClass:"wheel"},[_c('canvas',{ref:"wheelCanvas",staticClass:"item",attrs:{"id":"wheelCanvas","width":"1400px","height":"1400px"}}),_c('img',{staticClass:"pointer",attrs:{"src":'https://oss-kiddo.manqu88.com/h5/drawBtn.png?' + Date.now()},on:{"click":_vm.onClick}}),_c('div',{staticClass:"pointer",on:{"click":_vm.onClick}},[_c('div',{staticClass:"drawtitle"},[_vm._v("立即抽奖")]),_c('div',{staticClass:"needScore"},[_vm._v("("+_vm._s(_vm.lotteryTimesData.lotteryConsumeScore)+"积分)")])])]),_c('div',{staticClass:"leftScoreTitle"},[_vm._v("剩余积分")]),_c('div',{staticClass:"leftScore"},[_vm._v(_vm._s(_vm.lotteryTimesData.score))]),_c('div',{staticClass:"leftNumTitle"},[_vm._v(" 今天已抽次数 "),_c('div',{staticClass:"leftNum"},[_vm._v(_vm._s(_vm.lotteryTimesData.timesPlayedToday))]),_vm._v(" 次 ")]),_c('div',{staticClass:"label",staticStyle:{"top":"76px"},on:{"click":function($event){return _vm.goPage(0)}}},[_vm._v("活动规则")]),_c('div',{staticClass:"label",staticStyle:{"top":"120px"},on:{"click":function($event){return _vm.goPage(1)}}},[_vm._v("中奖记录")]),(_vm.showModal)?_c('div',{staticClass:"mask",on:{"click":_vm.stopClick}},[_c('div',{staticClass:"bgGet",style:({
          backgroundImage: ("url(" + ('https://oss-kiddo.manqu88.com/h5/getPrizeBg.png?' +
            Date.now()) + ")")
        })},[(_vm.prizeType !== 3)?_c('div',{staticClass:"prizeName"},[_vm._v(_vm._s(_vm.curPrizeName))]):_vm._e(),(
            _vm.prizeType == 1 &&
              _vm.drwaData.extension &&
              _vm.drwaData.extension.USER_RULE &&
              _vm.drwaData.extension.USER_RULE === 1
          )?_c('div',{staticClass:"coupon"},[_c('div',{staticClass:"couponPrice"},[_vm._v(_vm._s(_vm.curPrizeName.split("元")[0]))]),_c('div',{staticClass:"yuan"},[_vm._v("元")])]):_vm._e(),(
            _vm.prizeType == 2 ||
              (_vm.drwaData.extension &&
                _vm.drwaData.extension.USER_RULE &&
                _vm.drwaData.extension.USER_RULE === 2)
          )?_c('div',{staticClass:"goods",style:({
            backgroundImage: ("url(" + _vm.curItemUrl + ")")
          })}):_vm._e(),(_vm.prizeType == 3)?_c('div',{staticClass:"scoreBg"},[_c('div',{staticClass:"score"},[_vm._v(_vm._s(_vm.curPrizeName))])]):_vm._e(),_c('div',{staticClass:"toRecord",style:({ left: _vm.prizeType == 2 ? '67px' : 'unset' }),on:{"click":function($event){return _vm.goPage(1, _vm.prizeType)}}}),(_vm.prizeType == 2)?_c('div',{staticClass:"toAddress",on:{"click":function($event){return _vm.toAddress(_vm.prizeRecordId)}}}):_vm._e(),_c('div',{staticClass:"btnClose",on:{"click":function($event){_vm.showModal = false}}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }