<template>
  <div class="">
    <div
      :class="['ck-wheel', 'rules-1']"
      :style="{
        backgroundImage: `url(${'https://oss-kiddo.manqu88.com/h5/scoreTableRules_1.png?' +
          Date.now()})`
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "活动规则";
  },
  data() {
    return {
      token: this.$route.query.token,
      type: 1
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rules-1 {
  min-height: 1475px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rules_1.png)
    no-repeat;
  background-size: 100% 100%;
}
</style>
