<template>
  <div class="invite_container">
    <div class="universal_head">
      <div class="universal_head_mask">
        <div class="universal_head_line"></div>
        <p class="universal_head_title">邀请概况</p>
        <img v-show="isShowInviteDynamic" class="dynamic_icon" src="../../../assets/manage_dynamic_msg.png" alt="">
        <p v-show="isShowInviteDynamic" class="dynamic_tip van-ellipsis">{{ dynamicContent }}</p>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="content">
      <div class="content_charts">
        <div class="charts1" @click="handleClickGoMyClient">
          <div class="mask1">
            <div class="charts1_detail" @click="handleClickGoMyClient">
              <p class="title">直属会员</p>
              <p class="value">{{ directDistUser.total }}</p>
              <p class="add">今日+{{ directDistUser.totalToday }}</p>
            </div>
            <div id="real_charts1" class="charts1_canvas"></div>
          </div>
          <div class="charts1_legend">
            <div v-for="(item, index) in directToadyAddDistUser" :key="index" class="charts1_legend_item">
              <p :class="[`charts1_legend_name_${index}`]">{{ item.name }}</p>
              <p class="direct_value">{{ item.totalValue }}</p>
              <p class="direct_today_value">今日 +{{ item.value }}</p>
            </div>
          </div>
        </div>
        <div class="charts2 van-hairline--top">
          <div class="mask2">
            <div class="charts2_detail">
              <p class="title">间接会员</p>
              <p class="value">{{ indirectDistUser.total }}</p>
              <p class="add">今日+{{ indirectDistUser.totalToday }}</p>
            </div>
            <div id="real_charts2" class="charts2_canvas"></div>
          </div>
          <div class="charts2_legend">
            <div v-for="(item, index) in indirectToadyAddDistUser" :key="index" class="charts2_legend_item">
              <p :class="[`charts2_legend_name_${index}`]">{{ item.name }}</p>
              <p class="indirect_value">{{ item.totalValue }}</p>
              <p class="indirect_today_value">今日 +{{ item.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-if="isShowEmpty" description="暂无数据" />
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([LegendComponent, PieChart, CanvasRenderer]);
export default {
  props: {
    token: {
      default: "",
      type: String,
      require: true
    },
    distUserId: {
      default: "",
      type: String,
      require: true
    }
  },
  data() {
    return {
      isShowEmpty: false,
      inviteStatisticsData: {},
      levelNames: [],
      directDistUser: {},
      indirectDistUser: {},
      directToadyAddDistUser: [],
      indirectToadyAddDistUser: [],
      inviteDynamicModel: {},
      isShowInviteDynamic: false
    };
  },
  mounted() {
    if (this.token.length > 0) {
      this.getUserInviteStatistics();
      this.getInviteDynamicData();
    }
  },
  computed: {
    dynamicContent() {
      if (this.inviteDynamicModel.agentName && this.inviteDynamicModel.agentName.length > 0) {
        let name = this.inviteDynamicModel.nickname
        if (this.inviteDynamicModel.nickname.length > 10) {
          name = name.slice(0, 9)
        }
        if (this.inviteDynamicModel.mobile < 4) {
          return `您已成功邀请${name}成为${this.inviteDynamicModel.agentName}`
        } else {
          return `您已成功邀请${name}尾号${this.inviteDynamicModel.mobile.slice(-4)}成为${this.inviteDynamicModel.agentName}`
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    // 获取邀请数据
    async getUserInviteStatistics() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryDistUserInviteStatistics",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.isShowEmpty = false;
          this.inviteStatisticsData = { ...data.data };
          this.directDistUser = { ...this.inviteStatisticsData.directDistUser };
          this.indirectDistUser = { ...this.inviteStatisticsData.indirectDistUser };
          if (Object.keys(this.inviteStatisticsData.directDistUser).length > 0) {
            const {
              seniorAgentName,
              intermediateAgentName,
              primaryAgentName,
              seniorTotalToday,
              primaryTotalToday,
              intermediateTotalToday,
              seniorCount,
              intermediateCount,
              primaryCount
            } = this.inviteStatisticsData.directDistUser;
            this.levelNames = [seniorAgentName, intermediateAgentName, primaryAgentName];
            this.directToadyAddDistUser = [
              { name: seniorAgentName, value: seniorTotalToday, totalValue: seniorCount },
              { name: intermediateAgentName, value: intermediateTotalToday, totalValue: intermediateCount },
              { name: primaryAgentName, value: primaryTotalToday, totalValue: primaryCount }
            ];
            this.initChart(
              "real_charts1",
              { seniorAgentName, seniorCount },
              { intermediateAgentName, intermediateCount },
              { primaryAgentName, primaryCount }
            );
          }
          if (Object.keys(this.inviteStatisticsData.indirectDistUser).length > 0) {
            const {
              seniorAgentName,
              intermediateAgentName,
              primaryAgentName,
              seniorTotalToday,
              primaryTotalToday,
              intermediateTotalToday,
              seniorCount,
              intermediateCount,
              primaryCount
            } = this.inviteStatisticsData.indirectDistUser;
            this.indirectToadyAddDistUser = [
              { name: seniorAgentName, value: seniorTotalToday, totalValue: seniorCount },
              { name: intermediateAgentName, value: intermediateTotalToday, totalValue: intermediateCount },
              { name: primaryAgentName, value: primaryTotalToday, totalValue: primaryCount }
            ];
            this.initChart(
              "real_charts2",
              { seniorAgentName, seniorCount },
              { intermediateAgentName, intermediateCount },
              { primaryAgentName, primaryCount }
            );
          }
        } else {
          this.isShowEmpty = true;
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    // 获取邀请动态数据
    async getInviteDynamicData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryDistUserInviteDynamic",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.inviteDynamicModel = { ...data.data }
          this.isShowInviteDynamic = true
        } else {
          this.isShowInviteDynamic = false
        }
      } else {
        this.isShowInviteDynamic = false
        this.$toast(data.message)
      }
    },
    // 初始化饼图
    initChart(chartsID, seniorAgentObj, intermediateAgentObj, primaryAgentObj) {
      var chartDom = document.getElementById(chartsID);
      var myChart = echarts.init(chartDom);
      var color = ["#7590FF", "#FF9762", "#E6453B"];

      var seriesData = [
        {
          name: `${primaryAgentObj.primaryAgentName}`,
          value: primaryAgentObj.primaryCount,
          label: {
            rich: {
              value: {
                fontSize: 9,
                color: "#7590FF"
              }
            }
          }
        },
        {
          name: `${intermediateAgentObj.intermediateAgentName}`,
          value: intermediateAgentObj.intermediateCount,
          label: {
            rich: {
              value: {
                fontSize: 9,
                color: "#FF9762"
              }
            }
          }
        },
        {
          name: `${seniorAgentObj.seniorAgentName}`,
          value: seniorAgentObj.seniorCount,
          label: {
            rich: {
              value: {
                fontSize: 9,
                color: "#E6453B"
              }
            }
          }
        }
      ];

      var option = {
        color: color,
        legend: { show: false },
        series: [
          {
            name: "等级比",
            type: "pie",
            center: ["50%", "50%"],
            radius: ["70%", "80%"],
            showEmptyCircle: true,
            label: {
              normal: {
                show: false,
                position: "inside",
                backgroundColor: "#FFFFFF",
                formatter: "{value|{b} {c}}",
                borderRadius: [3, 3, 3, 3],
                padding: [3, 4, 3, 4],
                shadowColor: "#E1E1E1",
                shadowOffsetY: 2,
                shadowBlur: 4
              }
            },
            data: seriesData
          }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    handleClickGoMyClient() {
      this.$router.push(`/addol/myclient?token=${this.token}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.invite_container {
  padding: 12px 12px;
  box-sizing: border-box;
  width: 100%;

  .universal_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .universal_head_mask {
      flex: 1;
      width: 0;
      display: flex;
      justify-content: left;
      align-items: center;

      .universal_head_line {
        width: 5px;
        height: 14px;
        background: #f9550a;
        margin-right: 5px;
      }

      .universal_head_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
      }

      .dynamic_icon {
        width: 14px;
        height: 14px;
        margin-left: 10px;
        margin-right: 5px;
      }

      .dynamic_tip {
        flex: 1;
        width: 0;
        padding: 0;
        margin: 0;
        font-size: 9px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F9550A;
        margin-right: 4px;
      }
    }
  }

  .content {
    width: 100%;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
    border-radius: 4px;
    box-sizing: border-box;

    .content_charts {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .charts1 {
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 15px 0px 15px 10px;

        .mask1 {
          position: relative;

          .charts1_detail {
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 2px rgba(225, 225, 225, 0.5);
            border-radius: 45px;
            width: 90px;
            height: 90px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #232323;
            }

            .value {
              padding: 0;
              margin: 0;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #232323;
            }

            .add {
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              color: #F9550A;
            }
          }

          .charts1_canvas {
            width: 150px;
            height: 150px;
          }
        }

        .charts1_legend {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-evenly;
          margin-left: 20px;
          flex: 1;
          width: 0;
          min-height: 140px;

          .charts1_legend_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 28px;
            background: #F8FAFF;
            border-radius: 14px;
            box-sizing: border-box;
            padding-left: 7px;
            padding-right: 7px;

            .direct_today_value {
              margin: 0;
              padding: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #000000;
            }

            .direct_value {
              margin: 0;
              padding: 0;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #404040;
              padding-left: 10px;
              padding-right: 10px;
            }

            .charts1_legend_name_0 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #E6453B;
              border-radius: 14px;
              border: 1px solid #E6453B;
              text-align: center;
              line-height: 22px;
            }

            .charts1_legend_name_1 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #FF9762;
              border-radius: 14px;
              text-align: center;
              line-height: 22px;
            }

            .charts1_legend_name_2 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #7590FF;
              border-radius: 14px;
              text-align: center;
              line-height: 22px;
            }
          }
        }
      }

      .charts2 {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        padding: 15px 0px 15px 10px;

        .mask2 {
          position: relative;

          .charts2_detail {
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 2px rgba(225, 225, 225, 0.5);
            border-radius: 45px;
            width: 90px;
            height: 90px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #232323;
            }

            .value {
              padding: 0;
              margin: 0;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #232323;
            }

            .add {
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              color: #F9550A;
            }
          }

          .charts2_canvas {
            width: 150px;
            height: 150px;
          }
        }

        .charts2_legend {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-evenly;
          margin-left: 20px;
          flex: 1;
          width: 0;
          min-height: 140px;

          .charts2_legend_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 28px;
            background: #F8FAFF;
            border-radius: 14px;
            box-sizing: border-box;
            padding-left: 7px;
            padding-right: 7px;

            .indirect_today_value {
              margin: 0;
              padding: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #000000;
            }

            .indirect_value {
              margin: 0;
              padding: 0;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #404040;
              padding-left: 10px;
              padding-right: 10px;
            }

            .charts2_legend_name_0 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #E6453B;
              border-radius: 14px;
              border: 1px solid #E6453B;
              text-align: center;
              line-height: 22px;
            }

            .charts2_legend_name_1 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #FF9762;
              border-radius: 14px;
              text-align: center;
              line-height: 22px;
            }

            .charts2_legend_name_2 {
              padding: 0px 7px;
              margin: 0;
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: white;
              height: 22px;
              background: #7590FF;
              border-radius: 14px;
              text-align: center;
              line-height: 22px;
            }
          }


        }
      }
    }
  }
}
</style>
