<template>
  <div class="wrap">
    <img src="https://oss-kiddo.manqu88.com/activity/chongfen/zhiyin.png" alt="" style="width:100%">
  </div>
</template>

<script>
export default {
  mounted(){
    document.title = '直升KA指引'
  }
}
</script>


<style scoped>
.wrap {
  width: 100vw;
  min-height: 100vh;
  background: #ffffff;
}
</style>