<template>
  <div class="ck-wheel">
    <div class="top">
      <div :class="['tab', !curTab ? 'choosed' : '']" @click="onTabChange(null)">全部</div>
      <div :class="['tab', curTab === 3 ? 'choosed' : '']" @click="onTabChange(3)">首席</div>
      <div :class="['tab', curTab === 2 ? 'choosed' : '']" @click="onTabChange(2)">店主</div>
      <div :class="['tab', curTab === 1 ? 'choosed' : '']" @click="onTabChange(1)">会员</div>
    </div>


    <div class="scroll">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="noList" v-if="list.length === 0"></div>
        <div class="card" v-for="(item, index) in list" :key="index" @click="itemDetail(item.buyerId)">
          <div class="common-view user-view">
            <div class="user-display">
              <div class="user-head" :style="{ backgroundImage: `url(${item.headPicUrl})` }"></div>

              <div class="user-msg-view">
                <div class="user-name-line">
                  <div class="name">{{ item.nickname }}</div>
                  <div :class="[`level-${levelEnum[item.agentName]}`]"></div>
                  <div class="role"
                    :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/cycleCard/customer/role_${item.inviteType}.png)` }">
                  </div>
                </div>

                <div class="user-phone-line">
                  <div class="phone">手机号:{{ item.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}</div>
                  <div class="copy" v-if="item.inviteType === 2" @click.stop="copy(item.mobile)"></div>
                </div>
              </div>

              <div class="detail">
                <div>查看详情</div>
                <div class="arrow"></div>
              </div>
            </div>

            <div class="data-display">
              <div class="data-show">
                <div class="data-title">预计佣金收益</div>
                <div class="data-num">{{ formatPrice(item.predictCommission) }}</div>
                <div class="data-unit">元</div>
              </div>
              <div class="data-show">
                <div class="data-title">已解锁佣金</div>
                <div class="data-num">{{ formatPrice(item.cycleCommission) }}</div>
                <div class="data-unit">元</div>
              </div>
            </div>
          </div>


          <div class="common-view data-view" v-if="item.personagePerformanceDTO">
            <div class="common-title">个人业绩</div>
            <div class="data-display">
              <div class="data-show">
                <div class="data-title">本月购买卡数</div>
                <div class="data-num">{{ item.personagePerformanceDTO.cycleCardNumMonth }}</div>
                <div class="data-unit">件</div>
              </div>
              <div class="data-show">
                <div class="data-title">本月个人金额</div>
                <div class="data-num">{{ formatPrice(item.personagePerformanceDTO.cycleCardAmountMonth) }}</div>
                <div class="data-unit">元</div>
              </div>
            </div>
            <div class="data-display">
              <div class="data-show">
                <div class="data-title">累积购买卡数</div>
                <div class="data-num">{{ item.personagePerformanceDTO.cycleCardNumTotal }}</div>
                <div class="data-unit">件</div>
              </div>
              <div class="data-show">
                <div class="data-title">累积个人金额</div>
                <div class="data-num">{{ formatPrice(item.personagePerformanceDTO.cycleCardAmountTotal) }}</div>
                <div class="data-unit">元</div>
              </div>
            </div>
          </div>



          <div v-if="item.inviteType === 2 && item.teamPerformanceDTO">
            <div class="line"></div>
            <div class="common-view data-view">
              <div class="common-title">团队业绩</div>
              <div class="data-display">
                <div class="data-show">
                  <div class="data-title">本月购买卡数</div>
                  <div class="data-num">{{ item.teamPerformanceDTO.cycleCardNumMonth }}</div>
                  <div class="data-unit">件</div>
                </div>
                <div class="data-show">
                  <div class="data-title">本月团队金额</div>
                  <div class="data-num">{{ formatPrice(item.teamPerformanceDTO.cycleCardAmountMonth) }}</div>
                  <div class="data-unit">元</div>
                </div>
              </div>
              <div class="data-display">
                <div class="data-show">
                  <div class="data-title">累积购买卡数</div>
                  <div class="data-num">{{ item.teamPerformanceDTO.cycleCardNumTotal }}</div>
                  <div class="data-unit">件</div>
                </div>
                <div class="data-show">
                  <div class="data-title">累积团队金额</div>
                  <div class="data-num">{{ formatPrice(item.teamPerformanceDTO.cycleCardAmountTotal) }}</div>
                  <div class="data-unit">元</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>


    <!-- <div :class="['data-fixed', dataVisible ? 'data-show' : 'data-hide']" @click="dataVisible = !dataVisible"></div> -->

  </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,

      curTab: null,
      changeEnable: false,

      list: [],
      loading: false,
      finished: false,
      currentPage: 1,
      canLoad: false,
      dataVisible: true,
      levelEnum: {
        '会员': 1,
        '店主': 2,
        '首席': 3,
        '实习店主': 4,
      }

    };
  },
  created() {
    document.title = "尊享卡客户";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getDetail();

      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetail();

        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    async getDetail() {
      this.changeEnable = false;
      console.log('currentPage', this.currentPage)

      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardClient",
          data: {
            currentPage: this.currentPage,
            pageSize: 8,
            gradeCode: this.curTab
          }
        });



        if (data.success) {
          if (data.data.length === 0) {
            this.finished = true;
          }
          this.list = [...this.list, ...data.data];

          await this.getClientSalesPerformance();

        } else {
          this.$toast(data.message);
        }
        console.log(this.list)


        // 加载状态结束
        this.loading = false;
        this.canLoad = true;
        this.changeEnable = true;
      }

    },

    async getClientSalesPerformance() {
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].isGetData) {
          const { data } = await this.$axios({
            method: "post",
            headers: {
              mac: "mac",
              clientSource: this.$clientSource(),
              Authorization: this.token
            },
            url: "/api/dist/data/clientSalesPerformance",
            data: {
              userId: this.list[i].buyerId,
              isNeedTeamPerformance: this.list[i].inviteType === 2,
            }
          });

          if (data.success) {
            this.list[i] = { ...this.list[i], ...data.data, isGetData: true };
          }
        }
      }
      this.$forceUpdate();
    },

    async onLoad() {
      if (!this.canLoad) {
        return;
      }
      this.currentPage++;
      this.getDetail();
    },

    onTabChange(gradeCode) {
      if (!this.changeEnable) {
        return;
      }
      this.curTab = gradeCode;
      this.list = [];
      this.currentPage = 1;
      this.loading = false;
      this.finished = false;
      this.getDetail();
    },

    itemDetail(buyerId) {
      this.$router.push(`/cycleCard/statisticsList?token=${this.token}&buyerId=${buyerId}&time=${Date.now()}`);
    },

    copy(str) {
      var copyDom = document.createElement("div");
      copyDom.innerText = str;
      copyDom.style.position = "absolute";
      copyDom.style.top = "0px";
      copyDom.style.right = "-9999px";
      document.body.appendChild(copyDom);
      //创建选中范围
      var range = document.createRange();
      range.selectNode(copyDom);
      //移除剪切板中内容
      window.getSelection().removeAllRanges();
      //添加新的内容到剪切板
      window.getSelection().addRange(range);
      //复制
      var successful = document.execCommand("copy");
      copyDom.parentNode.removeChild(copyDom);
      if (successful) {
        this.$toast("复制成功");
      } else {
        this.$toast("复制失败");
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F5F5F5;

  .top {
    width: 375px;
    height: 38px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .tab {
      width: 66px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
    }

    .choosed {
      width: 64px;
      border-radius: 2px;
      border: 1px solid #BCBCBC;
      color: #404040;
    }
  }

  .scroll {
    width: 375px;
    height: calc(100vh - 38px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
      width: 0;
    }


    .noList {
      margin: auto;
      margin-top: 53px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }

    .card {
      margin-top: 10px;
      width: 355px;
      height: auto;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;


      .common-view {
        display: flex;
        flex-direction: column;
        align-items: center;

        .common-title {
          margin-top: 8px;
          width: 329px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
        }

        .data-display {
          width: 329px;
          margin-top: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .data-show {
            width: 50%;
            display: flex;
            align-items: center;

            .data-title {
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              margin-right: 5px;

            }

            .data-num {
              height: 17px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: bold;
              color: #666666;
              line-height: 16px;
            }

            .data-unit {
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: bold;
              color: #666666;
            }
          }
        }
      }

      .user-view {
        width: 355px;
        height: 94px;
        background: #FAFAFA;
        border: 1px solid #EAEAEA;
        position: relative;

        .user-display {
          width: 355px;
          height: 38px;
          margin-top: 15px;
          margin-bottom: 9px;
          display: flex;
          align-items: center;

          .user-head {
            margin-left: 14px;
            margin-right: 6px;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
            background-size: 100% 100%;
          }

          .user-msg-view {
            height: 100%;
            width: auto;
            display: flex;
            flex-direction: column;

            .user-name-line {
              height: 50%;
              overflow: visible;
              display: flex;
              align-items: center;

              .name {
                max-width: 84px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #141414;
                margin-right: 4px;
              }

              .level-1 {
                width: 40px;
                height: 17px;
                background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_1.png) no-repeat;
                background-size: 100% 100%;
              }

              .level-2 {
                width: 48px;
                height: 17px;
                background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_dz_2.png) no-repeat;
                background-size: 100% 100%;
              }

              .level-3 {
                width: 40px;
                height: 17px;
                background: url(https://oss-kiddo.manqu88.com/h5/levelLabe_sx_3.png?1) no-repeat;
                background-size: 100% 100%;
              }

              .level-4 {
                width: 57px;
                height: 17px;
                background: url(https://oss-kiddo.manqu88.com/h5/levelLabe_sxdz.png?1) no-repeat;
                background-size: 100% 100%;
              }

              .role {
                width: 32px;
                height: 15px;
                background: url(https://oss-kiddo.manqu88.com/h5/cycleCard/customer/role_1.png) no-repeat;
                background-size: 100% 100%;
                margin-left: 4px;
              }
            }

            .user-phone-line {
              height: 50%;
              width: auto;
              overflow: visible;
              display: flex;
              align-items: center;

              .phone {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-right: 10px;
              }

              .copy {
                width: 28px;
                height: 14px;
                background: url(https://oss-kiddo.manqu88.com/h5/cycleCard/customer/copy.png) no-repeat;
                background-size: 100% 100%;
                margin-right: 4px;
              }
            }
          }


          .detail {
            position: absolute;
            right: 14px;
            width: 74px;
            height: 27px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #CECECE;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 9px;
            color: #666666;


            .arrow {
              width: 4px;
              height: 9px;
              background: url(https://oss-kiddo.manqu88.com/h5/cycleCard/customer/arrow.png) no-repeat;
              background-size: 100% 100%;
              margin-left: 6px;
            }
          }
        }

      }


      .data-view {
        width: 355px;
        height: 79px;
        background: #FFFFFF;
      }

      .line {
        width: 330px;
        height: 0px;
        border-bottom: 1px solid #F5F5F5;
      }



    }
  }












}
</style>
  