import { render, staticRenderFns } from "./scoreTurntable.vue?vue&type=template&id=54d03544&scoped=true&"
import script from "./scoreTurntable.vue?vue&type=script&lang=js&"
export * from "./scoreTurntable.vue?vue&type=script&lang=js&"
import style0 from "./scoreTurntable.vue?vue&type=style&index=0&id=54d03544&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d03544",
  null
  
)

export default component.exports