<template>
  <div class="wrap">
    <div class="Section1" style="layout-grid: 15.6pt">
      <p class="MsoNormal" align="center" style="text-align: center">
        <b><span style="font-family: 宋体">应用权限申请与使用情况说明</span></b>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">1</span
        ><span style="font-family: 宋体"
          >、为保障产品或服务能实现与安全稳定运行之目的，我们可能会申请或使用操作系统的相关权限；</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">2</span
        ><span style="font-family: 宋体"
          >、为保障您的知情权，我们通过下列列表将产品或服务可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal" style="text-indent: 0cm">
        <span lang="EN-US">3、</span
        ><span style="font-family: 宋体"
          >根据产品或服务的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申<a
            name="_GoBack"
          ></a
          >请与使用情况；</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">4</span
        ><span style="font-family: 宋体">、本说明适用于</span
        ><span lang="EN-US">Kiddol APP</span
        ><span style="font-family: 宋体"
          >、小程序，具体的适用范围将在以下列表中说明。</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <b><span style="font-family: 宋体">安卓操作系统应用权限列表</span></b>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：位置权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.ACCESS_FINE_LOCATION(</span
        ><span style="font-family: 宋体">精准位置</span
        ><span lang="EN-US">)</span><span style="font-family: 宋体">；</span
        ><span lang="EN-US">android.permission.ACCESS_COARSE_LOCATION(</span
        ><span style="font-family: 宋体">大概位置</span
        ><span lang="EN-US">)</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >权限功能说明：获取您当时所处的地理位置</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：为您快速填写收货地址</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：摄像头（相机）</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.CAMERA</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：使用拍摄视频、照片</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：完成视频拍摄、拍照</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：相册</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.WRITE_EXTERNAL_STORAGE(</span
        ><span style="font-family: 宋体">读取存储</span
        ><span lang="EN-US">)</span><span style="font-family: 宋体">；</span
        ><span lang="EN-US">android.permission.READ_EXTERNAL_STORAGE</span
        ><span style="font-family: 宋体">（写入存储）；</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：上传照片</span
        ><span lang="EN-US">/</span><span style="font-family: 宋体">视频</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：晒圈、问题反馈功能</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：电话</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.READ_PHONE_STATE</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：识别您的本机识别码</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：完成安全风控、进行统计和服务推送</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：外置存储器</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.WRITE_EXTERNAL_STORAGE(</span
        ><span style="font-family: 宋体">读取存储</span
        ><span lang="EN-US">)</span><span style="font-family: 宋体">；</span
        ><span lang="EN-US">android.permission.READ_EXTERNAL_STORAGE</span
        ><span style="font-family: 宋体">（写入存储）</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >权限功能说明：读取或向其中写入图片、文件、崩溃日志信息等必要信息</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：允许读取或者写入图片、文件等内容，用于提供信息发布或在本地记录崩溃日志信息等功能，保障客户端的稳定运行</span
        >
      </p>

      <p class="MsoNormal">
        <s
          ><span lang="EN-US"
            ><span style="text-decoration: none"><br></span></span
          ></s
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：剪切板</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：访问剪切板内容</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：复制、分享商品信息、标题、短信验证码</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.INTERNET</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：访问网络权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景或目的：实现应用程序联网</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.ACCESS_WIFI_STATE</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：获取</span
        ><span lang="EN-US">WiFi</span
        ><span style="font-family: 宋体">状态权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：监控网络变化，提示用户当前网络环境</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.ACCESS_NETWORK_STATE</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：获取网络状态权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：监控网络变化，提示用户当前网络环境</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.MODIFY_AUDIO_SETTINGS</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：修改声音设置权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：修改全局音频设置，例如调节音量和用于输出的扬声器</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">android.permission.WRITE_SETTINGS</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：允许写设备缓存</span
        ><span lang="EN-US">/</span
        ><span style="font-family: 宋体">允许程序读取或写入系统设置</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景或目的：移动一键登录</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <b><span lang="EN-US">iOS</span></b
        ><b><span style="font-family: 宋体">操作系统应用权限列表</span></b>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：位置</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">NSLocationAlwaysAndWhenInUseUsageDescription</span
        ><span style="font-family: 宋体">（持续获取）；</span
        ><span lang="EN-US">NSLocationAlwaysUsageDescription</span
        ><span style="font-family: 宋体">（持续获取）；</span
        ><span lang="EN-US">NSLocationWhenInUseUsageDescription(</span
        ><span style="font-family: 宋体">使用</span><span lang="EN-US">App</span
        ><span style="font-family: 宋体">期间</span><span lang="EN-US">)</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >权限功能说明：添加收货地址时，使用定位可快速填写详细地址</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：为您快速填写收货地址</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：摄像头（相机）</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">NSCameraUsageDescription</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限功能说明：拍摄照片、视频</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景或目的：完成视频拍摄、拍照</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限类型：相册</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">权限名称：</span
        ><span lang="EN-US">NSPhotoLibraryAddUsageDescription(</span
        ><span style="font-family: 宋体">相册添加</span
        ><span lang="EN-US">)</span><span style="font-family: 宋体">；</span
        ><span lang="EN-US">NSPhotoLibraryUsageDescription(</span
        ><span style="font-family: 宋体">相册使用</span
        ><span lang="EN-US">)</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >权限功能说明：分享、售后时上传或保存图片和视频</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景或目的：分享、售后功能</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "illustrate",
  components: {},

  data() {
    return {};
  },
  created() {
    // window.location.href = 'https://static.manqu88.com/kiddo-privacy.html'
    document.title = "应用权限说明";
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 0;
  // min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  text-align: left;
}
.cotent {
  width: 100%;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 25px;
  margin-top: 0px;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
}
p{
  margin: 5px 15px;
}
span{
        word-break: break-all;
      white-space: pre-wrap;
}

</style>
