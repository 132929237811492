<template>
	<div class="reward-rain-award-wrap" >
		<div 
		class="counpon-ul" >
			<div  
			v-for="(item, index) in prizeData"
			:key="index"
			@click="handleToDetail(item)"
			class="counpon-li">
				<div class="prize-name" >
					{{ item.turntableDTO.prizeName }}
				</div>
				<div class="prize-time" >
					获得时间：{{ formatDate(item.gmtCreate) }}
				</div>
			</div>
		</div>
		
		<!-- 空值 -->
		<div v-if="!load && !prizeData.length" class="empty-box" >
			<img  class="empty-box-img" src="https://kiddo-bucket.manqu88.com/empty-order.png" alt="">
			<div class="empty-box-text" >啥都没有呢</div>
		</div>
		
	</div>
</template>

<script>
	import { formatDate } from "@/utils/time";
	export default {
		data() {
			return {
				token: this.$route.query.token,
				isMini: true,
				prizeData: [],
				load: true
			}
		},
		mounted() {
			document.title = '我的奖品';
			
			uni.webView.getEnv(res => {
			  if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
			    this.isMini = true;
			    this.token = this.$route.query.token;
				
				// 我的奖品
				this.getMyAward();
			  } else {
			    this.isMini = false;
			    this.$bridge.call("getToken", {}).then(e => {
			      console.log("getToken客户端返回: ", e);
			      this.token = e;
				  
				  // 我的奖品
				  this.getMyAward();
			    });
			  }
			});
		},
		methods: {
			formatDate,
			toClientLogin() {
			  if (!this.token || JSON.stringify(this.token) == "{}") {
			    this.$bridge.call("openPage", { type: 26 });
			    return;
			  }
			},
			async getMyAward() {
				  const {
				    data: { data, success, message, code }
				  } = await this.$axios({
				    method: "post",
				    headers: {
				      mac: "mac",
				      clientSource: this.$clientSource(),
				      Authorization: this.token
				    },
				    url: "/api/prizeRecord/queryList",
				    data: {
				      type: 13,
				      startTime: new Date("2023/01/18 00:00:00").getTime()
				    }
				  });
				  if (success) {
				    if (data) {
				      this.prizeData = [...data];
				    } else {
				      this.prizeData = [];
				    }
				  } else {
				    this.$toast(message);
				    this.prizeData = [];
				    if (code === 987654322) {
				      if (this.isMini) {
				        uni.webView.reLaunch({
				          url: "/pagesA/login/index"
				        });
				      } else {
				        this.toClientLogin();
				      }
				    }
				
				    if (code === 100039 && this.isMini) {
				      uni.webView.navigateTo({
				        url: "/pagesA/login/invitationCode"
				      });
				    }
				  }
				  
				  
				  this.load = false;
			},
			handleToDetail(item) {
				const prizeType = item.prizeType;
				if(prizeType == 1) {
					// 优惠券
					uni.webView.getEnv(res => {
					  if (res.miniprogram) {
					    uni.webView.navigateTo({
					      url: `/pagesA/coupon/index`
					    });
					  } else {
					    this.$bridge
					      .call("openPage", {
					        type: 5,
					        param: 1
					      })
					      .then(e => {
					        console.log("jumpToDetail客户端返回: ", e);
					      });
					  }
					});
				}else if(prizeType == 4) {
					// 金币
					this.$router.push(`/goldDetail?token=${this.token}&time=${Date.now()}`);
				}
			}
		}
		
	}
</script>

<style lang="scss" scoped >
	.reward-rain-award-wrap{
		min-height: 100vh;
		padding: 15px 0 40px 0;
		background: #f5f5f5;
		
		.empty-box{
			margin-top: 236px;
			.empty-box-img{
				display: block;
				height: 84px;
				margin: auto;
				margin-bottom: 14px;
			}
			.empty-box-text{
				height: 20px;
				line-height: 20px;
				font-size: 14px;
				font-weight: 300;
				text-align: center;
				color: #404040;
			}
		}
		
		.counpon-ul{
			.counpon-li{
				position: relative;
				width: 341px;
				height: 88px;
				padding-left: 20px;
				padding-top: 12px;
				margin: auto;
				margin-bottom: 12px;
				background: url('https://kiddo-bucket.manqu88.com/reward-rain-counpon-bg.png') no-repeat center;
				background-size: cover;
				box-sizing: border-box;
				.prize-name{
					max-width: 200px;
					height: 25px;
					margin-top: 4px;
					line-height: 25px;
					font-weight: 600;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-size: 18px;
					color: #C90E09;
				    white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
				}
				.prize-time{
					height: 16px;
					margin-top: 14px;
					line-height: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
				}
			}
		}
	}
</style>