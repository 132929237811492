<template>
  <div class="main">
    <div class="swiper-div">
      <van-swipe
        :autoplay="0"
        :loop="false"
        :show-indicators="false"
        :touchable="touchable"
        @change="onSwiperChange"
        ref="swiper"
      >
        <van-swipe-item class="swiper-item" v-for="(image, index) in resouceData" :key="index">
          <img class="swiper-item" :src="`${resouceData[index].bg}`" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="arrow-left"
      v-if="activeIndex > 0 && !level"
      @click="doSwiperChange(activeIndex - 1)"
    ></div>
    <div
      class="arrow-right"
      v-if="activeIndex < 2 && !level"
      @click="doSwiperChange(activeIndex + 1)"
    ></div>
    <div class="curLevel" v-if="activeIndex === userInfo.gradeCode - 1 && !level">当前等级</div>

    <div class="title" style="margin-top: 25px; margin-bottom: 8px">
      <div>{{ activeIndex > 0 ? "通道任务" : "升级任务" }}</div>
      <div>
        <img
          class="enName"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/Upgradetask.png"
          alt=""
        />
      </div>
    </div>
    <div class="part1">
      <div class="box">
        <div class="buy2">
          <div>
            <img class="icon" :src="resouceData[activeIndex]['icon_1']" alt="" />
          </div>
          <div class="txt" v-if="activeIndex == 0">注册成功即可升级为KC会员</div>
          <div class="txt" v-if="activeIndex == 1">
            <div style="text-align: left">充值2680元货款可成为KB</div>
          </div>
          <div class="txt" v-if="activeIndex == 2">充值16800元货款可成为KA</div>
        </div>
        <div v-if="userInfo.gradeCode < activeIndex + 1 && activeIndex == 1">
          <div class="pushMoney" @click="checkProgress" v-if="status1 != 3 && processEndFlag1 != 1">
            查看进度
          </div>
          <div v-else class="pushMoney" @click="payUpdate" style="background-color:#FBC766">
            立即充值
            <div class="addtips">
              充值无响应请更新最新版本
            </div>
          </div>
        </div>
        <div v-if="userInfo.gradeCode < activeIndex + 1 && activeIndex == 2">
          <div class="pushMoney" @click="checkProgress" v-if="status != 3 && processEndFlag != 1">
            查看进度
          </div>
          <div v-else class="pushMoney" @click="payUpdate" style="background-color:#DA2D30">
            立即充值
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeIndex == 1" class="updaterule">
      <div style="display: flex; align-items: center">
        <div>
          <img class="icon" :src="resouceData[activeIndex]['icon_2']" alt="" />
        </div>
        <div class="txt">同时满足规则1和规则2可成为KB</div>
      </div>
      <div class="rule">
        <div class="name">
          <div>规则1</div>
          <img src="@/assets/line.png" alt="" />
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">邀请确认订单金额满¥168的用户</div>
            <div class="box" v-if="userInfo.gradeCode < 2">
              {{ forEverKC >= 6 ? "已完成" : forEverKC + "/6" }}
            </div>
          </div>
        </div>
      </div>
      <div class="rule">
        <div class="name">
          <div>规则2</div>
          <img src="@/assets/line.png" alt="" />
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">累计消费1680元</div>
            <div class="box" v-if="userInfo.gradeCode < 2">
              {{ Number(finishConsumerAmount) >= 1680 ? "已完成" : finishConsumerAmount + "/1680" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeIndex == 2" class="updaterule">
      <div style="display: flex; align-items: center">
        <div>
          <img class="icon" :src="resouceData[activeIndex]['icon_2']" alt="" />
        </div>
        <div class="txt">分别满足规则1和规则2中各1条可成为KA</div>
      </div>
      <div class="rule">
        <div class="name">
          <div>规则1</div>
          <img src="@/assets/line.png" alt="" />
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">邀请永久KB 6人</div>
            <div class="box" v-if="userInfo.gradeCode < 3">
              {{ forEverKB >= 6 ? "已完成" : forEverKB + "/6" }}
            </div>
          </div>
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">所有下级永久会员达到100人</div>
            <div class="box" v-if="userInfo.gradeCode < 3">
              {{ forEverDistUser >= 100 ? "已完成" : forEverDistUser + "/100" }}
            </div>
          </div>
        </div>
      </div>
      <div class="rule">
        <div class="name">
          <div>规则2</div>
          <img src="@/assets/line.png" alt="" />
          <div class="shuju" v-if="userInfo.gradeCode < 3">数据更新于:{{ gmtModified }}</div>
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">
              <div>团队业绩达到150000元</div>
              <div class="des">直邀下级用户中最大团队业绩占比需小于70%</div>
            </div>
            <div class="box" v-if="userInfo.gradeCode < 3">
              <div v-if="qualified == 1">
                {{ Number(teamAmount) >= 150000 ? "已完成" : teamAmount + "/15万" }}
              </div>
              <div v-else>
                <div style="color:#FF0000;">占比小于30%</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 5px">
          <div class="rulelevel">
            <div class="text">累计消费50000元</div>
            <div class="box" v-if="userInfo.gradeCode < 3">
              {{ Number(finishConsumerAmount) > 50000 ? "已完成" : finishConsumerAmount + "/5万" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tips" v-if="activeIndex == 1 || activeIndex == 2">
      注：升级任务中个人和团队累计消费金额对应的订单需确认收货后才会生效；永久会员即升级条件对应的订单已确认收货或充值订单完成7日后；
    </div>
    <div class="title" style="margin-top: 17px; margin-bottom: 8px">
      等级权益
    </div>
    <div :class="[`grade-${activeIndex + 1}`]"></div>

    <div class="mask" v-if="showTips">
      <div class="card">
        <div class="img"></div>
        <div class="btn" @click="showTips=false">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { formatPrice } from "../utils/money";
import moment from "moment";
export default {
  data() {
    return {
      token: this.$route.query.token,
      level: this.$route.query.level,
      touchable: true,
      resouceData: [
        {
          bg: "https://oss-kiddo.manqu88.com/h5/memberCenter/KC.png",
          icon_1: "https://oss-kiddo.manqu88.com/h5/memberCenter/labelKc_1.png"
        },
        {
          bg: "https://oss-kiddo.manqu88.com/h5/memberCenter/KB.png",
          icon_1: "https://oss-kiddo.manqu88.com/h5/memberCenter/labelKb_1.png",
          icon_2: "https://oss-kiddo.manqu88.com/h5/memberCenter/IconKb_2.png"
        },
        {
          bg: "https://oss-kiddo.manqu88.com/h5/memberCenter/KA.png",
          icon_1: "https://oss-kiddo.manqu88.com/h5/memberCenter/labelKa_1.png",
          icon_2: "https://oss-kiddo.manqu88.com/h5/memberCenter/IconKa_2.png"
        }
      ],
      userInfo: {},
      activeIndex: 0,
      swiper: {},
      intermediateGradeAmount: 0,
      currentTotalConsume: 0,
      sub: 0,
      myprogress: 0,
      finishConsumerAmount: 0,
      forEverKB: 0,
      forEverKC: 0,
      forEverDistUser: 0,
      teamAmount: null,
      processEndFlag: 1,
      status: 3,
      processEndFlag1: 1,
      status1: 3,
      type: this.$route.query.type,
      gmtModified: 0,
      qualified: 1,
      showTips: false
    };
  },
  created() {
    document.title = "会员中心";
    console.log(this.isBanner);
    this.getUserInfo();
    this.nextLevel();
    this.getOfflineRemitRecord();
    this.getOfflineRemitRecordKB();
  },
  mounted() {
    this.swiper = this.$refs.swiper;
    const limitTime = new Date("2022/09/01 00:00:00").getTime();
    console.log("1111", Date.now(), limitTime);
  },
  methods: {
    //基本信息
    async getUserInfo() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/dist/user/getDistUserInfo",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.userInfo = data.data;
        let num = 1;
        if (this.userInfo.gradeCode === 1) {
          num = 0;
        }
        if (this.userInfo.gradeCode === 2) {
          num = 1;
        }
        if (this.userInfo.gradeCode === 3) {
          num = 2;
        }
        if (this.level) {
          this.swiper.swipeTo(this.level - 1);
          this.touchable = false;
        } else {
          this.swiper.swipeTo(2);
          // if (this.type == 1) {
          //   this.swiper.swipeTo(1);
          // } else {
          //   console.log("num:", num);
          //   this.swiper.swipeTo(num);
          // }
        }
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    //查看邀请消费数据
    async nextLevel() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/member/queryUpgradeProgress",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.currentTotalConsume = formatPrice(data.data.consumerAmount).split(".")[0];
        this.finishConsumerAmount = formatPrice(data.data.finishConsumerAmount || 0).split(".")[0];
        this.forEverKB = data.data.forEverKB || 0;
        this.forEverKC = data.data.forEverKC || 0;
        this.forEverDistUser = data.data.forEverDistUser || 0;
        this.teamAmount = formatPrice(data.data.teamAmount || 0).split(".")[0];
        this.qualified = data.data.qualified;
        this.gmtModified = moment(data.data.gmtModified).format("YYYY.MM.DD");
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    async getOfflineRemitRecord() {
      let payType = 1;
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/offlineRemit/queryOfflineRemitRecord",
        data: { payType }
      });
      this.processEndFlag = data.data.processEndFlag;
      this.status = data.data.status;
    },
    async getOfflineRemitRecordKB() {
      let payType = 2;
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/offlineRemit/queryOfflineRemitRecord",
        data: { payType }
      });
      this.processEndFlag1 = data.data.processEndFlag;
      this.status1 = data.data.status;
    },
    //查看进度
    checkProgress() {
      let payType = "";
      if (this.$clientSource() === "2") {
        uni.webView.navigateTo({
          url: `/pagesA/manualtransfer/index?level=${this.activeIndex + 1}`
        });
      } else {
        if (this.activeIndex == 2) {
          payType = 1;
        }
        if (this.activeIndex == 1) {
          payType = 2;
        }
        this.$bridge.call("checkProgress", { payType }).then(e => {
          console.log("客户端返回: ", e);
        });
      }
    },
    payUpdate() {
      const limitTime = new Date("2022/09/01 00:00:00").getTime();
      console.log("1111", Date.now(), limitTime);
      if (Date.now() >= limitTime) {
        this.showTips = true;
        return;
      }
      if (this.$clientSource() === "2") {
        uni.webView.navigateTo({
          url: `/pagesA/paycheck/paystatus?isSuccess=0&level=${this.activeIndex + 1}`
        });
      } else {
        if (this.activeIndex == 2) {
          this.$bridge.call("payUpdate", {}).then(e => {
            console.log("客户端返回: ", e);
          });
        } else {
          this.$bridge
            .call("payUpdateKB", {})
            .then(e => {
              console.log("客户端返回: ", e);
            })
            .catch(e => {
              console.log(e);
              Toast("请更新APP到最新版本");
            });
        }
      }
    },
    jumpToMiniHome() {
      this.$bridge.call("jumpToMiniHome", {}).then(e => {
        console.log("客户端返回: ", e);
      });
    },
    jumpToLevelIndex() {
      this.$bridge.call("jumpToLevelIndex", { level: this.activeIndex + 1 }).then(e => {
        console.log("客户端返回: ", e);
      });
    },
    toPay() {
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "此充值仅用于消费充值，不支持会员升级充值，如需升级充值，请到KA,KB的升级通道进行付款。"
        })

        .then(() => {
          this.$router.push("/pay?token=" + this.token);
        });
    },
    onSwiperChange(index) {
      this.activeIndex = index;
    },
    doSwiperChange(index) {
      if (index >= 0 && index < 3) {
        this.swiper.swipeTo(index);
        // this.activeIndex = index;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-color: #f5f5f5;
  padding-bottom: 80px;
  .swiper-div {
    position: relative;
    width: 100vw;
    height: 340px;
    margin-bottom: -69px;
    z-index: 0;
    .swiper-item {
      width: 100vw;
      height: 340px;
      z-index: 0;
    }
  }
  .arrow-left {
    position: absolute;
    left: 10px;
    top: 140px;
    width: 38px;
    min-height: 40px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/arrow_left.png") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
  }
  .arrow-right {
    position: absolute;
    right: 10px;
    top: 140px;
    width: 38px;
    min-height: 40px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/arrow_right.png") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
  }
  .curLevel {
    position: absolute;
    right: 142px;
    top: 255px;
    width: 92px;
    height: 26px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/curLevelBg.png") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.slideitem {
  height: 100px;
}
.title {
  position: relative;
  text-align: left;
  color: #232323;
  font-weight: bold;
  font-family: PingFangSC-Bold, PingFang SC;
  font-size: 16px;
  padding-left: 13px;
  padding-right: 13px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  .enName {
    width: 138px;
    height: 17px;
    z-index: 1;
  }
}
.grade-1 {
  position: relative;
  z-index: 1;
  margin: auto;
  width: 362px;
  height: 100px;
  background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/ruleKC.png") no-repeat;
  background-size: 100% 100%;
}
.grade-2 {
  position: relative;
  z-index: 1;
  margin: auto;
  width: 362px;
  height: 100px;
  background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/ruleKB.png") no-repeat;
  background-size: 100% 100%;
}
.grade-3 {
  position: relative;
  z-index: 1;
  margin: auto;
  width: 362px;
  height: 177px;
  background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/ruleKA.png") no-repeat;
  background-size: 100% 100%;
}
.icon {
  width: 28px;
  height: 28px;
}
.part1 {
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
  border-radius: 17px;
  box-shadow: 0px 0px 12px 0px rgba(221, 221, 221, 0.5);
  background: #ffffff;
  padding-top: 17px;
  padding-bottom: 17px;

  .buy,
  .buy2 {
    display: flex;
    align-items: center;
    padding: 0 17px;
    margin-bottom: 17px;
    img {
      width: 32px;
      height: 32px;
    }
    .txt {
      color: #666666;
      font-family: PingFangSC;
      font-size: 14px;
      margin-left: 9px;
    }
  }
  .box {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
    .btn {
      color: #ffffff;
      font-size: 8px;
      font-weight: 500;
      width: 48px;
      border-radius: 13px;
      background: #f9550a;
      height: 26px;
      line-height: 26px;
      margin-right: 20px;
    }
    .over {
      background: #d8d8d8;
    }
    .buy2:first-child {
      margin-bottom: 0;
    }
  }

  .buy:last-child,
  .box:last-child {
    margin-bottom: 0;
  }
}
.smallbg {
  background-image: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/smallbg.png");
  background-size: 200px 250px;
  background-repeat: no-repeat;
  background-position: right 30px;
  min-height: 279px;
}
.bgc {
  // background-image: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/bgc.png");
  background-image: unset;
  height: auto;
  min-height: unset;
}
.bgb {
  background-image: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/bgb.png");
}
.tusted_box {
  .swiper-slide {
    position: relative;
    font-size: 18px;
    // background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    // box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    .levelname {
      position: absolute;
      text-align: left;
      left: 26px;
      top: 20px;
      .tit {
        color: #404040;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 22px;
        position: relative;
        top: -25px;
      }
      .name {
        color: #747474;
        font-family: ArialMT;
        font-size: 65px;
        position: relative;
        img {
          width: 82px;
          // height: 47px;
        }
      }
      .op {
        opacity: 0.2;
      }
    }
    .progress {
      position: absolute;
      text-align: left;
      left: 50px;
      top: 109px;
      width: 166px;
      .line {
        width: 100%;
        height: 5px;
        background: #f9550a;
        border-radius: 6px;
      }
      .des {
        margin-top: 5px;
        color: #747474;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 10px;
      }
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
  }
  .trus_img {
    img {
      // height: 150px;
      border-radius: 4px;
      box-shadow: 0px 0px 12px 0px rgba(221, 221, 221, 0.5);
    }
  }

  .swiper-slide-prev {
    right: -8px;
  }
  .swiper-slide-next {
    left: -8px;
  }
  .swiper-pagination {
    text-align: center;
    width: 100%;
    bottom: -50px;
  }
  .tusted_box {
    position: relative;
  }
  .swiper-pagination-bullet {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 1px solid #4a9a83;
    border-radius: 50%;
    background: transparent;
    margin: 0 15px;
    vertical-align: middle;
    opacity: 1;
    outline: none;
  }
  .swiper-pagination-bullet-active {
    background: #4a9a83;
    width: 20px;
    height: 20px;
  }
}
.tips {
  margin: 12px 20px 0;
  color: #666666;
  font-weight: 300;
  font-family: PingFangSC-Light, PingFang SC;
  font-size: 10px;
  text-align: left;
}
.updaterule {
  padding: 17px;
  margin-left: 13px;
  margin-right: 13px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
  margin-top: 10px;
  .txt {
    color: #666666;
    font-family: PingFangSC;
    font-size: 14px;
    margin-left: 9px;
  }
  .rule {
    border-radius: 7px;
    background: #fafafa;
    padding: 11px 15px;
    margin-top: 16px;
    .name {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #404040;
      font-weight: 500;
      display: flex;
      align-items: center;
      position: relative;
      .shuju {
        position: absolute;
        font-size: 10px;
        color: #a3a3a3;
        right: 0;
        background: #fafafa;
        margin: auto 0;
      }
      img {
        display: block;
        width: 240px;
        height: 7px;
        margin-left: 6px;
      }
    }
    .rulelevel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        color: #666666;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 13px;
        text-align: left;
        .des {
          color: #919191;
          font-weight: 300;
          font-family: PingFangSC-Light, PingFang SC;
          font-size: 10px;
          width: 201px;
        }
      }
      .box {
        text-align: center;
        min-width: 84px;
        border-radius: 12px;
        background: #ffffff;
        height: 23px;
        font-size: 12px;
        color: #666666;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 23px;
      }
    }
  }
}
.hadbuy {
  font-size: 13px;
  color: #fff;
  border-radius: 13px;
  height: 26px;
  line-height: 27px;
  min-width: 80px;
  background: #dedede;
  margin-right: 20px;
}
.pushMoney {
  width: 80px;
  border-radius: 13px;
  background: #323cb3;
  height: 26px;
  color: #fff;
  line-height: 26px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  text-align: center;
  margin-right: 20px;
}
.overBg {
  color: #fff;
  border-radius: 13px;
  background: #ff7b3e;
}
.wid {
  width: 279px;
  height: 126.85px;
}
.lt {
  margin-right: 11px;
  width: 8px;
  height: 10px;
}
.rt {
  margin-left: 11px;
  width: 8px;
  height: 10px;
}
.addtips {
  position: absolute;
  bottom: -2.5px;
  right: 6px;
  font-weight: 400;
  text-align: left;
  font-size: 10px;
  color: #919191;
}

.pay-text-area {
  margin-top: 17px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.text-pay {
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 18px;
}

.mask {
  position: fixed;
  z-index: 19999999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 288px;
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      margin-top: 28px;
      width: 239px;
      height: 178px;
      background: url(https://oss-kiddo.manqu88.com/h5/memberCenter/memberTips.png) no-repeat;
      background-size: 100% 100%;
    }

    .btn {
      margin-top: 22px;
      width: 195px;
      height: 43px;
      background: #323cb3;
      border-radius: 58px;
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
