<template>
  <div class="ck-wheel">
    <div
      :class="['rules-1']"
      :style="{
        backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/gold/invitationRules.png?${Date.now()})`
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "金币说明";
  },
  data() {
    return {
      token: this.$route.query.token,
      type: 1
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rules-1 {
  width: 353px;
  height: 722px;
  background: url(https://oss-kiddo.manqu88.com/h5/assist/rule_2.png?123) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 42px;
}
</style>
