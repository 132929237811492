var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{class:['ck-wheel', _vm.type === 2 ? 'background-2' : 'background-1']},[_c('div',{staticClass:"share",on:{"click":_vm.onShare}}),_c('div',{staticClass:"msgView"},[_c('van-notice-bar',{staticClass:"notice",attrs:{"scrollable":false}},[_c('van-swipe',{staticClass:"notice-swipe",attrs:{"vertical":"","autoplay":3000,"duration":1000,"show-indicators":false,"touchable":false}},_vm._l((_vm.prizeTurnTableDTO),function(item,index){return _c('van-swipe-item',{key:index},[_vm._v(_vm._s(item))])}),1)],1)],1),_c('div',{staticClass:"title"}),_c('div',{staticClass:"tableBg"}),_c('div',{staticClass:"wheel"},[_c('canvas',{ref:"wheelCanvas",staticClass:"item",attrs:{"id":"wheelCanvas","width":"1368px","height":"1368px"}}),_c('div',{staticClass:"choosed"}),_c('div',{staticClass:"pointer"})]),_c('div',{staticClass:"btn-draw",on:{"click":_vm.onClick}}),_c('div',{staticClass:"btn-goSuper",on:{"click":function($event){return _vm.goPage(2)}}}),_c('div',{staticClass:"btn-goDuihuan",on:{"click":function($event){return _vm.goPage(5)}}}),(_vm.showModal)?_c('div',{staticClass:"mask",on:{"click":_vm.stopClick}},[_c('div',{staticClass:"bgGet",style:({
          backgroundImage: ("url(" + (_vm.popType === 1
              ? 'https://oss-kiddo.manqu88.com/h5/11turnTable/noScore.png'
              : _vm.popType === 0
              ? 'https://oss-kiddo.manqu88.com/h5/11turnTable/noCounts.png'
              : 'https://oss-kiddo.manqu88.com/h5/11turnTable/lucky_getBg.png') + ")")
        })},[(_vm.popType === 2)?_c('div',{staticClass:"prize",style:({
            backgroundImage: ("url(" + ('https://oss-kiddo.manqu88.com/h5/11turnTable/lucky_prize_' +
              _vm.stopIndex +
              '.png?' +
              Date.now()) + ")")
          })}):_vm._e(),_c('div',{staticClass:"btn-close",on:{"click":function($event){_vm.showModal = false}}}),_c('div',{staticClass:"btn-goRecord",on:{"click":function($event){return _vm.jumpToMain()}}})])]):_vm._e()]),_c('div',{staticClass:"bg-1"},[_c('div',{staticClass:"btn-left",on:{"click":function($event){return _vm.showDuihuan(306, 170)}}}),_c('div',{staticClass:"btn-right",on:{"click":function($event){return _vm.showDuihuan(307, 320)}}}),_vm._l((_vm.duihuanList),function(item,index){return _c('div',{key:index,class:['item-' + (index + 1)],on:{"click":function($event){return _vm.goodsDetail(item)}}})}),_vm._l((_vm.duihuanList),function(item,index){return _c('div',{key:index,class:['duihuan-' + (index + 1)],on:{"click":function($event){return _vm.showDuihuan(item.lotteryPrizeId, 0)}}})})],2),_c('div',{staticClass:"bg-2"}),(_vm.showConfirmFrame)?_c('div',{staticClass:"shadow"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"btn-close",on:{"click":function($event){_vm.showConfirmFrame = false}}}),_c('div',{staticClass:"card-title"},[_vm._v("确认兑换该奖品吗？")]),_c('div',{staticClass:"btn-view"},[_c('div',{staticClass:"btn-cancel",on:{"click":function($event){_vm.showConfirmFrame = false}}},[_vm._v("取消")]),_c('div',{staticClass:"btn-confirm",on:{"click":_vm.duihuan}},[_vm._v("确定兑换")])])])]):_vm._e(),_c('div',{staticClass:"label label-1",on:{"click":function($event){return _vm.goPage(1)}}}),_c('div',{staticClass:"label label-2",on:{"click":function($event){return _vm.goPage(0)}}}),_c('div',{staticClass:"label-3",on:{"click":function($event){return _vm.goPage(3)}}},[_c('div',{staticStyle:{"margin-left":"8px"}},[_vm._v("我的活力值:")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.score))])]),_c('div',{staticClass:"label-4",on:{"click":function($event){return _vm.goPage(4)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }