<template>
  <div class="ck-wheel">
    <div class="back" v-if="showBack" @click="goBack"></div>
    <div class="poster">
      <img class="poster-img" src="https://oss-kiddo.manqu88.com/teamGruop/poster.png" alt="" />
    </div>

    <!-- <div class="left-num"></div>
    <div class="tips"></div> -->

    <div class="btn" @click="share">立即邀请</div>

    <div class="rule"></div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      showBack: false,
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      inviteCode: "",
      leftNum: 0,
      url: "https://a.app.qq.com/o/simple.jsp?pkgname=com.mq.kiddo.mall"
    };
  },
  created() {
    this.showBack = this.$route.query.showBack ? true : false;
    document.title = "实习推荐官邀请";
  },
  async mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
        });
      }
    });
  },

  methods: {
    formatPrice,
    goBack() {
      this.$router.go(-1);
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/distGrouponLeader/queryInviteCount",
        data: {}
      });
      if (data.success) {
        this.leftNum = data.data || 0;
      } else {
        this.$toast(data.message);
      }
    },

    async share() {
      if(true){
        this.$toast('活动已结束~');
        return
      }
      let inviteCode;
      let levelUpCode;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/distGrouponLeader/getInviteCode",
        data: {}
      });
      if (data.success) {
        levelUpCode = data.data.token;
        inviteCode = data.data.userCode;
      } else {
        this.$toast(data.message);
        return;
      }

      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/superGroupPackage/gruopLeaderLevelUp/share?inviteCode=${inviteCode}&levelUpCode=${levelUpCode}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 29,
              param: JSON.stringify({
                inviteCode: inviteCode,
                levelUpCode: levelUpCode
              })
            })
            .then(e => {
              console.log("openPage29客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 741px;
  background: url(https://oss-kiddo.manqu88.com/h5/superGruopBuy/bg.png?1) no-repeat;
  background-size: 100% 100%;
  .back {
    position: fixed;
    left: 13px;
    top: 42px;
    width: 110px;
    height: 30px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/btn_backToMember.png?11")
      no-repeat;
    background-size: 100% 100%;
  }

  .poster {
    margin-top: 84px;
    width: 212px;
    height: 267px;
    background: linear-gradient(180deg, #3679ff 0%, #0a44cb 100%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .poster-img {
      width: 202px;
      height: 257px;
    }
  }

  .left-num {
    margin-top: 29px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .tips {
    margin-top: 3px;
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .btn {
    margin-top: 50px;
    width: 230px;
    height: 44px;
    background: linear-gradient(180deg, #fc7223 0%, #f9550a 100%);
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    width: 375px;
    height: 262px;
    background: url(https://oss-kiddo.manqu88.com/h5/superGruopBuy/invitationRule.png?2) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
