<template>
  <div class="">
    <div :class="['ck-wheel', type === 2 ? 'background-2' : 'background-1']">
      <!-- 通知栏 -->
      <div class="msgView">
        <van-notice-bar class="notice" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :duration="1000"
            :show-indicators="false"
            :touchable="false"
          >
            <van-swipe-item v-for="(item, index) in prizeTurnTableDTO" :key="index">{{
              item
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>

      <!-- 转盘 -->
      <div class="wheel">
        <canvas
          class="item"
          ref="wheelCanvas"
          id="wheelCanvas"
          width="1368px"
          height="1368px"
        ></canvas>
        <div class="choosed"></div>
        <div class="pointer-up" v-if="pointerStatus === 0" @click="onClick"></div>
        <div class="pointer" v-else>
          <!-- <div class="drawtitle">立即抽奖</div>
          <div class="needScore">({{ lotteryTimesData.lotteryConsumeScore }}积分)</div> -->
        </div>
      </div>

      <div class="label label-1" style="top: 132px" @click="goPage(1)"></div>
      <div class="label label-2" style="top: 168px" @click="goPage(0)"></div>
      <!-- gif -->
      <img
        v-if="pointerStatus !== 0"
        class="goldGif"
        :src="'https://oss-kiddo.manqu88.com/h5/618turnTable/goldGif.gif?' + Date.now()"
      />

      <!-- 中奖弹窗 -->
      <div v-if="showModal" class="mask" @click="stopClick">
        <div class="bgGet">
          <div
            class="noTimes"
            v-if="popType < 2"
            :style="{
              backgroundImage: `url(${
                popType === 0
                  ? 'https://oss-kiddo.manqu88.com/h5/618turnTable/noTimes.png?'
                  : 'https://oss-kiddo.manqu88.com/h5/618turnTable/noTimes_2.png?' + Date.now()
              })`
            }"
          ></div>
          <div class="winView" v-else>
            <div class="winLabel"></div>
            <div class="goldView">
              <div class="gold">{{ parseGold(drwaData.value) }}</div>
              <div class="unit">金币</div>
            </div>
            <div class="winTips"></div>
          </div>
          <div class="btnClose" @click="showModal = false"></div>
          <div class="btn" @click="onBtn(popType)">
            <div class="btnStr">&nbsp;{{ popType === 2 ? "立即查看" : "去首页逛逛" }} ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg">
      <div class="btnView">
        <div class="left">
          <div class="leftTitle"></div>
          <div class="leftNum">{{ lotteryTimesData.remainTimes }}</div>
        </div>
        <div class="right" @click="getMoreChance"></div>
      </div>
      <div class="tip"></div>
      <div class="tips">
        <div class="tips-top">
          距离下次抽奖机会还差：
          <span class="gold-top">{{ formatPrice(thresholdAmount - scheduleAmount) }}</span
          >元
        </div>
        <div class="progressBg">
          <div class="progess-left"></div>
          <div class="progress" :style="{ width: progressWidth + 'px' }"></div>
          <div class="progess-right"></div>
        </div>
        <div class="arrowView">
          <div class="arrow">
            {{ formatPrice(scheduleAmount) }}
            <div class="arrow-top"></div>
          </div>
          <div class="target">{{ formatPrice(thresholdAmount) }}<span class="unit">元</span></div>
        </div>
        <div class="tips-bottom">
          <div class="des">
            当前累计实付金额：<span class="gold">{{ formatPrice(totalAmount) }}</span
            >元
          </div>
          <div class="des">
            已抽次数:<span class="gold">{{ timesPlayed }}</span
            >次
          </div>
        </div>
        <div class="tipsDes">小贴士：活动期间若发生退款，会影响抽奖金币正常发放哦～</div>
      </div>
      <!-- <div class="goSuperView">
        <div class="goSuper"></div>
      </div> -->
      <div class="rule"></div>
      <div class="logo"></div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice, parseGold } from "@/utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      lotteryTimesData: {
        score: 0,
        timesPlayedToday: 0,
        lotteryConsumeScore: 50
      },
      prizeType: 2,
      curItemUrl: "",
      curPrizeName: "",
      drwaData: {},
      token: this.$route.query.token,
      pointerStatus: 0,
      type: this.$route.query.type || 7, //活动类型（6：618幸运大转盘；7:618超级大转盘）
      progressWidth: 0,
      showModal: false,
      popType: 2, //0:今日次数不足 1:抽奖次数不足 2:中奖
      prizeTurnTableDTO: [],
      useNum: 0, //剩余抽奖次数
      totalUseNum: 0, //累计抽奖次数
      totalAmount: 0, //类型：Number  必有字段  备注：累计消费金额
      thresholdAmount: 0, //类型：Number  必有字段  备注：门槛金额
      scheduleAmount: 0, //类型：String  必有字段  备注：进度金额
      timesPlayed: 0,
      canClick: false,
      img_bg: "",
      turnTime: 30,
      turnAngle: 15,
      turnLoop: 4,
      curIntervalId: 0,
      stopIndex: 1,
      prizeRecordId: 0,
      rewardList: [
        //逆时针
        {
          "288金币": { index: 1 },
          "258金币": { index: 2 },
          "218金币": { index: 3 },
          "188金币": { index: 4 },
          "158金币": { index: 5 },
          "118金币": { index: 6 },
          "388金币": { index: 7 },
          "328金币": { index: 8 }
        }
      ],
      firstIn: true
    };
  },
  created() {
    if (this.type == 2) {
      this.type = 7;
    }
    document.title = "618超级大转盘最高可得388金币";
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.drawWheelCanvas();
    // this.getTurnTableDate(); //test
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.canClick = true;
          this.token = this.$route.query.token;
          if (!this.token || this.token == "") {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
            return;
          }
          this.getTurnTableDate();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            this.canClick = true;
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getTurnTableDate();
          });
        }
      });
    }, 1000);
  },

  methods: {
    formatPrice,
    parseGold,
    async getDrawCounts() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/lottery/queryLotteryTimes",
          data: {
            type: this.type
          }
        });
        if (data.success) {
          this.lotteryTimesData = data.data;
        } else {
          this.$toast(data.message);
        }
      }
    },
    async getTurnTableDate() {
      //获取抽奖次数
      this.getDrawCounts();

      //累计金额
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/lottery/queryLotteryAmountProgress",
          data: {
            type: this.type
          }
        });
        if (data.success) {
          this.totalAmount = data.data.totalAmount;
          this.thresholdAmount = data.data.thresholdAmount;
          this.scheduleAmount = data.data.progressAmount;
          this.timesPlayed = data.data.timesPlayed;
          this.progressWidth = (this.scheduleAmount / this.thresholdAmount) * 300;
          console.log(data);
        } else {
          this.$toast(data.message);
        }
      }

      //中奖记录播报
      if (this.firstIn) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/prizeRecord/queryLimit",
          data: {
            type: this.type
          }
        });
        if (data.success) {
          this.prizeTurnTableDTO = [];
          if (data.data && data.data.length > 0) {
            data.data.map((item, index) => {
              this.prizeTurnTableDTO.push(
                `恭喜${this.noPassByName(
                  item.extension.userName ? item.extension.userName : ""
                )}获得${item.turntableDTO.prizeName}`
              );
            });
          }
          this.firstIn = false;
        } else {
          this.$toast(data.message);
        }
      }
    },
    stopClick() {},
    noPassByName(str) {
      if (null != str && str != undefined) {
        if (str.length <= 3) {
          return "*" + str.substring(1, str.length);
        } else if (str.length > 3 && str.length <= 6) {
          return "**" + str.substring(2, str.length);
        } else if (str.length > 6) {
          return str.substring(0, 2) + "****" + str.substring(6, str.length);
        }
      } else {
        return "";
      }
    },

    hasToken() {
      if (!this.canClick) return false;
      if (!this.token || this.token == "undefined") {
        this.$router.push("/downloadApp?timeStamp=" + Date.now());
        return false;
      }
      clearInterval(this.curIntervalId);
      return true;
    },
    toAddress(id) {
      this.$router.push(`/turnTableAddress?id=${id}&token=${this.token}&timeStamp=${Date.now()}`);
    },
    goPage(index, prizeType = null) {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      var path = ["/turnTableRules", "/turnTableRecord"];
      this.$router.push(
        path[index] + "?token=" + this.token + "&type=" + this.type + "&timeStamp=" + Date.now()
      );
    },
    goTo() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.$router.push(
        "/turnTable2?token=" + this.token + "&type=2" + "&timeStamp=" + Date.now(),
        () => {
          console.log(window.location.href);
        }
      );
    },
    getMoreChance() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.jumpToMain();
    },
    onBtn(popType) {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      if (popType === 2) {
        this.$router.push(`/goldDetail?token=${this.token}&time=${Date.now()}`);
      } else {
        this.jumpToMain();
      }
    },
    jumpToMain() {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    },

    // 抽取按钮按钮点击触发事件
    async onClick() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.canClick = false;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/luckyDraw",
        data: {
          type: this.type
        }
      });

      if (data.success) {
        this.resetTable();
        this.drwaData = data.data;
        // this.priceValue = data.data.extension.priceValue;
        this.turnAngle = 15;
        this.turnLoop = 3;
        this.prizeRecordId = data.data.prizeRecordId;
        this.prizeType = data.data.prizeType;
        this.curPrizeName = data.data.prizeName;
        this.curItemUrl = "";
        if (this.prizeType == 2) {
          this.curItemUrl = data.data.extension.ITEM_URL;
        } else if (this.drwaData.extension && this.drwaData.extension.COUPON_ITEM_URL) {
          this.curItemUrl = this.drwaData.extension.COUPON_ITEM_URL;
        }
        setTimeout(() => {
          // this.stopIndex = 1;
          this.stopIndex = this.rewardList[0][data.data.prizeName].index;
          this.action();
        }, 10);
        this.getTurnTableDate();
      } else {
        this.canClick = true;
        this.pointerStatus = 0;
        if (data.code == 60000027) {
          //当日抽奖次数已用尽
          this.popType = 0;
          this.showModal = true;
        } else if (data.code == 60000026) {
          //无抽奖次数
          this.showModal = true;
          this.popType = 1;
        } else {
          this.$toast(data.message);
        }
      }
    },
    resetTable() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      ctx.rotate(-(45 * (this.stopIndex - 1) * Math.PI) / 180);
      ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
    },
    action() {
      this.pointerStatus = 1;
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度

      var allAngle = 0;
      this.curIntervalId = setInterval(() => {
        ctx.rotate((this.turnAngle * Math.PI) / 180);
        ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
        allAngle += this.turnAngle;
        if (allAngle == (this.turnLoop * 360 == 0 ? 360 : this.turnLoop * 360)) {
          clearInterval(this.curIntervalId);
          this.turnAngle -= 5;
          if (this.turnAngle > 5) {
            this.turnLoop = 2;
            this.action();
          } else if (this.turnAngle == 5) {
            this.turnLoop = (45 * (this.stopIndex - 1)) / 360;

            console.log("this.turnLoop", this.turnLoop, this.stopIndex);
            this.action();
          } else {
            this.getDrawCounts();
            setTimeout(() => {
              this.canClick = true;
              this.showModal = true;
              this.pointerStatus = 0;
            }, 300);
          }
        }
      }, this.turnTime);
    },
    drawWheelCanvas() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      console.log(canvasW);
      ctx.fillStyle = "#fff000";
      ctx.clearRect(0, 0, canvasW, canvasH); //去掉背景默认的黑色
      console.log(canvasW);
      ctx.strokeStyle = "#199301"; //线的颜色
      ctx.font = "26px Microsoft YaHei";

      //画转盘
      ctx.translate(canvasW / 2, canvasH / 2); //设置旋转锚点
      var img_bg = new Image(canvasW + "px", canvasH + "px");
      img_bg.src =
        "https://oss-kiddo.manqu88.com/h5/618turnTable/superTable.png?time=" +
        Date.now() +
        "&x-oss-process=image/format,webp";
      img_bg.onload = () => {
        console.log("onload", this.stopIndex);
        ctx.rotate((45 * (this.stopIndex - 1) * Math.PI) / 180);
        ctx.drawImage(img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
      };
      this.img_bg = img_bg;
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  max-width: 100vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-1 {
  height: 685px;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/superBg.png) no-repeat;
  background-size: 100% 100%;
}
.bg {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/bg.png) no-repeat;
  background-size: 100% 100%;

  .btnView {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    z-index: 100;
    .left {
      margin-left: 11px;
      width: 159px;
      height: 42px;
      display: flex;
      align-items: center;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/leftCountsBg.png) no-repeat;
      background-size: 100% 100%;
      .leftTitle {
        margin-left: 17px;
        width: 89px;
        height: 14px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/leftCountsTitle.png) no-repeat;
        background-size: 100% 100%;
      }
      .leftNum {
        margin-left: 4px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
      }
    }
    .right {
      width: 194px;
      height: 42px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btnMore.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .tip {
    z-index: 100;
    margin-top: 11px;
    margin-bottom: 21px;
    width: 265px;
    height: 13px;
    display: flex;
    align-items: center;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/superTips.png?1) no-repeat;
    background-size: 100% 100%;
  }
  .tips {
    position: relative;
    margin-bottom: 16px;
    width: 355px;
    height: 188px;
    display: flex;
    flex-direction: column;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/msgBg.png) no-repeat;
    background-size: 100% 100%;
    .tips-top {
      margin-top: 21px;
      margin-left: 15px;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2c2c2c;
      line-height: 21px;
      .gold-top {
        font-size: 21px;
        color: #f46211;
      }
    }

    .progressBg {
      margin: 0 auto;
      margin-top: 16px;
      width: 327px;
      height: 28px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/progressBg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      padding-top: 3px;
      padding-left: -1px;

      .progess-left {
        // margin-left: 1.5px;
        width: 14px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/progressLeft.png) no-repeat;
        background-size: 100% 100%;
      }
      .progress {
        width: 18px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/progressMid.png) repeat;
        background-size: 18px 100%;
      }
      .progess-right {
        width: 14px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/progressRight.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .arrowView {
      margin-top: 5px;
      width: 100%;
      height: 19px;
      display: flex;
      justify-content: space-between;
      .arrow {
        height: 20px;
        padding: 1px 10px;
        margin-left: 15px;
        background: linear-gradient(180deg, #fd8c4d 0%, #f46211 100%);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
        .arrow-top {
          position: absolute;
          top: -4px;
          width: 9px;
          height: 4px;
          background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/arrow.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .target {
        margin-right: 15px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #f46211;
        line-height: 20px;
        .unit {
          font-size: 11px;
          color: #2c2c2c;
        }
      }
    }

    .tips-bottom {
      margin-top: 16px;
      width: 100%;
      height: 19px;
      display: flex;
      justify-content: space-between;
      .des {
        margin: 0 15px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2c2c2c;
        line-height: 20px;
        .gold {
          color: #f46211;
        }
      }
    }

    .tipsDes {
      margin: 10px 0 16px 15px;
      height: 14px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #777777;
      line-height: 14px;
    }
  }
  .goSuperView {
    position: relative;
    width: 355px;
    height: 104px;
    display: flex;
    align-items: center;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/showGoSuper.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 16px;
    .goSuper {
      position: absolute;
      right: 19px;
      bottom: 18px;
      width: 117px;
      height: 40px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btnGoSuper.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .rule {
    width: 355px;
    height: 465px;
    display: flex;
    align-items: center;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/superRules.png?1) no-repeat;
    background-size: 100% 100%;
  }
  .logo {
    margin-top: 29px;
    margin-bottom: 37px;
    width: 129px;
    height: 52px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/logo.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 42px;
  }
}
.label {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0px;
  width: 80px;
  height: 30px;
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 17px 0 0 17px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}
.label-1 {
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/myPrize.png) no-repeat;
  background-size: 100% 100%;
}
.label-2 {
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/goRules.png) no-repeat;
  background-size: 100% 100%;
}
.goldGif {
  position: absolute;
  top: 591px;
  width: 298px;
  height: 189px;
  z-index: 0;
}

.msgView {
  position: relative;
  margin-bottom: 115px;
  margin-top: 29px;
  width: 322px;
  height: 27px;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/broadBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .notice {
    width: 260px;
    height: 24px;
    position: absolute;
    bottom: 0px;
    left: 20px;
    font-size: 12px;
    color: #ff6411;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }
  /deep/ .van-notice-bar {
    background-color: rgba($color: #ffffff, $alpha: 0) !important;
  }
}

.wheel {
  display: block;
  width: 342px;
  height: 342px;
  position: relative;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 0;
}
.choosed {
  position: absolute;
  left: 113px;
  top: 9px;
  width: 125px;
  height: 152px;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/choosed.png) no-repeat;
  background-size: 100% 100%;
}
.pointer-up {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 123px;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btnDrawGif.gif) no-repeat;
  background-size: 100% 100%;
}
.pointer {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 123px;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btnDraw.png) no-repeat;
  background-size: 100% 100%;
  .drawtitle {
    margin-top: 37px;
    width: 58px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
  }
  .needScore {
    height: 20px;
    font-size: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 14px;
  }
}
.leftScore {
  position: absolute;
  left: 106px;
  top: 550px;
  height: 29px;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f24006;
  line-height: 29px;
}
.leftNumTitle {
  display: flex;
  position: absolute;
  left: 41px;
  top: 591px;
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
  .leftNum {
    margin-left: 11px;
    margin-right: 11px;
    height: 29px;
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #f24006;
    line-height: 16px;
  }
}
#wheelCanvas {
  width: 342px;
  height: 342px;
}

.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  .bgGet {
    position: relative;
    width: 270px;
    height: 353px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/popBg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .noTimes {
      margin-top: 36px;
      width: 227px;
      height: 194px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/noTimes.png) no-repeat;
      background-size: 100% 100%;
    }
    .winView {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .winLabel {
        margin-top: 62px;
        width: 160px;
        height: 23px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/winLabel_1.png) no-repeat;
        background-size: 100% 100%;
      }
      .goldView {
        width: 100%;
        height: 97px;
        display: flex;
        justify-content: center;
        position: relative;
        .gold {
          height: 97px;
          font-size: 68px;
          font-family: KeepCalm-Medium, KeepCalm;
          font-weight: 500;
          color: #f36b0e;
          line-height: 97px;
        }
        .unit {
          height: 97px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 127px;
        }
      }
      .winTips {
        margin-top: 0;
        width: 140px;
        height: 32px;
        background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/winTips.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .btnClose {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 36px;
      height: 36px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btnClose.png) no-repeat;
      background-size: 100% 100%;
    }
    .btn {
      position: absolute;
      right: 20px;
      bottom: 13px;
      width: 230px;
      height: 47px;
      background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/btn.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .btnStr {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 25px;
      }
    }
  }
}
</style>
