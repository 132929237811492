<template>
  <div class="ck-wheel" id="app">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    console.log(this.$route.query);
    this.getRich(this.$route.query.content);
    document.title = this.$route.query.title;
  },
  async mounted() {},
  data() {
    return {
      token: this.$route.query.token,
      skipType: "",
      skipParam: "",
      content: ""
    };
  },
  methods: {
    async getRich(url) {
      const e = await this.$axios({
        method: "get",
        headers: {
          mac: "mac"
        },
        url: url,
        data: {}
      });

      this.content = e.data;
      this.content = this.content.replaceAll("<video", '<video show-mute-btn="true"');
      this.content = this.content.replaceAll('autoplay=""', "");
      console.log("get", this.content);
      // new Vue({
      //   el: "#app",
      //   data: {
      //     hello: this.content
      //   }
      // });
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
