<template>
  <div class="ck-wheel">
    <div class="top" id="top">
      <div class="helpIcon" @click="goRules"></div>
      <div class="topTips">1金币价值相当于1人民币</div>
      <div class="goldView">
        <div class="goldIcon"></div>
        {{ parseGold(goldData.settledGold + goldData.unsettlementGold) || 0 }}
      </div>
      <div
        class="goldTips"
        :style="{
          visibility:
            goldData.unsettlementGold && goldData.unsettlementGold > 0 ? 'visible' : 'hidden'
        }"
      >
        <div class="tips">
          含待结算
          {{ parseGold(goldData.unsettlementGold, true, false) || 0 }}
        </div>
      </div>
      <!-- <div class="warn">
        您有
        <div class="goldColor">50金币</div>
        将于2023.6.30到期失败，请尽快使用！
      </div> -->
    </div>

    <van-tabs color="#323CB0" style="width:100%" :sticky="true" @click="changeTab">
      <van-tab id="tab" v-for="(items, indexs) in tabList" :key="indexs" :title="items">
        <div
          class="list"
          :style="{
            maxHeight: listHeight + 'px'
          }"
          @scroll="onScroll"
        >
          <div class="noList" v-if="noList && list.length === 0"></div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="left">
              <div class="des">{{ item.flowDesc }}</div>
              <div class="time">{{ moment(item.gmtModified).format("YYYY.MM.DD HH:mm:ss") }}</div>
            </div>
            <div class="add">
              {{ curTab === 0 ? "+" : "-" }}{{ parseGold(item.goldAmount, true, false) }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="shadow" v-if="popData.isPopup === true">
      <div class="modal" v-if="modalStep === 1">
        <div class="close" @click="popData.isPopup = false"></div>
        <div class="title">金币限时兑换</div>
        <div class="str">
          恭喜你获得1次金币兑换资格，您可直接将余额账户资金兑换为
          <span class="goldStr">{{ parseGold(popData.settledAmount) }}</span>
          金币（兑换比例1:1），兑换后金币可直接用于商城消费抵扣，省去余额提现个税申报相关繁杂流程。
        </div>
        <div class="str">
          点击立即兑换，系统将为您自动兑换
        </div>
        <div class="btnView">
          <div class="cancel" @click="modalStep = 2">拒绝</div>
          <div class="confirm" @click="onGoldConvert">立即兑换</div>
        </div>
      </div>

      <div class="modal" v-if="modalStep === 2">
        <div class="str" style="text-align:center;margin-top:34px;margin-bottom: 8px;">
          拒绝后将放弃本次机会无法进行金币兑换，是否继续拒绝？
        </div>
        <div class="btnView">
          <div class="cancel" @click="modalStep = 1">取消</div>
          <div class="cancel" @click="onRefuse">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      assistGoldId: this.$route.query.id,
      token: this.$route.query.token,
      tabList: ["收入", "支出"],
      currentPage: 1,
      pageSize: 20,
      canGetList: true,
      curTab: 0,
      loading: false,
      finished: false,
      goldData: {
        settledGold: 0,
        unsettlementGold: 0
      },
      popData: {},
      noList: false,
      list: [],
      showShadow: true,
      modalStep: 1,
      listHeight: 0
    };
  },
  created() {
    document.title = "我的金币";
  },
  destroyed() {},
  mounted() {
    console.log(
      "121212",
      document.getElementById("top").clientHeight,
      "121212",
      document.getElementById("tab").clientHeight,
      "121212",
      screen.height,
      document.body.clientHeight
    );
    this.listHeight = document.body.clientHeight - document.getElementById("top").clientHeight - 56;
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          if (!this.token || this.token == "") {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
            return;
          }
          this.isPop();
          this.getData();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.isPop();
            this.getData();
          });
        }
        //
      });
    }, 1000);
  },

  methods: {
    formatPrice,
    moment,
    parseGold(gold) {
      let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
      let arr = ret.split(".");
      console.log(ret, arr);
      if (arr[1] === "00") {
        return arr[0];
      } else {
        return ret;
      }
    },
    async isPop() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/user/isPopup",
        data: {}
      });
      if (data.success) {
        this.popData = data.data;
      } else {
        this.$toast(data.message);
      }
    },
    async onGoldConvert() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/user/goldConvert",
        data: {
          amount: this.popData.settledAmount
        }
      });
      if (data.success) {
        this.popData.isPopup = false;
        this.list = [];
        this.getData();
      } else {
        this.$toast(data.message);
      }
    },
    async onRefuse() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/user/info/refuseConvert",
        data: {}
      });
      if (data.success) {
        this.popData.isPopup = false;
      } else {
        this.$toast(data.message);
      }
    },

    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/goldAccount/queryGoldAccount",
        data: {}
      });
      if (data.success) {
        console.log("111", JSON.stringify(data.data));
        if (JSON.stringify(data.data)) {
          this.goldData = data.data;
        }
      } else {
        this.$toast(data.message);
      }
      this.getList();
    },
    async getList() {
      const tabId = JSON.parse(JSON.stringify(this.curTab));
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/goldAccount/queryGoldFlow",
        data: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          bizType: this.curTab === 0 ? 1 : 2
        }
      });
      if (data.success) {
        console.log(
          { tabId },
          this.curTab,
          JSON.parse(JSON.stringify(this.curTab)),
          tabId !== this.curTab
        );
        if (tabId !== this.curTab) {
          return;
        }
        if (this.currentPage === 1) {
          this.list = [];
        }
        this.list = [...this.list, ...data.data];
        if (data.data.length < this.pageSize) {
          this.canGetList = false;
        }
      } else {
        this.$toast(data.message);
      }
      this.noList = true;
    },
    goRules() {
      this.$router.push(`/goldDetailRules?time=${Date.now()}`);
    },

    changeTab(e) {
      console.log(e);
      this.curTab = e;
      this.list = [];
      this.currentPage = 1;
      this.noList = false;
      this.canGetList = true;
      this.getList();
    },
    onScroll(e) {
      console.log(
        e,
        e.target.scrollHeight,
        e.target.scrollTop,
        e.target.clientHeight,
        e.target.scrollTop + e.target.clientHeight
      );
      if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
        //触发加载
        if (this.canGetList) {
          this.currentPage++;
          this.getList();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .top {
    position: relative;
    width: 375px;
    height: 222px;
    background: url(https://oss-kiddo.manqu88.com/h5/gold/goldDetailBg.png?123) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .helpIcon {
      position: absolute;
      right: 12px;
      top: 14px;
      width: 21px;
      height: 21px;
      background: url(https://oss-kiddo.manqu88.com/h5/gold/helpIcon.png?124) no-repeat;
      background-size: 100% 100%;
    }
    .topTips {
      position: absolute;
      right: 41px;
      top: 17px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }

    .goldView {
      margin-top: 58px;
      height: 45px;
      display: flex;
      align-items: center;
      height: 42px;
      font-size: 35px;
      font-family: JDZhengHT-Regular, JDZhengHT;
      font-weight: 400;
      color: #000000;
      line-height: 42px;
      .goldIcon {
        width: 34px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/gold/goldIcon.png?123) no-repeat;
        background-size: 100% 100%;
      }
    }
    .goldTips {
      position: relative;
      width: 124px;
      height: 35px;
      background: url(https://oss-kiddo.manqu88.com/h5/gold/goldTips.png?123) no-repeat;
      background-size: 100% 100%;

      .tips {
        width: 100%;
        height: 35px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3px;
      }
    }
    .warn {
      display: flex;
      margin-top: 12px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }
    .goldColor {
      color: #f9570c;
    }
  }

  .list {
    padding-bottom: 10px;
    width: 100vw;
    max-height: 539px;
    overflow: hidden;
    overflow-y: scroll;

    .noList {
      margin: auto;
      margin-top: 129px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }
    .item {
      margin-top: 20px;
      width: 100%;
      display: flex;
      position: relative;

      .left {
        display: flex;
        flex-direction: column;
        width: 247px;
        margin-left: 23px;
        .des {
          width: 247px;
          height: 36px;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 2;
        }
        .time {
          margin-top: 5px;
          width: 247px;
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }
    }

    .add {
      position: absolute;
      text-align: right;
      top: 14px;
      right: 23px;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f6550a;
      line-height: 24px;
    }
  }

  .shadow {
    z-index: 10000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal {
      position: absolute;
      // top: 168px;
      width: 292px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .close {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 34px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/gold/closeBtn.png?123) no-repeat;
        background-size: 100% 100%;
      }
      .title {
        margin-top: 38px;
        margin-bottom: 13px;
        height: 24px;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }

      .str {
        margin-bottom: 13px;
        width: 240px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #848484;
        line-height: 28px;
        .goldStr {
          color: #f6550a;
        }
      }
      .btnView {
        margin: 23px 0 33px 0;
        width: 100%;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        .cancel {
          margin: 0 10px 0 10px;
          width: 122px;
          height: 40px;
          border-radius: 23px;
          border: 1px solid #bebebe;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .confirm {
          margin: 0 10px 0 10px;
          width: 122px;
          height: 40px;
          background: linear-gradient(270deg, #fc7223 0%, #f9550a 100%);
          border-radius: 23px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
