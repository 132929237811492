<template>
  <div class="ck-wheel">
    <div class="dataArea" style="margin-top:13px">
      <div class="card">
        <div class="title">已确认活力值</div>
        <div class="num">{{ scoreDatas.score }}</div>
      </div>
      <div class="card">
        <div class="title">已获得奖励数</div>
        <div class="num">{{ scoreDatas.rewardNum }}</div>
      </div>
    </div>
    <div class="dataArea" style="margin-top:4px">
      <div class="card">
        <div class="title">即将到账活力值</div>
        <div class="num">{{ scoreDatas.frozenScore }}</div>
      </div>
      <div class="card">
        <div class="title">预估奖励数</div>
        <div class="num">{{ scoreDatas.totalRewardNum }}</div>
      </div>
    </div>

    <van-tabs
      color="#323CB0"
      style="width:100%"
      title-inactive-color="#A7A7A7"
      title-active-color="#000000"
      @click="changeTab"
      ref="tabs"
      :sticky="true"
    >
      <van-tab
        v-for="(item, index) in list"
        :key="index"
        :name="item.prizeType"
        :title="item.title"
      >
        <div class="scroll">
          <div class="noData" v-if="detailList.length === 0"></div>
          <div class="item" v-for="(item, index) in detailList" :key="index">
            <div class="left">
              <div class="titles">{{ item.recordDescribe || item.extension.prizeName }}</div>
              <div class="date">{{ moment(item.gmtModified).format("YYYY.MM.DD HH:mm:ss") }}</div>
            </div>
            <div :class="['right', item.changeType !== 1 ? 'del' : '']" v-if="curTab != 2">
              {{ item.changeType === 1 ? "+" : "" }}{{ formatPrice(item.score) }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      token: this.$route.query.token,
      flashSaleId: this.$route.query.flashSaleId,
      curTab: 0,
      list: [
        {
          title: "已确认活力值"
        },
        {
          title: "即将到账活力值"
        },
        {
          title: "已获得奖励"
        }
      ],
      scoreDatas: {
        frozenScore: 0,
        rewardNum: 0,
        score: 0,
        totalRewardNum: 0
      },
      detailList: []
    };
  },
  created() {
    document.title = "多买多赠奖励明细";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.userId = this.$route.query.userId;
        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }

          this.$bridge.call("getUserId", {}).then(id => {
            console.log("getUserId客户端返回: ", e);
            this.userId = id;
            this.getDetail();
          });
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    changeTab(index) {
      console.log(index);
      this.curTab = index;
      this.detailList = [];
      this.getDetail();
    },
    async getScore() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/flashsale/gift/summary",
        data: {
          flashSaleId: this.flashSaleId
        }
      });
      if (data.success) {
        this.scoreDatas = data.data;
      } else {
        this.$toast(data.message);
      }
    },
    async getDetail() {
      this.getScore();
      if (this.curTab < 2) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/temp/score/queryDetailList",
          data: {
            type: 51,
            recodeTypeList: this.curTab === 0 ? [1] : [2, 3],
            bizId: Number(this.flashSaleId)
          }
        });
        if (data.success) {
          this.detailList = data.data;
          this.curIndex = data.data.length - 1;
        } else {
          this.$toast(data.message);
        }
      } else {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/prizeRecord/queryList",
          data: {
            type: 12,
            lotteryId: this.flashSaleId
            // prizeType: 3
          }
        });
        if (data.success) {
          this.detailList = data.data;
          this.curIndex = data.data.length - 1;
        } else {
          this.$toast(data.message);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  .dataArea {
    display: flex;
    width: 100vw;
    height: 70px;
    justify-content: center;
    align-items: center;

    .card {
      margin-right: 5px;
      width: 162px;
      height: 70px;
      background: #fbfbfb;
      border-radius: 9px;
      display: flex;
      flex-direction: column;

      .title {
        margin-left: 24px;
        margin-top: 12px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        line-height: 17px;
      }
      .num {
        margin-left: 24px;
        margin-top: 4px;
        height: 24px;
        font-size: 20px;
        font-family: JDZhengHT-Bold, JDZhengHT;
        font-weight: bold;
        color: #f9550a;
        line-height: 24px;
      }
    }
  }

  .scroll {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;

    .noData {
      margin-top: 60px;
      width: 65px;
      height: 124px;
      background: url(https://oss-kiddo.manqu88.com/h5/gift/noData.png?11) no-repeat;
      background-size: 100% 100%;
    }

    .item {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;

      .left {
        margin-left: 23px;
        display: flex;
        flex-direction: column;
        // align-items: center;

        .titles {
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          line-height: 18px;
        }
        .date {
          margin-top: 4px;
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }

      .right {
        margin-right: 23px;
        height: 21px;
        font-size: 17px;
        font-family: JDZhengHT-Regular, JDZhengHT;
        font-weight: 400;
        color: #f6550a;
        line-height: 21px;
      }
      .del {
        color: #404040;
      }
    }
  }
}
</style>
