<template>
  <div class="">
    <div
      :class="['ck-wheel', 'rules-1']"
    >
      <div class="rule"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "活动规则";
  },
  data() {
    return {
      token: this.$route.query.token,
      type: 1,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rules-1 {
  width: 375px;
  min-height: 100vh;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/giftH5/rulesBg.png)
    no-repeat;
  background-size: 100% 100%;
}
.rule{
  width: 375px;
  min-height: 701px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/giftH5/giftRule.png)
    no-repeat;
  background-size: 100% 100%;
}

</style>
