<template>
  <div style="padding: 20px 22px 50px;">
    <img src="@/assets/couponRule.png" alt="" style="width:100%">
  </div>
</template>
<script>
export default {
  mounted(){
    document.title = '优惠券规则'
  }
}
</script>
