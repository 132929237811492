<template>
  <div class="back"></div>
</template>

<script>
import { formatList } from "@/utils/utils";
export default {
  name: "goHomePage",
  components: {},
  created() {
    document.title = "";
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("11当前环境：" + JSON.stringify(res));
      if (res.miniprogram) {
        uni.webView.switchTab({
          url: "/pages/index/index"
        });
      } else {
        this.$bridge.call("jumpToMain", {}).then(e => {
          console.log("jumpToMain客户端返回: ", e);
        });
      }
    });
  },
  data() {
    return {
      list: formatList(),
      src: this.$route.query.src
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.back {
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .video {
    width: 100%;
  }
  .ck-wheels {
    margin-top: 10px;
    width: 355px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .Q {
      margin-left: 11px;
      margin-top: 17px;
      width: 335px;
      margin-bottom: 8px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323cb0;
      line-height: 23px;
    }

    .A {
      margin-left: 11px;
      margin-bottom: 17px;
      width: 335px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
    }
  }
}
</style>
