<template>
  <div class="home_container">
    <!-- 顶部总收益模块 -->
    <div class="top_head">
      <div class="top_head_user">
        <div class="top_head_user_info">
          <img class="top_head_user_info_avatar" :src="userInfo.headPicUrl" alt="" />
          <p class="top_head_user_info_name van-ellipsis">
            {{ userInfo.nickName }}
          </p>
          <img class="top_head_user_info_leve" :src="userLeveIcon" alt="" />
        </div>
        <div class="tip" @click="handleClickDataTip">
          <p class="tip_title">数据说明</p>
          <img class="tip_icon" src="../../assets//addol_tip.png" alt="" />
        </div>
      </div>
      <div class="top_head_total">
        <div class="top_head_total_line"></div>
        <p class="top_head_total_tip">{{ userEarningsData.allAmountName }}</p>
        <p class="top_head_total_icon">¥</p>
        <p class="top_head_total_money">{{ formatPrice(userEarningsData.allAmount) }}</p>
      </div>
      <div class="top_head_other">
        <div class="top_head_other_commission">
          <p class="top_head_other_commission_tip">
            ¥&nbsp;<span>{{ formatPrice(userEarningsData.allCommission) }}</span>
          </p>
          <p class="top_head_other_commission_money">{{ userEarningsData.allCommissionName }}</p>
        </div>
        <div class="top_head_other_line"></div>
        <div class="top_head_other_serve">
          <p class="top_head_other_serve_tip">
            ¥&nbsp;<span>{{ formatPrice(userEarningsData.teamServiceAmount) }}</span>
          </p>
          <p class="top_head_other_serve_money">{{ userEarningsData.teamServiceAmountName }}</p>
        </div>
      </div>
      <div class="top_head_income">
        <div class="top_head_income_day">
          <p class="top_head_income_day_title">
            <span></span>&nbsp;{{ userEarningsData.currentMonthCommissionAmountName }}
          </p>
          <p class="top_head_income_day_money">
            ¥<span>{{ formatPrice(userEarningsData.currentMonthCommissionAmount) }}</span>
          </p>
        </div>
        <div class="top_head_income_month">
          <p class="top_head_income_month_title">
            <span></span>&nbsp;{{ userEarningsData.currentMonthKAmountName }}
          </p>
          <p class="top_head_income_month_money">
            ¥<span>{{ formatPrice(userEarningsData.currentMonthKAmount) }}</span>
          </p>
        </div>
      </div>
      <img class="top_head_image" src="../../assets/addol_home_image.png" alt="" />
    </div>
    <!-- 总销售业绩 -->
    <HomeTotalSalePerformanceVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeTotalSalePerformanceVue>
    <!-- 超相团销售业绩 -->
    <HomeSuperValuePerformanceVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeSuperValuePerformanceVue>
    <!-- 尊享卡业绩 -->
    <HomeExclusiveCardPerformanceVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeExclusiveCardPerformanceVue>
    <!-- 邀请概况 -->
    <HomeInviteInfoVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeInviteInfoVue>
    <!-- 意向用户 -->
    <HomeIntentionUserVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeIntentionUserVue>
    <!-- 交易动态 -->
    <HomeTradeInfoVue :token="this.$route.query.token || '1f9fb822696e825d22b55cf4a242a742'"
      :distUserId="this.$route.query.userId || '406020'"></HomeTradeInfoVue>
  </div>
</template>

<script>
import { formatPrice } from "../../utils/money";
import HomeExclusiveCardPerformanceVue from "./components/HomeExclusiveCardPerformance.vue";
import HomeIntentionUserVue from "./components/HomeIntentionUser.vue";
import HomeInviteInfoVue from "./components/HomeInviteInfo.vue";
import HomeSuperValuePerformanceVue from "./components/HomeSuperValuePerformance.vue";
import HomeTotalSalePerformanceVue from "./components/HomeTotalSalePerformance.vue";
import HomeTradeInfoVue from "./components/HomeTradeInfo.vue";

export default {
  created() {
    document.title = "经营数据";
  },
  components: {
    HomeTotalSalePerformanceVue,
    HomeSuperValuePerformanceVue,
    HomeExclusiveCardPerformanceVue,
    HomeInviteInfoVue,
    HomeTradeInfoVue,
    HomeIntentionUserVue
  },
  data() {
    return {
      token: "",
      distUserId: '',
      userInfo: {},
      userEarningsData: {}
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token || "1f9fb822696e825d22b55cf4a242a742";
        this.distUserId = this.$route.query.userId || '406020'
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getUserInfo();
        this.getIncomeData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getUserInfo();
          this.getIncomeData();
        });
      }
    });
  },
  computed: {
    userLeveIcon() {
      if (this.userInfo.gradeCode === 1) {
        return require("../../assets/new_kc.png");
      } else if (this.userInfo.gradeCode === 2) {
        return require("../../assets/new_kb.png");
      } else if (this.userInfo.gradeCode === 3) {
        return require("../../assets/new_ka.png");
      } else {
        return require("../../assets/nomember.png");
      }
    }
  },
  methods: {
    formatPrice,
    // 获取用户信息
    async getUserInfo() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/user/getDistUserInfo",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.userInfo = { ...data.data };
        } else {
          this.$toast("暂无数据");
        }
      } else {
        this.$toast(data.message);
      }
    },
    // 获取我的收益、佣金数据
    async getIncomeData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryDistUserEarningsData",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.userEarningsData = { ...data.data };
        } else {
          this.$toast("暂无数据");
        }
      } else {
        this.$toast(data.message);
      }
    },
    handleClickDataTip() {
      this.$router.push(`/illustrate?token=${this.token}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.home_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  .top_head {
    width: 100%;
    height: 258px;
    background: url(../../assets/addol_home_bg.png) no-repeat;
    background-size: 100% 258px;
    background-color: #f5f5f5;
    position: relative;

    .top_head_image {
      position: absolute;
      height: 34px;
      right: 37px;
      bottom: 148px;
    }

    .top_head_user {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .top_head_user_info {
        flex: 1;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-left: 12px;
        margin-right: 10px;

        .top_head_user_info_avatar {
          width: 35px;
          height: 35px;
          background: #ffffff;
          border-radius: 17.5px;
        }

        .top_head_user_info_name {
          max-width: 180px;
          margin-left: 5px;
          margin-right: 5px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }

        .top_head_user_info_leve {
          height: 19px;
        }
      }

      .tip {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-right: 12px;

        .tip_title {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-right: 5px;
        }

        .tip_icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .top_head_total {
      margin-top: 5px;
      margin-left: 12px;
      margin-right: 12px;
      display: flex;
      justify-content: left;
      align-items: center;

      .top_head_total_line {
        width: 5px;
        height: 14px;
        background: #f9550a;
      }

      .top_head_total_tip {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
      }

      .top_head_total_icon {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-right: 3px;
      }

      .top_head_total_money {
        padding: 0;
        margin: 0;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .top_head_other {
      margin: 12px 12px;
      height: 72px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      display: flex;
      align-items: center;

      .top_head_other_commission {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc((100% - 1px)/2);

        .top_head_other_commission_tip {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;

          span {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }

        .top_head_other_commission_money {
          padding: 0;
          margin: 0;
          margin-top: 5px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }

      .top_head_other_line {
        width: 1px;
        height: 36px;
        background-color: #f5f5f5;
      }

      .top_head_other_serve {
        width: calc((100% - 1px)/2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .top_head_other_serve_tip {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;

          span {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }

        .top_head_other_serve_money {
          padding: 0;
          margin: 0;
          margin-top: 5px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
    }

    .top_head_income {
      margin: 12px 12px 0px;
      height: 94px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      box-sizing: border-box;

      .top_head_income_day {
        box-sizing: border-box;
        width: 165px;
        height: 82px;
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        align-items: left;
        justify-content: center;

        .top_head_income_day_title {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #131313;

          span {
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #323cb3;
            border-radius: 3px;
          }
        }

        .top_head_income_day_money {
          padding: 0;
          margin: 0;
          margin-top: 3px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #000000;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #000000;
          }
        }
      }

      .top_head_income_month {
        box-sizing: border-box;
        width: 165px;
        height: 82px;
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        align-items: left;
        justify-content: center;

        .top_head_income_month_title {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #131313;

          span {
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #323cb3;
            border-radius: 3px;
          }
        }

        .top_head_income_month_money {
          padding: 0;
          margin: 0;
          margin-top: 3px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #000000;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #000000;
          }
        }
      }
    }
  }
}
</style>
