//帮助页面list处理
export const formatList = () => {
  let list = [{
      "type": "会员咨询",
      "index": "1",
      "QA": "Q：如何查看我的邀请码？"
    },
    {
      "QA": "A：登录Addol点击我的，在最上方可以查看您的专属邀请码。"
    },
    {
      "index": "2",
      "QA": "Q：如何查看我的上级？"
    },
    {
      "QA": "A：登录Kiddol/Addol点击我的，在专属服务管家中可以查看。"
    },
    {
      "type": "售前咨询",
      "index": "3",
      "QA": "Q：如何取消订单？"
    },
    {
      "QA": "A：当订单状态为待付款时，可以直接取消订单。已付款的订单，可以在30分钟内自助取消。"
    },
    {
      "index": "4",
      "QA": "Q：都是正品吗？"
    },
    {
      "QA": "A：Kiddol平台的每一件商品，我们都是跟来自国内外的优质的品牌方合作，通过严苛的品质把关，资质齐全，100%正品保障，可以放心购买!"
    },
    {
      "index": "5",
      "QA": "Q：平台有哪些支付方式？"
    },
    {
      "QA": "A：APP目前可以用支付宝、微信支付，KA、KB会员还可以使用货款支付。微信小程序只能使用微信及货款支付。"
    },
    {
      "index": "6",
      "QA": "Q：产品包邮吗？"
    },
    {
      "QA": "A：全国大部分地区包邮，除港澳台及部分偏远地区（新疆、青海、西藏）等，具体以页面显示为准。"
    },
    {
      "index": "7",
      "QA": "Q：优惠券什么情况下可以返还？"
    },
    {
      "QA": "A：使用优惠券下单生成订单后，只有2种情况下，优惠券会返还。1. 在30分钟内取消所有使用该优惠券的订单；2. 超时未付款，订单自动关闭。优惠券会原路退回原账户，其余情况优惠券一律不退回，若退还时优惠券已过期，该优惠券将同时作废。"
    },
    {
      "type": "发货&物流",
      "index": "8",
      "QA": "Q：下单后多久发货？"
    },
    {
      "QA": "A：支付成功后，非预售商品2-3个工作日内发出。预售及特定日期发货商品，页面会标注发货时间。如遇大型节假日及特殊情况，发货时间会略有延迟。"
    },
    {
      "index": "9",
      "QA": "Q：下单后多久可以收到商品？"
    },
    {
      "QA": "A：商品发出后，根据收货地址距离，一般2-7天内收到（偏远地区除外）。如遇大型节假日及特殊情况，物流时间会略有延迟。"
    },
    {
      "index": "10",
      "QA": "Q：为什么我的订单会拆分成好几个包裹？"
    },
    {
      "QA": "A：您购买的商品可能来自多个品牌仓。为了您能够更早地收到商品，每个品牌仓的商品会在备齐后独立打包发货。"
    },
    {
      "index": "11",
      "QA": "Q：订单发什么快递？"
    },
    {
      "QA": "A：目前各个品牌仓合作的快递略有不同，常见的合作快递有圆通、中通、韵达、顺丰、EMS等，品牌仓会根据您的收货地址进行匹配。"
    },
    {
      "index": "12",
      "QA": "Q：能指定快递吗？"
    },
    {
      "QA": "A：目前暂时不支持指定快递。"
    },
    {
      "index": "13",
      "QA": "Q：订单还没发货，能改地址吗？"
    },
    {
      "QA": "A：目前在付款之后，不能修改地址。如您需要修改地址，建议您退款重拍。"
    },
    {
      "index": "14",
      "QA": "Q：订单已经发货了，能改地址吗？"
    },
    {
      "QA": "A：可以为您尝试修改地址，但不能保证一定可以修改成功的，如果没有修改成功，麻烦您配合拒收哦（生鲜冷冻产品不支持拒收）。"
    },
    {
      "index": "15",
      "QA": "Q：物流信息怎么一直没有更新？"
    },
    {
      "QA": "A：物流信息正常会在48小时内更新。如遇大型节假日及特殊情况，会略有延迟。"
    },
    {
      "type": "售后咨询",
      "index": "16",
      "QA": "Q：在线客服的工作时间是怎么样的？"
    },
    {
      "QA": "A：客服工作时间9：00-21：00，周一-周日。非服务时间内，可以给在线客服留言。客服上线后，会第一时间为您处理。"
    },
    {
      "index": "17",
      "QA": "Q：商品支持7天无理由退货吗？"
    },
    {
      "QA": "A：商品签收7日内可享受无忧退货服务。特殊商品（如贴身衣物、生鲜冷冻食品等），具体以页面显示为准。"
    },
    {
      "index": "18",
      "QA": "Q：商品退货如何操作？"
    },
    {
      "QA": "A：登录Kiddol-我的-我的订单，进入订单页面后点击退款的按钮。不支持7天无理由退货的订单如遇到质量问题，可及时联系在线客服。"
    },
    {
      "index": "19",
      "QA": "Q：退货运费由谁承担？"
    },
    {
      "QA": "A：商品质量问题导致的退货费用由Kiddol承担，其它原因退货费用由您自行承担。"
    },
    {
      "index": "20",
      "QA": "Q：商品退货有哪些注意事项？"
    },
    {
      "QA": "A：1、商品必须由Kiddol售出，同时商品支持7天无理由退货；2、申请退货须在商品签收7日内提出，并在申请退货后3日内寄出，超期可能导致无法处理退货；3、退货商品须未经穿着、洗涤、使用、污损（商品本身质量问题除外），吊牌未经拆除，商品配件及包装齐全且不影响二次销售；4、退货须先联系在线客服，直接寄回未经申请的退货商品可能导致丢件无法受理。"
    },
    {
      "index": "21",
      "QA": "Q：退款多久可以收到？"
    },
    {
      "QA": "A：在收到您的退回商品后会进行验货，完成后立即退款，退款金额原路返回到您的购买账户。验货后的退款时间一般为3-5个工作日内。"
    },
    {
      "index": "22",
      "QA": "Q：能换货吗？"
    },
    {
      "QA": "A：目前平台不支持换货哦。建议您办理退货重新下单会更合适。"
    },
    {
      "index": "23",
      "QA": "Q：退货有运费险吗？"
    },
    {
      "QA": "A：目前暂时没有。"
    },
    {
      "index": "24",
      "QA": "Q：可以开发票吗？"
    },
    {
      "QA": "A：不是跨境海外商品，都可以提供发票。"
    },
    {
      "index": "25",
      "QA": "Q：为什么退货的地址都不一样？"
    },
    {
      "QA": "A：目前我们kiddol平台有多个品牌，发货地都不一样，退货是根据品牌提供对应的地址。如果订单中有多个品牌的产品，会存在多个退货地址的情况。"
    }
  ]

  let ret = [];
  for (let i = 0; i < list.length; i++) {
    if (i % 2 == 0) {
      if (list[i].type) {
        ret.push({
          title: list[i].type,
          subList: []
        })
      }
      ret[ret.length - 1].subList.push({
        'Q': list[i].QA,
        'A': list[i + 1].QA,
      })
    }
  }
  console.log({
    ret
  })
  const sort = ['售前咨询', '售后咨询', '发货&物流', '会员咨询'];
  let retArr = [];
  sort.map(item => {
    ret.map(it => {
      if (it.title == item) {
        retArr.push(it);
      }
    })
  })

  return retArr;
};
