<template>
  <div>
    <!-- <div class="btn" @click="jumpToPkg">用户升级</div> -->
    <wx-open-launch-weapp id="launch-btn" username="gh_8fcc77e2c5f2" path="pages/index/index.html?id=111">
        <template>

            <div class="btn">跳转小程序</div>
        </template>
    </wx-open-launch-weapp>
  </div>
</template>
<script>
export default {
  name:'',
  data(){
    return {}
  },
  methods:{
    jumpToPkg() {
      this.$bridge.call("jumpToLevelIndex", { level:2 }).then((e) => {
        console.log("客户端返回: ", e);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.btn{
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  color: black;
}
.btn{
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    width: 206px;
                    height: 44px;
                    line-height: 44px;
                    margin: 53px auto;
                    background-color: #572b8c;
                    border-radius: 30px;
                    letter-spacing: 2px;
                }
</style>
