<template>
  <div class="ck-wheel">
    <van-sticky>
      <div style="width: 100%; display: flex; flex-direction: column">
        <div style="
            width: 100%;
            height: 50px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
          ">
          <van-icon v-if="false" name="arrow-left" style="margin-left: 20px" size="20" @click="back" />
          <van-search v-model="aaa" placeholder="请输入搜索关键词" style="flex: 1; height: 34px" @click="search()" />
          <div style="margin-right: 20px; font-size: 14px" @click="search()">
            搜索
          </div>
        </div>

        <van-dropdown-menu style="width: 100%" active-color="#000000" v-if="true">
          <van-dropdown-item style="display: flex; justify-content: space-around" :title="title" ref="item">
            <van-cell>
              <template>
                <div class="tab-normal" :class="{ tabselect: tabselect0 }" @click="typeClick(0)">
                  会员
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template>
                <div class="tab-normal" :class="{ tabselect: tabselect1 }" @click="typeClick(1)">
                  店主
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template>
                <div class="tab-normal" :class="{ tabselect: tabselect2 }" @click="typeClick(2)">
                  首席
                </div>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </van-sticky>

    <div class="refresh-con" ref="wrapper">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="scroll">
        <van-list v-model="loading" :finished="finished" :finished-text="list.length != 0 ? '没有更多了' : ''" @load="onLoad">
          <div class="noList" v-if="list.length === 0"></div>
          <div class="card" v-for="(item, index) in list" :key="index" @click="onItemClick(item)">
            <div style="display: flex; margin-left: 13px">
              <van-image round width="48" height="48" :src="item.headUrl">
              </van-image>
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 7px;
                  justify-content: space-around;
                ">
                <div style="display: flex">
                  <span class="nick">{{ item.nickname }}</span>
                  <span class="name" style="margin-left: 10px">{{
                                      item.realName
                                      }}</span>
                  <!-- @touchstart="gtouchstart(item.mobile)"
                    @touchend="cleartouchend()"
                    @touchmove="gtouchmove()" -->
                  <span class="name" style="margin-left: 10px" v-if="item.mobile">{{
                                      item.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                                      }}</span>
                </div>
                <div style="display: flex">
                  <div class="agent">{{ item.agentName }}</div>
                  <div class="zhishu">直属</div>
                </div>
              </div>
            </div>

            <div class="data-display" style="margin-top: 18px">
              <div class="data-show">
                <div class="data-title">购买件数</div>
                <div class="data-num">
                  {{ item.allQuantity }}
                </div>
              </div>
              <div class="data-show" style="flex: 1">
                <div class="data-title">购买总额</div>
                <div class="data-num">
                  {{ `￥${formatPrice(item.allConsumeAmount)}` }}
                </div>
              </div>
            </div>
            <div class="data-display" style="margin-top: 8px">
              <div class="data-show">
                <div class="data-title">首席</div>
                <div class="data-num">
                  {{ item.inviteInfo.seniorCount }}
                </div>
              </div>
              <div class="data-show">
                <div class="data-title">店主</div>
                <div class="data-num">
                  {{ item.inviteInfo.intermediateCount }}
                </div>
              </div>
              <div class="data-show">
                <div class="data-title">会员</div>
                <div class="data-num">
                  {{ item.inviteInfo.primaryCount }}
                </div>
              </div>
            </div>

            <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 13px;
                margin-right: 13px;
                margin-top: 18px;
                align-items: center;
              ">
              <div class="note">最近消费时间： {{ item.lastConsumeTime }}</div>
              <div class="detail">查看详情</div>
            </div>
          </div>
        </van-list></van-pull-refresh>
    </div>
  </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "myclient",
  components: {},
  data() {
    return {
      aaa: "",
      scroll: 0,
      token: this.$route.query.token,
      tabselect0: false,
      tabselect1: false,
      tabselect2: false,
      title: "等级",

      loading: false,
      finished: false,
      currentPage: 1,
      canLoad: false,
      dataVisible: true,
      gradeCode: "",
      list: [],
      refreshing: false,

      userId: undefined,
      time: null,
      copyText: "",
    };
  },
  activated() {
    this.$refs.wrapper.scrollTop = this.scroll;
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.wrapper.scrollTop;
    next();
  },
  created() {
    document.title = "我的客户";
  },
  mounted() {
    this.token = this.$route.query.token;
    this.userId = this.$route.query.userId;
    // this.token = "978bb7aedaa6edad360f89656baa246b";
    uni.webView.getEnv((res) => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.userId = this.$route.query.userId;
        // this.token = "978bb7aedaa6edad360f89656baa246b";
        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          this.userId = this.$route.query.userId;
          // this.token = "978bb7aedaa6edad360f89656baa246b";
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetail();
        });
      }
    });
  },

  methods: {
    gtouchstart(val) {
      this.copyText = val;
      // 设置定时器，500毫秒触发长按事件
      this.time = setTimeout(() => {
        this.$copyText(this.copyText)
          .then(() => {
            this.$toast("复制成功");
          })
          .catch(() => {
            this.$toast("复制失败");
          });
      }, 1000);
      return false;
    },
    // 触摸屏幕离开
    cleartouchend() {
      clearTimeout(this.time);
      this.copyText = "";
    },
    // 手指在屏幕上移动
    gtouchmove() {
      clearTimeout(this.time);
      this.copyText = "";
    },
    formatPrice,
    moment,
    back() {
      this.$router.go(-1);
    },
    search() {
      this.$router.push(`/addol/searchclient?token=${this.token}`);
    },
    typeClick(val) {
      if (val === 0) {
        if (this.tabselect0) {
          this.tabselect0 = false;
          this.title = "等级";
          this.gradeCode = "";
        } else {
          this.tabselect0 = true;
          this.tabselect1 = false;
          this.tabselect2 = false;
          this.title = "会员";
          this.gradeCode = "1";
        }
      }
      if (val === 1) {
        if (this.tabselect1) {
          this.title = "等级";
          this.tabselect1 = false;
          this.gradeCode = "";
        } else {
          this.tabselect0 = false;
          this.tabselect1 = true;
          this.tabselect2 = false;
          this.title = "店主";
          this.gradeCode = "2";
        }
      }
      if (val === 2) {
        if (this.tabselect2) {
          this.title = "等级";
          this.tabselect2 = false;
          this.gradeCode = "";
        } else {
          this.tabselect0 = false;
          this.tabselect1 = false;
          this.tabselect2 = true;
          this.title = "首席";
          this.gradeCode = "3";
        }
      }
      this.scroll = 0;
      this.$refs.wrapper.scrollTop = 0;

      this.$refs.item.toggle();
      this.currentPage = 1;
      this.finished = false;
      this.list = [];
      this.loading = true;
      this.getDetail();
    },
    onRefresh() {
      this.currentPage = 1;
      this.finished = false;
      this.list = [];
      this.loading = true;
      this.getDetail();
    },
    async onLoad() {
      if (!this.canLoad) {
        return;
      }
      this.currentPage++;
      this.getDetail();
    },

    async getDetail() {
      this.changeEnable = false;
      console.log("currentPage", this.currentPage);

      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/user/myClients",
        data: {
          gradeCode: this.gradeCode,
          currentPage: this.currentPage,
          pageSize: 8,
          userId: this.userId,
        },
      });
      if (this.currentPage == 1) {
        this.list = [];
      }
      if (data.success) {
        if (data.data.length === 0) {
          this.finished = true;
        }
        this.list = [...this.list, ...data.data];
      } else {
        this.$toast(data.message);
      }

      // 加载状态结束
      this.loading = false;
      this.canLoad = true;
      this.changeEnable = true;
      this.refreshing = false;
    },

    onItemClick(item) {
      this.$router.push({
        name: "clientdetail",
        query: { token: this.token },
        params: { item: item },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  /deep/.van-dropdown-menu__bar {
    box-shadow: 0 0px 0px white;
  }

  .refresh-con {
    display: flex;
    flex: 1;

    .scroll {
      padding-bottom: 20px;
      width: 375px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .noList {
        margin: auto;
        margin-top: 53px;
        width: 100px;
        height: 133px;
        background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  /deep/ .van-popup--top {
    display: flex;
  }

  .van-cell__value--alone {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tab-normal {
    width: 100%;
    height: 25px;
    background: #f5f5f5;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
    line-height: 25px;
  }

  .tabselect {
    background: #fff5de;
  }

  .card {
    width: 355px;
    background: #ffffff;
    border-radius: 2px;
    margin-top: 7px;
    padding-top: 13px;
    padding-bottom: 12px;
    box-sizing: border-box;

    .note {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }

    .detail {
      width: 83px;
      height: 28px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 28px;
      width: 93px;
      background: #ffffff;
      text-align: center;
      border: 1px solid #b0b0b0;
    }
  }

  .user-head {
    width: 48px;
    height: 48px;
  }

  .nick {
    display: inline-block;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .name {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .agent {
    min-width: 57px;
    height: 18px;
    background: #020202;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }

  .zhishu {
    width: 57px;
    height: 18px;
    background: #ececec;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
    line-height: 18px;
    margin-left: 7px;
    text-align: center;
  }

  .data-display {
    display: flex;
    margin-left: 13px;
    margin-right: 13px;

    .data-show {
      width: 33%;
      display: flex;

      .data-title {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        margin-right: 5px;
      }

      .data-num {
        margin-top: 1px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a08849;
      }

      .data-unit {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #666666;
      }
    }
  }
}
</style>
  