<template>
  <div class="back">
    <div :class="['ck-wheel']">
      <div class="title">帮助中心</div>
      <van-tabs color="#323CB0" style="width:100%">
        <van-tab  v-for="(item, index) in list" :key="index" :title="item.title">
          <van-list class="list" :finished="finished" finished-text="">
            <van-cell
              v-for="(items, indexs) in item.subList"
              :key="indexs"
              :title="items.Q.replace('Q：', '')"
              class="cell"
              @click="goDetail(index, indexs)"
            >
              <div class="arrow"></div>
            </van-cell> </van-list
        ></van-tab>
      </van-tabs>
    </div>
    <div v-if="isMini" class="contact" @click="toQiYu"><img src="../../assets/contact_icon.png" />在线客服</div>
  </div>
</template>

<script>
import { formatList } from "@/utils/utils";
export default {
  name: "Help",
  components: {},
  created() {
    document.title = "帮助与客服";
  },
  data() {
    return {
      list: formatList(),
      finished: true,
      isMini: false,
    };
  },
  mounted() {
    uni.webView.getEnv(async (res) => {
      if (res.miniprogram) {
        this.isMini = true;
      } else {
        this.isMini = false;
      }
    });
  },
  methods: {
    goDetail(index1, index2) {
      this.$router.push("/helpDetail?index1=" + index1 + "&index2=" + index2);
    },
    toQiYu() {
      const { userInfo } = this.$route.query;
      this.$router.push(`/contact/index?userInfo=${userInfo}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 88px;
  box-sizing: border-box;

  .ck-wheel {
    margin-top: 10px;
    width: 355px;
    max-height: 90vh;
    background: #ffffff;
    border-radius: 4px;
    text-align: left;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      // position: absolute;

      left: 11px;
      width: 325px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-top: 15px;
    }

    .list {
      width: 100%;
      position: relative;

      .cell {
        height: 48px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        align-content: center;

        .van-cell__value {
          flex: unset;
          width: 10%;
        }
      }
      .arrow {
        position: absolute;
        top: 2px;
        right: 0px;
        width: 12px;
        height: 24px;
        background: url(https://oss-kiddo.manqu88.com/h5/rightArrow.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .contact {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 375px;
    height: 68px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;

    img {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
}
</style>
