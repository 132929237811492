<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.title = "在线客服";
    const { type, id, deliveryFee } = this.$route.query;
    if (type === 'refund') {
      uni.webView.redirectTo({
        url: `/pagesA/refund/apply?id=${id}&deliveryFee=${deliveryFee}`
      })
    } else if (type === 'product') {
      uni.webView.redirectTo({
        url: `/pagesA/detail/index?id=${id}`
      })
    } else if (type === 'order') {
      uni.webView.redirectTo({
        url: `/pagesA/order/detail?id=${id}`
      })
    }
  },
};
</script>

<style scoped>
</style>