import Bridge from '@/utils/bridge/index'

export const linkToPage = (type, params) => {
  uni.webView.getEnv(res => {
    console.log("link当前环境：" + JSON.stringify(res), {
      type,
      params
    });
    if (res.miniprogram) {
      // uni.webView.navigateTo({
      //   url: "/pagesA/superLink/index?type=" + this.skipType + "&param=" + this.skipParam
      // });
      switch (type) {
        // 装修二级页面
        case 0:
          if (!params) return;
          uni.webView.navigateTo({
            url: `/pages/index/sub-page?id=${params}`
          });
          break;

          // 品牌主页
        case 1:
          if (!params) return;
          uni.webView.navigateTo({
            url: `/pagesA/goodslist/index?brandId=${params}`
          });
          break;

          // 链接
        case 2:
          if (!params) return;
          //   uni.webView.setStorageSync(
          //     "webview-url",
          //     `${params}?token=${uni.webView.getStorageSync("accessToken")}`
          //   );
          //   uni.webView.navigateTo({
          //     url: `/pages/common/webview`
          //   });
          window.location.href = params;
          break;

          // 分类商品列表
        case 3:
          if (!params) return;
          const val = params.split(",");
          uni.webView.navigateTo({
            url: `/pagesA/goodslist/index?categoryId=${val}`
          });
          break;

          // 指定商品
        case 4:
          if (!params) return;
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${params}`
          });
          break;

          // 固定页面
        case 5:
          // if (!params) return;
          let p = Number(params);
          if (p === 0) {
            uni.webView.navigateTo({
              url: `/pagesA/pkgUpgrades/index`
            });
          } else if (p === 1) {
            uni.webView.navigateTo({
              url: `/pagesA/coupon/index`
            });
          } else if (p === 2) {
            uni.webView.navigateTo({
              url: `/pagesA/article/index`
            });
          }
          break;

          // 营销活动
        case 6:
          let p1 = Number(params);
          if (p1 === 0) {
            // 每周新品
            uni.webView.navigateTo({
              url: `/pagesA/newProduct/index`
            });
          } else if (p1 === 1) {
            // 主题活动
            uni.webView.navigateTo({
              url: `/pagesA/activity/index`
            });
          } else if (p1 === 2) {
            // 热卖排行
            uni.webView.navigateTo({
              url: `/pagesA/ranking/index`
            });
          }
          break;

          // 任选
        case 7:
          if (!params) return;
          uni.webView.navigateTo({
            url: `/pagesA/nmgoods/index?id=${params}`
          });
          break;

          // 文章详情
        case 25:
          if (!params) return;
          uni.webView.navigateTo({
            url: `/pagesA/article/detail?mediumId=${params}`
          });
          break;

        default:
          break;
      }
      //   window.history.back();
    } else {
      console.log("link2当前环境：" + JSON.stringify(res));
      Bridge
        .call("openPage", {
          type: type,
          param: params
        })
        .then(e => {
          console.log("getToken客户端返回: ", e);
          // window.history.back();
        });
    }
  });
}
