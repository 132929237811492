<template>
  <div class="ck-wheel">
    <div class="bg" :style="{ backgroundImage: `url(${curBg})` }">
      <div class="btn" @click="goMiniPage">去查看</div>
    </div>

    <div class="mask" v-if="showQrCode">
      <div class="tip" :style="{ backgroundImage: `url(${curTips})` }">
        <div class="close" @click="showQrCode = false"></div>
        <div class="copyLink" @click="copyLink"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  created() {
    document.title = "Kiddol✖️VTN 联合会员";

    if (window.location.href.includes("pinkCard")) {
      this.curBg = this.bgList[0];
      this.pageType = "pinkCard";
      this.curTips =
        "https://oss-kiddo.manqu88.com/h5/VTN/card/pinkTips.png?" + Date.now();
    }
    if (window.location.href.includes("blackCard")) {
      this.curBg = this.bgList[1];
      this.pageType = "blackCard";
      this.curTips =
        "https://oss-kiddo.manqu88.com/h5/VTN/card/blackTips.png?" + Date.now();
    }
  },
  data() {
    return {
      bgList: [
        "https://oss-kiddo.manqu88.com/h5/VTN/card/pinkCard_bg.png?" + Date.now(),
        "https://oss-kiddo.manqu88.com/h5/VTN/card/blackCard_bg.png?" + Date.now(),
      ],
      curBg: "",
      curTips: "",
      pageType: "",

      config: { ios: 0, android: 0 },

      isWx: false,
      qrCode: "https://oss-kiddo.manqu88.com/h5/VTN/qrCode.png",
      showQrCode: false,
    };
  },
  mounted() {
    uni.webView.getEnv((res) => {
      if (res.miniprogram) {
        this.isWx = true;
      } else {
        this.isWx = false;
      }
    });
  },
  methods: {
    async getConfig() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
        },
        url: "/api/common/storage/getJsonObj",
        data: {
          key: "VTN_CONFIG",
        },
      });
      if (data.success) {
        this.config = data.data;
        console.log(this.config);
      }
    },
    goMiniPage() {
      uni.webView.getEnv((res) => {
        let url = "";
        if (this.pageType === "pinkCard") {
          url = "otherPackage/vtn/pinkCard";
        }
        if (this.pageType === "blackCard") {
          url = "otherPackage/vtn/blackCard";
        }
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/${url}`,
          });
        } else {
          this.appGoWeapp(url);
        }
      });
    },

    showQrCodeMask() {
      this.showQrCode = true;
    },

    appGoWeapp(url) {
      this.$bridge
        .call("openPage", {
          type: 50,
          param: url,
        })
        .then((e) => {
          console.log("jumpToDetail客户端返回: ", e);
        });

      setTimeout(() => {
        this.showQrCodeMask();
      }, 1000);
    },

    copyLink() {
      let shortLink = "";
      if (this.pageType === "pinkCard") {
        shortLink = "#小程序://Kiddol/3JcnT68lKEeDkXC";
      }
      if (this.pageType === "blackCard") {
        shortLink = "#小程序://Kiddol/Tj6MzaIPoD2IT3t";
      }
      this.$copyText(shortLink)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch(() => {
          this.$toast("复制失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  text-align: center;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .tip {
      position: relative;
      width: 250px;
      height: 347px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 70px;

      .close {
        position: absolute;
        right: 0;
        top: -36px;
        width: 30px;
        height: 30px;
        background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/close.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .download {
        position: absolute;
        left: 12px;
        bottom: 15px;
        width: 111px;
        height: 30px;
      }

      .copyLink {
        position: absolute;
        right: 69px;
        bottom: 15px;
        width: 111px;
        height: 30px;
      }
    }
  }

  .bg {
    width: 100vw;
    height: 680px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .btn {
      position: absolute;
      left: 26px;
      bottom: 70px;
      width: 324px;
      height: 38px;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
