/**
 * 格式化小数
 * @param {*} num
 * @param {*} decimals
 * @param {*} keeptype
 */
export const formatDecimal = (num, decimals = 2, keeptype = true) => {
  const _value = Math.abs(num) < Number.EPSILON ? 0 : Number(
    `${Math.round(Number(`${num}e${decimals}`))}e-${decimals}`);
  return keeptype ? _value : keeptype === undefined ? _value.toString() : _value.toFixed(
  decimals);
};

/**
 * 价格分转化为元
 * @param {String|Number} price
 * @param {Boolean} reverse 反向
 */
export const formatPrice = (price, reverse = true, unit = false) => {
  if (!price) return unit ? '￥0.00' : '0.00';
  const fmv = formatDecimal(price, reverse ? 0 : 2);
  let val = (price ? (price !== undefined && price !== null && price !== '' ? (reverse ? fmv /
    100 : (fmv * 100).toFixed(0) * 1) : '') : price).toFixed(2);
  if (val !== undefined && val !== null && val !== '' && /\.$/.test(`${price}`)) val += '.';
  if (price == '0.0') val = price;
  if (val == 0) val = '0.00';
  return isNaN(val) || val === undefined ? '' : (unit ? `￥${val}` : val);
};


/**
 * 金币分转化为元
 * @param {gold} gold
 */
export const parseGold = (gold) => {
  let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
  let arr = ret.split(".");
  console.log(ret, arr);
  if (arr[1] === "00") {
    return arr[0];
  } else {
    return ret;
  }
}
