<template>
  <div class="detail_container">
    <div v-if="!isShowEmpty" class="order-detail">
      <!-- 订单编号头部 -->
      <div class="order-detail-head van-hairline--bottom">
        <p class="order-detail-num">订单编号：{{ orderDetailData.orderId }}</p>
        <img class="order-detail-copy" src="../../../assets/order_center_copy.png" alt=""
          @click="onCopyOrderNo(orderDetailData.orderId)" />
        <p :class="['order-detail-orderstatus', `orderstatus_color_${orderDetailData.orderStatus}`]">
          {{ orderStatusContent(orderDetailData.orderStatus) }}
        </p>
      </div>
      <!-- 商品列表 -->
      <div v-for="goodsItem in orderDetailData.distSubOrderList" :key="goodsItem.skuId" class="order-detail-goods">
        <img class="goods_cover" :src="goodsItem.resources" alt="" />
        <div class="goods_middle">
          <p class="goods_name">{{ goodsItem.itemName }} {{ skuSpec(goodsItem.specification) }}</p>
          <div class="income_mask">
            <p v-if="goodsItem.hasOwnProperty('promotionCommissionInfo')" class="goods_income">
              {{ goodsItem.promotionCommissionInfo.commissionName }}{{
                dataVisible ? `¥${formatPrice(goodsItem.promotionCommissionInfo.commission)}` : '****'
              }}
            </p>
            <p v-if="goodsItem.hasOwnProperty('kCommissionInfo')" class="goods_income">
              {{ goodsItem.kCommissionInfo.commissionName }}{{
                dataVisible ? `¥${formatPrice(goodsItem.kCommissionInfo.commission)}` : '****'
              }}
            </p>
            <p v-if="goodsItem.hasOwnProperty('doubleCommissionInfo')" class="goods_income">
              {{ goodsItem.doubleCommissionInfo.commissionName }}{{
                dataVisible ? `¥${formatPrice(goodsItem.doubleCommissionInfo.commission)}` : '****'
              }}
            </p>
          </div>
          <div class="goods_right">
            <p class="goods_price">
              ¥{{ formatPrice(goodsItem.distSubOrderExtension.price) }}
              <span>售价¥{{ formatPrice(goodsItem.distSubOrderExtension.salePrice) }}</span>
            </p>
            <p class="goods_num">x{{ goodsItem.amount }}</p>
          </div>
        </div>
      </div>
      <!-- 下单信息 -->
      <div class="order-detail_info">
        <div class="order-detail_info_time">
          <p class="order-detail_info_tip">下单时间:</p>
          <p class="order-detail_info_value">
            {{
              orderDetailData.gmtCreate &&
              moment(orderDetailData.gmtCreate).format("YYYY.MM.DD HH:mm:ss")
            }}
          </p>
        </div>
        <div class="order-detail_info_user" @click="handleClickGoClient(orderDetailData.sourceType)">
          <p :class="[
            'order-detail_info_tip',
            orderDetailData.sourceType === 2 ? 'public_style' : ''
          ]">
            下单用户:
          </p>
          <p :class="[
            'order-detail_info_name',
            'van-ellipsis',
            orderDetailData.sourceType === 2 ? 'public_style' : ''
          ]">
            {{
              dataVisible ? orderDetailData.userInfo && orderDetailData.userInfo.nickName : "****"
            }}
          </p>
          <p :class="[
              'order-detail_info_phone',
              orderDetailData.sourceType === 2 ? 'public_style' : ''
            ]">
            {{ parseMobile(orderDetailData.userInfo && orderDetailData.userInfo.mobile) }}
          </p>
          <p v-show="orderDetailData.userInfo && orderDetailData.userInfo.agentName" :class="[
            'order-detail_info_level',
            `level_color_${orderDetailData.userInfo && orderDetailData.userInfo.gradeCode}`
          ]">
            {{ orderDetailData.userInfo && orderDetailData.userInfo.agentName }}
          </p>
        </div>
      </div>
    </div>
    <!-- 具体价格优惠信息 -->
    <div v-if="!isShowEmpty" class="order-detail-price">
      <div class="order-detail-price-mask">
        <p class="title">商品货款</p>
        <p class="moneu">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.itemPrice
            )
          }}
        </p>
      </div>
      <div class="order-detail-price-mask">
        <p class="title">运费</p>
        <p class="moneu">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.deliveryFee
            )
          }}
        </p>
      </div>
      <div v-if="orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.taxPrice"
        class="order-detail-price-mask">
        <p class="title">税费</p>
        <p class="moneu">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.taxPrice
            )
          }}
        </p>
      </div>
      <div class="order-detail-price-mask">
        <p class="title">优惠</p>
        <p class="moneu">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.totalDiscount
            )
          }}
        </p>
      </div>
      <div class="order-detail-price-mask">
        <p class="title">实付合计</p>
        <p class="moneu">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.payPrice
            )
          }}
        </p>
      </div>
      <div v-if="orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.refundAmount"
        class="order-detail-price-mask">
        <p class="title title_hlight">售后退款</p>
        <p class="moneu moneu_hlight">
          ¥{{
            formatPrice(
              orderDetailData.distOrderExtension && orderDetailData.distOrderExtension.refundAmount
            )
          }}
        </p>
      </div>
      <van-divider />
      <!-- 佣金 -->
      <div v-if="orderDetailData.hasOwnProperty('promotionCommission')" class="order-detail-price-income">
        <p class="title">{{ orderDetailData.orderStatus === 3 ? "佣金合计" : "预估佣金合计" }}</p>
        <div class="content_mask">
          <img v-if="isShowSettlementTag(orderDetailData)" class="status" src="../../../assets/order_detail_fee_tag.png"
            alt="" />
          <p :class="[
            'money',
            orderDetailData.status === 0 && orderDetailData.orderStatus === 4
              ? `${dataVisible ? 'middle_line' : ''}`
              : ''
          ]">
            {{ dataVisible ? `¥${formatPrice(orderDetailData.promotionCommission)}` : "****" }}
          </p>
        </div>
      </div>
      <div v-if="orderDetailData.hasOwnProperty('kCommission')" class="order-detail-price-income">
        <p class="title">{{ orderDetailData.orderStatus === 3 ? "佣金补贴" : "预估佣金补贴" }}</p>
        <div class="content_mask">
          <img v-if="isShowSettlementTag(orderDetailData)" class="status" src="../../../assets/order_detail_fee_tag.png"
            alt="" />
          <p :class="[
            'money',
            orderDetailData.status === 0 && orderDetailData.orderStatus === 4
              ? `${dataVisible ? 'middle_line' : ''}`
              : ''
          ]">
            {{ dataVisible ? `¥${formatPrice(orderDetailData.kCommission)}` : "****" }}
          </p>
        </div>
      </div>
      <div v-if="orderDetailData.hasOwnProperty('doubleCommission')" class="order-detail-price-income">
        <p class="title">{{ orderDetailData.orderStatus === 3 ? "翻倍佣金" : "预估翻倍佣金" }}</p>
        <div class="content_mask">
          <img v-if="isShowSettlementTag(orderDetailData)" class="status" src="../../../assets/order_detail_fee_tag.png"
            alt="" />
          <p :class="[
            'money',
            orderDetailData.status === 0 && orderDetailData.orderStatus === 4
              ? `${dataVisible ? 'middle_line' : ''}`
              : ''
          ]">
            {{ dataVisible ? `¥${formatPrice(orderDetailData.doubleCommission)}` : "****" }}
          </p>
        </div>
      </div>
      <div v-if="orderDetailData.hasOwnProperty('cycleCommission')" class="order-detail-price-income">
        <p class="title">预计待解锁总佣金合计</p>
        <div class="content_mask">
          <img v-if="isShowSettlementTag(orderDetailData)" class="status" src="../../../assets/order_detail_fee_tag.png"
            alt="" />
          <p :class="[
            'money',
            orderDetailData.status === 0 && orderDetailData.orderStatus === 4
              ? `${dataVisible ? 'middle_line' : ''}`
              : ''
          ]">
            {{ dataVisible ? `¥${formatPrice(orderDetailData.cycleCommission)}` : "****" }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="order-detail-income">
      <div class="order-detail-income_head">
        <p class="title">收益明细</p>
        <img class="image_bk" src="../../../assets/order_detail_water_Income.png" alt="" />
      </div>
      <div v-for="(promotionDetailItem, index) in orderDetailData.promotionDetailList" :key="index" class="big_list">
        <p class="big_list_title">{{ promotionDetailItem.promotionName || "" }}</p>
        <div class="mask">
          <div v-for="(item, index) in promotionDetailItem.promotionUserList" :key="index" class="info">
            <p class="info_tip">{{ item.promotionUserName }}</p>
            <p class="info_name van-ellipsis">
              {{ dataVisible ? item.nickName : "****" }}
            </p>
            <p class="info_phone">{{ parseMobile(item.mobile) }}</p>
            <p :class="['info_level', item.gradeCode ? `level_color_${item.gradeCode}` : '']">
              {{ item.agentName }}
            </p>
          </div>
          <van-divider />
          <div class="money-name">
            <p class="title">{{ promotionDetailItem.promotionCommissionName }}</p>
            <p :class="[
              'money',
              orderDetailData.status === 0 && orderDetailData.orderStatus === 4
                ? `${dataVisible ? 'middle_line' : ''}`
                : ''
            ]">
              {{
                dataVisible ? `¥${formatPrice(promotionDetailItem.promotionCommission)}` : "****"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :class="['data-fixed', dataVisible ? 'data-show' : 'data-hide']" v-if="!isShowEmpty"
      @click="dataVisible = !dataVisible"></div>
    <van-empty v-if="isShowEmpty" description="数据异常" />
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "../../../utils/money";
export default {
  created() {
    document.title = "订单详情";
  },
  data() {
    return {
      token: "",
      dataVisible: true,
      isShowEmpty: false,
      orderDetailData: {}
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getOrderDetailData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getOrderDetailData();
        });
      }
    });
  },
  computed: {
    orderStatusContent() {
      return orderStatus => {
        if (orderStatus === 0) {
          return "待付款";
        } else if (orderStatus === 1) {
          return "待发货";
        } else if (orderStatus === 2) {
          return "已发货";
        } else if (orderStatus === 3) {
          return "已完成";
        } else {
          return "已关闭";
        }
      };
    },
    skuSpec() {
      return specification => {
        const specArr = [];
        if (specification) {
          const specJSON = JSON.parse(specification);
          Object.keys(specJSON).map(ele => {
            specArr.push(specJSON[ele]);
          });
        }
        return specArr.join("，");
      };
    },
    isShowSettlementTag() {
      return orderDetailModel => {
        if (
          orderDetailModel.status === 1 ||
          orderDetailModel.orderStatus === 3 ||
          orderDetailModel.orderStatus === 4
        ) {
          return false;
        } else {
          return true;
        }
      };
    }
  },
  methods: {
    moment,
    formatPrice,
    parseMobile(phone) {
      phone = phone || "";
      var pho = /(\d{3})\d*(\d{4})/;
      var str = phone.replace(pho, "$1****$2");
      return str;
    },
    // 获取订单详情数据
    async getOrderDetailData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/user/getDistOrderDetail",
        data: { orderId: this.$route.query.orderId }
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.isShowEmpty = false;
          this.orderDetailData = { ...data.data };
        } else {
          this.isShowEmpty = true;
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    onCopyOrderNo(orderId) {
      this.$copyText(orderId)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch(() => {
          this.$toast("复制失败");
        });
    },
    handleClickGoClient(sourceType) {
      if (sourceType === 2) {
        this.$router.push(`/addol/myclient?token=${this.token}&userId=${this.orderDetailData.userInfo.distUserId}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  /deep/ .van-empty {
    width: 100vw;
    height: 90vh;
  }

  .order-detail {
    width: calc(100% - 16px);
    margin-top: 10px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding-bottom: 5px;

    .order-detail-head {
      margin-left: 16px;
      margin-right: 16px;
      box-sizing: border-box;
      height: 47px;
      display: flex;
      align-items: center;

      .order-detail-num {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8d8c8c;
      }

      .order-detail-copy {
        width: 12px;
        height: 12px;
        margin-left: 5px;
      }

      .order-detail-orderstatus {
        flex: 1;
        width: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: right;
      }

      .orderstatus_color_0 {
        color: #fa550a;
      }

      .orderstatus_color_1 {
        color: #fa550a;
      }

      .orderstatus_color_2 {
        color: #fa550a;
      }

      .orderstatus_color_3 {
        color: #8d8c8c;
      }

      .orderstatus_color_4 {
        color: #fa550a;
      }
    }

    .order-detail-goods {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;

      .goods_cover {
        width: 71px;
        height: 71px;
        background-color: #F5F5F5;
      }

      .goods_middle {
        flex: 1;
        width: 0;
        margin-left: 8px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .goods_name {
          padding: 0;
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
        }

        .income_mask {
          width: 100%;
          display: flex;
          justify-content: left;
          flex-wrap: wrap;

          .goods_income {
            padding: 0px 5px;
            margin: 0;
            margin-right: 5px;
            margin-top: 5px;
            height: 17px;
            border-radius: 1px;
            border: 1px solid #323cb4;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323cb4;
            line-height: 17px;
            text-align: center;
          }
        }

        .goods_right {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 3px;

          .goods_price {
            padding: 0;
            margin: 0;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;

            span {
              font-size: 10px;
              color: #8d8c8c;
            }
          }

          .goods_num {
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #404040;
          }
        }
      }
    }

    .order-detail_info {
      margin: 7px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .order-detail_info_time {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 2px;

        .order-detail_info_tip {
          padding: 0;
          margin: 0;
          margin-right: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .order-detail_info_value {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }
      }

      .order-detail_info_user {
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;
        justify-content: left;

        .order-detail_info_tip {
          padding: 0;
          margin: 0;
          padding-right: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .order-detail_info_name {
          max-width: 125px;
          padding: 0;
          margin: 0px 0px 0px 0px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
          text-align: left;
        }

        .order-detail_info_phone {
          padding: 0;
          margin: 0px 2px 0px 0px;
          padding-left: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8d8c8c;
        }

        .public_style {
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -ms-box-sizing: border-box;
          -o-box-sizing: border-box;
          border-bottom: 1px solid #f9550a;
          color: #f9550a !important;
        }

        .order-detail_info_level {
          padding: 1px 3px;
          margin: 0;
          height: 15px;
          border-radius: 1px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 15px;
          text-align: center;
        }

        .level_color_1 {
          background: #b6c4ff;
        }

        .level_color_2 {
          background: #f06d32;
        }

        .level_color_3 {
          background: #323cb4;
        }

        .level_color_-1 {
          background: #c0c0c0;
        }
      }
    }
  }

  .order-detail-price {
    width: calc(100% - 16px);
    margin-top: 10px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;

    .order-detail-price-mask {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 7px 16px;

      .title {
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }

      .title_hlight {
        color: #de7d36 !important;
      }

      .moneu_hlight {
        color: #de7d36 !important;
      }

      .moneu {
        padding: 0;
        margin: 0;
        font-size: 15px;
        font-family: PingFangHK-Semibold, PingFangHK;
        font-weight: 600;
        color: #404040;
      }
    }

    .van-divider {
      background-color: #f1f1f1;
      color: #fff0bd;
      margin: 4px 16px 0px 16px;
      border: none;
    }

    .order-detail-price-income {
      display: flex;
      box-sizing: border-box;
      margin: 15px 16px;
      justify-content: space-between;
      align-items: center;

      .title {
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
      }

      .content_mask {
        display: flex;
        justify-content: right;
        align-items: center;

        .status {
          width: 50px;
          height: 19px;
          margin-right: 5px;
        }

        .money {
          padding: 0;
          margin: 0;
          font-size: 15px;
          font-family: PingFangHK-Semibold, PingFangHK;
          font-weight: 600;
          color: #404040;
        }
      }

      .middle_line {
        text-decoration: line-through;
      }
    }
  }

  .order-detail-income {
    width: calc(100% - 16px);
    margin-top: 10px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;

    .order-detail-income_head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      .title {
        padding: 0;
        margin: 0;
        margin-top: 13px;
        margin-bottom: 1px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }

      .image_bk {
        height: 14px;
      }
    }

    .big_list {
      width: 100%;
      margin: 0 5px 15px 5px;

      .big_list_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #000000;
      }

      .mask {
        width: 100%;
        background: #fcfcfc;
        border-radius: 4px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding: 1px 10px;

        .info {
          display: flex;
          box-sizing: border-box;
          margin: 10px 0px;
          width: 100%;
          align-items: center;

          .info_tip {
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-right: 8px;
          }

          .info_name {
            max-width: 90px;
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            text-align: left;
          }

          .info_phone {
            padding: 0;
            margin: 0px 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          .info_level {
            padding: 1px 3px;
            margin: 0;
            height: 16px;
            border-radius: 1px;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            line-height: 16px;
          }

          .level_color_1 {
            background: #b6c4ff;
          }

          .level_color_2 {
            background: #f06d32;
          }

          .level_color_3 {
            background: #323cb4;
          }

          .level_color_-1 {
            background: #c0c0c0;
          }
        }

        /deep/ .van-divider {
          margin: 5px 0px;
        }

        .money-name {
          width: 100%;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          margin: 5px 0px;
          align-items: center;

          .title {
            padding: 0;
            margin: 0;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #404040;
          }

          .money {
            padding: 0;
            margin: 0;
            font-size: 15px;
            font-family: PingFangHK-Semibold, PingFangHK;
            font-weight: 600;
            color: #404040;
          }

          .middle_line {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  .data-fixed {
    position: fixed;
    top: 58px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .data-show {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/show.png?") no-repeat;
    background-size: 100% 100%;
  }

  .data-hide {
    background: url("https://oss-kiddo.manqu88.com/h5/cycleCardStatisticsList/hide.png?") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
