<template>
  <div class="wrap">
    <div class="specBox">
      <div class="specItem" v-for="item in skuShowDTOS" :key="item.id">
        <div class="specTitle">{{ item.specificationName }}</div>
        <div class="specVal">
          <div
            class="item"
            :class="{ selected: selectedSku[item.specificationName] === ele }"
            v-for="ele in item.specificationValues"
            :key="ele"
            @click="selectSpec(item.specificationName, ele)">
            {{ ele }}
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="descBox">
      <table>
        <tr>
          <th>收益类型</th>
          <th>场景</th>
          <th>金额</th>
        </tr>
        <!-- <tr>
          <td rowspan="8" class="rowspan">佣金</td>
          <td>普通用户 下单</td>
          <td>￥{{ commissionData.levelCommissionInfo['-1_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr>
          <td>普通用户 下单间隔KC</td>
          <td>￥{{ commissionData.levelCommissionInfo['-1_1_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr>
          <td>普通用户 下单间隔KB</td>
          <td>￥{{ commissionData.levelCommissionInfo['-1_2_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr>
          <td>普通用户 下单间隔KC&KB</td>
          <td>￥{{ commissionData.levelCommissionInfo['-1_1_2_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr> -->
        <tr>
          <td rowspan="4" class="rowspan">佣金</td>
          <td>KC 下单</td>
          <td>￥{{ commissionData.levelCommissionInfo[gradeCode === 3 ? '1_3' : '1_2_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr v-if="gradeCode === 3">
          <td>KC 下单间隔KB</td>
          <td>￥{{ commissionData.levelCommissionInfo['1_2_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr v-if="gradeCode === 3">
          <td>KB 下单</td>
          <td>￥{{ commissionData.levelCommissionInfo['2_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr v-if="gradeCode === 3">
          <td>直属KA 下单</td>
          <td>￥{{ commissionData.levelCommissionInfo['3_3'] | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
      </table>

      <table v-if="gradeCode === 3">
        <tr>
          <td rowspan="2" class="rowspan">佣金补贴</td>
          <td>首级</td>
          <td>￥{{ commissionData.subsidyInfo.firstAmount | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr>
          <td>次级</td>
          <td>￥{{ commissionData.subsidyInfo.secondAmount | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
      </table>

      <table v-if="gradeCode === 3 && commissionData.extraSubsidyInfo">
        <tr>
          <td rowspan="2" class="rowspan" style="color: #FA6400">额外补贴</td>
          <td>首级</td>
          <td>￥{{ commissionData.extraSubsidyInfo.firstAmount | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
        <tr>
          <td>次级</td>
          <td>￥{{ commissionData.extraSubsidyInfo.secondAmount | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
      </table>

      <table>
        <tr>
          <td class="rowspan">自购立减</td>
          <td>自购</td>
          <td>￥{{ commissionData.selfInfo.amount | formatPrice }}<span v-if="!skuId" class="tip">起</span></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "../utils/money";

export default {
  data() {
    return {
      itemId: null,
      skuId: null,
      skuDTOS: [],
      skuShowDTOS: [],
      selectedSku: {},
      commissionData: {
        levelCommissionInfoList: [],
        levelCommissionInfo: {},
        selfInfo: {
          amount: 0
        },
        subsidyInfo: {
          firstAmount: 0,
          secondAmount: 0
        },
        extraSubsidyInfo: {
          firstAmount: 0,
          secondAmount: 0
        }
      },
      token: '',
      gradeCode: 3, // KA 3, KB 2
    }
  },
  filters: {
    formatPrice
  },
  mounted(){
    document.title = '收益说明';
    this.itemId = this.$route.query.itemId;
    this.token = this.$route.query.token;
    // this.queryUserLevel();
    this.queryItemDetail();
  },
  methods: {
    // 查询会员等级
    async queryUserLevel() {
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          version: 'mini_1.2.4',
          Authorization: this.token,
        },
        url: "/api/member/queryMemberByUserId",
      });
      if (success) {
        this.gradeCode = data.gradeCode;
      } else {
        this.$toast(message);
      }
    },

    // 查询商品详情规格信息
    async queryItemDetail() {
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          version: 'mini_1.2.4',
          Authorization: this.token,
        },
        url: "/api/item/queryItemById",
        data: {
          id: this.itemId
        }
      });
      if (success) {
        console.log('商品数据---', data);
        this.skuDTOS = [ ...data.skuDTOS ];
        this.skuShowDTOS = [ ...data.skuShowDTOS ];
        if (this.skuDTOS.length) {
          this.selectedSku = JSON.parse(this.skuDTOS[0].specification);
          this.skuId = this.skuDTOS[0].id;
          this.$forceUpdate();
          this.queryCommissionDetail();
        }
      } else {
        this.$toast(message);
      }
    },

    /**
     * 选择规格
     * param1 规格名
     * param2 规格值
     */
    selectSpec(specName, specVal) {
      const { skuDTOS } = this;
      if (this.selectedSku[specName] === specVal) {
        // delete this.selectedSku[specName];
        return;
      } else {
        this.selectedSku[specName] = specVal;
      }
      this.$forceUpdate();
      const currentSku = skuDTOS.filter(item => item.specification === JSON.stringify(this.selectedSku));
      if (currentSku.length) {
        this.skuId = currentSku[0].id;
      } else {
        this.skuId = null;
      }
      console.log('已选数据---', this.selectedSku, this.skuId);

      // 防抖
      let timer;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        this.queryCommissionDetail();
      }, 300);
    },

    // 查询佣金数据
    async queryCommissionDetail() {
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          version: 'mini_1.2.4',
          Authorization: this.token,
        },
        url: "/api/item/queryCommissionDetail",
        data: {
          itemId: this.itemId,
          skuId: this.skuId
        }
      });
      if (success) {
        console.log('佣金数据---', data);
        let levelCommissionInfo = {};
        data.levelCommissionInfoList.map(ele => {
          levelCommissionInfo[ele.levelKey] = ele.amount;
        })
        this.commissionData = {
          ...data,
          levelCommissionInfo
        }
      } else {
        this.$toast(message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 375px;
  background: #FFFFFF;

  .specBox {
    width: 100%;
    padding: 20px 10px 7px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .specItem {
      .specTitle {
        margin-bottom: 12px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        line-height: 20px;
      }

      .specVal {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-right: 10px;
          padding: 5px 25px;
          border-radius: 4px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646464;
          margin-bottom: 15px;
          background: #F7F7F7;
          border: 1px solid #F7F7F7;

          &.selected {
            background: #FFFFFF;
            border: 1px solid #141414;
          }
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 5px;
    background: #F7F7F7;
  }

  .descBox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    table {
      width: 100%;
      margin-bottom: 32px;
      border-collapse: collapse;

      tr {
        width: 100%;

        th {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #121212;
          padding-bottom: 15px;
          text-align: center;

          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 50%;
          }
          &:nth-child(3) {
            width: 25%;
          }
        }

        td {
          margin: 0;
          padding: 0;
          height: 30px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #121212;
          line-height: 30px;
          text-align: center;
          border: 1px solid #F7F7F7;

          &.rowspan {
            font-weight: bold;
          }

          .tip {
            font-size: 9px;
          }

          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 50%;
          }
          &:nth-child(3) {
            width: 25%;
          }
        }
      }
    }
  }
}
</style>
