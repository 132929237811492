<template>
    <div class="ck-wheel">

        <!-- <div class="share" @click="share">分享</div> -->
        <div class="topBg">
            <div class="btn-share" @click="share"></div>
            <div class="btn-rule" @click="goRule(1)"></div>
            <div v-if="recordList.length > 0">
                <prizeRecord :recordList="recordList" ref="orderMessageRef" :statusBarHeight="298">
                </prizeRecord>
            </div>

        </div>
        <div class="bg">
            <div class="paddingTop"></div>


            <div class="des-card" v-for="(item, index) in cardList" @click="goDetailPage(item.activityId, item.phaseId)"
                :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/activity_${index}.png?${Date.now()})` }">

                <div class="phaseNum">
                    <div>第</div>
                    <div style="width:1px"></div>
                    <div>{{ item.phase && item.phase.toString().padStart(2, '0') }}</div>
                    <div style="width:1px"></div>
                    <div>期</div>
                </div>

                <div class="des-view">
                    <div :class="['des-' + index]"></div>
                    <div class="needCodeNum">{{ item.surplusCodeNum }}</div>
                    <div class="des-unit"></div>
                </div>

                <div class="des-progress">
                    <div class="progess-inner"
                        :style="{ width: `${100 - (item.surplusCodeNum <= 0 ? 0 : (100 * item.surplusCodeNum / item.needCodeNum))}%` }">
                    </div>
                </div>

                <div class="des-btn"
                    :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/btn_need${item.phase <= 1 ? '_0' : ''}.png?${Date.now()})` }">
                    <div class="needBean">
                        {{ item.lessCodeNum }}金豆参与
                    </div>
                </div>
            </div>


            <div class="midPadding"></div>
            <div :class="['curGift-' + (index === 0 || index === 3 ? 1 : 2), curGoldLevel === index ? '' : 'curGiftNone']"
                v-for="(item, index) in cardList" :key="index">
                <div class="gift"
                    :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/gift_${index}.png?${Date.now()})` }">
                </div>
            </div>


            <div class="level-progress">
                <div class="level-progress-inner"
                    :style="{ width: `${goldData.useTakeGoldNum >= 110 ? 100 : (goldData.useTakeGoldNum * 100 / 110)}%` }">
                </div>
                <div class="status-icon"
                    :style="{ left: '14%', backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/getStatus_${curGoldLevel < 0 ? 0 : curGoldLevel === 0 ? 1 : 2}.png)` }">
                    <div class="level">第一档</div>
                    <div class="nums">{{ goldLevel[0] }}个</div>
                </div>
                <div class="status-icon"
                    :style="{ left: '40%', backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/getStatus_${curGoldLevel < 1 ? 0 : curGoldLevel === 1 ? 1 : 2}.png)` }">
                    <div class="level">第二档</div>
                    <div class="nums">{{ goldLevel[1] }}个</div>
                </div>
                <div class="status-icon"
                    :style="{ left: '66%', backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/getStatus_${curGoldLevel < 2 ? 0 : curGoldLevel === 2 ? 1 : 2}.png)` }">
                    <div class="level">第三档</div>
                    <div class="nums">{{ goldLevel[2] }}个</div>
                </div>
                <div class="status-icon"
                    :style="{ left: '90%', backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/getStatus_${curGoldLevel < 3 ? 0 : curGoldLevel === 3 ? 1 : 2}.png)` }">
                    <div class="level">第四档</div>
                    <div class="nums">{{ goldLevel[3] }}个</div>
                </div>
            </div>


            <div class="score-des score-des-left">
                {{ goldData.useTakeGoldNum }}
                <!-- <div class="score-unit">个</div> -->
            </div>

            <div class="score-des score-des-right">
                {{ nextGoldLeft }}
                <!-- <div class="score-unit">个</div> -->
            </div>



            <div class="myScore" @click="goGoldBean">
                <div class="score">x{{ goldData.useGoldNum }}</div>
            </div>
            <div class="record" @click="goRecord"></div>
            <div class="getWay" @click="showPop = true"></div>
        </div>

        <div class="mask" v-if="showPop">
            <div class="pop" v-if="winId < 0">
                <div class="btn-close" @click.stop="showPop = false"></div>
                <div class="btn-invite" @click="share"></div>
                <div class="btn-goHome" @click="goHomePage"></div>
            </div>

            <div class="pop" v-else @click="goYHQ"
                :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/duobao/reward_${winList[winId]}.png?${Date.now()})` }">
                <div class="btn-close" @click.stop="showPop = false; winId = -1;"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import prizeRecord from '@/views/activity/treasure/components/prizeRecord.vue';
export default {
    name: "assist",
    components: { prizeRecord },
    data() {
        return {
            id: "",
            itemId: "",
            token: this.$route.query.token,
            assistList: [],
            isWx: false,
            goldData: {
                userId: '',
                useTakeGoldNum: '',
                useGoldNum: '',
            },
            cardList: [
                {
                    needCodeNum: 1,
                },
                {
                    needCodeNum: 3,
                },
                {
                    needCodeNum: 2,
                },
                {
                    needCodeNum: 1,
                },
            ],
            goldLevel: [20, 50, 80, 110],
            curGoldLevel: -1,
            nextGoldLeft: 0,
            canClick: false,
            showPop: false,
            winId: -1,
            winList: {
                19: 1,
                7: 0,
                8: 1,
                9: 2,
                10: 3,
            },
            recordList: [],
        };
    },
    created() {
        document.title = "kiddol 6.1嘉年华 金豆大作战";
    },
    mounted() {
        this.token = this.$route.query.token;
        // this.getActivityData();
        // this.getList();
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                    this.getActivityData();
                    this.getList();
                    this.isWx = true;
                } else {
                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                        this.getActivityData();
                        this.getList();
                    });
                }
            });
        }, 0);
        this.$setAssistGoldData({
            type: 0 //1:详情页 0:活动页 2:列表页
        });
    },

    methods: {
        formatPrice,
        async queryPagePrizeRecord() {
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token
                },
                url: "/api/treasure/queryPagePrizeRecord",
                data: {
                    currentPage: 1,
                    pageSize: 200
                }
            });
            if (data.success) {
                this.recordList = data.data;
                setTimeout(() => {
                    this.$refs['orderMessageRef'].init();
                }, 0);
                console.log(this.recordList)
            } else {
                this.$toast(data.message);
            }
        },
        async getPop() {
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token
                },
                url: "/api/popupWindow/trigger",
                data: {
                    pageType: 2
                }
            });
            if (data.success) {
                if (data.data.length > 0) {
                    this.showPop = true;
                    this.winId = Number(data.data[0].pageIdent);
                }
            } else {
                this.$toast(data.message);
            }
        },
        async getActivityData() {
            this.queryPagePrizeRecord();
            this.getPop();
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token
                },
                url: "/api/treasure/queryMyPhase",
                data: {}
            });
            if (data.success) {
                this.itemId = data.data.itemId;
            } else {
                this.$toast(data.message);
            }

            this.getGoldData();


        },
        async getGoldData() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/userGold/queryUserGoldData",
                    data: {
                    }
                });
                if (data.success) {
                    this.goldData = data.data;
                    // this.goldData.useTakeGoldNum = 50;
                    for (let i = 0; i < this.goldLevel.length - 1; i++) {
                        if (this.goldData.useTakeGoldNum < this.goldLevel[i + 1] && this.goldData.useTakeGoldNum >= this.goldLevel[i]) {
                            this.curGoldLevel = i;
                        }
                    }
                    if (this.goldData.useTakeGoldNum >= this.goldLevel[this.goldLevel.length - 1]) {
                        this.curGoldLevel = this.goldLevel.length - 1;
                    }
                    this.nextGoldLeft = (this.curGoldLevel >= this.goldLevel.length - 1) ? 0 : (this.goldLevel[this.curGoldLevel + 1] - this.goldData.useTakeGoldNum);
                    console.log('this.goldData', this.goldData, this.nextGoldLeft)
                } else {
                    this.$toast(data.message);
                }
            }
        },
        async getList() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/treasureActivity/queryPage",
                    data: {
                        pageSize: 4,
                        currentPage: 1,
                    }
                });
                if (data.success) {
                    this.cardList = data.data;
                    this.canClick = true;
                } else {
                    this.$toast(data.message);
                }
            }
        },



        goRule(type) {
            this.$router.push(`/treasure/rule?type=${type}`);
        },
        goGoldBean() {
            this.$router.push(`/treasure/goldBean?token=${this.token}`);
        },

        goRecord(activityId, phaseId) {
            this.$router.push(`/treasure/drawRecord?token=${this.token}`);
        },

        goDetailPage(activityId, phaseId) {
            if (!this.canClick) {
                return;
            }
            this.$router.push(`/treasure/detail?token=${this.token}&activityId=${activityId}&phaseId=${phaseId}`);
        },
        // 优惠券
        goYHQ() {
            uni.webView.getEnv(res => {
                if (res.miniprogram) {
                    uni.webView.navigateTo({
                        url: `/pagesA/coupon/index`
                    });
                } else {
                    this.$bridge
                        .call("openPage", {
                            type: 5,
                            param: 1
                        })
                        .then(e => {
                            console.log("jumpToDetail客户端返回: ", e);
                        });
                }
            });
        },
        // 跳转应用主页
        goHomePage() {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram) {
                    uni.webView.switchTab({
                        url: "/pages/index/index"
                    });
                } else {
                    this.$bridge.call("jumpToMain", {}).then(e => {
                        console.log("jumpToMain客户端返回: ", e);
                    });
                }
            });
        },
        share() {
            let activityId = 0;

            uni.webView.getEnv(res => {
                if (res.miniprogram) {
                    uni.webView.navigateTo({
                        url: `/otherPackage/db/share?activityId=${activityId}` //shareType    分享类型  1- 活动分享   2-助力页分享   assistId    // 助力金id  shareType=2时，必填
                    });


                } else {
                    let param = {
                        type: 0,
                        param: {
                            activityId: activityId.toString(), phaseId: '', title: '', des: '', image: ''
                        }
                    }
                    this.$bridge
                        .call("openPage", {
                            type: 49,
                            param: JSON.stringify(param)
                        })
                        .then(e => {
                            console.log("openPage27客户端返回: ", e);
                        });
                }
            });
        }

    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "mcFont";
    src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .share {
        width: 50%;
        height: 100px;
    }

    .topBg {
        position: relative;
        width: 375px;
        height: 344px;
        background: url(https://oss-kiddo.manqu88.com/h5/duobao/topBg.png?7) no-repeat;
        background-size: 100% 100%;

        .btn-share {
            position: absolute;
            top: 64px;
            right: 7px;
            width: 32px;
            height: 32px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_share.png?7) no-repeat;
            background-size: 100% 100%;
        }

        .btn-rule {
            position: absolute;
            top: 22px;
            right: 7px;
            width: 32px;
            height: 32px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_rule.png?7) no-repeat;
            background-size: 100% 100%;
        }
    }

    .bg {
        width: 375px;
        height: 1929px;
        background: url(https://oss-kiddo.manqu88.com/h5/duobao/duobaoMainBg.png?7) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;


        .paddingTop {
            width: 100%;
            height: 248px;
        }

        .des-card {
            width: 350px;
            height: 118px;
            margin-bottom: 10px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/activity_0.png?7) no-repeat;
            background-size: 100% 100%;
            position: relative;

            .phaseNum {
                position: absolute;
                left: 0px;
                top: 4px;
                padding-top: 1px;
                width: 64px;
                height: 26px;
                background: #FFE800;
                border-radius: 13px;
                line-height: 26px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #010100;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .des-btn {
                position: absolute;
                width: 136px;
                height: 30px;
                left: 182px;
                top: 77px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_need.png?7) no-repeat;
                background-size: 100% 100%;

                .needBean {
                    position: absolute;
                    left: 5px;
                    top: 7px;
                    width: 60px;
                    height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #404040;
                    line-height: 17px;
                    text-align: center;
                    word-break: keep-all;
                }
            }

            .des-progress {
                position: absolute;
                width: 140px;
                height: 10px;
                left: 182px;
                top: 54px;
                background: #F3F3F6;
                border-radius: 6px;

                .progess-inner {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 85px;
                    height: 10px;
                    background: #FF5A27;
                    border-radius: 6px;
                }
            }

            .des-view {
                position: absolute;
                width: auto;
                height: 12px;
                left: 182px;
                top: 34px;
                display: flex;

                .needCodeNum {
                    height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FF5A27;
                    line-height: 13px;
                }

                .des-unit {
                    width: 20px;
                    height: 12px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/des_unit.png?7) no-repeat;
                    background-size: 100% 100%;
                }



                .des-0 {
                    width: 108px;
                    height: 12px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/des_0.png?7) no-repeat;
                    background-size: 100% 100%;
                }

                .des-1 {
                    width: 109px;
                    height: 12px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/des_1.png?7) no-repeat;
                    background-size: 100% 100%;
                }

                .des-2 {
                    width: 106px;
                    height: 12px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/des_2.png?7) no-repeat;
                    background-size: 100% 100%;
                }

                .des-3 {
                    width: 101px;
                    height: 12px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/des_3.png?7) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

        .myScore {
            width: 160px;
            height: 60px;
            position: absolute;
            left: 23px;
            top: 20px;

            .score {
                position: absolute;
                left: 104px;
                top: 14px;
                height: 30px;
                font-size: 22px;
                font-family: Barlow-Bold, Barlow, Barlow-SemiBold;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 30px;
            }
        }

        .record {
            width: 160px;
            height: 60px;
            position: absolute;
            right: 22px;
            top: 20px;
        }

        .getWay {
            width: 330px;
            height: 37px;
            position: absolute;
            left: 23px;
            top: 86px;
        }


        .level-progress {
            position: absolute;
            left: 30px;
            top: 976px;
            width: 316px;
            height: 8px;
            background: #D7E1E7;
            border-radius: 4px;

            .level-progress-inner {
                position: absolute;
                left: 0;
                top: 0;
                width: 149px;
                height: 8px;
                background: #FFC600;
                border-radius: 4px;
            }


            .status-icon {
                position: absolute;
                left: 13%;
                top: -10px;
                width: 36px;
                height: 28px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/getStatus_0.png?7) no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #191919;

                .level {
                    margin-top: -23px;
                    width: 36px;
                    height: 17px;

                }

                .nums {
                    margin-top: 43px;
                    width: 36px;
                    height: 17px;
                }
            }
        }


        .score-des {
            position: absolute;
            top: 1040px;
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-family: Barlow-Bold, Barlow;
            font-weight: bold;
            color: #FF5A27;

            .score-unit {
                margin-left: 2px;
                width: 13px;
                height: 18px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #0B0B0B;
                line-height: 26px;
            }
        }

        .score-des-left {
            left: 114px;
            width: 30px;
            text-align: center;
        }

        .score-des-right {
            left: 298px;
            width: 30px;
            text-align: center;
        }



        .midPadding {
            height: 347px;
            width: 100%;
        }

        .curGift-2 {
            position: relative;
            width: 366px;
            height: 175px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/curGift_2.png?7) no-repeat;
            background-size: 100% 100%;

            .gift {
                position: absolute;
                left: 9px;
                bottom: 8px;
                width: 350px;
                height: 148px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/gift_0.png?7) no-repeat;
                background-size: 100% 100%;
            }
        }

        .curGift-1 {
            position: relative;
            width: 366px;
            height: 175px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/curGift_1.png?7) no-repeat;
            background-size: 100% 100%;

            .gift {
                position: absolute;
                left: 9px;
                bottom: 8px;
                width: 350px;
                height: 148px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/gift_0.png?7) no-repeat;
                background-size: 100% 100%;
            }
        }

        .curGiftNone {
            background: none;
        }


    }

    .mask {
        z-index: 10000;
        width: 375px;
        height: 100vh;
        background: rgba($color: #000000, $alpha: 0.5);
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;


        .pop {
            position: relative;
            width: 290px;
            height: 372px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/getRule.png?7) no-repeat;
            background-size: 100% 100%;

            .btn-close {
                position: absolute;
                right: -18px;
                top: -18px;
                width: 36px;
                height: 36px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_close.png?7) no-repeat;
                background-size: 100% 100%;
            }

            .btn-invite {
                position: absolute;
                left: 10px;
                bottom: 8px;
                width: 134px;
                height: 56px;
            }

            .btn-goHome {
                position: absolute;
                right: 10px;
                bottom: 8px;
                width: 134px;
                height: 56px;
            }
        }
    }
}
</style>
  