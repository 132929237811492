import { render, staticRenderFns } from "./gruopSelfRewardsDetail.vue?vue&type=template&id=da8e620e&scoped=true&"
import script from "./gruopSelfRewardsDetail.vue?vue&type=script&lang=js&"
export * from "./gruopSelfRewardsDetail.vue?vue&type=script&lang=js&"
import style0 from "./gruopSelfRewardsDetail.vue?vue&type=style&index=0&id=da8e620e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da8e620e",
  null
  
)

export default component.exports