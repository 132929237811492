<template>
  <div :class="['ck-wheel']">
    <!-- 地址 -->
    <div class="popup" v-show="showModal">
      <div class="mask"></div>
      <div class="modal">
        <!-- <img
          class="close"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/icon-close.png"
          @click="close"
        /> -->
        <div class="title">填写收货信息</div>
        <div class="border"></div>
        <div class="form">
          <div class="label">收货人姓名</div>
          <van-field
            v-model="receiverName"
            placeholder="请输入收货人姓名"
            maxlength="16"
            :border="false"
          />
          <div class="label">收货人手机号</div>
          <van-field
            v-model="receiverPhone"
            type="tel"
            placeholder="请输入收货人手机号"
            maxlength="11"
            :border="false"
          />
          <div class="label">收货地址</div>
          <van-field
            v-model="address"
            readonly
            placeholder="请选择省-市-区"
            :border="false"
            @click="onShowArea"
          />
          <van-field
            class="detail"
            v-model="detail"
            rows="2"
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="100"
            :border="false"
            @click="handleScrollNormal('detail')"
          />
          <div class="save" @click="onSave">保存</div>
        </div>
      </div>
    </div>
    <div v-show="showArea" class="maskArea" @click="cancel"></div>
    <van-area
      class="area"
      :class="{ showArea: showArea }"
      title=""
      :area-list="areaList"
      @confirm="confirm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import browser from "@/utils/browser";

export default {
  name: "turnTableRecord",
  components: {},
  created() {
    document.title = "填写地址";
    this.getAddressData();
  },
  data() {
    return {
      id: this.$route.query.id,
      token: this.$route.query.token,
      showModal: true,
      areaList,
      showArea: false,
      receiverName: "",
      receiverPhone: "",
      address: "",
      provinceName: "",
      cityName: "",
      areaName: "",
      detail: "",
    };
  },
  methods: {
    async getAddressData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/prizeRecord/queryById",
        data: {
          id: this.id,
        },
      });
      if (
        data.success &&
        data.data.extension &&
        data.data.extension.prizeAddressDTO
      ) {
        var datas = data.data.extension.prizeAddressDTO;
        this.address = `${datas.provinceName}-${datas.cityName}-${datas.areaName}`;
        this.provinceName = datas.provinceName;
        this.cityName = datas.cityName;
        this.areaName = datas.areaName;
        this.receiverName = datas.receiverName;
        this.receiverPhone = datas.receiverPhone;
        this.detail = datas.detail;
      } else if(!data.success) {
        this.$toast(data.message);
      }
    },
    showAdress() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    onShowArea() {
      this.showArea = true;
    },
    confirm(value) {
      this.showArea = false;
      console.log(value);
      this.address = `${value[0].name}-${value[1].name}-${value[2].name}`;
      this.provinceName = value[0].name;
      this.cityName = value[1].name;
      this.areaName = value[2].name;
    },
    cancel() {
      this.showArea = false;
    },
    onSave() {
      const {
        receiverName,
        receiverPhone,
        provinceName,
        cityName,
        areaName,
        detail,
        address,
      } = this;

      if (!receiverName) {
        Toast("请填写收货人姓名");
        return;
      }
      if (!receiverPhone) {
        Toast("请填写收货人手机号");
        return;
      }
      const reg = new RegExp(/^1\d{10}$/);
      if (!reg.test(receiverPhone)) {
        Toast("请填写正确的手机号");
        return;
      }
      if (!address) {
        Toast("请选择地区");
        return;
      }
      if (!detail) {
        Toast("请填写详细地址");
        return;
      }

      //area
      this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/prizeRecord/updateAddress",
        data: {
          id: this.id,
          prizeAddressDTO: {
            receiverName,
            receiverPhone,
            provinceName,
            cityName,
            areaName,
            detail,
          },
        },
      })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            Toast("保存成功！");

            setTimeout(() => {
              this.$router.back();
            }, 1000);
          } else {
            Toast(data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("保存失败，请重试！");
        });
    },
    handleScrollNormal(param) {
      setTimeout(function () {
        document.getElementsByClassName(param)[0].scrollIntoViewIfNeeded();
      }, 500);
    },
  },
};
</script>
<!-- <style lang="scss" scoped> -->
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  // height: 100%;
  background: url(../assets/turnTable/record_bg.png) no-repeat;
  background-size: 100% 100%;
}
.scroll {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    position: relative;
    margin-top: 25px;
    width: 340px;
    height: 95px;
    // background: url(../assets/turnTable/card_bg.png) no-repeat;
    background-size: 100% 100%;

    .title-1 {
      position: absolute;
      left: 25px;
      top: 18px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3d3d;
      line-height: 25px;
    }
    .time-1 {
      position: absolute;
      left: 25px;
      top: 55px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2e2e2e;
      line-height: 17px;
    }
    .line {
      position: absolute;
      right: 114px;
      top: 17px;
      height: 60px;
      border: 1px solid #000000;
    }

    .toUse {
      position: absolute;
      right: 24px;
      top: 33px;
      width: 78px;
      height: 29px;
      background: url(../assets/turnTable/toUse.png) no-repeat;
      background-size: 100% 100%;
    }

    .img {
      position: absolute;
      left: 19px;
      top: 14px;
      width: 67px;
      height: 67px;
    }

    .time-2 {
      position: absolute;
      left: 0px;
      top: -23px;
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }

    .title-2 {
      position: absolute;
      left: 92px;
      top: 22px;
      width: 132px;
      height: 18px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
    }

    .span-price-title {
      display: flex;
      align-items: center;
      position: absolute;
      left: 92px;
      top: 50px;
      height: 21px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #757575;
      line-height: 17px;
    }
    .span-price {
      display: flex;
      font-weight: 500;
      height: 21px;
      font-size: 15px;
      color: #ff3d3d;
      line-height: 19px;
    }
    .showAdress {
      position: absolute;
      right: 19px;
      top: 21px;
      width: 78px;
      height: 29px;
      // background: url(../assets/turnTable/address.png) no-repeat;
      background-size: 100% 100%;
    }
    .showGoods {
      position: absolute;
      right: 35px;
      top: 58px;
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      text-decoration: underline;
    }
  }
}
.noRecord {
  margin-top: 173px;
  width: 100px;
  height: 103px;
  background: url(../assets/turnTable/noRecord.png) no-repeat;
  background-size: 100% 100%;
}
.noRecordTitle {
  margin-top: 17px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
.logo {
  position: absolute;
  bottom: 48px;
  width: 323px;
  height: 72px;
  // background: url(../assets/turnTable/logo.png) no-repeat;
  background-size: 100% 100%;
}

.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100vw;
    height: 100vh;
    // background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    padding-top: 42px;
    box-sizing: border-box;
    width: 340px;
    height: 458px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 18px;
    background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: left;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: -30px;
    }

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      line-height: 25px;
      letter-spacing: 1px;
    }

    .border {
      margin: 2px auto 0;
      width: 133px;
      height: 3px;
      background: #f9e46e;
    }

    .form {
      padding: 0 55px;
      box-sizing: border-box;

      .label {
        margin-top: 15px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        line-height: 18px;
      }

      input {
        margin-top: 7px;
      }

      .save {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        height: 39px;
        background: #f9550a;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
  .van-field {
    padding: 0 11px;
    width: 230px;
    // height: 32px;
    line-height: 32px;
    border: 1px solid #efefef;
    margin-top: 7px;
  }

  .van-field--min-height {
    line-height: 22px;
  }
}
.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}

.maskArea {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
</style>
