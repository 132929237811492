<template>
  <div class="">
    <div :class="['ck-wheel', 'rules-1']"></div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "长高高送券活动说明";
  },
  data() {
    return {
      token: this.$route.query.token,
      type: 1
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rules-1 {
  width: 375px;
  height: 1055px;
  background: url(https://oss-kiddo.manqu88.com/h5/higogorule.jpg?4)  no-repeat;
  background-size: 100% 100%;
}
</style>
