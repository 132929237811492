<template>
  <div class="add_card_list">
    <p class="big_tip">{{ operationType === "0" ? '添加' : '修改' }}银行卡</p>
    <p class="small_tip">请绑定持卡本人的银行卡</p>
    <div class="mask">
      <van-field v-model.trim="accountName" label="持卡人" placeholder="请输入持卡人姓名" label-width="65px"
        :disabled="operationType === '0' ? false : true" />
      <van-field v-model.trim="idCard" maxlength="18" label="身份证" placeholder="请输身份证号码" label-width="65px"
        :disabled="operationType === '0' ? false : true" />
      <van-field v-model.trim="cardNumber" label="卡号" type="digit" placeholder="请输入银行卡号" label-width="65px"
        :disabled="operationType === '0' ? false : true" />
      <van-field v-model.trim="mobile" label="手机号" placeholder="请输入银行卡预留手机号" type="digit" label-width="65px"
        maxlength="11" />
      <van-field v-model.trim="bankName" label="开户银行" placeholder="请输入开户银行" label-width="65px" />
      <van-field v-model="subBankName" label="开户网点"
        right-icon="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/addol/bank_search.png" placeholder="点击选择开户网点"
        label-width="65px" readonly @click="handleClickBranch" />
      <div class="tip">
        <img src="./../../../assets/addbank_help.png" alt="" />
        <p>请保证银行卡开户信息填写正确，否则将影响提现进度</p>
      </div>
    </div>
    <div class="add_button" @click="handleClickAdd">
      {{ operationType === "0" ? "添加" : "修改" }}
    </div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="pop_mask">
        <div class="sticky_mask">
          <div class="head">
            <img class="head_close" src="../../../assets/close_icon.png" alt="" @click="handleClickClosePop" />
            <p class="head_title">请选择网点</p>
          </div>
          <van-search v-model="searchContent" show-action placeholder="请输入网点/营业厅名称搜索" @search="onSearch">
            <template #action>
              <div style="width: 50px;text-align: center;" @click="onSearch">搜索</div>
            </template>
          </van-search>
        </div>
        <van-list v-if="!isShowEmpty" v-model="loading" :finished="finished" finished-text="没有更多了"
          :immediate-check="false" @load="onLoad">
          <p v-for="(item, index) in bankSubBranchDTOS" :key="index" class="name" @click="handleClickSearchBank(item)">
            {{ item.subBankName }}
          </p>
        </van-list>
        <van-empty v-if="isShowEmpty" description="暂无数据" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  created() {
    document.title = " ";
  },
  data() {
    return {
      show: false,
      searchContent: "",
      token: "",
      // 操作类型 ： 0新增  1修改
      operationType: "",
      accountName: "",
      idCard: "",
      cardNumber: "",
      mobile: "",
      bankName: "",
      subBankName: "",
      searchParams: { page: 1 },
      isShowEmpty: true,
      bankSubBranchDTOS: [],
      loading: false,
      finished: false,
      currentPage: 2,
      subBankCode: ""
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.operationType = this.$route.query.operationType;
        if (this.$route.query.bankCardInfo && this.$route.query.bankCardInfo.length > 0) {
          const bankCardInfo = JSON.parse(this.$route.query.bankCardInfo);
          this.accountName = bankCardInfo.accountName;
          this.idCard = bankCardInfo.idCard;
          this.cardNumber = bankCardInfo.cardNumber;
          this.mobile = bankCardInfo.mobile;
          this.bankName = bankCardInfo.bankName;
          this.subBankName = bankCardInfo.subBankName;
          this.subBankCode = bankCardInfo.subBankCode;
        }
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
        });
      }
    });
  },
  methods: {
    handleClickBranch() {
      if (this.cardNumber.length === 0) {
        this.$toast("请输入银行卡号");
        return;
      }
      if (this.bankName.length === 0) {
        this.$toast("请输入开户银行");
        return;
      }
      this.show = true;
    },
    handleClickClosePop() {
      this.handleClosePopWithResetData();
    },
    onSearch() {
      if (this.searchContent.length === 0) {
        this.$toast("请输入网点/营业厅名称搜索");
        return;
      }
      this.searchParams.bankName = this.bankName;
      this.searchParams.cardNumber = this.cardNumber;
      this.searchParams.key = this.searchContent;
      this.postSearchBank(this.searchParams);
    },
    async postSearchBank(params) {
      this.$toast.loading({
        message: "搜索中...",
        forbidClick: true
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/bankcard/queryBankInfoByCard",
          data: params
        });
        this.$toast.clear();
        if (data.success) {
          this.loading = false;
          if (data.data && Object.keys(data.data).length > 0) {
            this.isShowEmpty = false;
            if (data.data.bankSubBranchDTOS.length < 10) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.bankSubBranchDTOS = [...data.data.bankSubBranchDTOS];
          } else {
            this.isShowEmpty = true;
            this.finished = true;
          }
        } else {
          this.$toast(data.message);
          this.isShowEmpty = true;
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        this.isShowEmpty = true;
        this.$toast.clear();
      }
    },
    async onLoad() {
      this.searchParams.page = this.currentPage;
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/bankcard/queryBankInfoByCard",
          data: this.searchParams
        });
        if (data.success) {
          this.currentPage++;
          this.loading = false;
          if (data.data && Object.keys(data.data).length > 0) {
            if (data.data.bankSubBranchDTOS.length < 10) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.bankSubBranchDTOS = [...this.bankSubBranchDTOS, ...data.data.bankSubBranchDTOS];
          } else {
            this.finished = true;
          }
        } else {
          this.finished = true;
          this.loading = false;
          this.$toast(data.message);
        }
      } catch (error) {
        this.finished = true;
        this.loading = false;
      }
    },
    handleClickSearchBank(item) {
      this.subBankCode = item.subBankCode;
      this.subBankName = item.subBankName;
      this.handleClosePopWithResetData();
    },
    handleClosePopWithResetData() {
      this.show = false;
      this.bankSubBranchDTOS = [];
      this.searchContent = "";
      this.currentPage = 2;
      this.searchParams = { page: 1 };
      this.isShowEmpty = true;
    },
    handleClickAdd() {
      if (this.operationType === "0") {
        // 新增
        if (this.accountName.length === 0) {
          this.$toast("请输入姓名");
          return;
        }
        if (this.idCard.length === 0) {
          this.$toast("请输入身份证号");
          return;
        }
        if (this.idCard.length < 18) {
          this.$toast("请输入正确身份证号");
          return;
        }
        if (this.cardNumber.length === 0) {
          this.$toast("请输入银行卡号");
          return;
        }
        if ((this, this.cardNumber.length > 20)) {
          this.$toast("请输入正确银行卡号");
          return;
        }
        if (this.mobile.length === 0) {
          this.$toast("请输入手机号");
          return;
        }
        if (this.mobile.length < 11) {
          this.$toast("手机号不正确");
          return;
        }
        if (this.bankName.length === 0) {
          this.$toast("请输入开户银行");
          return;
        }
        if (this.subBankName.length === 0) {
          this.$toast("请选择开户网点");
          return;
        }
        const params = {
          accountName: this.accountName,
          bankName: this.bankName,
          subBankName: this.subBankName,
          cardNumber: this.cardNumber,
          mobile: this.mobile,
          idCard: this.idCard,
          subBankCode: this.subBankCode
        };
        this.addBankCard(params);
      } else {
        // 修改
        this.updateBankCardInfo();
      }
    },
    // 修改银行卡号
    async updateBankCardInfo() {
      this.$toast.loading({
        message: "修改中...",
        forbidClick: true
      });
      const params = {
        cardNumber: this.cardNumber,
        mobile: this.mobile,
        subBankCode: this.subBankCode,
        subBankName: this.subBankName,
        bankName: this.bankName
      };
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/bankcard/updateBankInfo",
          data: params
        });
        this.$toast.clear();
        if (data.success) {
          if (data.data === true) {
            this.$toast("修改成功");
            this.$router.back();
          } else {
            this.$toast("修改失败");
          }
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    // 新增银行卡号
    async addBankCard(params) {
      this.$toast.loading({
        message: "修改中...",
        forbidClick: true
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/bankcard/bindingBankCard",
          data: params
        });
        this.$toast.clear();
        if (data.success) {
          if (data.data === true) {
            this.$toast("添加成功");
            this.$router.back();
          } else {
            this.$toast("添加失败");
          }
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add_card_list {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #ffffff;

  .big_tip {
    padding: 0;
    margin: 0;
    margin-top: 50px;
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #232323;
  }

  .small_tip {
    padding: 0;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 40px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
  }

  .mask {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }

  /deep/ .van-field__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .tip {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      height: 14px;
    }

    p {
      padding: 0;
      margin: 0;
      margin-left: 5px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
    }
  }

  .add_button {
    margin-top: 60px;
    width: 178px;
    height: 40px;
    background: #141414;
    border-radius: 20px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }

  /deep/ .van-popup--center {
    border-radius: 8px;
  }

  /deep/ .van-empty {
    padding: 0;
    margin-top: 82px;
  }

  .pop_mask {
    width: 300px;
    height: 320px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .sticky_mask {
      width: 100%;
      background-color: white;
      position: fixed;
      left: 0;
      top: 0;

      .head {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px 0px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .head_close {
          width: 15px;
        }

        .head_title {
          flex: 1;
          width: 0;
          text-align: center;
          padding: 0;
          margin: 0;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #232323;
        }
      }

      /deep/ .van-search--show-action {
        padding-right: 5px;
      }
    }

    /deep/ .van-list {
      position: fixed;
      left: 0;
      top: 82px;
      width: 300px;
      height: 238px;
      overflow-y: auto;
    }

    .name {
      width: 300px;
      box-sizing: border-box;
      margin: 0;
      padding: 5px 10px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
    }
  }
}
</style>
