<template>
  <div class="ck-wheel" >
    <div class="bg-new"></div>
  </div>
  <!-- <div class="ck-wheel">
    <div class="bg-1">
      <div class="label" @click="changePeriod">
        <div class="period">第{{ (detail && detail.period) || 1 }}期</div>
        <div class="icon"></div>
      </div>
      <div class="label label-rule">
        <div style="margin-left:14px" @click="goRules">活动规则</div>
      </div>
    </div>
    <div class="bg-2">
      <div class="progress-bg">
        <div
          class="progress-line"
          :style="{
            width: lineWidth + 'px'
          }"
        ></div>
      </div>
      <div class="progress">
        当前进度：{{
          detail
            ? (detail.checkInNum >= detail.needCheckInNum
                ? detail.needCheckInNum
                : detail.checkInNum) +
              "/" +
              detail.needCheckInNum
            : "活动未开始"
        }}
      </div>
      <div
        class="btn check"
        @click="detail && detail.checkInNum < detail.needCheckInNum && (showMask = true)"
      >
        {{ detail && detail.checkInNum >= detail.needCheckInNum ? "已完成" : "我要打卡" }}
      </div>
      <div class="btn rank" @click="goRank">查看榜单</div>
    </div>
    <div class="bg-3"></div>
    <div class="bg-4"></div>
    <div class="bg-5">
      <van-swipe class="bg-5" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in swiperList" :key="index"
          ><img class="bg-5" :src="item" alt=""
        /></van-swipe-item>
      </van-swipe>
    </div>
    <div class="bg-6"></div>
    <video
      controls
      class="video"
      poster="https://video.manqu88.com/b9d4672a3a474a38902bc5687db26642/snapshots/aa3bc0a784454bdd975218e25406317e-00001.jpg"
      src="https://video.manqu88.com/b9d4672a3a474a38902bc5687db26642/6bc5972d58f746128c382b78273c6e36-de6bc725fcc77576c6fb1a4d622d9917-sd.mp4"
    ></video>
    <div class="bg-7"></div>

    <div class="mask" v-if="showMask" @click="showMask = false">
      <div class="card">
        <div class="title">温馨提示</div>
        <img
          class="img"
          src="https://oss-kiddo.manqu88.com/h5/checkInActivity/checkTips.png?1"
          alt=""
        />
        <div class="btn" @click="goPublic">去发布</div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      token: this.$route.query.token,
      detailList: [],
      detail: null,
      lineWidth: 0,
      curIndex: 0,
      showMask: false,
      swiperList: [
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/1.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/2.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/3.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/4.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/5.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/6.png",
        "https://oss-kiddo.manqu88.com/h5/checkInActivity/swiper/7.png"
      ]
    };
  },
  created() {
    document.title = "kiddol圈活动";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.userId = this.$route.query.userId;
        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }

          this.$bridge.call("getUserId", {}).then(id => {
            console.log("getUserId客户端返回: ", e);
            this.userId = id;
            this.getDetail();
          });
        });
      }
    });
  },

  methods: {
    formatPrice,
    async getDetail() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/mediumCheckIn/queryCheckInPeriod",
          data: {
            activityId: this.id
          }
        });
        if (data.success) {
          this.detailList = data.data;
          this.detail = data.data[data.data.length - 1];
          this.curIndex = data.data.length - 1;
          this.computeLength();
        } else {
          this.$toast(data.message);
        }
      }
    },
    changePeriod() {
      this.curIndex--;
      if ((this, this.curIndex < 0)) {
        this.curIndex = this.detailList.length - 1;
      }
      this.detail = this.detailList[this.curIndex];
      this.computeLength();
      console.log("dsdsadasd");
    },
    computeLength() {
      let width = (310 / 375) * document.documentElement.clientWidth;
      let curNum =
        this.detail.checkInNum >= this.detail.needCheckInNum
          ? this.detail.needCheckInNum
          : this.detail.checkInNum;
      this.lineWidth = (width * curNum) / this.detail.needCheckInNum;
    },
    goRank() {
      this.$router.push(
        `/checkIn/checkInRank?token=${this.token}&id=${this.id}&period=${this.detail.period}`
      );
    },
    goRules() {
      this.$router.push(`/checkIn/checkInRules`);
    },
    goPublic() {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/kiddolPackage/public/index`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 12 //推文发布
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fffdfd;
  .bg-new{
    width: 375px;
    height: 2482px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/sm.jpg?11) no-repeat;
    background-size: 100% 100%;
  }

  .bg-1 {
    width: 375px;
    height: 519px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_1.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .label {
      position: absolute;
      top: 135px;
      right: -1px;
      min-width: 88px;
      height: 33px;
      background: #ffffff;
      border-radius: 100px 0px 0px 100px;
      border: 0.2px solid #d0d0d0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 21px;
      letter-spacing: 1px;

      .period {
        margin-left: 13px;
        margin-right: 8px;
      }

      .icon {
        margin-right: 12px;
        width: 12px;
        height: 11px;
        background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/periodIcon.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .label-rule {
      top: 178px;
      height: 27px;
    }
  }
  .bg-2 {
    width: 375px;
    height: 319px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_2.png?1) no-repeat;
    background-size: 100% 100%;
    position: relative;

    .progress-bg {
      position: absolute;
      left: 33px;
      top: 98px;
      width: 310px;
      height: 10px;
      background: #e6e6e6;
      border-radius: 6px;

      .progress-line {
        position: absolute;
        left: 0;
        top: 0;
        width: 221px;
        height: 10px;
        background: linear-gradient(270deg, #f65812 0%, #ffece8 100%);
        border-radius: 6px;
      }
    }

    .progress {
      position: absolute;
      left: 33px;
      top: 118px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 20px;
    }
    .btn {
      width: 72px;
      height: 24px;
      background: #f7550a;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }

    .check {
      top: 116px;
      right: 32px;
    }
    .rank {
      top: 208px;
      right: 32px;
    }
  }
  .bg-3 {
    width: 375px;
    height: 1127px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_3.png?1) no-repeat;
    background-size: 100% 100%;
  }
  .bg-4 {
    width: 375px;
    height: 169px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_4.png?11) no-repeat;
    background-size: 100% 100%;
  }
  .bg-5 {
    width: 375px;
    height: 319px;
    // background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_5.png) no-repeat;
    // background-size: 100% 100%;
  }
  .bg-6 {
    width: 375px;
    height: 144px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_6.png) no-repeat;
    background-size: 100% 100%;
  }
  .video {
    width: 375px;
  }
  .bg-7 {
    width: 375px;
    height: 126px;
    background: url(https://oss-kiddo.manqu88.com/h5/checkInActivity/bg_7.png) no-repeat;
    background-size: 100% 100%;
  }
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #979797;
  .card {
    width: 318px;
    height: 469px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 19px;
      margin-bottom: 12px;
      height: 24px;
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 24px;
    }

    .img {
      width: 283px;
      height: 321px;
    }
    .btn {
      margin-top: 33px;
      width: 254px;
      height: 34px;
      background: linear-gradient(270deg, #fc7223 0%, #f9550a 100%);
      border-radius: 17px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
