<template>
	<div 
	class="activity-reward-rain-wrap" >
		<!-- 音频 -->
		<audio  style="display: none;"  controls loop ref="audio" preload="auto" src="https://kiddo-bucket.manqu88.com/rain-bg-video.mp3"></audio>
		
		<!-- 图片加载进度 -->
		<div 
		:style="{
			display: !loadingImgBloon ? 'flex' : 'none'
		}"
		class="progress-box" >
			<div class="progress" >
				<div 
				:style="{
					width: percentRain + '%'
				}"
				class="progress-done" ></div>
			</div>
			<div class="percent" >{{ percentRain }}%</div>
			
			<div class="loading-text" >Loading...</div>
		</div>
		
		
		<!-- 预加载 -->
		<img style="display: none;" src="https://kiddo-bucket.manqu88.com/rewardRain-click.gif" alt="">
		<img style="display: none;" src="https://kiddo-bucket.manqu88.com/rewardRain-del-icon.png" alt="">
		
		
		<div 
		:style="{
			display: (gameStep === 0 && loadingImgBloon) ? 'block' : 'none'
		}"
		class="bg-status" >
			<img class="bg-status-img" src="https://kiddo-bucket.manqu88.com/rewardRain-status-bg-1.png" alt="">
			
			<!-- 我的奖品 -->
			<img @click.prevent="handleToActivityAward" class="award-entry" src="https://kiddo-bucket.manqu88.com/rewardRain-my-award.png" alt="">
			<!-- 活动规则 -->
			<img @click.prevent="handleToActivityRule" class="rule-entry" src="https://kiddo-bucket.manqu88.com/rewardRain-rule.png" alt="">
			<!-- 分享 -->
			<img @click.prevent="toShare" class="share-entry" src="https://kiddo-bucket.manqu88.com/rewardRain-share-icon.png" alt="">
			
			<!-- 无进行中——倒计时 -->
			<template v-if="activeStatus === 1" >
				<img @click="handleToNoStart" class="rewardRain-start-text" src="https://kiddo-bucket.manqu88.com/rewardRain-start-text.png" alt="">
				<img class="rewardRain-start-time" src="https://kiddo-bucket.manqu88.com/rewardRain-start-time.png" alt="">
				<!-- 倒计时 -->
				<div  class="count-time" >
					<div class="count-time-value" >{{ timeDay }}</div>
					<div class="count-time-day" >天</div>
					<div class="count-time-value" >{{ timeHour }}</div>
					<div class="count-time-split" >:</div>
					<div class="count-time-value" >{{ timeMinute }}</div>
					<div class="count-time-split" >:</div>
					<div class="count-time-value" >{{ timeSecond }}</div>
				</div>
			</template>
			
			<!-- kiddol logo图 -->
			<img v-if="activeStatus === 2 || activeStatus === 3" class="kiddol-logo" src="https://kiddo-bucket.manqu88.com/kiddol-rewardRain-logo.png" alt="">
			
			<!-- 抢红包 进行中-->
			<img v-if="activeStatus === 2" @click="handleToGameStart" class="kiddol-seckill-btn" src="https://kiddo-bucket.manqu88.com/kiddol-rewardRain-sekill-btn.gif" alt="">
			
			<!-- 已结束 -->
			<img v-if="activeStatus === 3" class="kiddol-gameover-btn" src="https://kiddo-bucket.manqu88.com/rewardRain-game-over-text.png" alt="">
			
		</div>
		
		<div 
		:style="{
			display: (gameStep !== 0 && loadingImgBloon) ? 'block' : 'none'
		}"
		class="start-bg" >
			<!-- 红包雨倒计时 -->
			<div 
			:style="{
				display: gameStep === 1 ? 'block' : 'none'
			}" >
				<img class="ready-go" src="https://kiddo-bucket.manqu88.com/rewardRain-ready-go-1.png" alt="">
				<img class="ready-go-title" src="https://kiddo-bucket.manqu88.com/rewardRain-ready-title.png" alt="">
				<!-- 倒计时 -->
				<img class="ready-go-count" :style="{ display: activityCount === 4 ? 'block' : 'none' }" src="https://kiddo-bucket.manqu88.com/rewardRain-count-3.png" alt="">
				<img class="ready-go-count" :style="{ display: activityCount === 3 ? 'block' : 'none' }" src="https://kiddo-bucket.manqu88.com/rewardRain-count-2.png" alt="">
				<img class="ready-go-count" :style="{ display: activityCount === 2 ? 'block' : 'none' }" src="https://kiddo-bucket.manqu88.com/rewardRain-count-1.png" alt="">
				<img class="ready-go-count" :style="{ display: activityCount === 1 ? 'block' : 'none' }" src="https://kiddo-bucket.manqu88.com/rewardRain-count-go.png" alt="">
				
				<!-- 点击指引 -->
				<img class="ready-click-tip" src="https://kiddo-bucket.manqu88.com/rewardRain-click-tip.png" alt="">
			</div>
			
			
			<!-- 红包雨进行中 -->
			<div 
			:style="{
				display: gameStep === 2 ? 'block' : 'none'
			}"
			 >
				<!-- 4种红包——动效 -->
				<div class="package-list" >
					<!-- 第一行 -->
					<div 
					class="package-list-one animate-move-down" >
						<div
						v-for="(item, index) in rewardOne"
						:key="index"
						class="package-li-div"
						:style="{
							marginBottom: item.marginBottom + 'px',
							marginLeft: item.marginLeft + 'px'
						}"
						>	
						
							<img
							:style="{
								opacity: item.opacity ? 1 : 0
							}"
							class="package-li"
							:src="`https://kiddo-bucket.manqu88.com/rewardRain-package-${item.img}.png`" 
							alt=""
							@click.prevent="handleToClickPackage(item, index, 1)"
							>
							
							<!-- 动效 -->
							<img v-if="!item.opacity" class="gift-package animation" src="https://kiddo-bucket.manqu88.com/rewardRain-click.gif" alt="">
						</div>
					</div>
					
					<!-- 第二行 -->
					<div class="package-list-two animate-move-down" >
						<div
						v-for="(item, index) in rewardTwo"
						:key="index"
						class="package-li-div"
						:style="{
							marginBottom: item.marginBottom + 'px',
							marginLeft: item.marginLeft + 'px'
						}"
						>
							<img
							:style="{
								opacity: item.opacity ? 1 : 0
							}"
							class="package-li"
							:src="`https://kiddo-bucket.manqu88.com/rewardRain-package-${item.img}.png`" 
							alt=""
							@click.prevent="handleToClickPackage(item, index, 2)"
							>
							
							<!-- 动效 -->
							<img v-if="!item.opacity" class="gift-package animation" src="https://kiddo-bucket.manqu88.com/rewardRain-click.gif" alt="">
						</div>
					</div>
					
					<!-- 第三行 -->
					<div class="package-list-three animate-move-down" >
						<div
						v-for="(item, index) in rewardThree"
						:key="index"
						class="package-li-div"
						:style="{
							marginBottom: item.marginBottom + 'px',
							marginLeft: item.marginLeft + 'px'
						}"
						>
							<img
							:style="{
								opacity: item.opacity ? 1 : 0
							}"
							class="package-li"
							:src="`https://kiddo-bucket.manqu88.com/rewardRain-package-${item.img}.png`" 
							alt=""
							@click.prevent="handleToClickPackage(item, index, 3)"
							>
							
							<!-- 动效 -->
							<img v-if="!item.opacity" class="gift-package animation" src="https://kiddo-bucket.manqu88.com/rewardRain-click.gif" alt="">
						</div>
					</div>
				</div>
				
				<img class="rewardRain-package-time-name" src="https://kiddo-bucket.manqu88.com/rewardRain-package-time-name.png" alt="">
				<div class="rewardRain-package-count" >
					<span class="rewardRain-package-count-num" >{{ packageCount }}</span>
					<span class="rewardRain-package-count-label" >s</span>
				</div>
				
				<div 
				:style="{
					display: noStopClickNum ? 'inline-flex' : 'none'
				}"
				class="rewardRain-package-click" >
					<div class="click-box" >
						<span class="click-box-label" >x</span>
						<span class="click-box-num" >{{ noStopClickNum }}</span>
					</div>
					<img class="click-img" src="https://kiddo-bucket.manqu88.com/rewardRain-package-click-label.png" alt="">
				</div>
				
			</div>
			
			
			<!-- 红包雨弹窗奖励 -->
			<template v-if="gameStep === 3" >
				<div class="award-popup" >
					<img class="award-img" :src="awardImg" alt="">
					<img @click="handleToInit" class="award-popup-del" src="https://kiddo-bucket.manqu88.com/rewardRain-del-icon.png" alt="">
					<div @click="handleToPage" class="look-btn" ></div>
				</div>
			</template>
		</div>
		
		
	</div>
</template>

<script>
	import  FastClick  from  'fastclick';
	export default {
		data() {
			return {
				token: this.$route.query.token,
				isMini: true,
				
				// 图片加载进度
				loadingImgNum: 0,
				loadingImgBloon: false,
				percentRain: 0,
				
				// 活动状态
				activeStatus: 0,	// 1-未开始  2-进行中  3-已结束
				gameStep: 0,		// 1-倒计时  2-红包雨进行中 3-奖品弹窗
				
				// 倒计时_数字
				timeLoad: true,
				timer: null,
				timeDay: '',
				timeHour: '',
				timeMinute: '',
				timeSecond: '',
				
				// 红包雨倒计时
				activityTimer: null,
				activityCount: 4,
				
				// 抢红包倒计时
				packageTimer: null,
				packageCount: 10,
				
				// 红包雨
				rewardOne: [],
				rewardTwo: [],
				rewardThree: [],
				
				// 连击数
				noStopTimer: null,
				noStopClickNum: 0,
				
				// 点击声效
				audioClick: null,
				audioShow: true,
				
				// 抽奖积分总数
				rewardScore: 0,
				
				// 奖品类型
				prizeType: 0,
				
				// 弹窗奖品图
				awardImg: 'https://kiddo-bucket.manqu88.com/rain-award/no-reward.png'
				
			}
		},
		mounted() {
		  FastClick.attach(document.body);
			
		  this.token = this.$route.query.token;
		  uni.webView.getEnv(res => {
		    console.log("当前环境1：" + JSON.stringify(res));
		    if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
		      this.token = this.$route.query.token;
		      this.isMini = true;
		      if (!this.token || this.token == "") {
				setTimeout(() => {
					uni.webView.reLaunch({
					  url: "/pagesA/login/index"
					});
				}, 500)
		        return;
		      }
			  
			  this.mountInit();
		    } else {
		      this.isMini = false;
		      this.$bridge.call("getToken", {}).then(e => {
			  console.log("getToken客户端返回: ", e);
		      this.token = e;
		        if (!e || JSON.stringify(e) == "{}") {
		          this.$bridge.call("openPage", { type: 26 });
		          return;
		        }
				
				this.mountInit();
		      });
		    }
		  });
		  
		  
		},
		destroyed() {
			// 倒计时清理
			clearTimeout(this.timer);
			this.timer = null;
		},
		methods: {
			mountInit() {
				// 图片进度加载
				this.allImgProgress();
				
				// 音频暂停
				var audio = this.$refs.audio;
					audio.pause()
				
				// 倒计时清理
				clearTimeout(this.timer);
				this.timer = null;
				
				clearTimeout(this.activityTimer);
				this.activityTimer = null;
				
				clearTimeout(this.packageTimer);
				this.packageTimer = null;
				
				clearTimeout(this.noStopTimer);
				this.noStopTimer = null;
				
				// 积分合计
				this.rewardScore = 0;
				
				// 活动情况
				this.getActivityData();
				
				// 红包雨随机数据生成
				this.rainRandomData();
			},
			// 图片加载
			allImgProgress() {
				var arrImg = [
					'https://kiddo-bucket.manqu88.com/rewardRain-status-bg-1.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-my-award.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-rule.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-share-icon.png',
					'https://kiddo-bucket.manqu88.com/kiddol-rewardRain-sekill-btn.gif',
					'https://kiddo-bucket.manqu88.com/rewardRain-ready-go-1.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-ready-title.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-count-3.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-count-2.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-count-1.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-count-go.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-click-tip.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-time-name.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-click-label.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-click-bg.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-1.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-2.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-3.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-package-4.png',
					'https://kiddo-bucket.manqu88.com/rewardRain-click.gif'
				];
				var index = 0;
				var vm = this;
				for(var i = 0; i < arrImg.length; i++) {
					   var imgObj = new Image();
					   imgObj.src = arrImg[i];
					   
					   imgObj.onload = function() {
							index++;
							vm.percentRain = parseInt((index / arrImg.length) * 100);
							if(index == arrImg.length) {
								 console.log('完成')
								 vm.loadingImgBloon = true;
							}
					   }
				}
			},
			// 活动未开始
			handleToNoStart() {
				
				this.$toast('活动未开始');
			},
			// 随机
			randomSort(arrData) { 
				let arr = JSON.parse(JSON.stringify(arrData))
				let len = arr.length,res = [];
				  while(len--) {
				    let index = Math.floor(Math.random()*len);
				    res.push({
						img: arr[index],
						marginBottom: Math.random() * 250 + 10,
						marginLeft: Math.random() * 75 + 5,
						opacity: 1
					});
				    arr.splice(index,1);
				  }
				  
				  return res;
			},
			// 去分享
			toShare() {
			  if (this.isMini) {
			    uni.webView.navigateTo({
			      url: `/otherPackage/rewardRain/share`,
			    });
			  } else {
			    this.$bridge.call("openPage", { type: 42 });
			  }
			},
			// 去活动规则
			handleToActivityRule() {
				this.$router.push(`/rewardRainRule?time=${Date.now()}`);
			},
			// 我的奖品
			handleToActivityAward() {
				this.$router.push(`/rewardRainAward?token=${this.token}`);
			},
			// 活动情况
			async getActivityData() {
				const { data } = await this.$axios({
				  method: "post",
				  headers: {
				    mac: "mac",
				    clientSource: this.$clientSource(),
				    Authorization: this.token
				  },
				  url: "/api/lottery/queryEffectiveRPR",
				  data: {}
				});
				
				if (data.success) {
					
					// 活动状态
					if(data.data) {
						document.title = data.data.name;
						if(data.data.status === 0) {
							// 未开始
							this.activeStatus = 1;
							
							// 开始倒计时
							this.timeCountStart(data.data.timeToStartTime);
						}else if(data.data.status === 1) {
							// 判断是否有抽奖次数
							const { data: timeData } = await this.$axios({
							  method: "post",
							  headers: {
							    mac: "mac",
							    clientSource: this.$clientSource(),
							    Authorization: this.token
							  },
							  url: "/api/lottery/queryLotteryTimes",
							  data: {
								  type: 13
							  }
							});
							
							if(timeData.data && timeData.data.remainTimes) {
								// 进行中
								this.activeStatus = 2;
							}else {
								// 已结束
								this.activeStatus = 3;
							}
							
						}else if(data.data.status === 2) {
							// 已结束
							this.activeStatus = 3;
						}
					}else {
						document.title = '红包雨';
						this.activeStatus = 3;
					}
					
				} else {
				  this.$toast(data.message);
				}
			},
			rainRandomData() {
				let randomOrgin = [1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,4,4,4,4,4];
				this.rewardOne = this.randomSort(randomOrgin);
				this.rewardTwo = this.randomSort(randomOrgin);
				this.rewardThree = this.randomSort(randomOrgin);
			},
			// 活动开始
			timeCountStart(timeToStartTime) {
				let timeToStartTimeCount = parseInt(timeToStartTime / 1000);
			
				const timeCountF = (() => {
					  clearTimeout(this.timer);
					  this.timer = null;
					  if(timeToStartTimeCount <= 0) {
						  timeToStartTimeCount = 0;
						  this.timeDay = '0';  //计算天数
						  this.timeHour = '00';  //计算小时数
						  this.timeMinute = '00';  //计算分钟数
						  this.timeSecond = '00';  //计算秒数
						  
						  // 活动情况
						  this.getActivityData();
						  return
					  }
					  
					  let timeDay = Math.floor(timeToStartTimeCount/(60*60*24));  //计算天数
					  let timeHour = Math.floor(timeToStartTimeCount/(60*60)%24);  //计算小时数
					  let timeMinute = Math.floor(timeToStartTimeCount/(60)%60);  //计算分钟数
					  let timeSecond = Math.floor(timeToStartTimeCount%60);  //计算秒数
					  
					  this.timeDay = timeDay;
					  this.timeHour = timeHour >= 10 ? timeHour : '0' + timeHour;
					  this.timeMinute = timeMinute >= 10 ? timeMinute : '0' + timeMinute;
					  this.timeSecond = timeSecond >= 10 ? timeSecond : '0' + timeSecond;
					  
					  this.timer = setTimeout(() => {
						    --timeToStartTimeCount;
							// 倒计时结束
							if(timeToStartTimeCount <= -1) {
								clearTimeout(this.timer);
								this.timer = null;
								
								// 活动情况
								this.getActivityData();
							}else {
								// 继续循环
								timeCountF();
							}
					  }, 1000)
					  
				})
				
				timeCountF();
			},
			// 活动开始
			async handleToGameStart() {
				// 抽奖次数
				var audio = this.$refs.audio;
				const { data } = await this.$axios({
				  method: "post",
				  headers: {
				    mac: "mac",
				    clientSource: this.$clientSource(),
				    Authorization: this.token
				  },
				  url: "/api/lottery/play/deal",
				  data: {
					  type: 13
				  }
				});
				
				if(!data.success) {
					// 活动结束，无活动情况_刚进入就结束的场景
					if(data.code == 60000020) {
						this.handleToInit();
						return
					}
					
					
					this.$toast(data.message);
					return
				}
				
				setTimeout(() => {
					audio.play()
				}, 10);
					
				
				// 倒计时阶段
				this.gameStep = 1;
				
				// 倒计时执行
				this.activityCount = 4;
				
				// 计时器销毁
				clearTimeout(this.activityTimer);
				this.activityTimer = null;
				
				const activityCountF = (() => {
					
					this.activityTimer = setTimeout(() => {
						--this.activityCount;
						if(this.activityCount <= 0) {
							clearTimeout(this.activityTimer);
							this.activityTimer = null;
							
							// 下一步开始红包雨
							this.nextRainStep();
							
						}else {
							activityCountF();
						}
					}, 1000)
				})
				
				
				activityCountF();
			},
			// 红包雨开始
			nextRainStep() {
				console.log('进入红包雨')
				
				// 抢红包阶段
				this.gameStep = 2;
				
				// 倒计时执行
				this.packageCount = 10;
				
				// 计时器销毁
				clearTimeout(this.packageTimer);
				this.packageTimer = null;
				
				const packageCountF = (() => {
					this.packageTimer = setTimeout(() => {
						--this.packageCount;
						if(this.packageCount <= -1) {
							this.packageCount = 0;
							clearTimeout(this.packageTimer);
							this.packageTimer = null;
							// 下一步抽奖弹窗
							this.nextAwardPopop();
						}else {
							packageCountF();
						}
					}, 1000)
				})
				
				
				packageCountF();
			},
			// 点击红包
			handleToClickPackage(item, index, type) {
				if(!item.opacity) {
					return
				}
				
				if(type === 1) {
					let packageList1 = JSON.parse(JSON.stringify(this.rewardOne));
					packageList1[index].opacity = 0;
					this.rewardOne = packageList1;
				}else if(type === 2) {
					let packageList2 = JSON.parse(JSON.stringify(this.rewardTwo));
					packageList2[index].opacity = 0;
					this.rewardTwo = packageList2;
				}else if(type === 3) {
					let packageList3 = JSON.parse(JSON.stringify(this.rewardThree));
					packageList3[index].opacity = 0;
					this.rewardThree = packageList3;
				}
				
				// 积分累计
				if(item.img == 1) {
					this.rewardScore += 5;
				}else if(item.img == 2) {
					this.rewardScore += 1;
				}else if(item.img == 3) {
					this.rewardScore += 2;
				}else if(item.img == 4) {
					this.rewardScore += 2;
				}
				
				// 连击效果
				this.clickSomeNum();
			},
			// 连击效果
			clickSomeNum() {
				++this.noStopClickNum;
				
				// 清除倒计时
				clearTimeout(this.noStopTimer);
				this.noStopTimer = null;
				
				// 开始倒计时
				this.noStopTimer = setTimeout(() => {
					this.noStopClickNum = 0;
				}, 1000)
			},
			// 奖品确认
			awardConfim(data) {
				if(!data) {
					return;
				}
				
				const prizeName = data.prizeName;
				const preHttp = 'https://kiddo-bucket.manqu88.com/rain-award/';
				switch (prizeName){
					case '0.5金币':
						this.awardImg = preHttp + 'gold-0.5.png';
						break;
					case '1金币':
						this.awardImg = preHttp + 'gold-1.png';
						break;
					case '1.5金币':
						this.awardImg = preHttp + 'gold-1.5.png';
						break;
					case '2金币':
						this.awardImg = preHttp + 'gold-2.png';
						break;	
					case '3金币':
						this.awardImg = preHttp + 'gold-3.png';
						break;		
					case '5.8金币':
						this.awardImg = preHttp + 'gold-5.8.png';
						break;			
					case '8.8金币':
						this.awardImg = preHttp + 'gold-8.8.png';
						break;		
					case '12.8金币':
						this.awardImg = preHttp + 'gold-12.8.png';
						break;				
					case '28金币':
						this.awardImg = preHttp + 'gold-28.png';
						break;				
					case '58金币':
						this.awardImg = preHttp + 'gold-58.png';
						break;			
					case '88金币':
						this.awardImg = preHttp + 'gold-88.png';
						break;				
					case '5元无门槛优惠券':
						this.awardImg = preHttp + 'coupon-5.png';
						break;		
					case '10元无门槛优惠券':
						this.awardImg = preHttp + 'coupon-10.png';
						break;		
					case '99减5元优惠券':
						this.awardImg = preHttp + 'coupon-99-5.png';
						break;		
					case '199减10元优惠券':
						this.awardImg = preHttp + 'coupon-199-10.png';
						break;	
					case '299减30元优惠券':
						this.awardImg = preHttp + 'coupon-299-30.png';
						break;	
					case '长高高奶粉1罐兑换券':
						this.awardImg = preHttp + 'good-3.png';
						break;	
					case 'Aibeca爱楽倍佳DHA藻油小金珠1瓶兑换券':
						this.awardImg = preHttp + 'good-1.png';
						break;	
					case 'Aibeca爱楽倍佳伊敏舒1罐兑换券':
						this.awardImg = preHttp + 'good-2.png';
						break;	
					case '奇布奇布速干水粉棒兑换券':
						this.awardImg = preHttp + 'good-4.png';
						break;	
					case '一只水牛8.8元无门槛优惠券':
						this.awardImg = preHttp + 'good-5.png';
						break;	
					case '很遗憾':
						this.awardImg = preHttp + 'no-reward.png';
						break;
					default:
						break;
				}
			},
			// 抽奖弹窗
			async nextAwardPopop() {
				// 音频暂停
				var audio = this.$refs.audio;
					audio.pause()
				
				try{
					const { data } = await this.$axios({
					  method: "post",
					  headers: {
						mac: "mac",
						clientSource: this.$clientSource(),
						Authorization: this.token
					  },
					  url: "/api/lottery/luckyDraw",
					  data: {
						  type: 13,
						  score: this.rewardScore || 0
					  }
					});
					
					if(!data) {
						this.gameStep = 3;
						return
					}
					
					if(!data.success) {
						// 奖品弹窗
						this.gameStep = 3;
						
						this.$toast(data.message);
						return
					}
					
					if(!data.data) {
						this.gameStep = 3;
						return
					}
					
					// 奖品比对
					this.awardConfim(data.data);
					
					// 奖品类型
					this.prizeType = data.data.prizeType;
					
					// 奖品弹窗
					this.gameStep = 3;
				}catch(e){
					this.gameStep = 3;
					//TODO handle the exception
				}
			},
			// 跳转
			handleToPage() {
				if(this.prizeType == 1) {
					// 优惠券
					uni.webView.getEnv(res => {
					  if (res.miniprogram) {
					    uni.webView.navigateTo({
					      url: `/pagesA/coupon/index`
					    });
					  } else {
					    this.$bridge
					      .call("openPage", {
					        type: 5,
					        param: 1
					      })
					      .then(e => {
					        console.log("jumpToDetail客户端返回: ", e);
					      });
					  }
					});
				}else if(this.prizeType == 4) {
					// 金币
					this.$router.push(`/goldDetail?token=${this.token}&time=${Date.now()}`);
				}else if(this.prizeType == 6) {
					// 无奖品
					this.handleToInit();
				}else {
					this.handleToInit();
				}
				
				// 弹窗关闭_初始化
				setTimeout(() => {
					this.handleToInit();
				}, 1000)
			},
			// 回处始页面_初始化
			handleToInit() {
				this.gameStep = 0;
				this.rewardScore = 0;
				
				// 活动情况
				this.getActivityData();
				
				// 红包雨随机数据生成
				this.rainRandomData();
			}
			
		}
		
	}
</script>

<style lang="scss" scoped >
	.activity-reward-rain-wrap{
		min-height: 100vh;
		background-color: #DC040A;
		
		.award-entry{
			position: fixed;
			top: 79px;
			left: 0;
			height: 29px;
			margin-left: -54px;
			&:active{
				opacity: 0.8;
			}
		}
		.rule-entry{
			position: fixed;
			top: 114px;
			left: 0;
			height: 29px;
			margin-left: -54px;
			&:active{
				opacity: 0.8;
			}
		}
		.share-entry{
			position: fixed;
			top: 82px;
			right: 0;
			height: 55px;
			margin-right: -78px;
			&:active{
				opacity: 0.8;
			}
		}
		
		// 进度条
		.progress-box{
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			
			height: 20px;
			.loading-text{
				width: 80px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: -24px;
				margin: auto;
				color: #666666;
			}
			.progress{
				background-color: rgba(100, 100, 100, 0.2);
				border-radius: 5px;
				position: relative;
				margin: 0 10px;
				height: 10px;
				width: 200px;
				.progress-done{
					background: linear-gradient(to left, rgb(242, 112, 156), rgb(255, 148, 114));
					box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
					border-radius: 5px;
					height: 10px;
					width: 0;
					transition: all 0.1s;
				}
			}
			.percent{
				font-weight: 500;
				color: #666666;
			}
		}
		
		
		.bg-status{
			position: relative;
			.bg-status-img{
				display: block;
				width: 100vw;
			}
			.kiddol-logo{
				width: 68px;
				height: 19px;
				padding-right: 8px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 266px;
				margin: auto;
				box-sizing: content-box;
			}
			.kiddol-seckill-btn{
				height: 32px;
				padding-left: 40px;
				padding-right: 26px;
				position: absolute;
				left: 94px;
				bottom: 326px;
				margin: auto;
				&:active{
					opacity: 0.8;
				}
			}
			.kiddol-gameover-btn{
				width: 92px;
				height: 18px;
				position: absolute;
				left: 136px;
				bottom: 335px;
				margin: auto;
			}
			.rewardRain-start-text{
				width: 73px;
				height: 18px;
				position: absolute;
				left: 147px;
				bottom: 335px;
				margin: auto;
			}
			.rewardRain-start-time{
				width: 105px;
				height: 16px;
				position: absolute;
				left: 129px;
				bottom: 285px;
				margin: auto;
			}
			.count-time{
				height: 20px;
				width: 156px;
				position: absolute;
				left: 104px;
				bottom: 260px;
				margin: auto;
				z-index: 2;
				display: flex;
				align-items: center;
				
				.count-time-value{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 22px;
					height: 20px;
					font-size: 12px;
					font-weight: 500;
					color: #F6F1F1;
					border-radius: 3px;
					background-color: #000;
				}
				.count-time-day{
					width: 30px;
					font-size: 14px;
					font-weight: 500;
					text-align: center;
				}
				.count-time-split{
					width: 22px;
					font-size: 14px;
					font-weight: bold;
					text-align: center;
				}
				
			}
		}
		
		
		.start-bg{
			position: relative;
			width: 100vw;
			height: 100vh;
			background: url('https://kiddo-bucket.manqu88.com/rewardRain-click-bg.png') no-repeat center;
			background-size: cover;
			.ready-go{
				display: block;
				width: 223px;
				height: 123px;
				position: absolute;
				left: 0;
				right: 0;
				top: 11.8%;
				margin: auto;
				z-index: 2;
			}
			.ready-go-title{
				display: block;
				width: 208px;
				height: 44px;
				position: absolute;
				left: 0;
				right: 0;
				top: 39.6%;
				margin: auto;
				z-index: 2;
				z-index: 2;
			}
			.ready-go-count{
				display: block;
				width: 132px;
				height: 68px;
				position: absolute;
				left: 0;
				right: 0;
				top: 46.55%;
				margin: auto;
				z-index: 2;
			}
			.ready-click-tip{
				display: block;
				width: 168px;
				height: 34px;
				position: absolute;
				left: 0;
				right: 0;
				top: 82.6%;
				margin: auto;
				z-index: 2;
				animation: scoreAnimate 0.8s infinite;
			}
			
			.rewardRain-package-time-name{
				display: block;
				width: 142px;
				height: 44px;
				position: absolute;
				left: 120px;
				top: 5%;
				margin: auto;
				z-index: 2;
			}
			.rewardRain-package-count{
				display: flex;
				width: 50px;
				position: absolute;
				left: 0;
				right: 0;
				top: 11.3%;
				margin: auto;
				font-family: JDZhengHT;
				font-weight: bold;
				color: #F5DBA0;
				z-index: 2;
				.rewardRain-package-count-num{
					font-size: 38px;
				}
				.rewardRain-package-count-label{
					font-size: 34px;
					margin-top: 4px;
				}
			}
			.rewardRain-package-click{
				display: inline-flex;
				width: 100px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 11.2%;
				margin: auto;
				font-family: JDZhengHT;
				font-weight: bold;
				color: #F5DBA0;
				z-index: 2;
				.click-box{
					display: flex;
					font-family: JDZhengHT;
					font-weight: bold;
					margin-right: 9px;
					color: #F5DBA0;
					animation: clickAnimate 0.8s  infinite;
					.click-box-label{
						margin-top: 6px;
						font-size: 31px;
					}
					.click-box-num{
						font-size: 38px;
					}
				}
				.click-img{
					width: 67px;
					height: 39px;
					margin-top: 8px;
				}
			}
			
			.package-list{
				position: relative;
				width: 100%;
				height: 100vh;
				overflow: hidden;
				.package-list-one{
					position: absolute;
					left: 0;
					top: 0;
					width: 125px;
					transform: translateY(-100%);
					&.animate-move-down{
						animation: animateMoveDown 23s linear forwards;
					}
				}
				.package-list-two{
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					margin: auto;
					width: 125px;
					transform: translateY(-100%);
					&.animate-move-down{
						animation: animateMoveDown 23s linear forwards;
					}
				}
				.package-list-three{
					position: absolute;
					right: 0;
					top: 0;
					width: 125px;
					transform: translateY(-100%);
					&.animate-move-down{
						animation: animateMoveDown 23s linear forwards;
					}
				}
				.package-li-div{
					position: relative;
					width: 45px;
					.gift-package{
						position: absolute;
						right: 0;
						left: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 45px;
						height: 45px;
						transform: 0.1s all;
						opacity: 0;
						&.animation{
							animation: animateGift 1s forwards;
						}
					}
				}
				.package-li{
					display: block;
					width: 45px;
					height: auto;
					cursor: pointer;
				}
			}
			
			
			// 弹窗样式
			.award-popup{
				display: flex;
				align-items: center;
				justify-content: center;
				position: fixed;
				left: 0;
				right: 0;
				top: 22%;
				margin: auto;
				width: 285px;
				height: 337px;
				animation: animatePopup 0.6s ease-in-out forwards;
				.award-img{
					display: block;
					width: 100%;
				}
				.award-popup-del{
					position: absolute;
					right: 0;
					top: 0;
					margin-top: -23px;
					width: 34px;
					height: 34px;
					&:active{
						opacity: 0.8;
					}
				}
				.look-btn{
					position: absolute;
					left: 0;
					right: 0;
					bottom: 26px;
					margin: auto;
					width: 220px;
					height: 60px;
				}
			}
			
			
			
			@keyframes scoreAnimate {
			    0% {
					transform: scale(1);
			    }
				50% {
					transform: scale(0.95);
				}
			    100% {
					transform: scale(1);
			    }
			}
			
			
			@keyframes clickAnimate {
			    0% {
					transform: scale(1);
			    }
				50% {
					transform: scale(0.95);
				}
			    100% {
					transform: scale(1);
			    }
			}
			
			// 自由下落
			@keyframes animateMoveDown{
				0% {
					transform: translateY(-100%);
				}
				
				100% {
					transform: translateY(100vh);
				}
			}
			
			// 点击效果
			@keyframes animateGift{
				0% {
					opacity: 1;
				}
				99%{
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
			
			// 奖品弹窗效果
			@keyframes animatePopup{
				0% {
					opacity: 0;
					transform: scale(0.2);
				}
				90%{
					opacity: 1;
					transform: scale(1.1);
				}
				100% {
					opacity: 1;
					transform: scale(1);
				}
				
			}
			
		}
	}
</style>