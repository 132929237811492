import { render, staticRenderFns } from "./11TurntableRules.vue?vue&type=template&id=251735b7&scoped=true&"
import script from "./11TurntableRules.vue?vue&type=script&lang=js&"
export * from "./11TurntableRules.vue?vue&type=script&lang=js&"
import style0 from "./11TurntableRules.vue?vue&type=style&index=0&id=251735b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251735b7",
  null
  
)

export default component.exports