<template>
  <div class="card_list_container">
    <van-swipe-cell v-for="item in listData" :key="item.id">
      <div class="list" @click="handleClickCurrentBankCard(item)">
        <img class="list_logo" :src="require(`../../../assets/BranCardLogo/${item.bankCode}.png`)" alt="" />
        <p class="list_name van-ellipsis">
          {{ item.bankName }}({{ item.cardNumber && item.cardNumber.slice(-4) }})
        </p>
        <div class="list_button">修改</div>
        <img class="list_arrow" src="../../../assets/right_arrow_black_icon.png" alt="" />
      </div>
      <template #right>
        <van-button square type="danger" text="删除" @click="handleClickDelete(item.cardNumber)" />
      </template>
    </van-swipe-cell>
    <div class="add" @click="handleClickAddCard"><span>+</span>添加银行卡</div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" close-on-click-action cancel-text="取消"
      description="确定要解绑当前银行卡吗？" />
  </div>
</template>

<script>
export default {
  created() {
    document.title = "银行卡";
  },
  data() {
    return {
      token: "",
      listData: [],
      show: false,
      actions: [{ name: "删除", color: "#DE3B36" }],
      cardNumber: "",
      // 1 : 选择绑定银行卡
      type: ''
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.type = this.$route.query.type;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getBankCardListData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getBankCardListData();
        });
      }
    });
  },
  methods: {
    async getBankCardListData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/bankcard/queryBankCardList",
        data: {}
      });
      if (data.success) {
        if (data.data && data.data.length > 0) {
          this.listData = [...data.data];
        }
      } else {
        this.$toast(data.message);
      }
    },
    handleClickDelete(cardNumber) {
      this.show = true;
      this.cardNumber = cardNumber;
    },
    async onSelect(item) {
      this.$toast.loading({
        message: "解绑中...",
        forbidClick: true
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/bankcard/unboundBankCard",
          data: { cardNumber: this.cardNumber }
        });
        this.$toast.clear();
        if (data.success) {
          if (data.data === true) {
            this.$toast("解绑成功");
            this.cardNumber = "";
            this.getBankCardListData();
          } else {
            this.$toast("解绑失败");
          }
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    },
    handleClickAddCard() {
      // operationType: 操作类型 ： 0新增  1修改
      this.$router.push({
        path: `/addol/addBankCard`,
        query: {
          token: this.token,
          operationType: "0"
        }
      });
    },
    handleClickCurrentBankCard(item) {
      if (this.type === '1') {
        this.bindGiveRewardBankCard(item)
      } else {
        this.$router.push({
          path: `/addol/addBankCard`,
          query: {
            token: this.token,
            operationType: "1",
            bankCardInfo: JSON.stringify(item)
          }
        });
      }
    },
    // 绑定发放奖励的银行卡
    async bindGiveRewardBankCard(item) {
      this.$toast.loading({
        message: "绑定中...",
        forbidClick: true
      });
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/teamReward/updateTeamRewardUserBankCard",
          data: { accountName: item.accountName, idCard: item.idCard, bankName: item.bankName, cardNumber: item.cardNumber }
        });
        this.$toast.clear();
        if (data.success) {
          this.$toast('绑定成功');
          this.$router.back()
        } else {
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.$toast(error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card_list_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  .list {
    margin-top: 10px;
    width: 355px;
    height: 52px;
    background: #ffffff;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .list_logo {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 4px;
    }

    .list_name {
      flex: 1;
      width: 0;
      padding: 0;
      margin: 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }

    .list_button {
      width: 52px;
      height: 25px;
      border-radius: 13px;
      border: 1px solid #404040;
      font-size: 13px;
      font-family: PingFangSC;
      color: #404040;
      line-height: 25px;
      text-align: center;
      margin-left: 10px;
      margin-right: 7px;
    }

    .list_arrow {
      height: 12px;
      margin-right: 10px;
    }
  }

  .add {
    margin-top: 10px;
    width: 355px;
    height: 52px;
    background: #ffffff;
    border-radius: 3px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
    line-height: 52px;
    text-align: center;

    span {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }
  }
}
</style>
