<template>
  <div class="ck-wheel">
    <!-- <div :class="[assistList.length <= 0 ? 'poster' : 'poster-0']"></div> -->
    <div class="bg">
      <!-- <div v-if="assistList.length > 0" class="myAssist">
        <div class="title"></div>
        <div v-for="(item, index) in assistList" :key="index" class="itemBg">
          <div class="leftView">
            <div class="itemTitle">{{ item.name }}</div>
            <div class="itemGold">
              <div class="icon"></div>
              <div class="unit">¥</div>
              <div class="gold">{{ formatPrice(item.finalPrice) }}</div>
            </div>
          </div>
          <div class="rightView">
            <div class="invite" @click="share(item.id)"></div>
            <div class="goDetail" @click="goDetailPage(token, item.id)"></div>
          </div>
        </div>
      </div> -->
      <div class="shareBtn" v-if="isWx" @click="shareWX"></div>
      <div v-if="assistList.length <= 0 || true" class="join-1" @click="goGoodsDetail"></div>
      <!-- <div v-if="assistList.length > 0" :class="['join-2']" @click="goGoodsDetail"></div> -->

      <!-- <div class="rule-1"></div>
      <div :class="[assistList.length <= 0 ? 'rule-2' : 'rule-3']"></div>
      <div class="logo"></div> -->
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: "",
      itemId: "",
      token: this.$route.query.token,
      assistList: [],
      isWx: false
    };
  },
  created() {
    document.title = "Kiddol 年货助力金";
  },
  mounted() {
    this.token = this.$route.query.token;
    // this.getActivityData();
    // this.getList();
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.getActivityData();
          this.getList();
          this.isWx = true;
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getActivityData();
            this.getList();
          });
        }
      });
    }, 1000);
    this.$setAssistGoldData({
      type: 0 //1:详情页 0:活动页 2:列表页
    });
  },

  methods: {
    formatPrice,
    async getActivityData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/assistActivity/queryEffectAssistActivity",
        data: {}
      });
      if (data.success) {
        this.itemId = data.data.itemId;
      } else {
        this.$toast(data.message);
      }
    },
    async getList() {
      if (this.token) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/assist/queryAssistList",
          data: {
            pageSize: 100,
            currentPage: 1,
            assistListType: 0 //0：活动页面；1：我的助力金页面
          }
        });
        if (data.success) {
          this.assistList = data.data || [];
          if(this.assistList.length>0){
            this.$router.replace(`/assistDetail?token=${this.token}&id=${this.assistList[0].id}&time=${Date.now()}`);
          }
        } else {
          this.$toast(data.message);
        }
      }
    },

    async goGoodsDetail() {
      this.getActivityData();
      if (this.itemId && this.itemId !== "") {
        uni.webView.getEnv(res => {
          if (res.miniprogram) {
            uni.webView.navigateTo({
              url: `/pagesA/detail/index?id=${this.itemId}`
            });
          } else {
            this.$bridge
              .call("openPage", {
                type: 4,
                param: this.itemId
              })
              .then(e => {
                console.log("jumpToDetail客户端返回: ", e);
              });
          }
        });
      }
    },

    shareWX() {
      uni.webView.navigateTo({
        url: `/assistPackage/activity/share?shareType=1` //shareType    分享类型  1- 活动分享   2-助力页分享   assistId    // 助力金id  shareType=2时，必填
      });
      // uni.webView.postMessage({ data: { shareType: 0 } });
    },

    share(id) {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/assistPackage/activity/share?shareType=2&assistId=${id}` //shareType    分享类型  1- 活动分享   2-助力页分享   assistId    // 助力金id  shareType=2时，必填
          });
          // uni.webView.postMessage({ data: { shareType: 1, id: id } });
        } else {
          this.$bridge
            .call("openPage", {
              type: 27,
              param: {
                shareType: 1,
                id: id
              }
            })
            .then(e => {
              console.log("openPage27客户端返回: ", e);
            });
        }
      });
    },

    goDetailPage(token, id) {
      console.log("ddd", token);
      this.$router.push(`/assistDetail?token=${token}&id=${id}&time=${Date.now()}`);
      // window.open(`/assistDetail?token=${token}&id=${id}`);
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster {
    width: 100vw;
    height: 488px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/poster.gif?1) no-repeat;
    background-size: 100% 100%;
  }
  .poster-0 {
    width: 100vw;
    height: 518px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/poster_0.png?12) no-repeat;
    background-size: 100% 100%;
  }
  .bg {
    width: 375px;
    height: 1704px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/bg.png?2) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .myAssist {
      position: relative;
      width: 343px;
      height: auto;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 6px;
      margin-bottom: 17px;

      .title {
        width: 114px;
        height: 23px;
        background: url(https://oss-kiddo.manqu88.com/h5/assist/myAssist.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 23px;
        margin-bottom: 20px;
      }

      .itemBg {
        width: 317px;
        height: 83px;
        background: url(https://oss-kiddo.manqu88.com/h5/assist/itemBg.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: 13px;
        display: flex;
        .leftView {
          width: 90%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .itemTitle {
            margin-left: 26px;
            margin-top: 10px;
            margin-bottom: 12px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
          }
          .itemGold {
            margin-left: 26px;
            display: flex;
            .icon {
              margin-right: 6px;
              width: 30px;
              height: 32px;
              background: url(https://oss-kiddo.manqu88.com/h5/assist/goldIcon.png) no-repeat;
              background-size: 100% 100%;
            }
            .unit {
              height: 32px;
              font-size: 16px;
              font-family: mcFont;
              font-weight: 500;
              color: #d2013d;
              line-height: 40px;
            }
            .gold {
              height: 32px;
              font-size: 29px;
              font-family: mcFont;
              font-weight: 500;
              color: #d2013d;
              line-height: 32px;
            }
          }
        }
        .rightView {
          width: 10%;
          height: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          .invite {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 99px;
            height: 34px;
            background: url(https://oss-kiddo.manqu88.com/h5/assist/invite.png?2) no-repeat;
            background-size: 100% 100%;
          }
          .goDetail {
            position: absolute;
            bottom: 10px;
            right: 15px;
            width: 99px;
            height: 23px;
            background: url(https://oss-kiddo.manqu88.com/h5/assist/goDetail.png?1) no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .showMore {
        position: absolute;
        bottom: -48px;
        height: 29px;
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #a1a8ff;
        line-height: 29px;
      }
    }

    .shareBtn {
      position: absolute;
      right: 5px;
      top: 162px;
      width: 60px;
      height: 60px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/shareBtn.png?11) no-repeat;
      background-size: 100% 100%;
      // background-color: pink;
    }

    .join-1 {
      position: absolute;
      left: 92px;
      top: 486px;
      width: 192px;
      height: 55px;
      // background: #e01b55;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/assist_join.gif?1) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
    }

    .join-2 {
      width: 361px;
      height: 118px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/join_2.gif) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
    }

    .rule-1 {
      width: 353px;
      height: 234px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/rule_1.png?1234) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 18px;
    }
    .rule-2 {
      width: 353px;
      height: 651px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/rule_2.png?123) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 42px;
    }
    .rule-3 {
      width: 353px;
      height: 461px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/rule_3.png?123) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 42px;
    }

    .logo {
      width: 129px;
      height: 52px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/logo.png) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 42px;
    }
  }
}
</style>
