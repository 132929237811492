<template>
  <div class="trade_container">
    <div class="universal_head">
      <div class="universal_head_mask">
        <div class="universal_head_line"></div>
        <p class="universal_head_title">交易动态</p>
        <img v-show="isShowOrderDynamic" class="dynamic_icon" src="../../../assets/manage_dynamic_msg.png" alt="">
        <p v-show="isShowOrderDynamic" class="dynamic_tip van-ellipsis">{{ dynamicContent }}</p>
      </div>
    </div>
    <div class="order">
      <div class="order-dynamics-waitpay" @click="handleClickOrder(0)">
        <p class="num">{{ orderData.waitPayCount }}</p>
        <p class="title">待支付</p>
      </div>
      <div class="order-dynamics-canclepay" @click="handleClickOrder(1)">
        <p class="num">{{ orderData.closeCount }}</p>
        <p class="title">24h内取消支付</p>
      </div>
      <div class="order-dynamics-going" @click="handleClickOrder(2)">
        <p class="num">{{ orderData.waitShipCount }}</p>
        <p class="title">待发货</p>
      </div>
      <div class="order-dynamics-saleing" @click="handleClickOrder(3)">
        <p class="num">{{ orderData.refundCount }}</p>
        <p class="title">售后中</p>
      </div>
    </div>
    <div class="menu">
      <van-grid :border="false" :column-num="4">
        <van-grid-item @click="handleClickItem(0)">
          <div class="item">
            <img class="item-image" src="../../../assets/manage_item_center.png" alt="inm_shop_order.png" />
            <p class="item-tltle">订单中心</p>
          </div>
        </van-grid-item>
        <van-grid-item @click="handleClickItem(1)">
          <div class="item">
            <img class="item-image" src="../../../assets/manage_item_customer.png" alt="inm_shop_order.png" />
            <p class="item-tltle">我的客户</p>
          </div>
        </van-grid-item>
        <van-grid-item @click="handleClickItem(2)">
          <div class="item">
            <img class="item-image" src="../../../assets/manage_item_capital.png" alt="inm_shop_order.png" />
            <p class="item-tltle">账户资金</p>
          </div>
        </van-grid-item>
        <van-grid-item @click="handleClickItem(3)">
          <div class="item">
            <img class="item-image" src="../../../assets/manage_item_reward.png" alt="inm_shop_order.png" />
            <p class="item-tltle">平台奖励</p>
          </div>
        </van-grid-item>
        <van-grid-item @click="handleClickItem(4)">
          <div class="item">
            <img class="item-image" src="../../../assets/blank_card.png" alt="inm_shop_order.png" />
            <p class="item-tltle">银行卡</p>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      default: "",
      type: String,
      require: true
    },
    distUserId: {
      default: "",
      type: String,
      require: true
    }
  },
  data() {
    return {
      orderData: {},
      isShowOrderDynamic: false,
      orderDynamicModel: {},
    };
  },
  mounted() {
    if (this.token.length > 0) {
      this.getOrderStatusData();
      this.getOrderDynamic();
    }
  },
  computed: {
    dynamicContent() {
      if (this.orderDynamicModel.itemName && this.orderDynamicModel.itemName.length > 0) {
        let name = this.orderDynamicModel.nickname
        if (this.orderDynamicModel.nickname.length > 10) {
          name = name.slice(0, 9)
        }
        return `${name}购买了${this.orderDynamicModel.amount}件${this.orderDynamicModel.itemName}商品`
      } else {
        return ''
      }
    }
  },
  methods: {
    async getOrderStatusData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/difOrderCountByStatus",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.orderData = { ...data.data };
        }
      } else {
        this.$toast(data.message);
      }
    },
    // 获取订单动态
    async getOrderDynamic() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryTradeDynamic",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.orderDynamicModel = { ...data.data }
          this.isShowOrderDynamic = true
        } else {
          this.isShowOrderDynamic = false
        }
      } else {
        this.isShowOrderDynamic = false
        this.$toast(data.message);
      }
    },
    handleClickItem(index) {
      if (index === 0) {
        this.$router.push(`/addol/orderList?token=${this.token}`);
      } else if (index === 1) {
        this.$router.push(`/addol/myclient?token=${this.token}`);
      } else if (index === 2) {
        this.$router.push(`/addol/fundsList?token=${this.token}`);
      } else if (index === 3) {
        this.$router.push(`/monthReward?token=${this.token}&userId=${this.distUserId}`);
      } else {
        this.$router.push(`/addol/bankCardList?token=${this.token}`);
      }
    },
    handleClickOrder(index) {
      let orderStatus = 0;
      if (index === 0) {
        orderStatus = 0;
      } else if (index === 1) {
        orderStatus = 4;
      } else if (index === 2) {
        orderStatus = 1;
      } else {
        orderStatus = 5;
      }
      this.$router.push(
        `/addol/orderStatusList?token=${this.token}&distUserId=${this.distUserId}&orderStatus=${orderStatus}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.trade_container {
  padding: 12px 12px;
  box-sizing: border-box;
  width: 100%;

  .universal_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .universal_head_mask {
      flex: 1;
      width: 0;
      display: flex;
      justify-content: left;
      align-items: center;

      .universal_head_line {
        width: 5px;
        height: 14px;
        background: #f9550a;
        margin-right: 5px;
      }

      .universal_head_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
      }

      .dynamic_icon {
        width: 14px;
        height: 14px;
        margin-left: 10px;
        margin-right: 5px;
      }

      .dynamic_tip {
        flex: 1;
        width: 0;
        padding: 0;
        margin: 0;
        font-size: 9px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F9550A;
        margin-right: 4px;
      }

    }
  }

  .order {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #dddddd;
    border-radius: 4px;
    display: flex;
    margin-bottom: 15px;
    margin-top: 10px;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 12px 0px;

    .order-dynamics-waitpay {
      display: flex;
      flex-direction: column;

      .num {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f9550a;
        text-align: center;
      }

      .title {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }

    .order-dynamics-canclepay {
      .num {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f9550a;
        text-align: center;
      }

      .title {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }

    .order-dynamics-going {
      .num {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f9550a;
        text-align: center;
      }

      .title {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }

    .order-dynamics-saleing {
      .num {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f9550a;
        text-align: center;
      }

      .title {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }
  }

  .menu {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #dddddd;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    overflow: hidden;

    /deep/ .van-grid-item__content {
      padding: 7px 8px;
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .item-image {
        width: 25px;
      }

      .item-tltle {
        padding: 0;
        margin: 0;
        margin-top: 7px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
