<template>
  <div class="ck-wheel">
    <div class="bg-1">
      <div class="rule-btn" @click="goRule"></div>
      <div class="poster"></div>

      <div class="card">
        <div class="num-bg">
          <div class="num-1">{{ datas.reachStandardCount }}</div>
          <div class="num-2">{{ datas.confirmReachStandardCount }}</div>
          <div class="num-3">{{ datas.expectGoldAmount }}</div>
        </div>
        <div class="tips"></div>
        <div class="list-view">
          <div class="noData" v-if="list.length === 0"></div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div style="display:flex;">
              <div
                class="head"
                :style="{
                  backgroundImage: `url(${item.headPic})`
                }"
              ></div>
              <div class="right-view">
                <div class="item-name">{{ item.nickname }}</div>
                <div class="num-view">
                  <div class="title" style="width:45%;">
                    推广件数:
                    <div class="num">{{ item.promoteCount }}</div>
                    件
                  </div>
                  <div class="title" style="width:55%;">
                    已确认收货件数:
                    <div class="num">{{ item.confirmPromoteCount }}</div>
                    件
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>

      <div class="rule"></div>
      <div class="logo"></div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { formatPrice } from "@/utils/money";
import { Toast } from "vant";

export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      list: [],
      datas: {
        reachStandardCount: 0,
        confirmReachStandardCount: 0,
        expectGoldAmount: 0
      }
    };
  },
  created() {
    this.showBack = this.$route.query.showBack ? true : false;
    document.title = "实习招募季 推荐享好礼";
  },
  async mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
        });
      }
    });
  },

  methods: {
    formatPrice,
    goBack() {
      this.$router.go(-1);
    },
    goRule() {
      console.log("document.body.scrollHeight", document.body.scrollHeight);
      window.scrollTo(0, document.body.scrollHeight - 880);
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/nationalDay/activity/two",
        data: {}
      });
      if (data.success) {
        this.datas = data.data && data.data;
        this.list = data.data.progressList;
      } else {
        this.$toast(data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    min-height: 100vh;
    height: auto;
    // background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/bg.png?11) no-repeat;
    // background-size: 100% 100%;
    background: #f8f8fa;
    position: relative;

    .rule-btn {
      position: absolute;
      right: 8px;
      top: 168px;
      width: 93px;
      height: 29px;
      background: url(https://oss-kiddo.manqu88.com/h5/anniversary/ruleBtn.png) no-repeat;
      background-size: 100% 100%;
    }

    .poster {
      width: 375px;
      height: 811px;
      background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/poster.png) no-repeat;
      background-size: 100% 100%;
    }

    .card {
      padding-right: 1px;
      padding-left: -1px;
      margin-top: -240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 363px;
      height: auto;
      background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/card.png?11) no-repeat;
      background-size: 100% 100%;
      position: relative;

      .num-bg {
        margin-top: 21px;
        width: 342px;
        height: 86px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/nums_bg.png?11) no-repeat;
        background-size: 100% 100%;
        position: relative;

        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #eb6d11;
        line-height: 24px;
        .num-1 {
          position: absolute;
          text-align: center;
          left: 107px;
          top: 3px;
          width: 35px;
          height: 24px;
        }
        .num-2 {
          position: absolute;
          text-align: center;
          right: 30px;
          top: 3px;
          width: 35px;
          height: 24px;
        }
        .num-3 {
          position: absolute;
          text-align: center;
          right: 95px;
          bottom: 9px;
          width: 58px;
          height: 30px;
          font-size: 25px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #eb6d11;
          line-height: 30px;
        }
      }
      .tips {
        margin-top: 18px;
        width: 335px;
        height: 42px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/tips.png?11) no-repeat;
        background-size: 100% 100%;
      }

      .list-view {
        margin-top: 2px;
        margin-bottom: 10px;
        width: 343px;
        max-height: 446px;
        border-radius: 4px;
        border: 1px solid #ddd2b9;
        overflow: scroll;

        .noData {
          margin: auto;
          margin-top: 27px;
          margin-bottom: 27px;
          width: 52px;
          height: 42px;
          background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/noData.png?11) no-repeat;
          background-size: 100% 100%;
        }
        .item {
          width: 343px;
          height: 77px;
          .head {
            margin-top: 9px;
            margin-left: 9px;
            margin-right: 8px;
            border-radius: 45px;
            width: 52px;
            height: 52px;
            // background: #d8d8d8;
            background-size: 100% 100%;
          }
          .right-view {
            margin-top: 9px;
            width: 274px;
            height: 52px;
            // background: #eb6d11;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item-name {
              width: 150px;
              height: 21px;
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #141414;
              line-height: 21px;
              overflow: hidden;
              text-overflow: ellipsis;
              /* 作为弹性伸缩盒子模型显示 */
              display: -webkit-box;
              /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
              -webkit-box-orient: vertical;
              /* 显示的行 */
              -webkit-line-clamp: 1;
            }
            .num-view {
              width: 274px;
              height: 20px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #3f3f3f;
              display: flex;
              .title {
                width: 50%;
                height: 20px;
                display: flex;
                .num {
                  margin-left: 1px;
                  margin-right: 1px;
                  font-size: 14px;
                  font-weight: 500;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .line {
          margin-left: 6px;
          margin-top: 12px;
          margin-bottom: 0;
          width: 329px;
          height: 0px;
          border: 1px solid #ddd2b9;
          border-style: dashed;
        }
      }
    }

    .rule {
      margin-top: 12px;
      width: 363px;
      height: 413px;
      background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/rule2.png?1111) no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .logo {
      margin-top: 41px;
      margin-bottom: 65px;
      width: 116px;
      height: 51px;
      background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/logo.png?111) no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
  }
}
</style>
