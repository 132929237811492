<template>
  <div class="main">
    <div v-if="!showPoster">
      <div class="top"></div>
      <div class="tusted_box">
        <div class="swiper-container tust_swp">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide wid" style="">
              <div class="trus_img wid">
                <img
                  src="https://oss-kiddo.manqu88.com/h5/memberCenter/levelBg_1.png"
                  alt=""
                  class="wid"
                />
                <div class="curLevel" v-if="userInfo.gradeCode < 2 && !isLeader">当前等级</div>
                <div
                  class="notLevel"
                  v-if="userInfo.gradeCode >= 2 || (userInfo.gradeCode < 2 && isLeader)"
                >
                  当前高于该等级
                </div>
              </div>
            </div> -->
            <div class="swiper-slide wid">
              <div class="trus_img wid">
                <img
                  src="https://oss-kiddo.manqu88.com/h5/memberCenter/levelBg_2.png?11"
                  alt=""
                  class="wid"
                />
                <div class="notLevel" v-if="userInfo.gradeCode < 2 && !isLeader">
                  当前低于该等级
                </div>
                <div class="curLevel" v-if="userInfo.gradeCode < 2 && isLeader">当前等级</div>
                <div class="notLevel" v-if="userInfo.gradeCode >= 2">当前高于该等级</div>
              </div>
            </div>
            <div class="swiper-slide wid">
              <div class="trus_img wid">
                <img
                  src="https://oss-kiddo.manqu88.com/h5/memberCenter/levelBg_3.png?11"
                  alt=""
                  class="wid"
                />
                <div class="curLevel" v-if="userInfo.gradeCode === 2">当前等级</div>
                <div class="notLevel" v-if="userInfo.gradeCode < 2">当前低于该等级</div>
                <div class="notLevel" v-if="userInfo.gradeCode > 2">当前高于该等级</div>
              </div>
            </div>
            <div class="swiper-slide wid">
              <div class="trus_img wid">
                <img
                  src="https://oss-kiddo.manqu88.com/h5/memberCenter/levelBg_4.png?11"
                  alt=""
                  class="wid"
                />
                <div class="curLevel" v-if="userInfo.gradeCode === 3">当前等级</div>
                <div class="notLevel" v-if="userInfo.gradeCode < 3">当前低于该等级</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 会员权益 -->
      <div class="title">会员权益</div>
      <div :class="['qy-' + (activeIndex + 2)]"></div>

      <!-- 考核进度 -->
      <div
        class="btn-progress"
        v-if="activeIndex === 0 && isLeader && userInfo.gradeCode < 2"
        @click="goProgress"
      >
        查看考核进度 >
      </div>

      <!-- 会员升级 -->
      <div v-if="activeIndex > 0">
        <div class="title">会员升级</div>
        <div class="card">
          <div class="title-view">
            <div>方式一：购买直升套组</div>
            <div :class="['btn-' + activeIndex]" v-if="showBtn" @click="jump">
              去完成
            </div>
          </div>

          <div class="title-view">
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;购买直升任选商品
            </div>
            <div :class="['btn-' + activeIndex]" v-if="showBtn" @click="upGrade(31)">
              去完成
            </div>
          </div>
        </div>
        <!-- <div class="card">
          <div class="title-view">
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;购买直升任选商品
            </div>
            <div :class="['btn-' + activeIndex]" v-if="showBtn" @click="upGrade(31)">
              去完成
            </div>
          </div>
        </div> -->
        <div class="card" v-if="activeIndex > 1">
          <div class="title-view">
            <div>方式二：同时满足指标条件</div>
          </div>

          <div class="subTitle">邀请5名推荐官</div>
          <div class="progress-view">
            <div class="progress-bg">
              <div class="progress" :style="{ width: forEverKBWidth + 'px' }"></div>
            </div>
            <div class="progress-detail">{{ forEverKB }}/5</div>
          </div>

          <div class="subTitle">团队业绩达15万且下级最大团队占比需小于70%</div>
          <div class="progress-view">
            <div class="progress-bg">
              <div class="progress" :style="{ width: teamAmountWidth + 'px' }"></div>
            </div>
            <div class="progress-detail">
              {{ teamAmount }}/15万
              <div class="subDetail" v-if="qualified === 0">占比小于30%</div>
            </div>
          </div>
          <div style="height:40px"></div>
        </div>
      </div>

      <!-- 会员升级 -->
      <div v-if="activeIndex < 1">
        <div class="title">会员升级</div>
        <div class="invite-view">
          <div class="poster-1-1" @click="upGrade(31, 2)"></div>
          <div class="poster-2-1" @click="upGrade(31, 3)"></div>
        </div>
      </div>
      <!-- 邀请好友 -->
      <div v-if="activeIndex >= 0">
        <div class="title">邀请好友</div>
        <!-- <div class="goInvite" v-if="userInfo.gradeCode >= 2" @click="goInvite"></div> -->
        <div class="invite-view">
          <div class="poster-1" @click="upGrade(31, 2)"></div>
          <div class="poster-2" @click="upGrade(31, 3)"></div>
        </div>
      </div>
    </div>
    <div v-else class="poster-member"></div>
  </div>
</template>
<script>
import { Toast } from "vant";
import Swiper from "swiper";
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  data() {
    return {
      token: this.$route.query.token,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination"
        }
        // Some Swiper option/callback...
      },
      userInfo: {},
      activeIndex: 0,
      swiper: {},
      intermediateGradeAmount: 0,
      currentTotalConsume: 0,
      sub: 0,
      myprogress: 0,
      finishConsumerAmount: 0,
      forEverKB: 0,
      forEverKC: 0,
      forEverDistUser: 0,
      teamAmount: null,
      processEndFlag: 1,
      status: 3,
      processEndFlag1: 1,
      status1: 3,
      type: this.$route.query.type,
      gmtModified: 0,
      qualified: 1,
      proportion: 0,
      forEverKBWidth: 0,
      teamAmountWidth: 0,
      showBtn: false,
      isLeader: false,
      showPoster: false,
      canClick: false
    };
  },
  created() {
    document.title = "会员中心";
    console.log(this.isBanner);
    // this.showPoster = false;
  },
  mounted() {
    this.swiper = new Swiper(".tust_swp", {
      slidesPerView: "auto",
      centeredSlides: true,
      pagination: {
        el: ".tusted_box .swiper-pagination",
        clickable: true
      },
      on: {
        slideChangeTransitionEnd: e => {
          console.log("end", this.swiper); //切换结束时，告诉我现在是第几个slide
          this.activeIndex = this.swiper.activeIndex;
          this.showBtn = false;
          if (this.userInfo.gradeCode < 2) {
            this.showBtn = true;
          } else if (this.userInfo.gradeCode === 2 && this.activeIndex === 2) {
            this.showBtn = true;
          }
        }
      }
    });
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getDatas();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDatas();
        });
      }
    });
  },
  methods: {
    // checkTime
    checkTime() {
      const limitTime = new Date("2022/09/19 10:00:00").getTime();
      if (Date.now() >= limitTime) {
        return true;
      } else {
        return false;
      }
    },
    //获取数据
    async getDatas() {
      this.getUserInfo();
      this.nextLevel();
      this.getOfflineRemitRecord();
      this.getOfflineRemitRecordKB();
    },
    //基本信息
    async getUserInfo() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
          url: "/api/distGrouponLeader/isLeader",
          data: {}
        });
        this.isLeader = data.data || false;
      }

      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/dist/user/getDistUserInfo",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.userInfo = data.data;
        let num = 0;
        if (this.userInfo.gradeCode == 2) {
          num = 2;
        }
        if (this.userInfo.gradeCode == 3) {
          num = 3;
        }
        if (this.type == 1) {
          this.swiper.slideTo(1);
        } else {
          this.swiper.slideTo(num);
        }
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    //查看邀请消费数据
    async nextLevel() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/member/queryUpgradeProgress",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.currentTotalConsume = formatPrice(data.data.consumerAmount).split(".")[0];
        this.finishConsumerAmount = formatPrice(data.data.finishConsumerAmount || 0).split(".")[0];
        this.forEverKB = data.data.forEverKB || 0;
        this.forEverKC = data.data.forEverKC || 0;
        this.forEverDistUser = data.data.forEverDistUser || 0;
        this.teamAmount = formatPrice(data.data.teamAmount || 0).split(".")[0];
        this.qualified = data.data.qualified;
        this.gmtModified = moment(data.data.gmtModified).format("YYYY.MM.DD");
        this.proportion = data.data.proportion || 0;

        let width = (232 / 375) * document.documentElement.clientWidth;
        this.forEverKBWidth = ((this.forEverKB >= 5 ? 5 : this.forEverKB) / 5) * width;
        this.teamAmountWidth =
          ((this.teamAmount >= 150000 ? 150000 : this.forEverKB) / 150000) * width;
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    async getOfflineRemitRecord() {
      let payType = 1;
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/offlineRemit/queryOfflineRemitRecord",
        data: { payType }
      });
      this.processEndFlag = data.data.processEndFlag;
      this.status = data.data.status;
    },
    async getOfflineRemitRecordKB() {
      let payType = 2;
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/offlineRemit/queryOfflineRemitRecord",
        data: { payType }
      });
      this.processEndFlag1 = data.data.processEndFlag;
      this.status1 = data.data.status;
    },
    //查看进度
    checkProgress() {
      let payType = "";
      if (this.activeIndex == 2) {
        payType = 1;
      }
      if (this.activeIndex == 1) {
        payType = 2;
      }
      this.$bridge.call("checkProgress", { payType }).then(e => {
        console.log("客户端返回: ", e);
      });
    },
    payUpdate() {
      if (this.activeIndex == 2) {
        this.$bridge.call("payUpdate", {}).then(e => {
          console.log("客户端返回: ", e);
        });
      } else {
        this.$bridge
          .call("payUpdateKB", {})
          .then(e => {
            console.log("客户端返回: ", e);
          })
          .catch(e => {
            console.log(e);
            Toast("请更新APP到最新版本");
          });
      }
    },
    jumpToMiniHome() {
      this.$bridge.call("jumpToMiniHome", {}).then(e => {
        console.log("客户端返回: ", e);
      });
    },
    jumpToLevelIndex() {
      this.$bridge.call("jumpToLevelIndex", { level: this.activeIndex + 1 }).then(e => {
        console.log("客户端返回: ", e);
      });
    },
    toPay() {
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "此充值仅用于消费充值，不支持会员升级充值，如需升级充值，请到KA,KB的升级通道进行付款。"
        })
        .then(() => {
          this.$router.push("/pay?token=" + this.token);
        });
    },
    goProgress() {
      this.$router.push(`/superGruopBuy/gruoperRule?token=${this.token}&showBack=1`);
    },
    goInvite() {
      this.$router.push(`/superGruopBuy/invitation?token=${this.token}&showBack=1`);
    },
    jump() {
      if (!this.checkTime()) {
        Toast("9.19日10:00正式开放");
        return;
      }
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        let id = this.activeIndex === 1 ? 2755 : 2756;
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pages/index/sub-page?id=${id}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 0,
              param: id
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },
    upGrade(index, activeIndex = null) {
      if (!this.checkTime()) {
        Toast("9.19日10:00正式开放");
        return;
      }
      activeIndex = activeIndex || this.activeIndex + 1;
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          let url;
          if (activeIndex === 2) {
            url =
              index === 30
                ? "/levelPackage/package/index?gradeCode=2"
                : "/levelPackage/good/index?gradeCode=2";
          } else {
            url =
              index === 30
                ? "/levelPackage/package/index?gradeCode=3"
                : "/levelPackage/good/index?gradeCode=3";
          }
          uni.webView.navigateTo({
            url: url
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: index,
              param: activeIndex
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-color: #ffffff;
  // padding-top: 30px;
  display: flex;
  flex-direction: column;

  .poster-member {
    width: 375px;
    height: 780px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberPoster.png?11") no-repeat;
    background-size: 100% 100%;
  }

  .top {
    width: 375px;
    height: 166px;
    background: linear-gradient(360deg, #ffffff 0%, #f1f6ff 100%);
    margin-bottom: -111px;
  }
  .title {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-left: 15px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    line-height: 22px;
  }
  .btn-progress {
    margin: auto;
    margin-top: 18px;
    margin-bottom: -5px;
    width: 240px;
    height: 36px;
    background: linear-gradient(309deg, #ffcb75 0%, #ffe160 100%);
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #333333;
    line-height: 18px;
  }

  .goInvite {
    margin: auto;
    margin-top: 0;
    margin-bottom: 10px;
    width: 345px;
    height: 50px;
    background: #e6e6e6;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/goInvite.png?11") no-repeat;
    background-size: 100% 100%;
  }
  .invite-view {
    width: 345px;
    height: 75px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .poster-1 {
      width: 168px;
      height: 75px;
      background: #e6e6e6;
      border-radius: 6px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/inviteLeft.png?2") no-repeat;
      background-size: 100% 100%;
    }
    .poster-1-1 {
      width: 168px;
      height: 75px;
      background: #e6e6e6;
      border-radius: 6px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/levelUpleft.png?2") no-repeat;
      background-size: 100% 100%;
    }
    .poster-2 {
      width: 168px;
      height: 75px;
      background: #e6e6e6;
      border-radius: 6px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/inviteRight.png?2") no-repeat;
      background-size: 100% 100%;
    }
    .poster-2-1 {
      width: 168px;
      height: 75px;
      background: #e6e6e6;
      border-radius: 6px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/levelUpRight.png?2") no-repeat;
      background-size: 100% 100%;
    }
  }
  .qy-1 {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 345px;
    height: 210px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberQY_1.png?11") no-repeat;
    background-size: 100% 100%;
  }
  .qy-2 {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 345px;
    height: 210px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberQY_2.png?11") no-repeat;
    background-size: 100% 100%;
  }
  .qy-3 {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 345px;
    height: 210px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberQY_3.png?11") no-repeat;
    background-size: 100% 100%;
  }
  .qy-4 {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 345px;
    height: 210px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberQY_4.png?1") no-repeat;
    background-size: 100% 100%;
  }
  .card {
    width: 345px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 8px;
    .title-view {
      width: 315px;
      height: 26px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-1 {
        width: 80px;
        height: 26px;
        background: #695750;
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
      }
      .btn-2 {
        width: 80px;
        height: 26px;
        background: #e05858;
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
      }
    }

    .subTitle {
      margin-top: 6px;
      margin-bottom: 6px;
      // padding-left: 19px;
      width: 315px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
    .progress-view {
      margin-bottom: 17px;
      height: 20px;
      width: 315px;
      display: flex;
      align-items: center;
      .progress-bg {
        position: relative;
        width: 232px;
        height: 20px;
        background: #f1f1f1;
        border-radius: 12px;
        .progress {
          position: absolute;
          left: 0;
          width: 177px;
          height: 20px;
          background: #fb6a1c;
          border-radius: 12px;
        }
      }
      .progress-detail {
        margin-left: 8px;
        position: relative;
        height: 16px;
        font-size: 13px;
        font-family: JDZhengHT-Regular, JDZhengHT;
        font-weight: 400;
        color: #404040;
        line-height: 16px;
        .subDetail {
          word-break: keep-all;
          position: absolute;
          left: 0;
          bottom: -18px;
          height: 13px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a5a5a5;
          line-height: 13px;
        }
      }
    }
  }
}
.tusted_box {
  .swiper-slide {
    position: relative;
    font-size: 18px;
    // background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    // box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    .levelname {
      position: absolute;
      text-align: left;
      left: 26px;
      top: 20px;
      .tit {
        color: #404040;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 22px;
        position: relative;
        top: -25px;
      }
      .name {
        color: #747474;
        font-family: ArialMT;
        font-size: 65px;
        position: relative;
        img {
          width: 82px;
          // height: 47px;
        }
      }
      .op {
        opacity: 0.2;
      }
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
  }
  .trus_img {
    position: relative;
    img {
      // height: 150px;
      border-radius: 4px;
      box-shadow: 0px 0px 12px 0px rgba(221, 221, 221, 0.5);
    }
    .curLevel {
      position: absolute;
      left: 20px;
      top: 10px;
      width: 66px;
      height: 20px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 11px;
      font-size: 11px;
      font-family: OPPOSans-M, OPPOSans;
      font-weight: normal;
      color: #ffffff;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .notLevel {
      position: absolute;
      left: 20px;
      top: 14px;
      height: 12px;
      font-size: 11px;
      font-family: OPPOSans-M, OPPOSans;
      font-weight: normal;
      color: #ffffff;
      line-height: 12px;
    }
  }

  .swiper-slide-prev {
    right: -8px;
  }
  .swiper-slide-next {
    left: -8px;
  }
  .swiper-pagination {
    text-align: center;
    width: 100%;
    bottom: -50px;
  }
  .tusted_box {
    position: relative;
  }
  .swiper-pagination-bullet {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 1px solid #4a9a83;
    border-radius: 50%;
    background: transparent;
    margin: 0 15px;
    vertical-align: middle;
    opacity: 1;
    outline: none;
  }
  .swiper-pagination-bullet-active {
    background: #4a9a83;
    width: 20px;
    height: 20px;
  }
}
.wid {
  // width: 279px;
  // height: 126.85px;
  width: 270px;
  height: 153px;
  margin-left: 7px;
  margin-right: 7px;
}
</style>
