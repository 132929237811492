<template>
  <div class="ck-wheel">
    <van-tabs color="#323CB0" style="width:100%" :sticky="true" @click="changeTab">
      <van-tab v-for="(items, indexs) in tabList" :key="indexs" :title="items">
        <div class="tips">仅展示最近邀请的200位好友，会员可进入Addol查看更多</div>
        <div class="list" @scroll="onScroll">
          <div class="noList" v-if="noList && list.length === 0"></div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div
              class="head"
              :style="{
                backgroundImage: `url(${item.headPicUrl})`
              }"
            ></div>
            <div class="nameView">
              <div class="name">{{ item.nickname }}</div>
              <div class="bottom-view">
                <div
                  :class="[
                    item.isGrouponLeader
                      ? 'level-0-1'
                      : 'level-' + ((item.gradeCode >= 0 && item.gradeCode) || 0)
                  ]"
                ></div>
                <div class="phone">{{ parseMobile(item.mobile) }}</div>
              </div>
              <!-- <div
                class="level"
                :style="{
                  width:
                    item.extension && item.extension.PRACTICE_KA && item.extension.PRACTICE_KA === 1
                      ? '54px'
                      : '41px',
                  backgroundImage:
                    item.extension && item.extension.PRACTICE_KA && item.extension.PRACTICE_KA === 1
                      ? `url(https://oss-kiddo.manqu88.com/h5/gold/level_3_0.png)`
                      : `url(https://oss-kiddo.manqu88.com/h5/level_${
                          item.gradeCode > 0 ? item.gradeCode : 1
                        }.png?)`
                }"
              >
                <div class="phone">{{ parseMobile(item.mobile) }}</div>
              </div> -->
            </div>
            <div class="time">
              邀请时间：{{ moment(item.invitaTime || item.gmtCreate).format("YYYY.MM.DD") }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      tabList: ["消费未满￥168", "消费已满￥168"],
      list: [],
      canGetList: true,
      currentPage: 1,
      pageSize: 20,
      curTab: 0,
      loading: false,
      finished: false,
      noList: false
    };
  },
  created() {
    document.title = "我邀请的好友";
  },
  mounted() {
    // this.getList(); //test
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.userId = this.$route.query.userId;
          this.getList();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }

            this.$bridge.call("getUserId", {}).then(id => {
              console.log("getUserId客户端返回: ", e);
              this.userId = id;
              this.getList();
            });
          });
        }
      });
    }, 1000);
    this.$setAssistGoldData({
      type: 2 //1:详情页 0:活动页 2:列表页
    });
  },

  methods: {
    formatPrice,
    moment,
    parseMobile(phone) {
      phone = phone || "";
      var pho = /(\d{3})\d*(\d{4})/;
      var str = phone.replace(pho, "$1****$2");
      return str;
    },
    async getList() {
      const tabId = JSON.parse(JSON.stringify(this.curTab));
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url:
          this.curTab === 0
            ? "/api/dist/data/queryIntentionUserList"
            : "/api/dist/user/querySubordinate",
        data: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          pid: this.userId
        }
      });
      if (data.success) {
        if (tabId !== this.curTab) {
          return;
        }
        if (this.currentPage === 1) {
          this.list = [];
        }
        this.list = [...this.list, ...data.data];
        if (data.data.length < this.pageSize || this.list.length >= 200) {
          this.canGetList = false;
        }
      } else {
        this.$toast(data.message);
      }
      this.noList = true;
    },
    changeTab(e) {
      console.log(e);
      this.curTab = e;
      this.list = [];
      this.currentPage = 1;
      this.canGetList = true;
      this.noList = false;
      this.getList();
    },

    onScroll(e) {
      console.log(
        e,
        e.target.scrollHeight,
        e.target.scrollTop,
        e.target.clientHeight,
        e.target.scrollTop + e.target.clientHeight
      );
      if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
        //触发加载
        if (this.canGetList) {
          this.currentPage++;
          this.getList();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tips {
    text-align: center;
    margin-top: 10px;
    width: 100%;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a6a6a6;
    line-height: 17px;
  }
  .list {
    max-height: 100vh;
    height: 729px;
    overflow: hidden;
    overflow-y: scroll;
    // padding-top: 23px;

    .noList {
      margin: auto;
      margin-top: 129px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }
    .item {
      position: relative;
      width: 375px;
      height: 43px;
      display: flex;
      align-items: center;
      margin-top: 20px;

      .head {
        margin-left: 20px;
        width: 43px;
        height: 43px;
        border-radius: 45px;
        background: url(https://oss-kiddo.manqu88.com/h5/level_3.png?123) no-repeat;
        background-size: 100% 100%;
      }
      .nameView {
        height: 43px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        .name {
          width: 150px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 1;
        }
        .bottom-view {
          height: 17px;
          display: flex;
          align-items: center;
          .level-0 {
            height: 18px;
            width: 40px;
            height: 17px;
            background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_1.png) no-repeat;
            background-size: 100% 100%;
          }
          .level-0-1 {
            height: 18px;
            width: 66px;
            height: 17px;
            background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_0_1.png) no-repeat;
            background-size: 100% 100%;
          }
          .level-1 {
            height: 18px;
            width: 40px;
            height: 17px;
            background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_1.png) no-repeat;
            background-size: 100% 100%;
          }
          .level-2 {
            height: 18px;
            width: 48px;
            height: 17px;
            background: url(https://oss-kiddo.manqu88.com/h5/levelLabel_dz_2.png) no-repeat;
            background-size: 100% 100%;
          }
          .level-3 {
            height: 18px;
            width: 40px;
            height: 17px;
            background: url(https://oss-kiddo.manqu88.com/h5/levelLabe_sx_3.png?1) no-repeat;
            background-size: 100% 100%;
          }
          .phone {
            margin-left: 5px;
            font-size: 11px;
            font-family: JDZhengHT-Light, JDZhengHT;
            font-weight: 300;
            color: #666666;
            display: flex;
            align-items: center;
          }
        }
        .level {
          position: relative;
          width: 41px;
          height: 18px;
          background: url(https://oss-kiddo.manqu88.com/h5/level_3.png?123) no-repeat;
          background-size: 100% 100%;

          .phone {
            position: absolute;
            right: -68px;
            height: 18px;
            font-size: 11px;
            font-family: JDZhengHT-Light, JDZhengHT;
            font-weight: 300;
            color: #666666;
            display: flex;
            align-items: center;
          }
        }
      }

      .time {
        position: absolute;
        right: 25px;
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a2a2a2;
        line-height: 16px;
      }
    }
  }
}
</style>
