<template>
  <div class="detail_container">
    <van-tabs
      v-model="active"
      sticky
      line-width="50px"
      line-height="2px"
      color="black"
      @click="onTabClick"
    >
      <van-tab
        v-for="(value, index) in tabTitles"
        :key="index"
        :title="value"
        :title-class="active === index ? 'select' : 'noselect'"
      >
        <van-list
          v-if="!isShowEmpty"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <div
            v-for="item in detailListData"
            :key="item.id"
            class="universal-list"
            @click="handleGoToOrderDetail(item.orderId)"
          >
            <div class="universal-list-left">
              <p class="universal-list-title van-ellipsis">
                {{ item.title }}
              </p>
              <p class="universal-list-number">交易编号 {{ item.flowId }}</p>
              <p class="universal-list-time">
                交易时间
                {{ item.gmtCreate && moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss") }}
              </p>
            </div>
            <div class="universal-list-right">
              <p class="universal-list-money">{{ item.amountStr }}</p>
              <div v-show="item.orderId" class="universal-list-detail">
                查看详情
              </div>
            </div>
            <div class="universal-list-line"></div>
          </div>
        </van-list>
        <van-empty v-if="isShowEmpty" description="暂无数据" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import moment from "moment";

export default {
  created() {
    if (this.$route.query.type === "0") {
      document.title = "余额明细";
    } else {
      document.title = "货款明细";
    }
  },
  data() {
    return {
      tabTitles:
        this.$route.query.type === "0"
          ? ["全部", "交易", "提现"]
          : ["全部", "充值", "交易", "提现"],
      active: 0,
      token: "",
      type: "",
      detailListData: [],
      isShowEmpty: false,
      loading: false,
      finished: false,
      currentPage: 2,
      params: { currentPage: 1, pageSize: 20 }
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.type = this.$route.query.type;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        if (this.type === "0") {
          this.params.accountType = 1;
        } else {
          this.params.accountType = 2;
        }
        this.getDetailListData(this.params);
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          if (this.type === "0") {
            this.params.accountType = 1;
          } else {
            this.params.accountType = 2;
          }
          this.getDetailListData(this.params);
        });
      }
    });
  },
  methods: {
    moment,
    async getDetailListData(params) {
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/queryDisAccountFlowCondition",
          data: params
        });
        if (data.success) {
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.isShowEmpty = true;
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.isShowEmpty = false;
            this.detailListData = [...data.data];
          }
        } else {
          this.finished = true;
          this.loading = false;
          this.isShowEmpty = true;
          this.$toast(data.message);
        }
      } catch (error) {
        this.finished = true;
        this.loading = false;
        this.isShowEmpty = true;
      }
    },
    async onLoad() {
      this.params.currentPage = this.currentPage;
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/queryDisAccountFlowCondition",
          data: this.params
        });
        if (data.success) {
          this.currentPage++;
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.detailListData = [...this.detailListData, ...data.data];
          }
        } else {
          this.finished = true;
          this.loading = false;
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast(error.message);
        this.finished = true;
        this.loading = false;
      }
    },
    onTabClick(index) {
      this.finished = true;
      this.loading = false;
      this.currentPage = 2;
      this.detailListData = [];
      if (index === 0) {
        if (this.type === "0") {
          this.params.accountType = 1;
        } else {
          this.params.accountType = 2;
        }
        if (this.params.hasOwnProperty("typeList")) {
          delete this.params.typeList;
        }
      } else if (index === 1) {
        if (this.type === "0") {
          this.params.typeList = [0, 1, 11, 12];
        } else {
          this.params.typeList = [7];
        }
      } else if (index === 2) {
        if (this.type === "0") {
          this.params.typeList = [2, 3];
        } else {
          this.params.typeList = [5, 6];
        }
      } else {
        this.params.typeList = [2, 3];
      }
      this.params.currentPage = 1;
      this.getDetailListData(this.params);
    },
    handleGoToOrderDetail(orderId) {
      if (orderId && orderId.length > 0) {
        this.$router.push(`/addol/orderDetail?token=${this.token}&orderId=${orderId}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;
  /deep/ .van-empty {
    width: 100vw;
    height: 90vh;
  }
  /deep/ .van-tabs {
    width: 100%;
  }
  /deep/ .van-tabs__nav--line {
    padding-bottom: 10px;
  }
  /deep/ .select {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
  }
  /deep/ .noselect {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }
  .universal-list {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    background-color: white;
    position: relative;

    .universal-list-left {
      flex: 1;
      width: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;

      .universal-list-title {
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
      }

      .universal-list-number {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .universal-list-time {
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }

    .universal-list-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 73px;

      .universal-list-money {
        padding: 0;
        margin: 0;
        font-size: 11px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #de7d36;
        text-align: right;
      }

      .universal-list-detail {
        margin-top: 15px;
        width: 73px;
        height: 25px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #b0b0b0;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        text-align: center;
        line-height: 25px;
      }
    }

    .universal-list-line {
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 17px;
      width: 317px;
      height: 1px;
      background-color: #f5f5f5;
      display: block;
    }
  }

  .universal-list:nth-last-child(1) {
    .universal-list-line {
      display: none;
    }
  }
}
</style>
