<template>
  <div class="user_container">
    <div class="user_mask">
      <div class="user_mask_head">
        <div class="user_mask_head_mask">
          <div v-show="false" class="user_mask_head_line"></div>
          <p class="user_mask_head_title">意向用户</p>
          <p class="user_mask_head_sub">（填写邀请码但消费未满¥168）</p>
        </div>
        <p class="user_mask_head_more" @click="handleClickMore">查看更多</p>
      </div>
      <van-divider />
      <div v-if="!isShowEmpty" class="user_mask_content">
        <div v-for="item in intentionUserData" :key="item.distUserId" class="user_mask_content_list"
          @click="handleCopyContent(item.mobile)">
          <img class="list_avatar" :src="item.headPicUrl" alt="" />
          <p class="list_name van-ellipsis">{{ item.nickname }}</p>
          <p class="list_phone">{{ parseMobile(item.mobile) }}</p>
          <p v-show="item.hadBuy" class="list_mark">已下单</p>
        </div>
      </div>
      <div v-if="isShowEmpty" class="empty_content">近期暂无意向用户</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      default: "",
      type: String,
      require: true
    },
    distUserId: {
      default: "",
      type: String,
      require: true
    }
  },
  data() {
    return {
      isShowEmpty: false,
      intentionUserData: []
    };
  },
  mounted() {
    if (this.token.length > 0) {
      this.getIntentionUserData();
    }
  },
  methods: {
    parseMobile(phone) {
      phone = phone || "";
      var pho = /(\d{3})\d*(\d{4})/;
      var str = phone.replace(pho, "$1****$2");
      return str;
    },
    // 获取意向用户数据
    async getIntentionUserData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryIntentionUserList",
        data: { currentPage: 1, pageSize: 10, pid: this.distUserId }
      });
      if (data.success) {
        if (data.data === null || data.data.length === 0) {
          this.isShowEmpty = true;
        } else {
          this.isShowEmpty = false;
          this.intentionUserData = [...data.data];
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    handleClickMore() {
      this.$router.push(`/addol/myintention?token=${this.token}&pid=${this.distUserId}`);
    },
    handleCopyContent(mobile) {
      this.$copyText(mobile)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch(() => {
          this.$toast("复制失败");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.user_container {
  padding: 5px 12px 12px;
  box-sizing: border-box;
  width: 100%;

  .user_mask {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(221, 221, 221, 0.5);
    border-radius: 4px;

    .user_mask_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 10px;
      padding-bottom: 3px;

      .user_mask_head_mask {
        flex: 1;
        width: 0;
        display: flex;
        justify-content: left;
        align-items: center;

        .user_mask_head_line {
          width: 5px;
          height: 14px;
          background: #f9550a;
          margin-right: 5px;
        }

        .user_mask_head_title {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #232323;
        }

        .user_mask_head_sub {
          padding: 0;
          margin: 0;
          font-size: 10px;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          color: #949494;
        }
      }

      .user_mask_head_more {
        padding: 0;
        margin: 0;
        font-size: 11px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f9550a;
      }
    }

    /deep/ .van-divider {
      margin: 3px 10px 10px 10px;
    }

    .empty_content {
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8C8C8C;
      line-height: 30px;

    }

    .user_mask_content {
      display: grid;
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
      grid-template-columns: calc((100% - 8px) / 2) calc((100% - 8px) / 2);
      column-gap: 8px;
      row-gap: 8px;

      .user_mask_content_list {
        height: 19px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: left;

        .list_avatar {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background-color: white;
          margin-left: 3px;
        }

        .list_name {
          padding: 0;
          margin: 0;
          margin-left: 3px;
          margin-right: 4px;
          font-size: 9px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6f6f6f;
          max-width: 50px;
        }

        .list_phone {
          padding: 0;
          margin: 0;
          margin-right: 3px;
          font-size: 9px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #6f6f6f;
        }

        .list_mark {
          box-sizing: border-box;
          height: 10px;
          background: #f4f4f4;
          padding: 0 1.5px 0 1.5px;
          margin: 0;
          font-size: 6px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #757575;
          line-height: 10px;
          text-align: center;
        }
      }
    }
  }
}
</style>
