<template>
  <div class="ck-wheel">
    <div class="card" v-for="(item, index) in list" :key="index" @click="goDeatil(item)">
      <div
        class="image"
        :style="{
          backgroundImage: `url(${item.banner})`
        }"
      >
        <div :class="['label', 'status-' + item.status]">
          {{ item.status === 1 ? "即将开始" : item.status === 2 ? "进行中" : "已结束" }}
        </div>
      </div>
      <div class="des">
        {{ item.name }}
      </div>
      <div class="str">
        活动时间：{{ moment(item.beginTime).format("YYYY.MM.DD HH:mm") }}～{{
          moment(item.endTime).format("YYYY.MM.DD HH:mm")
        }}
      </div>
      <div class="str">
        <div :class="[item.status === 2 ? '' : 'hide']">
          完成进度：本期已累计签到{{ item.currentCheckInNum }}天
        </div>

        <div class="goDetail">点击查看活动详情></div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import { formatDate } from "@/utils/time";
import { linkToPage } from "@/utils/link";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: "",
      itemId: "",
      token: this.$route.query.token,
      list: [],
      isWx: false
    };
  },
  created() {
    document.title = "kiddol圈活动";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.userId = this.$route.query.userId;
        this.getList();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }

          this.$bridge.call("getUserId", {}).then(id => {
            console.log("getUserId客户端返回: ", e);
            this.userId = id;
            this.getList();
          });
        });
      }
    });
  },

  methods: {
    formatDate,
    formatPrice,
    moment,
    goDeatil(item) {
      linkToPage(
        item.linkPage === 1 ? 0 : item.linkPage,
        item.linkPage === 1
          ? item.pageResource
          : `${item.pageResource}?id=${item.id}&token=${this.token}`
      );
      // this.$router.push(`/checkIn/detail?token=${this.token}&id=${item.id}`);
    },
    async getList() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/mediumCheckIn/pageList",
          data: {
            pageSize: 100,
            currentPage: 1
          }
        });
        if (data.success) {
          this.list = data.data;
        } else {
          this.$toast(data.message);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  .card {
    width: 345px;
    // height: 190px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 15px;

    .image {
      position: relative;
      width: 345px;
      height: 96px;
      background: #d2d2d2;
      border-radius: 4px 4px 0px 0px;
      background-size: 100% 100%;

      .label {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 51px;
        height: 19px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .status-1 {
        background: #fa6400;
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 14px;
      }
      .status-2 {
        background: #e02020;
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 14px;
      }
      .status-3 {
        background: #d8d8d8;
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
        line-height: 14px;
      }
    }

    .des {
      margin-top: 8px;
      margin-bottom: 6px;
      width: 324px;
      // height: 37px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      /* 作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
      -webkit-box-orient: vertical;
      /* 显示的行 */
      -webkit-line-clamp: 2;
    }

    .str {
      width: 324px;
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8e8e8e;
      line-height: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1px;

      .goDetail {
        margin-top: 1px;
        height: 14px;
        font-size: 9px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #1c1c1c;
        line-height: 14px;
        text-decoration: underline;
      }
    }
  }
}
.hide {
  visibility: hidden;
}
</style>
