<template>
  <div class="activity-kiddolRank-wrap">
    <div class="top-box"></div>
    <!-- 滚动区域 -->
    <div class="scroll-view">
      <div v-for="(item, index) in list" :key="index" class="tweet-li">
        <div class="tweet-li-left">
          <div class="user-info">
            <div
              :style="'background: url(' + item.headIcon + ') no-repeat center'"
              class="user-info-head"
            ></div>
            <div class="user-info-nice">
              <div class="user-info-name">{{ item.nickName }}</div>
              <div class="user-info-like">
                <span>点赞: </span>
                <span style="color: #c31b1b">{{ item.likeNumberValue }}</span>
              </div>
            </div>

            <!-- 排行图标 -->
            <img
              :style="{
                display: index === 0 ? 'block' : 'none',
                width: '20px',
                height: '19px',
                marginTop: '-8px',
                marginLeft: '-4px',
              }"
              class="rank-icon"
              src="https://kiddo-bucket.manqu88.com/kiddol-rank1.png"
              alt=""
            />
            <img
              :style="{
                display: index === 1 ? 'block' : 'none',
                width: '22px',
                height: '19px',
                marginTop: '-8px',
                marginLeft: '-7px',
              }"
              class="rank-icon"
              src="https://kiddo-bucket.manqu88.com/kiddol-rank2.png"
              alt=""
            />
            <img
              :style="{
                display: index === 2 ? 'block' : 'none',
                width: '20px',
                height: '18px',
                marginTop: '-8px',
                marginLeft: '-4px',
              }"
              class="rank-icon"
              src="https://kiddo-bucket.manqu88.com/kiddol-rank3.png"
              alt=""
            />
          </div>
          <div class="user-content">
            <div class="user-content-box">
              <div class="user-content-text">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div
          :style="'background: url(' + item.coverImg + ') no-repeat center'"
          class="tweet-li-right"
        ></div>
      </div>

      <!-- 空值 -->
      <div v-if="!loading && !list.length" class="empty-tweet-box">暂无推文数据～</div>
    </div>

    <!-- 底部图 -->
    <img
      class="bottom-bg"
      src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddol-rank-bottom-bg-1.png"
      alt=""
    />

    <!-- 活动规则 -->
    <img
      @click="handleToRule"
      class="activity-rule-entry"
      src="https://kiddo-bucket.manqu88.com/kiddol-rank-rule.png"
      alt=""
    />

    <!-- 活动推文 -->
    <img
      @click="handleToTweet"
      class="activity-rule-tweet"
      src="https://kiddo-bucket.manqu88.com/kiddol-rank-tweet.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: this.$route.query.token,
      isMini: true,
      list: [],
      loading: true,
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv((res) => {
      console.log("当前环境1：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.isMini = true;
      } else {
        this.isMini = false;
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
        });
      }
    });

    setTimeout(() => {
      document.title = "排行榜";
    }, 500);

    // 排行榜数据
    this.getRankData();
  },
  methods: {
    async getRankData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
        },
        url: "/api/shortMediumRank/queryMediumRankList",
        data: {
          type: 1,
        },
      });

      if (!data.success) {
        this.$toast(data.message);
        return;
      }

      if (data.data && data.data.length) {
        this.list = data.data.map((item) => {
          let coverImg = "";
          item.resourceDTOS.forEach((rItem) => {
            if (rItem.type === "image" && !coverImg) {
              coverImg = rItem.path;
            }
          });

          return {
            headIcon: item.userDTO.headPicUrl,
            nickName: item.userDTO.nickName,
            likeNumberValue: item.likeNumberValue,
            content: item.content,
            coverImg: coverImg,
          };
        });
      } else {
        this.list = [];
      }

      this.loading = false;
    },
    // 规则
    handleToRule() {
      this.$router.push({
        name: "kiddolRankRule",
      });
    },
    // 活动推文
    handleToTweet() {
      if (this.isMini) {
        // 小程序未登录
        // if(!this.token) {
        //  uni.webView.reLaunch({
        //    url: "/pagesA/login/index"
        //  });
        //  return
        // }

        uni.webView.navigateTo({
          url: `/kiddolPackage/topic/index?topic=“🐏没🐇气”迎新年`,
        });
      } else {
        this.$bridge.call("openPage", { type: 11, param: "“🐏没🐇气”迎新年" });
      }
    },
  },
};
</script>

<style lang="scss">
.activity-kiddolRank-wrap {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: url("https://kiddo-bucket.manqu88.com/kiddol-rank-top-bg-1.png") no-repeat
    top;
  background-size: contain;
  background-color: #b9011d;

  .activity-rule-entry {
    position: fixed;
    right: 0;
    top: 21px;
    width: 79px;
    height: 29px;
    &:active {
      opacity: 0.8;
    }
  }
  .activity-rule-tweet {
    position: fixed;
    right: 0;
    top: 56px;
    width: 78px;
    height: 29px;
    &:active {
      opacity: 0.8;
    }
  }
  .top-box {
    height: 407px;
  }
  .scroll-view {
    margin: auto;
    width: 375px;
    min-height: 200px;
    padding-top: 6px;
    padding-bottom: 4px;
    background: url("https://kiddo-bucket.manqu88.com/kiddol-rank-repeat-bg-1.png")
      repeat-y top;
    background-size: contain;
    .tweet-li {
      display: flex;
      width: 334px;
      height: 125px;
      padding: 9px 3px 10px 10px;
      margin: auto;
      margin-bottom: 15px;
      background-color: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #c31b1b;
      &:last-child {
        margin-bottom: 0;
      }
      .tweet-li-left {
        width: 195px;
        .user-info {
          display: flex;
          position: relative;
          .user-info-head {
            flex-shrink: 0;
            width: 47px;
            height: 47px;
            margin-right: 9px;
            margin-left: 2px;
            border-radius: 50%;
            background-size: cover !important;
            background-color: #dddddd;
          }
          .rank-icon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }

          .user-info-nice {
            .user-info-name {
              max-width: 130px;
              margin-top: 4px;
              margin-bottom: 3px;
              font-size: 15px;
              font-weight: 400;
              color: #222222;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
            }
            .user-info-like {
              font-size: 11px;
              font-weight: 400;
              color: #222222;
            }
          }
        }

        .user-content {
          width: 195px;
          height: 60px;
          padding: 20px 7px 0 6px;
          box-sizing: border-box;
          background: url("https://kiddo-bucket.manqu88.com/kiddol-content-bg.png")
            no-repeat center;
          background-size: cover;
          .user-content-box {
            height: 28.8px;
            overflow: hidden;
          }
          .user-content-text {
            width: 120%;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            white-space: pre-wrap;
            color: #222222;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
            transform: scale(0.8);
            transform-origin: left top;
          }
        }
      }
      .tweet-li-right {
        flex-shrink: 0;
        width: 122px;
        height: 106px;
        margin-left: 4px;
        border-radius: 6px;
        background-size: cover !important;
      }
    }
  }

  .bottom-bg {
    display: block;
    width: 100%;
    margin-top: -2px;
  }

  .empty-tweet-box {
    padding-top: 80px;
    text-align: center;
    font-size: 14px;
  }
}
</style>
