<template>
    <div class="">
      <div :class="['ck-wheel', 'rules-1']"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Pay",
    components: {},
    created() {
      this.type = Number(this.$route.query.type);
      document.title = "操作指南";
    },
    data() {
      return {
      };
    },
    methods: {}
  };
  </script>
  <style lang="scss" scoped>
  .ck-wheel {
    width: 100%;
    background-color: #f5f5f5;
    box-sizing: border-box;
    text-align: left;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rules-1 {
    width: 375px;
    height: 2069px;
    background: url(https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/operation-guide.png?111) no-repeat;
    background-size: 100% 100%;
  }
  </style>
  