<template>
    <div class="main">
        <div class="img-content">
            <div class="item" @click="uploadFirstImg">
                <img class="img-content-img" :src="groupInfoUrl == '' ? defaultImgArray[0] : groupInfoUrl"/>
                <img class="img-content-btn" src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-upload-btn.png"/>
            </div>
            <div class="item" @click="uploadSecondImg">
                <img class="img-content-img" :src="groupCreateTimeUrl == '' ? defaultImgArray[1] : groupCreateTimeUrl"/>
                <img class="img-content-btn" src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-upload-btn.png"/>
            </div>
        </div>
        <div class="guide" @click="clickGuide">查看操作指南</div>
        <img class="code-img" :src="qrCode" />
        <img @click="clickSaveQrCode" class="save-code-img"
            src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-save.png" />
        <van-field
        class="wx-input input-label"
        v-model="wxCode"
        maxlength="100"
        :border="false"
        />
        <!-- <u-input class="wx-input input-label" v-model="wxCode" type="text" :border="false" :clearable="false"
            placeholder=" " /> -->
        <van-field
        class="mobilde-input input-label"
        v-model="mobile"
        type="tel"
        maxlength="11"
        :border="false"
        />
        <!-- <u-input class="mobilde-input input-label" v-model="mobile" maxlength="11" type="number" :border="false"
            :clearable="false" placeholder=" " /> -->
        <div class="bottom">
            <img class="btn" @click="clickSubmit"
                src="https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-submit.png" />
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            token: this.$route.query.token,
            defaultImgArray: ['https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-place1.png', 
                            'https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-place2.png'],
            qrCode: 'https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/shouxifuchiweixinhao.png',
            groupInfoUrl: '',
            groupCreateTimeUrl: '',
            wxCode: '',
            mobile: '',
        };
    },
    created() {
        document.title = "填写资料";
    },
    mounted() {
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                } else {
                    this.wxCode = localStorage.getItem("recruit_wrtWx");
                    this.mobile = localStorage.getItem("recruit_wrtPh");
                    this.groupInfoUrl = localStorage.getItem("recruit_groupInfoUrl");
                    this.groupCreateTimeUrl = localStorage.getItem("recruit_groupCreateTimeUrl");

                    localStorage.removeItem('recruit_wrtWx');
                    localStorage.removeItem('recruit_wrtPh');
                    localStorage.removeItem('recruit_groupInfoUrl');
                    localStorage.removeItem('recruit_groupCreateTimeUrl');

                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        this.canClick = true;
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                    });
                }
            });
        }, 1000);
        // setInterval
    },
    methods: {
        clickGuide() {
            // console.log('----点击查看操作指南');
            localStorage.setItem("recruit_wrtWx", this.wxCode);
            localStorage.setItem("recruit_wrtPh", this.mobile);
            localStorage.setItem('recruit_groupInfoUrl', this.groupInfoUrl);
            localStorage.setItem('recruit_groupCreateTimeUrl', this.groupCreateTimeUrl);

            this.$router.push("/recruit/guide?timeStamp=" + Date.now());
        },
        uploadFirstImg() {
            console.log('----czm---上传示例图1=');
            // this.groupInfoUrl = e.url;
            this.$bridge.call("uploadFirstImg", {}).then((e) => {
                console.log("jumpToDetail客户端返回: ", e);
                // this.groupInfoUrl = e;
                if (Boolean(e)) {
                    this.groupInfoUrl = e;
                }
            });
        },
        uploadSecondImg() {
            console.log('----czm---上传示例图2=');
            // this.groupCreateTimeUrl = e.url;
            this.$bridge.call("uploadSecondImg", {}).then((e) => {
                console.log("jumpToDetail客户端返回: ", e);
                if (Boolean(e)) {
                    this.groupCreateTimeUrl = e;
                }
            });
        },
        clickSaveQrCode() {
            console.log('----保存图片到本地');
            this.$bridge.call("saveQrCode", {url: this.qrCode}).then((e) => {
                console.log("jumpToDetail客户端返回: ", e);
                this.groupCreateTimeUrl = e;
            });
        },
        async clickSubmit() {
            console.log('----点击提交');
            if (!this.groupInfoUrl || !this.groupCreateTimeUrl) {
                this.$toast('请先上传社群截图');
                return;
            }
            if (!this.wxCode) {
                this.$toast('请先填写微信号');
                return;
            }
            if (!this.mobile) {
                this.$toast('请先填写手机号');
                return;
            }
            const reg = new RegExp(/^1\d{10}$/);
            if (!reg.test(this.mobile)) {
                this.$toast('请填写正确的手机号');
                return;
            }
            const {
                data: { data, success, message, code },
            } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token,
                },
                url: "/api/taskProofSubmit/submit",
                data: {
                    taskId: 1,
                    taskProofDTO: {
                        groupInfoUrl: this.groupInfoUrl,
                        groupCreateTimeUrl: this.groupCreateTimeUrl,
                    },
                    taskUserInfoDTO: {
                        mobile: this.mobile,
                        wechatNo: this.wxCode,
                    }

                },
            });
            if (success) {
                this.$toast("提交成功");
                setTimeout(() => {
                    window.history.back();
                }, 800);
            } else {
                this.$toast(message);
            }
        },

    },

}
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 1304px;
    background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/22yearend/write-bg.png") no-repeat;
    background-size: cover !important;
}

.img-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 342px;
    width: 295px;
    height: 237px;
    .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .img-content-img {
        width: 137px;
        height: 204px;
        overflow: hidden;
    }
    .img-content-btn {
        width: 137px;
        height: 26px;
    }
}

.guide {
    align-self: flex-start;
    margin-left: 63px;
    margin-top: 15px;
    height: 16px;
    line-height: 13px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #B80A04;
    border-bottom: 1px solid #B80A04;
}

.code-img {
    margin-top: 95px;
    width: 161px;
    height: 161px;
}

.save-code-img {
    margin-top: 6px;
    width: 155px;
    height: 27px;
}

.input-label {
    font-size: 30rpx;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #131313;
    padding: 0;
    padding-left: 2px;
    width: 286px;
    height: 33px;
    line-height: 33px;
    background-color: rgba(0, 0, 0, 0);
}

.wx-input {
    margin-top: 88px;
}

.mobilde-input {
    margin-top: 98px;
}

.bottom {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding-bottom: 10px;
    padding-bottom: calc(10 + constant(safe-area-inset-bottom));
    padding-bottom: calc(10 + env(safe-area-inset-bottom));
    background-color: #ffffff;

    .btn {
        width: 375px;
        height: 51px;
    }
}
</style>