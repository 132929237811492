<template>
  <div class="wrap">
    <div class="card">
      <div class="title">前期奖励发放：</div>
      <div class="detail">
        2021年9月、10月、11月团队奖励将会在2021年12月13日24:00
        进行汇总审核结算，累计的收益会合并计入11
        月奖励中发放，后续每月团队奖励会在次月13日24:00进行审核结算；
      </div>

      <div class="title">后期奖励发放：</div>
      <div class="detail">每月团队奖励会在次月13日24:00进行审核结算；</div>

      <div class="title">数据更新时间：</div>
      <div class="detail">列表统计中数据每天8点更新上一日24点前数据；</div>

      <div class="title">奖励发放范围：</div>
      <div class="detail">奖励发放范围为每月统计周期截止时的KA用户；</div>

      <div class="title">订单统计范围：</div>
      <div class="detail">每统计月周期内创建的订单中在次月13日24:00前确认收货部分；</div>

      <div class="title">奖励对应团队销售额：</div>
      <div class="detail">奖励销售额统计为已完成订单扣除退款部分的商品实付金额（不包含运费）；</div>

      <div class="title">统计数据说明：</div>
      <div class="detail">
        ● 预计直属社群总额为根据当前用户及其直属社群已支付订单估算的商品实付总金额；
      </div>
      <div class="detail">
        ● 预计团队总额为根据当前用户及其所有下级用户已支付订单估算的商品实付总金额；
      </div>
      <div class="detail">
        ●
        已确认直属社群总额为根据当前用户及其直属社群已完成订单核算的商品实付总金额；
      </div>
      <div class="detail">
        ●
        已确认团队总额为根据当前用户及其所有下级用户已完成订单核算的商品实付总金额；
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { formatPrice } from "../utils/money";

export default {
  name: "Pay",
  components: {},
  data() {
    return {};
  },
  created() {
    document.title = "奖励说明";
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 12px;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 0px auto;
  width: 355px;
  height: auto;
  background: #ffffff;
}
.title {
  margin: 14px auto;
  margin-bottom: -9px;
  width: 326px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 21px;
}
.detail {
  margin: 14px auto;
  width: 326px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
</style>
