<template>
  <div class="">
    <div :class="['ck-wheel']">
      <div :class="[type === 7 ? 'rules-2' : 'rules-1']"></div>
      <div class="logo"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "活动规则";
  },
  data() {
    return {
      token: this.$route.query.token,
      type: 7
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  padding-top: 24px;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/bg.png) no-repeat;
  background-size: 100% 100%;
  .rules-1 {
    width: 355px;
    height: 541px;
    display: flex;
    align-items: center;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/luckyRules.png?1) no-repeat;
    background-size: 100% 100%;
  }
  .rules-2 {
    width: 355px;
    height: 465px;
    display: flex;
    align-items: center;
    background: url(https://oss-kiddo.manqu88.com/h5/202311turnTable/superRules.png?2) no-repeat;
    background-size: 100% 100%;
  }

  .logo {
    margin-top: 69px;
    margin-bottom: 37px;
    width: 129px;
    height: 52px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/logo.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 42px;
  }
}
</style>
