<template>
  <div class="ck-wheel">
    <div class="bg-1">
      <div class="rule-btn" @click="goRule"></div>
      <div class="item-view">
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 1 ? 'item-1-selected' : 'item-1']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 2 ? 'item-2-selected' : 'item-2']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 3 ? 'item-3-selected' : 'item-3']"
        ></div>
      </div>

      <!-- 进度条 -->
      <div class="progress">
        <div
          class="progress-in"
          v-if="datas.prizeIndex"
          :style="{
            width: `${datas.prizeIndex === 1 ? 15 : datas.prizeIndex === 2 ? 50 : 85}%`
          }"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 1 ? 'dot-1-selected' : 'dot-1']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 2 ? 'dot-2-selected' : 'dot-2']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 3 ? 'dot-3-selected' : 'dot-3']"
        ></div>
      </div>

      <!-- 消费、获奖 -->
      <div class="money">¥{{ formatPrice(datas.consumeAmount) }}</div>
      <div class="prize">
        <div v-if="datas.prizeList.length > 0">
          <div class="prize-item" v-for="(item, index) in datas.prizeList" :key="index">
            {{ item.prizeName }}
            <div class="prize-num">{{ item.count }}</div>
            {{ item.unit }}
          </div>
        </div>
        <div v-if="datas.prizeList.length === 0">
          <div class="prize-item">
            无
          </div>
        </div>
      </div>

      <!-- 填写地址 -->
      <div
        :class="[hasAddress ? 'btn-2' : 'btn']"
        @click="showModal = true"
        v-if="datas.prizeList.length > 0"
      ></div>
    </div>
    <div class="bg-2">
      <div class="click-1" @click="jump(2755)"></div>
      <div class="click-2" @click="jump(2756)"></div>
      <div class="click-3" @click="jump(2754)"></div>
    </div>
    <div class="bg-3">
      <div class="click-4" @click="jump(2757)"></div>
    </div>

    <div class="popup" v-show="showModal">
      <div class="mask"></div>
      <div class="modal">
        <img
          class="close"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/icon-close.png"
          @click="close"
        />
        <div class="title">填写收货信息</div>
        <div class="border"></div>
        <div class="form">
          <div class="label">收货人姓名</div>
          <van-field
            v-model="receiverName"
            placeholder="请输入收货人姓名"
            maxlength="16"
            :border="false"
          />
          <div class="label">收货人手机号</div>
          <van-field
            v-model="receiverPhone"
            type="tel"
            placeholder="请输入收货人手机号"
            maxlength="11"
            :border="false"
          />
          <div class="label">收货地址</div>
          <van-field
            v-model="address"
            readonly
            placeholder="请选择省-市-区"
            :border="false"
            @click="onShowArea"
          />
          <van-field
            class="detail"
            v-model="detail"
            rows="2"
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="100"
            :border="false"
            @click="handleScrollNormal('detail')"
          />
          <div class="save" @click="onSave">保存</div>
        </div>
      </div>
    </div>
    <div v-show="showArea" class="mask" @click="cancel"></div>
    <van-area
      class="area"
      :class="{ showArea: showArea }"
      title="标题"
      :area-list="areaList"
      @confirm="confirm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { formatPrice } from "@/utils/money";
import { Toast } from "vant";

export default {
  name: "assist",
  components: {},
  data() {
    return {
      showBack: false,
      datas: {
        consumeAmount: 0,
        prizeList: [],
        prizeIndex: 0
      },
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      inviteCode: "",
      leftNum: 0,
      url: "https://a.app.qq.com/o/simple.jsp?pkgname=com.mq.kiddo.mall",
      hasAddress: false,
      showModal: false,
      areaList,
      showArea: false,
      receiverName: "",
      receiverPhone: "",
      address: "",
      provinceName: "",
      cityName: "",
      areaName: "",
      detail: "",
      addressId: ""
    };
  },
  created() {
    this.showBack = this.$route.query.showBack ? true : false;
    document.title = "Kiddol周年庆";
  },
  async mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
        this.getAddressInfo();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
          this.getAddressInfo();
        });
      }
    });
  },

  methods: {
    formatPrice,
    goBack() {
      this.$router.go(-1);
    },
    goRule() {
      console.log("document.body.scrollHeight", document.body.scrollHeight);
      window.scrollTo(0, document.body.scrollHeight - 880);
    },
    jump(id) {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pages/index/sub-page?id=${id}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 0,
              param: id
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/anniversary/queryProgress",
        data: {}
      });
      if (data.success) {
        this.datas = data.data && data.data;
      } else {
        this.$toast(data.message);
      }
    },

    async share() {
      let inviteCode;
      let levelUpCode;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/distGrouponLeader/getInviteCode",
        data: {}
      });
      if (data.success) {
        levelUpCode = data.data.token;
        inviteCode = data.data.userCode;
      } else {
        this.$toast(data.message);
        return;
      }

      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/superGroupPackage/gruopLeaderLevelUp/share?inviteCode=${inviteCode}&levelUpCode=${levelUpCode}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 29,
              param: JSON.stringify({
                inviteCode: inviteCode,
                levelUpCode: levelUpCode
              })
            })
            .then(e => {
              console.log("openPage29客户端返回: ", e);
            });
        }
      });
    },

    async getAddressInfo() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "api/reward/getUserAddress",
        data: {
          type: this.type
        }
      });
      if (data.success) {
        if (data.data) {
          this.hasAddress = true;
          const datas = data.data;
          this.addressId = datas.id;
          this.address = `${datas.provinceName}-${datas.cityName}-${datas.areaName}`;
          this.provinceName = datas.provinceName;
          this.cityName = datas.cityName;
          this.areaName = datas.areaName;
          this.receiverName = datas.receiverName;
          this.receiverPhone = datas.receiverPhone;
          this.detail = datas.detail;
        } else {
          this.hasAddress = false;
        }
      } else {
        this.$toast(data.message);
      }
    },
    onShowArea() {
      this.showArea = true;
    },
    confirm(value) {
      this.showArea = false;
      console.log(value);
      this.address = `${value[0].name}-${value[1].name}-${value[2].name}`;
      this.provinceName = value[0].name;
      this.cityName = value[1].name;
      this.areaName = value[2].name;
    },
    cancel() {
      this.showArea = false;
    },
    onSave() {
      const {
        addressId,
        receiverName,
        receiverPhone,
        provinceName,
        cityName,
        areaName,
        detail,
        address
      } = this;

      if (!receiverName) {
        Toast("请填写收货人姓名");
        return;
      }
      if (!receiverPhone) {
        Toast("请填写收货人手机号");
        return;
      }
      const reg = new RegExp(/^1\d{10}$/);
      if (!reg.test(receiverPhone)) {
        Toast("请填写正确的手机号");
        return;
      }
      if (!address) {
        Toast("请选择地区");
        return;
      }
      if (!detail) {
        Toast("请填写详细地址");
        return;
      }

      this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: this.hasAddress ? "api/reward/updateUserAddress" : "/api/reward/addUserAddress",
        data: {
          id: addressId,
          receiverName,
          receiverPhone,
          provinceName,
          cityName,
          areaName,
          detail
        }
      })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            Toast("保存成功！");
            this.showModal = false;
            this.hasAddress = true;
            if (this.addressId == "") {
              this.getAddressInfo();
            }
          } else {
            Toast(data.message);
          }
        })
        .catch(err => {
          console.log(err);
          Toast("保存失败，请重试！");
        });
    },
    handleScrollNormal(param) {
      setTimeout(() => {
        document.getElementsByClassName(param)[0].scrollIntoViewIfNeeded();
      }, 500);
    },
    close() {
      this.showModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg-1 {
    width: 375px;
    height: 1158px;
    background: url(https://oss-kiddo.manqu88.com/h5/anniversary/bg_1.png?11) no-repeat;
    background-size: 100% 100%;
    position: relative;

    .rule-btn {
      position: absolute;
      right: 8px;
      top: 88px;
      width: 93px;
      height: 29px;
      background: url(https://oss-kiddo.manqu88.com/h5/anniversary/ruleBtn.png) no-repeat;
      background-size: 100% 100%;
    }

    .item-view {
      position: absolute;
      top: 610px;
      left: 18px;
      width: 335px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .item-1 {
        width: 99px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_1.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-1-selected {
        width: 100px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_1_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .item-2 {
        width: 111px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_2.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-2-selected {
        width: 105px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_2_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .item-3 {
        width: 111px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_3.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-3-selected {
        width: 112px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/item_3_selected.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .progress {
      position: absolute;
      left: 24px;
      top: 751px;
      width: 326px;
      height: 6px;
      border-radius: 13px;
      border: 1px #e86c11 solid;
      display: flex;
      align-items: center;

      .progress-in {
        position: absolute;
        left: 0;
        background: #e86c11;
        border-radius: 13px;
        width: 326px;
        height: 6px;
      }

      .dot-1 {
        position: absolute;
        left: 10%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-1-selected {
        position: absolute;
        left: 10%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .dot-2 {
        position: absolute;
        left: 45%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-2-selected {
        position: absolute;
        left: 45%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .dot-3 {
        position: absolute;
        left: 80%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-3-selected {
        position: absolute;
        left: 80%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .btn {
      position: absolute;
      left: 123px;
      top: 1006px;
      width: 130px;
      height: 31px;
      background: url(https://oss-kiddo.manqu88.com/h5/anniversary/btn.png) no-repeat;
      background-size: 100% 100%;
    }

    .btn-2 {
      position: absolute;
      left: 123px;
      top: 1006px;
      width: 130px;
      height: 31px;
      background: url(https://oss-kiddo.manqu88.com/h5/anniversary/btn_2.png) no-repeat;
      background-size: 100% 100%;
    }

    .money {
      position: absolute;
      left: 159px;
      top: 926px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #eb6d11;
      line-height: 18px;
      text-decoration: underline;
    }
    .prize {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 159px;
      top: 954px;

      .prize-item {
        height: 13px;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 19px;
        display: flex;
        margin-bottom: 5px;

        .prize-num {
          font-size: 16px;
          color: #ff6300;
        }
      }
    }
  }
  .bg-2 {
    width: 375px;
    height: 913px;
    background: url(https://oss-kiddo.manqu88.com/h5/anniversary/bg_2.png?) no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;

    .click-1 {
      position: absolute;
      top: 195px;
      left: 25px;
      //background: #e86c11;
      background-size: 100% 100%;
      width: 325px;
      height: 170px;
    }

    .click-2 {
      position: absolute;
      top: 387px;
      left: 25px;
      //background: #e86c11;
      background-size: 100% 100%;
      width: 325px;
      height: 170px;
    }

    .click-3 {
      position: absolute;
      bottom: 30px;
      left: 25px;
      //background: #e86c11;
      background-size: 100% 100%;
      width: 325px;
      height: 170px;
    }
  }
  .bg-3 {
    width: 375px;
    height: 1051px;
    background: url(https://oss-kiddo.manqu88.com/h5/anniversary/bg_3.png?) no-repeat;
    background-size: 100% 100%;
    position: relative;

    .click-4 {
      position: absolute;
      top: 80px;
      left: 25px;
      //background: #e86c11;
      background-size: 100% 100%;
      width: 325px;
      height: 170px;
    }
  }
}
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    padding-top: 42px;
    box-sizing: border-box;
    width: 340px;
    height: 458px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: left;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: -30px;
    }

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      line-height: 25px;
      letter-spacing: 1px;
    }

    .border {
      margin: 2px auto 0;
      width: 133px;
      height: 3px;
      background: #f9e46e;
    }

    .form {
      padding: 0 55px;
      box-sizing: border-box;

      .label {
        margin-top: 15px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        line-height: 18px;
      }

      input {
        margin-top: 7px;
      }

      .save {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        height: 39px;
        background: #f9550a;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
}
.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}
</style>
