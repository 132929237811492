<template>
  <div class="">
    <div :class="['ck-wheel', type === 2 ? 'background-2' : 'background-1']">
      <!-- 通知栏 -->
      <!-- <div
        class="msgView"
        :style="{
          'margin-top': type === 2 ? '342px' : '331px',
        }"
      >
        <van-notice-bar class="notice" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :duration="1000"
            :show-indicators="false"
            :touchable="false"
          >
            <van-swipe-item
              v-for="(item, index) in prizeTurnTableDTO"
              :key="index"
              >{{ item }}</van-swipe-item
            >
          </van-swipe>
        </van-notice-bar>
      </div> -->

      <!-- 转盘 -->
      <div class="wheel">
        <canvas
          class="item"
          ref="wheelCanvas"
          id="wheelCanvas"
          width="1400px"
          height="1400px"
        ></canvas>
        <img
          class="pointer"
          :src="'https://oss-kiddo.manqu88.com/h5/drawBtn.png?' + Date.now()"
          @click="onClick"
        />
        <div class="pointer" @click="onClick">
          <div class="drawtitle">立即抽奖</div>
          <div class="needScore">({{ lotteryTimesData.lotteryConsumeScore }}积分)</div>
        </div>
      </div>

      <!-- 剩余积分 已抽次数 -->
      <div class="leftScoreTitle">剩余积分</div>
      <div class="leftScore">{{ lotteryTimesData.score }}</div>

      <div class="leftNumTitle">
        今天已抽次数
        <div class="leftNum">{{ lotteryTimesData.timesPlayedToday }}</div>
        次
      </div>

      <!-- 规则 -->
      <div class="label" style="top: 76px" @click="goPage(0)">活动规则</div>
      <!-- 记录 -->
      <div class="label" style="top: 120px" @click="goPage(1)">中奖记录</div>

      <!-- 剩余次数 -->
      <!-- <div class="leftCounts">
        <span class="leftTitle">剩余抽奖次数：</span>
        <span class="counts">{{ useNum }}</span>
      </div> -->

      <!-- tips -->
      <!-- <div v-if="type == 1" class="tipsView-1">
        <div class="btn-goto-1" @click="goTo"></div>
      </div>
      <div v-else class="tipsView-2">
        <div class="leftMoneyTitle">
          距离下次抽奖机会还差：
          <span class="leftMoney">{{
            formatPrice(thresholdAmount - scheduleAmount)
          }}</span>
          元
        </div>

        <div class="progressBg">
          <div class="progess-left"></div>
          <div class="progress" :style="{ width: progressWidth + 'px' }"></div>
          <div class="progess-right"></div>
        </div>

        <div class="showMoney">
          <div>
            已累计
            <span class="span-money-1">{{ formatPrice(scheduleAmount) }}</span>
            元
          </div>
          <div>
            <span class="span-money-2">{{ formatPrice(thresholdAmount) }}</span>
            元
          </div>
        </div>

        <div class="allCounts">
          <div class="leftView">
            当前累计实付金额：
            <span class="span-money-2">{{ formatPrice(totalAmount) }}</span>
            元
          </div>

          <div class="rightView">
            已抽奖
            <span class="span-times">{{ totalUseNum }}</span>
            次
          </div>
        </div>
      </div> -->

      <!-- 更多抽奖 -->
      <!-- <div class="getMore" @click="getMoreChance"></div> -->

      <!-- 规则 -->
      <!-- <div
        v-if="type == 1"
        class="rule-1"
        :style="{
          backgroundImage: `url(${
            type == 1
              ? 'https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_1.png?' +
                Date.now()
              : 'https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_2.png?' +
                Date.now()
          })`,
        }"
      ></div>
      <div
        v-else
        class="rule-2"
        :style="{
          backgroundImage: `url(${
            type == 1
              ? 'https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_1.png?' +
                Date.now()
              : 'https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_2.png?' +
                Date.now()
          })`,
        }"
      ></div> -->

      <!-- 中奖弹窗 -->
      <div v-if="showModal" class="mask" @click="stopClick">
        <div
          class="bgGet"
          :style="{
            backgroundImage: `url(${'https://oss-kiddo.manqu88.com/h5/getPrizeBg.png?' +
              Date.now()})`
          }"
        >
          <div v-if="prizeType !== 3" class="prizeName">{{ curPrizeName }}</div>

          <div
            v-if="
              prizeType == 1 &&
                drwaData.extension &&
                drwaData.extension.USER_RULE &&
                drwaData.extension.USER_RULE === 1
            "
            class="coupon"
          >
            <div class="couponPrice">{{ curPrizeName.split("元")[0] }}</div>
            <div class="yuan">元</div>
          </div>

          <div
            v-if="
              prizeType == 2 ||
                (drwaData.extension &&
                  drwaData.extension.USER_RULE &&
                  drwaData.extension.USER_RULE === 2)
            "
            class="goods"
            :style="{
              backgroundImage: `url(${curItemUrl})`
            }"
          ></div>
          <div v-if="prizeType == 3" class="scoreBg">
            <div class="score">{{ curPrizeName }}</div>
          </div>

          <div
            class="toRecord"
            :style="{ left: prizeType == 2 ? '67px' : 'unset' }"
            @click="goPage(1, prizeType)"
          ></div>

          <div class="toAddress" v-if="prizeType == 2" @click="toAddress(prizeRecordId)"></div>
          <div class="btnClose" @click="showModal = false"></div>
        </div>
      </div>

      <!-- 失败弹窗 -->
      <!-- <div v-if="showFail" class="mask" @click="stopClick">
        <div
          class="bgGet"
          :style="{
            backgroundImage: `url(${failBg[failType]})`
          }"
        >
          <div class="toMain" @click="jumpToMain"></div>
          <div class="btnClose" @click="showFail = false"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "@/utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      lotteryTimesData: {
        score: 0,
        timesPlayedToday: 0,
        lotteryConsumeScore: 50
      },
      prizeType: 2,
      curItemUrl: "",
      curPrizeName: "",
      drwaData: {},
      token: this.$route.query.token,
      type: 1,
      progressWidth: 0,
      showModal: false,
      showFail: false,
      failType: 0,
      failBg: [
        "https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/fail_0.png?" + Date.now(),
        "https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/fail_1.png?" + Date.now(),
        "https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/fail_2.png?" + Date.now()
      ],
      prizeTurnTableDTO: [],
      useNum: 0, //剩余抽奖次数
      totalUseNum: 0, //累计抽奖次数
      totalAmount: 0, //类型：Number  必有字段  备注：累计消费金额
      thresholdAmount: 0, //类型：Number  必有字段  备注：门槛金额
      scheduleAmount: 0, //类型：String  必有字段  备注：进度金额
      canClick: true,
      img_bg: "",
      turnTime: 30,
      turnAngle: 15,
      turnLoop: 4,
      curIntervalId: 0,
      stopIndex: 1,
      prizeRecordId: 0,
      rewardList: [
        //逆时针
        {
          "18.8元无门槛消费券": { index: 1 },
          橙童文化四大名著思维导图折页: { index: 2 },
          "20积分": { index: 3 },
          "2.88元无门槛消费券": { index: 4 },
          "10积分": { index: 5 },
          单车小子奶酪夹心海苔片: { index: 6 },
          "5.88元无门槛消费券": { index: 7 },
          乐乐兄弟微小颗粒积木玩具: { index: 8 }
        }
      ]
    };
  },
  created() {
    this.type = Number(this.$route.query.type);
    document.title = "Kiddol圈积分大转盘";

    if (this.type == 3) {
      this.$router.replace(
        "/scoreTurntableRecord" + "?token=" + this.token + "&type=2" + "&timeStamp=" + Date.now()
      );
      return;
    }

    // if (this.type == 1 || this.type == 2) {
    //   this.$forceUpdate();
    // }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.drawWheelCanvas();

    // this.getTurnTableDate(); //test
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          this.token = this.$route.query.token;
          this.getTurnTableDate();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getTurnTableDate();
          });
        }
      });
    }, 1000);
  },

  methods: {
    formatPrice,
    async getDrawCounts() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/lottery/queryLotteryTimes",
          data: {
            type: 5
          }
        });
        if (data.success) {
          this.lotteryTimesData = data.data;
        } else {
          this.$toast(data.message);
        }
      }
    },
    async getTurnTableDate() {
      //获取抽奖次数
      this.getDrawCounts();

      // //累计金额
      // if (this.type == 2) {
      //   const { data } = await this.$axios({
      //     method: "post",
      //     headers: {
      //       mac: "mac",
      //       clientSource: this.$clientSource(),
      //       Authorization: this.token
      //     },
      //     url: "/api/dist/data/queryActivityAmount",
      //     data: {}
      //   });
      //   if (data.success) {
      //     this.totalAmount = data.data.totalAmount;
      //     this.thresholdAmount = data.data.thresholdAmount;
      //     this.scheduleAmount = data.data.scheduleAmount;
      //     this.progressWidth = (this.scheduleAmount / this.thresholdAmount) * 312;
      //     console.log(data);
      //   } else {
      //     this.$toast(data.message);
      //   }
      // }
      // //中奖记录播报
      // if (true) {
      //   const { data } = await this.$axios({
      //     method: "post",
      //     headers: {
      //       mac: "mac",
      //       clientSource: this.$clientSource(),
      //       Authorization: this.token
      //     },
      //     url: "/api/prizeRecord/queryLimit",
      //     data: {
      //       type: this.type
      //     }
      //   });
      //   if (data.success) {
      //     this.prizeTurnTableDTO = [];
      //     if (data.data && data.data.length > 0) {
      //       data.data.map((item, index) => {
      //         this.prizeTurnTableDTO.push(
      //           `恭喜${this.noPassByName(
      //             item.extension.userName ? item.extension.userName : ""
      //           )}获得${item.turntableDTO.prizeName}`
      //         );
      //       });
      //     }
      //   } else {
      //     this.$toast(data.message);
      //   }
      // }
    },
    stopClick() {},
    noPassByName(str) {
      if (null != str && str != undefined) {
        if (str.length <= 3) {
          return "*" + str.substring(1, str.length);
        } else if (str.length > 3 && str.length <= 6) {
          return "**" + str.substring(2, str.length);
        } else if (str.length > 6) {
          return str.substring(0, 2) + "****" + str.substring(6, str.length);
        }
      } else {
        return "";
      }
    },

    hasToken() {
      // if (!this.$route.query.token || this.$route.query.token == "undefined") {
      //   this.$router.push("/downloadApp?timeStamp=" + Date.now());
      //   return false;
      // }
      if (!this.canClick) return false;
      clearInterval(this.curIntervalId);
      return true;
    },
    toAddress(id) {
      this.$router.push(`/turnTableAddress?id=${id}&token=${this.token}&timeStamp=${Date.now()}`);
    },
    goPage(index, prizeType = null) {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      var path = ["/scoreTurntableRules", "/scoreTurntableRecord"];
      this.$router.push(
        path[index] +
          "?token=" +
          this.token +
          "&type=" +
          this.type +
          "&prizeType=" +
          prizeType +
          "&timeStamp=" +
          Date.now()
      );
    },
    goTo() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.$router.push(
        "/turnTable2?token=" + this.token + "&type=2" + "&timeStamp=" + Date.now(),
        () => {
          console.log(window.location.href);
        }
      );
    },
    getMoreChance() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.jumpToMain();
    },
    jumpToMain() {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    },

    // 抽取按钮按钮点击触发事件
    async onClick() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.canClick = false;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/luckyDraw",
        data: {
          type: 5
        }
      });

      if (data.success) {
        this.resetTable();
        this.drwaData = data.data;
        // this.priceValue = data.data.extension.priceValue;
        this.turnAngle = 15;
        this.turnLoop = 3;
        this.prizeRecordId = data.data.prizeRecordId;
        this.prizeType = data.data.prizeType;
        this.curPrizeName = data.data.prizeName;
        this.curItemUrl = "";
        if (this.prizeType == 2) {
          this.curItemUrl = data.data.extension.ITEM_URL;
        } else if (this.drwaData.extension && this.drwaData.extension.COUPON_ITEM_URL) {
          this.curItemUrl = this.drwaData.extension.COUPON_ITEM_URL;
        }
        setTimeout(() => {
          // this.stopIndex = 1;
          this.stopIndex = this.rewardList[0][data.data.prizeName].index;
          this.action();
        }, 10);
      } else {
        this.getDrawCounts();
        this.canClick = true;
        if (data.code == 20000504) {
          //当日抽奖次数已用尽
          this.showFail = true;
          this.failType = 0;
        } else if (data.code == 20000507) {
          //无抽奖次数
          this.showFail = true;
          this.failType = this.type;
        } else {
          this.$toast(data.message);
        }
        return;
      }
    },
    resetTable() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      ctx.rotate(-(45 * (this.stopIndex - 1) * Math.PI) / 180);
      ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
    },
    action() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度

      var allAngle = 0;
      this.curIntervalId = setInterval(() => {
        ctx.rotate((this.turnAngle * Math.PI) / 180);
        ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
        allAngle += this.turnAngle;
        if (allAngle == (this.turnLoop * 360 == 0 ? 360 : this.turnLoop * 360)) {
          clearInterval(this.curIntervalId);
          this.turnAngle -= 5;
          if (this.turnAngle > 5) {
            this.turnLoop = 2;
            this.action();
          } else if (this.turnAngle == 5) {
            this.turnLoop = (45 * (this.stopIndex - 1)) / 360;

            console.log("this.turnLoop", this.turnLoop, this.stopIndex);
            this.action();
          } else {
            this.getDrawCounts();
            setTimeout(() => {
              this.canClick = true;
              this.showModal = true;
            }, 300);
          }
        }
      }, this.turnTime);
    },
    drawWheelCanvas() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      console.log(canvasW);
      ctx.fillStyle = "#fff000";
      ctx.clearRect(0, 0, canvasW, canvasH); //去掉背景默认的黑色
      console.log(canvasW);
      ctx.strokeStyle = "#199301"; //线的颜色
      ctx.font = "26px Microsoft YaHei";

      //画转盘
      ctx.translate(canvasW / 2, canvasH / 2); //设置旋转锚点
      var img_bg = new Image(canvasW + "px", canvasH + "px");
      img_bg.src = "https://oss-kiddo.manqu88.com/h5/scoreTurntable_1.png?" + Date.now();
      img_bg.onload = () => {
        console.log("onload", this.stopIndex);
        ctx.rotate((45 * (this.stopIndex - 1) * Math.PI) / 180);
        ctx.drawImage(img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
      };
      this.img_bg = img_bg;
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  max-width: 100vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-1 {
  height: 965px;
  background: url(https://oss-kiddo.manqu88.com/h5/scoreTurntableBg_1.png?2) no-repeat;
  background-size: 100% 100%;
}
.background-2 {
  min-height: 1856px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/bg_2.png) no-repeat;
  background-size: 100% 100%;
}
.label {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0px;
  width: 80px;
  height: 30px;
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 17px 0 0 17px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}

.msgView {
  position: relative;
  margin-bottom: 18px;
  margin-top: 331px;
  width: 309px;
  height: 56px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/msg_bg.png)
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .notice {
    width: 260px;
    height: 24px;
    position: absolute;
    bottom: 2px;
    left: 20px;
    font-size: 12px;
    color: #2d2d2d;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }
  /deep/ .van-notice-bar {
    background-color: rgba($color: #ffffff, $alpha: 0) !important;
  }
}

.wheel {
  display: block;
  width: 350px;
  height: 350px;
  position: relative;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 203px;
}
.pointer {
  position: absolute;
  width: 90px;
  height: 104px;
  left: 132px;
  top: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .drawtitle {
    margin-top: 37px;
    width: 58px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
  }
  .needScore {
    height: 20px;
    font-size: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 14px;
  }
}
.leftScoreTitle {
  position: absolute;
  left: 41px;
  top: 557px;
  width: 55px;
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
}
.leftScore {
  position: absolute;
  left: 106px;
  top: 550px;
  height: 29px;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f24006;
  line-height: 29px;
}
.leftNumTitle {
  display: flex;
  position: absolute;
  left: 41px;
  top: 591px;
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
  .leftNum {
    margin-left: 11px;
    margin-right: 11px;
    height: 29px;
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #f24006;
    line-height: 16px;
  }
}
#wheelCanvas {
  width: 350px;
  height: 350px;
}

.leftCounts {
  position: relative;
  margin-top: 9px;
  margin-bottom: 14px;
  width: 163px;
  height: 60px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/leftCounts.png)
    no-repeat;
  background-size: 100% 100%;

  .leftTitle {
    position: absolute;
    left: 16px;
    bottom: 7px;
    height: 21px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    word-break: break-all;
  }
  .counts {
    text-align: center;
    position: absolute;
    left: 120px;
    bottom: 2px;
    width: 15px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
  }
}

.tipsView-1 {
  position: relative;
  width: 350px;
  height: 105px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/frame_1.png)
    no-repeat;
  background-size: 100% 100%;

  .btn-goto-1 {
    position: absolute;
    width: 102px;
    height: 33px;
    right: 23px;
    top: 33px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/goTo.png)
      no-repeat;
    background-size: 100% 100%;
  }
}
.tipsView-2 {
  position: relative;
  width: 350px;
  height: 168px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/frame_2.png)
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .leftMoneyTitle {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    height: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0a0a0a;
    line-height: 18px;

    .leftMoney {
      position: relative;
      bottom: 2px;
      font-size: 20px;
      color: #ff1000;
    }
  }

  .progressBg {
    margin-top: 6px;
    width: 320px;
    height: 12px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/progressBg.png)
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;

    .progess-left {
      margin-left: 1.5px;
      width: 3.5px;
      height: 10px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/progress_left.png)
        no-repeat;
      background-size: 100% 100%;
    }
    .progress {
      width: 18.5px;
      height: 10px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/progress.png)
        repeat;
      background-size: 100% 100%;
    }
    .progess-right {
      width: 3.5px;
      height: 10px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/progress_right.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .showMoney {
    margin-top: 8px;
    width: 320px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #131313;
    line-height: 17px;

    .span-money-1 {
      color: #ff1000;
      font-size: 15px;
      font-weight: 500;
    }
    .span-money-2 {
      font-size: 15px;
      color: #0a0a0a;
      font-weight: 500;
    }
  }

  .allCounts {
    margin-top: 14px;
    width: 320px;
    height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftView {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 223px;
      height: 27px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/moneyFrame.png)
        no-repeat;
      background-size: 100% 100%;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }
    .rightView {
      display: flex;
      align-items: center;
      height: 27px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;

      .span-times {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff1000;
        line-height: 18px;
        border-bottom: 1px solid #000000;
      }
    }
  }
}

.getMore {
  position: relative;
  margin-top: 6px;
  margin-bottom: 31px;
  width: 199px;
  height: 62px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/getMore.png)
    no-repeat;
  background-size: 100% 100%;
}
.rule-1 {
  width: 350px;
  height: 469px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_1.png)
    no-repeat;
  background-size: 100% 100%;
}
.rule-2 {
  width: 350px;
  height: 622px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/rule_2.png)
    no-repeat;
  background-size: 100% 100%;
}

.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;

  .bgGet {
    position: absolute;
    left: 35px;
    top: 95px;
    width: 320px;
    height: 420px;
    background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/bgGet.png)
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .coupon {
      position: absolute;
      top: 192px;
      width: 121px;
      height: 61px;
      background: url(https://oss-kiddo.manqu88.com/h5/coupon.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4px;
      .couponPrice {
        height: 36px;
        font-size: 25px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 36px;
      }
      .yuan {
        height: 36px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .goods {
      position: absolute;
      top: 189px;
      width: 68px;
      height: 68px;
      background: url(https://oss-kiddo.manqu88.com/h5/coupon.png) no-repeat;
      background-size: 100% 100%;
    }
    .scoreBg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 211px;
      width: 137px;
      height: 60px;
      background: url(https://oss-kiddo.manqu88.com/h5/scoreBg.png) no-repeat;
      background-size: 100% 100%;

      .score {
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
        line-height: 30px;
      }
    }
    .prizeName {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 258px;
      width: 148px;
      height: 32px;
      text-align: center;
      font-size: 11px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 18px;
      word-break: break-all;
    }
    .toRecord {
      position: absolute;
      top: 315px;
      width: 88px;
      height: 37px;
      background: url(https://oss-kiddo.manqu88.com/h5/look_1.png) no-repeat;
      background-size: 100% 100%;
    }
    .toAddress {
      position: absolute;
      top: 315px;
      right: 67px;
      width: 88px;
      height: 37px;
      background: url(https://oss-kiddo.manqu88.com/h5/address.png) no-repeat;
      background-size: 100% 100%;
    }
    .btnClose {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 36px;
      height: 36px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/btnClose.png)
        no-repeat;
      background-size: 100% 100%;
    }
    .toMain {
      position: absolute;
      top: 345px;
      width: 190px;
      height: 32px;
      background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/turnTableH5/failToMain.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
