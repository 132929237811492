<template>
  <div class="ck-wheel">
    <div style="
        width: 100%;
        height: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
      ">
      <van-icon v-if="false" name="arrow-left" style="margin-left: 20px" size="20" @click="back" />
      <van-search v-model="keyword" placeholder="请输入搜索关键词" style="flex: 1; height: 34px" @search="search()" />
      <div style="margin-right: 20px; font-size: 14px" @click="search">
        搜索
      </div>
    </div>

    <div v-if="showHis" class="his">
      <div style="
          display: flex;
          justify-content: space-between;
          margin-left: 20px;
          margin-right: 20px;
          padding-top: 20px;
          align-items: center;
        ">
        <div style="font-size: 15px; color: #141414; font-weight: bold">
          历史搜索
        </div>
        <van-icon name="delete-o" @click="deleteHis()" />
      </div>
      <!-- 存放历史记录信息 -->
      <div style="
          padding-top: 10px;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          flex-wrap: wrap;
        ">
        <span v-for="(item, index) in historyList" :key="index">
          <div style="
              border-radius: 6px;
              background-color: #d9d7d796;
              padding: 6px 10px;
              margin-right: 10px;
              font-size: 12px;
              margin-bottom: 10px;
            " @click="onTagClick(item)">
            {{ item }}
          </div>
        </span>
      </div>
    </div>

    <div v-if="!showHis" class="scroll">
      <van-list v-model="loading" :finished="finished" :finished-text="list.length != 0 ? '没有更多了' : ''" @load="onLoad">
        <div class="noList" v-if="list.length === 0"></div>
        <div class="card" v-for="(item, index) in list" :key="index" @click="onItemClick(item)">
          <div style="display: flex; margin-left: 13px">
            <van-image round width="48" height="48" :src="item.headUrl">
            </van-image>
            <div style="
                display: flex;
                flex-direction: column;
                margin-left: 7px;
                justify-content: space-around;
              ">
              <div style="display: flex">
                <span class="nick">{{ item.nickname }}</span>
                <span class="name" style="margin-left: 10px">{{
                                  item.realName
                                  }}</span>
                <span class="name" style="margin-left: 10px" v-if="item.mobile">{{
                                  item.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                                  }}</span>
              </div>
              <div style="display: flex">
                <div class="agent">{{ item.agentName }}</div>
                <div class="zhishu">直属</div>
              </div>
            </div>
          </div>

          <div class="data-display" style="margin-top: 18px">
            <div class="data-show">
              <div class="data-title">购买件数</div>
              <div class="data-num">
                {{ item.allQuantity }}
              </div>
            </div>
            <div class="data-show" style="flex: 1">
              <div class="data-title">购买总额</div>
              <div class="data-num">
                {{ `￥${formatPrice(item.allConsumeAmount)}` }}
              </div>
            </div>
          </div>
          <div class="data-display" style="margin-top: 8px">
            <div class="data-show">
              <div class="data-title">首席</div>
              <div class="data-num">
                {{ item.inviteInfo.seniorCount }}
              </div>
            </div>
            <div class="data-show">
              <div class="data-title">店主</div>
              <div class="data-num">
                {{ item.inviteInfo.intermediateCount }}
              </div>
            </div>
            <div class="data-show">
              <div class="data-title">会员</div>
              <div class="data-num">
                {{ item.inviteInfo.primaryCount }}
              </div>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: space-between;
              margin-left: 13px;
              margin-right: 13px;
              margin-top: 18px;
              align-items: center;
            ">
            <div class="note">最近消费时间： {{ item.lastConsumeTime }}</div>
            <div class="detail">查看详情</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "searchclient",
  components: {},
  data() {
    return {
      token: this.$route.query.token,

      showHis: true,
      historyList: [],

      loading: false,
      finished: false,
      currentPage: 1,
      canLoad: false,
      dataVisible: true,
      gradeCode: "",
      list: [],
      refreshing: false,

      keyword: "",
    };
  },
  created() {
    document.title = "客户搜索";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv((res) => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.historyList = JSON.parse(
          localStorage.getItem("searchClientHistory")
        );
        if (this.historyList == null) {
          this.historyList = [];
        }
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.historyList = JSON.parse(
            localStorage.getItem("searchClientHistory")
          );
          if (this.historyList == null) {
            this.historyList = [];
          }
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    back() {
      this.$router.go(-1);
    },
    onTagClick(val) {
      this.keyword = val;
      this.showHis = false;
      this.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.list = [];
      this.getDetail();
    },
    search() {
      this.finished = false;
      if (!this.keyword) {
        return;
      }
      if (
        this.historyList.length == 0 ||
        this.historyList.indexOf(this.keyword) == -1
      ) {
        this.historyList.unshift(this.keyword);
      } else {
        this.historyList.splice(this.historyList.indexOf(this.keyword), 1);
        this.historyList.unshift(this.keyword);
      }
      localStorage.setItem(
        "searchClientHistory",
        JSON.stringify(this.historyList)
      );
      this.showHis = false;
      this.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.list = [];
      this.getDetail();
    },

    deleteHis() {
      this.historyList = [];
      localStorage.setItem(
        "searchClientHistory",
        JSON.stringify(this.historyList)
      );
    },

    async onLoad() {
      if (!this.canLoad) {
        return;
      }
      this.currentPage++;
      this.getDetail();
    },

    async getDetail() {
      this.changeEnable = false;

      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/user/myClients",
        data: {
          gradeCode: "",
          currentPage: this.currentPage,
          pageSize: 8,
          keyWord: this.keyword,
        },
      });
      if (this.currentPage == 1) {
        this.list = [];
      }
      if (data.success) {
        if (data.data.length === 0) {
          this.finished = true;
        }
        this.list = [...this.list, ...data.data];
      } else {
        this.$toast(data.message);
      }

      // 加载状态结束
      this.loading = false;
      this.canLoad = true;
      this.changeEnable = true;
      this.refreshing = false;
    },

    onItemClick(item) {
      this.$router.push({
        name: "clientdetail",
        query: { token: this.token },
        params: { item: item },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;

  .scroll {
    width: 375px;
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .noList {
      margin: auto;
      margin-top: 53px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }
  }

  /deep/ .van-popup--top {
    display: flex;
  }

  .van-cell__value--alone {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tab-normal {
    width: 100%;
    height: 25px;
    background: #f5f5f5;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
    line-height: 25px;
  }

  .tabselect {
    background: #fff5de;
  }

  .card {
    width: 355px;
    background: #ffffff;
    border-radius: 2px;
    margin-top: 7px;
    padding-top: 13px;
    padding-bottom: 12px;
    box-sizing: border-box;

    .note {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }

    .detail {
      width: 83px;
      height: 28px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 28px;
      width: 93px;
      background: #ffffff;
      text-align: center;
      border: 1px solid #b0b0b0;
    }
  }

  .user-head {
    width: 48px;
    height: 48px;
  }

  .nick {
    display: inline-block;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .name {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .agent {
    min-width: 57px;
    height: 18px;
    background: #020202;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }

  .zhishu {
    width: 57px;
    height: 18px;
    background: #ececec;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
    line-height: 18px;
    margin-left: 7px;
    text-align: center;
  }

  .data-display {
    display: flex;
    margin-left: 13px;
    margin-right: 13px;

    .data-show {
      width: 33%;
      display: flex;

      .data-title {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        margin-right: 5px;
      }

      .data-num {
        margin-top: 1px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a08849;
      }

      .data-unit {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #666666;
      }
    }
  }

  .his {
    width: 100%;
  }
}
</style>
  