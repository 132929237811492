<template>
  <div class="ck-wheel">
    <div class="time">
      <img src="@/assets/shijian.png" style="width: 13px; height: 13px; margin-right: 7px" />列表数据更新于{{
        lists && lists[0].gmtModified
        ? moment(lists[0].gmtModified).format("YYYY.MM.DD HH:mm:ss")
        : moment(Date.now()).format("YYYY.MM.DD HH:mm:ss")
      }}
    </div>

    <div class="card" v-for="(item, index) in lists" :key="index" @click="goDetail(item)">
      <div class="title-view">
        {{ item.templateName }}
      </div>
      <div class="item-view">
        <img class="item-img" :src="item.itemResource" alt="" />
        <div class="item-right">
          <div class="item-name">{{ item.itemName }}</div>
          <!-- <div class="item-num">当前阶段：{{item.currentGradient}}件</div> -->
        </div>
      </div>

      <div class="detail-view">
        <div class="detail-left">销售总件数</div>
        <div class="detail-right">{{ item.totalOrderPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">已退款件数</div>
        <div class="detail-right">{{ item.refundPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">未确认件数</div>
        <div class="detail-right">{{ item.orderPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">已确认件数</div>
        <div class="detail-right">{{ item.orderCompletePieceNum }}</div>
      </div>

      <div class="line"></div>

      <div class="detail-view">
        <div class="detail-left">预计佣金收益</div>
        <div class="detail-right">￥{{ formatPrice(item.predictCommissionAward) }}</div>
      </div>
      <div class="detail-view" v-if="item.predictLadderAward && item.predictLadderAward > 0">
        <div class="detail-left">预计阶梯奖励</div>
        <div class="detail-right">￥{{ formatPrice(item.predictLadderAward) }}</div>
      </div>

      <div style="height:20px"></div>

      <!-- <div style="display:flex;align-items:center;margin-top: 14px;margin-bottom: 14px;">
        <div class="detail">
          查看收益说明
        </div>
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      lists: [],
      id: "",
      token: ""
    };
  },
  created() {
    document.title = "业绩明细";
  },
  mounted() {
    this.token = this.$route.query.token;
    this.id = this.$route.query.id;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
        });
      }
    });
  },

  methods: {
    moment,
    formatPrice,
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/distGrouponPerformanceDetails",
        data: {
          distGrouponPerformanceId: this.id || null
        }
      });
      if (data.success) {
        this.lists = data.data || [];
      } else {
        this.$toast(data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;

  .time {
    margin-top: 15px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }

  .card {
    margin-top: 15px;
    width: 355px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-view {
      margin-top: 12px;
      width: 325px;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      /* 作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
      -webkit-box-orient: vertical;
      /* 显示的行 */
      -webkit-line-clamp: 1;
    }

    .item-view {
      margin-top: 12px;
      margin-bottom: 10px;
      width: 325px;
      height: 60px;
      display: flex;
      align-items: center;

      .item-img {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      .item-right {
        height: 60px;
        width: 217px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .item-name {
          width: 217px;
          height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #141414;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 2;
        }

        .item-num {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }

    .line {
      margin-top: 14px;
      width: 325px;
      height: 0px;
      border-bottom: 1px solid #f5f5f5;
    }

    .detail-view {
      margin-top: 10px;
      width: 355px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .detail-left {
        margin-left: 16px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 18px;
      }

      .detail-right {
        margin-right: 16px;
        height: 16px;
        font-size: 13px;
        font-family: JDZhengHT-Regular, JDZhengHT;
        font-weight: 400;
        color: #130000;
        line-height: 16px;
      }
    }

    .detail {
      margin-right: 4px;
      width: 325px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f7b500;
      line-height: 17px;
      text-align: right;
      text-decoration: underline;
    }
  }
}</style>
