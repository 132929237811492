<template>
  <div class="ck-wheel">
    <div class="poster">
      <!-- <div class="rulesBtn" @click="goRules"></div> -->
      <van-count-down :time="time" format="助力倒计时：DD 天 HH: mm: ss" class="countdown" />
      <div class="goldView">
        <div class="unit">¥</div>
        <div class="gold">{{ formatPrice(detailData.finalPrice) }}</div>
        <!-- <div class="gold">288.22</div> -->
      </div>
      <!-- <div class="title"></div> -->
    </div>
    <div class="bg">
      <!-- <div class="golds"></div> -->
      <div :class="['join-1']" @click="share"></div>
      <!-- <div class="des-1">每邀请1位好友助力 助力金随机膨胀增加</div>
      <div class="des-2">温馨提示：邀请新用户助力，膨胀力UP↑</div> -->

      <div class="card">
        <div class="cardTitle">膨胀记录</div>
        <div v-for="(item, index) in assistList" :key="index" class="item">
          <div
            class="head"
            :style="{
              backgroundImage: `url(${item.userInfoDTO && item.userInfoDTO.headPicUrl})`
            }"
          ></div>

          <div class="details">
            <div class="nameView">
              <div class="name">
                {{ item.userInfoDTO && item.userInfoDTO.nickName }}
              </div>
              <div class="new" v-if="item.userType === 2"></div>
              <div class="xdzl" v-if="item.userType === 4"></div>
            </div>

            <div class="success">为您助力成功</div>
            <div class="date">
              {{ item.gmtCreate && moment(item.gmtCreate).format("YYYY.MM.DD HH:mm:ss") }}
            </div>
          </div>

          <div class="assistAdd">助力金+{{ formatPrice(item.assistPrice) }}</div>
          <div class="bottomLine"></div>
        </div>
      </div>

      <!-- <div class="logo"></div> -->
    </div>

    <div class="ruleBg"></div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      assistGoldId: this.$route.query.id,
      assistId: 0,
      token: this.$route.query.token,
      time: 0,
      detailData: [],
      assistList: [],
      internalId: 0,
      isInActivity: false
    };
  },
  created() {
    document.title = "Kiddol 年货助力金";
  },
  destroyed() {
    clearTimeout(this.internalId);
  },
  mounted() {
    // this.getActivityData();
    // this.getDetail(); //
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.getActivityData();
          this.getDetail();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getActivityData();
            this.getDetail();
          });
        }
        //
      });
    }, 1000);
    this.$setAssistGoldData({
      type: 1, //1:详情页 0:活动页 2:列表页
      id: this.assistGoldId
    });
  },

  methods: {
    formatPrice,
    moment,
    async getActivityData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/assistActivity/queryEffectAssistActivity",
        data: {}
      });
      if (data.success) {
        this.isInActivity = true;
      } else {
        this.isInActivity = false;
        this.$toast(data.message);
      }
    },
    async share() {
      this.getActivityData();
      if (!this.isInActivity) {
        return;
      }
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/assistPackage/activity/share?shareType=2&assistId=${this.assistGoldId}` //shareType    分享类型  1- 活动分享   2-助力页分享   assistId    // 助力金id  shareType=2时，必填
          });
          // uni.webView.postMessage({ data: { shareType: 1, id: this.assistGoldId } });
        } else {
          this.$bridge
            .call("openPage", {
              type: 27,
              param: {
                shareType: 1,
                id: this.assistGoldId
              }
            })
            .then(e => {
              console.log("openPage27客户端返回: ", e);
            });
        }
      });
    },
    async getDetail() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/assist/queryById",
        data: {
          pageSize: 100,
          assistRecordId: this.assistId,
          currentPage: 1,
          assistGoldId: this.assistGoldId,
          assistListType: 0 //0：活动页面；1：我的助力金页面
        }
      });
      if (data.success) {
        this.detailData = data.data || [];
        this.time = this.detailData.endTime - Date.now();
        this.assistList = this.detailData.assistRecordList;
      } else {
        this.$toast(data.message);
      }
      //  this.assistList = [...this.assistList,...{}];
      this.internalId = setTimeout(() => {
        this.getDetail();
      }, 5000);
    },
    goRules() {
      this.$router.push(`/assistRules?time=${Date.now()}`);
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mFont";
  src: url("../../../assets/fonts/poxel-font.ttf");
}
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster {
    position: relative;
    width: 375px;
    height: 648px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/assistDeatilPoster.png?3) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .rulesBtn {
      position: absolute;
      right: 14px;
      top: 99px;
      width: 95px;
      height: 39px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/rulesBtn.png?2) no-repeat;
      background-size: 100% 100%;
    }
    .countdown {
      position: absolute;
      left: 73px;
      top: 571px;
      text-align: center;
      width: 222px;
      height: 17px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 31px;
    }

    .goldView {
      position: absolute;
      left: 112px;
      top: 367px;
      width: 155px;
      height: 50px;
      // font-family: mFont;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #ff3f32;
      // background: #a5a5a5;
      display: flex;
      justify-content: center;
      text-decoration: underline;
      .unit {
        height: 90px;
        font-size: 24px;
        line-height: 43px;
      }
      .gold {
        text-align: center;
        height: 90px;
        font-size: 24px;
        line-height: 43px;
      }
    }

    .title {
      position: absolute;
      left: 91px;
      top: 283px;
      width: 148px;
      height: 116px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/detailTitle.png?1) no-repeat;
      background-size: 100% 100%;
    }
  }
  .bg {
    width: 375px;
    height: auto;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/detailBg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .golds {
      position: absolute;
      left: 0;
      top: 491px;
      width: 180px;
      height: 168px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/golds.gif) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
    }

    .join-1 {
      position: absolute;
      left: 92px;
      top: 482px;
      width: 194px;
      height: 55px;
      // background: #e01b55;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/assist_invite.gif?1) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
    }
    .des-1 {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 21px;
      margin-bottom: 4px;
    }
    .des-2 {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 16px;
      margin-bottom: 29px;
    }

    .card {
      position: relative;
      z-index: 100;
      width: 343px;
      overflow: hidden;
      overflow-y: scroll;
      height: auto;
      max-height: 718px;
      background: #ffffff;
      border-radius: 12px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      padding-bottom: 19px;
      // margin-bottom: 40px;
      margin-top: -40px;
      .cardTitle {
        margin: auto;
        margin-top: 17px;
        width: 130px;
        height: 25px;
        font-size: 25px;
        font-family: MiSans;
        font-weight: 600;
        text-align: center;
        color: #000000;
        padding-bottom: 6px;
        border-bottom: 3px solid;
        border-bottom-color: #b80a04;
      }

      .item {
        margin-top: 21px;
        width: 100%;
        height: 60px;
        display: flex;
        position: relative;
        .head {
          width: 60px;
          height: 60px;
          background: #eeeeee;
          border-radius: 4px;
          background: url(https://oss-kiddo.manqu88.com/h5/assist/detailPoster.png) no-repeat;
          background-size: 100% 100%;
          margin-left: 28px;
        }
        .details {
          margin-left: 9px;
          display: flex;
          flex-direction: column;
          height: 60px;

          .nameView {
            display: flex;
            height: 21px;
            .name {
              display: flex;
              align-items: center;
              width: 175px;
              height: 21px;
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #3f3f3f;
              line-height: 21px;
              margin-bottom: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              /* 作为弹性伸缩盒子模型显示 */
              display: -webkit-box;
              /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
              -webkit-box-orient: vertical;
              /* 显示的行 */
              -webkit-line-clamp: 1;
            }
            .new {
              margin-top: 3px;
              margin-left: 1px;
              width: 42px;
              height: 16px;
              background: url(https://oss-kiddo.manqu88.com/h5/assist/new.png) no-repeat;
              background-size: 100% 100%;
            }
            .xdzl {
              margin-top: 3px;
              margin-left: 1px;
              width: 42px;
              height: 15px;
              background: url(https://oss-kiddo.manqu88.com/h5/assist/xdzl.png) no-repeat;
              background-size: 100% 100%;
            }
          }

          .success {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #b80a04;
            line-height: 17px;
            margin-bottom: 3px;
          }
          .date {
            height: 16px;
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 16px;
          }
        }
        .assistAdd {
          position: absolute;
          text-align: right;
          right: 27px;
          top: 19px;
          height: 22px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #b80a04;
          line-height: 21px;
        }
        .bottomLine {
          position: absolute;
          bottom: -8px;
          left: 19px;
          width: 306px;
          height: 0px;
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    .logo {
      width: 129px;
      height: 52px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/logo.png) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 42px;
    }
  }

  .ruleBg {
    width: 375px;
    height: 1156px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/assistDetailRule.png) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
