<template>
  <div class="wrap">
    <!-- <div class="main"> -->
    <div class="btn" @click="showModal = true">填写收货信息</div>
    <!-- <div class="btn disabled" v-else>已填写</div> -->
    <!-- </div> -->
    <div class="popup" v-show="showModal">
      <div class="mask"></div>
      <div class="modal">
        <img
          class="close"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/icon-close.png"
          @click="close"
        />
        <div class="title">填写收货信息</div>
        <div class="border"></div>
        <div class="form">
          <div class="label">收货人姓名</div>
          <van-field
            v-model="receiverName"
            placeholder="请输入收货人姓名"
            maxlength="16"
            :border="false"
          />
          <div class="label">收货人手机号</div>
          <van-field
            v-model="receiverPhone"
            type="tel"
            placeholder="请输入收货人手机号"
            maxlength="11"
            :border="false"
          />
          <div class="label">收货地址</div>
          <van-field
            v-model="address"
            readonly
            placeholder="请选择省-市-区"
            :border="false"
            @click="onShowArea"
          />
          <van-field
            class="detail"
            v-model="detail"
            rows="2"
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="100"
            :border="false"
            @click="handleScrollNormal('detail')"
          />
          <div class="save" @click="onSave">保存</div>
        </div>
      </div>
    </div>
    <div v-show="showArea" class="mask" @click="cancel"></div>
    <van-area
      class="area"
      :class="{ showArea: showArea }"
      title="标题"
      :area-list="areaList"
      @confirm="confirm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';

export default {
  data() {
    return {
      token: this.$route.query.token,
      clientSource: this.$route.query.clientSource,
      hasAddress: false,
      showModal: false,
      areaList,
      showArea: false,
      receiverName: '',
      receiverPhone: '',
      address: '',
      provinceName: '',
      cityName: '',
      areaName: '',
      detail: '',
      addressId: '',
    };
  },
  beforeCreate() {
    document.title = '上线派礼';
  },
  mounted() {
    // this.$axios({
    //   method: 'post',
    //   headers: { mac: 'mac', clientSource: this.clientSource, Authorization: this.token },
    //   url: '/api/reward/checkPopupWindow',
    // })
    //   .then(({ data }) => {
    //     if (data.success && !data.data) {
    //       this.hasAddress = true;
    //     } else {
    //       this.hasAddress = false;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    this.clientSource = this.$clientSource();
    setTimeout(() => {
      uni.webView.getEnv((res) => {
        console.log(`当前环境：${JSON.stringify(res)}`);
        if (res.miniprogram) {
          this.token = this.$route.query.token;
          this.getAddressInfo();
        } else {
          console.log(`当前环境：${JSON.stringify(res)}`);
          this.$bridge.call('getToken', {}).then((e) => {
            console.log('getToken客户端返回: ', e);
            this.token = e;
            this.getAddressInfo();
          });
        }
      });
    }, 1000);
  },
  methods: {
    close() {
      this.showModal = false;
    },
    async getAddressInfo() {
      const { data } = await this.$axios({
        method: 'post',
        headers: {
          mac: 'mac',
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: 'api/reward/getUserAddress',
        data: {
          type: this.type,
        },
      });
      if (data.success) {
        if (data.data) {
          this.hasAddress = true;
          const datas = data.data;
          this.addressId = datas.id;
          this.address = `${datas.provinceName}-${datas.cityName}-${datas.areaName}`;
          this.provinceName = datas.provinceName;
          this.cityName = datas.cityName;
          this.areaName = datas.areaName;
          this.receiverName = datas.receiverName;
          this.receiverPhone = datas.receiverPhone;
          this.detail = datas.detail;
        } else {
          this.hasAddress = false;
        }
      } else {
        this.$toast(data.message);
      }
    },
    onShowArea() {
      this.showArea = true;
    },
    confirm(value) {
      this.showArea = false;
      console.log(value);
      this.address = `${value[0].name}-${value[1].name}-${value[2].name}`;
      this.provinceName = value[0].name;
      this.cityName = value[1].name;
      this.areaName = value[2].name;
    },
    cancel() {
      this.showArea = false;
    },
    onSave() {
      const {
        addressId,
        receiverName,
        receiverPhone,
        provinceName,
        cityName,
        areaName,
        detail,
        address,
      } = this;

      if (!receiverName) {
        Toast('请填写收货人姓名');
        return;
      }
      if (!receiverPhone) {
        Toast('请填写收货人手机号');
        return;
      }
      const reg = new RegExp(/^1\d{10}$/);
      if (!reg.test(receiverPhone)) {
        Toast('请填写正确的手机号');
        return;
      }
      if (!address) {
        Toast('请选择地区');
        return;
      }
      if (!detail) {
        Toast('请填写详细地址');
        return;
      }

      this.$axios({
        method: 'post',
        headers: {
          mac: 'mac',
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: this.hasAddress ? 'api/reward/updateUserAddress' : '/api/reward/addUserAddress',
        data: {
          id: addressId,
          receiverName,
          receiverPhone,
          provinceName,
          cityName,
          areaName,
          detail,
        },
      })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            Toast('保存成功！');
            this.showModal = false;
            this.hasAddress = true;
            if (this.addressId == '') {
              this.getAddressInfo();
            }
          } else {
            Toast(data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          Toast('保存失败，请重试！');
        });
    },
    handleScrollNormal(param) {
      setTimeout(() => {
        document.getElementsByClassName(param)[0].scrollIntoViewIfNeeded();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 375px;
  min-height: 812px;
  // padding-bottom: 49px;
  background: url(https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/h5/kiddol_38_bg.jpg?1)
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    position: absolute;
    top: 410px;
    width: 192px;
    height: 39px;
    background: #f9550a;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;

    &.disabled {
      background: #dddddd;
    }
  }

  img {
    display: flex;
    width: 100%;
    height: 270px;
  }

  .main {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 0 20px;
    text-align: left;

    .title {
      margin-top: 15px;
      margin-bottom: 10px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #323cb4;
      line-height: 22px;
      letter-spacing: 1px;
    }

    .desc {
      margin-bottom: 15px;
      font-size: 14px;
      font-family: OPPOSans-R, OPPOSans;
      font-weight: normal;
      color: #2c2c2c;
      line-height: 27px;
    }

    .border {
      width: 340px;
      height: 5px;
      background: #ffeb57;
    }
  }

  .popup {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    .mask {
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
    }

    .modal {
      padding-top: 42px;
      box-sizing: border-box;
      width: 340px;
      height: 458px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 18px;
      background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: left;

      .close {
        width: 36px;
        height: 36px;
        position: absolute;
        right: 0;
        top: -30px;
      }

      .title {
        text-align: center;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #272727;
        line-height: 25px;
        letter-spacing: 1px;
      }

      .border {
        margin: 2px auto 0;
        width: 133px;
        height: 3px;
        background: #f9e46e;
      }

      .form {
        padding: 0 55px;
        box-sizing: border-box;

        .label {
          margin-top: 15px;
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          line-height: 18px;
        }

        input {
          margin-top: 7px;
        }

        .save {
          margin: 20px auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 192px;
          height: 39px;
          background: #f9550a;
          border-radius: 20px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.van-field {
  padding: 0 11px;
  width: 230px;
  // height: 32px;
  line-height: 32px;
  border: 1px solid #efefef;
  margin-top: 7px;
}

.van-field--min-height {
  line-height: 22px;
}

.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
</style>
