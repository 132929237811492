<template>
  <div class="ck-wheel">
    <div class="bg-1" v-if="!showNone">
      <div class="rule-btn" @click="goRule"></div>
      <div class="item-view">
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 1 ? 'item-1-selected' : 'item-1']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 2 ? 'item-2-selected' : 'item-2']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 3 ? 'item-3-selected' : 'item-3']"
        ></div>
      </div>

      <div class="num-1">{{ datas.promoteCount }}</div>
      <div class="num-2">{{ datas.confirmPromoteCount }}</div>

      <!-- 进度条 -->
      <div class="progress">
        <div
          class="progress-in"
          :style="{
            width: `${progressPercent}%`
          }"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 1 ? 'dot-1-selected' : 'dot-1']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 2 ? 'dot-2-selected' : 'dot-2']"
        ></div>
        <div
          :class="[datas.prizeIndex && datas.prizeIndex === 3 ? 'dot-3-selected' : 'dot-3']"
        ></div>
      </div>
    </div>
    <div class="bg-2" v-if="showNone">
      <div class="tip">
        会员暂时无法参与当前活动，请升级后参与，敬请谅解
        <div class="btn" @click="goMember">前往升级</div>
      </div>
      <div class="logo"></div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { formatPrice } from "@/utils/money";
import { Toast } from "vant";

export default {
  name: "assist",
  components: {},
  data() {
    return {
      showBack: false,
      showNone: false,
      datas: {
        promoteCount: 0,
        confirmPromoteCount: 0,
        prizeIndex: null
      },
      token: this.$route.query.token,
      userId: this.$route.query.userId,
      progressPercent: 0
    };
  },
  created() {
    this.showBack = this.$route.query.showBack ? true : false;
    document.title = "实习招募季 推荐享好礼";
  },
  async mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          console.log("token2", this.token);
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getUserInfo();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getUserInfo();
        });
      }
    });
  },

  methods: {
    formatPrice,
    goBack() {
      this.$router.go(-1);
    },
    goMember() {
      this.$router.push(`/memberCenter/index?token=${this.token}`);
    },
    goRule() {
      console.log("document.body.scrollHeight", document.body.scrollHeight);
      window.scrollTo(0, document.body.scrollHeight - 880);
    },
    async getUserInfo() {
      let isLeader = false;
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/distGrouponLeader/isLeader",
          data: {}
        });
        if (data.success) {
          isLeader = data.data;
        } else {
          this.$toast(data.message);
        }
      }
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/user/getDistUserInfo",
        data: {}
      });
      if (data.success) {
        let gradeCode = data.data.gradeCode;
        if (gradeCode === -1 || (gradeCode === 1 && !isLeader)) {
          this.showNone = true;
        }
        if ((gradeCode === 1 && isLeader) || gradeCode === 2) {
          this.getData();
        }
        if (gradeCode > 2) {
          this.$router.replace(`/nationalDay/two?token=${this.token}`);
        }
      } else {
        this.$toast(data.message);
      }
    },
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/nationalDay/activity/one",
        data: {}
      });
      if (data.success) {
        this.datas = data.data && data.data;
        console.log(this.datas, "11");
        // this.datas.confirmPromoteCount = 79;
        if (this.datas.confirmPromoteCount < 10) {
          this.datas.prizeIndex = null;
          this.progressPercent = 15 * (this.datas.confirmPromoteCount / 10);
        } else if (this.datas.confirmPromoteCount < 30) {
          this.datas.prizeIndex = 1;
          this.progressPercent = 15 * (this.datas.confirmPromoteCount / 10);
        } else if (this.datas.confirmPromoteCount < 80) {
          this.datas.prizeIndex = 2;
          this.progressPercent = 50 * (this.datas.confirmPromoteCount / 30);
          this.progressPercent = this.progressPercent > 80 ? 80 : this.progressPercent;
        } else {
          this.datas.prizeIndex = 3;
          this.progressPercent = 85 * (this.datas.confirmPromoteCount / 80);
          this.progressPercent = this.progressPercent > 100 ? 100 : this.progressPercent;
        }
        console.log(this.progressPercent);
      } else {
        this.$toast(data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}
.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 375px;
    min-height: 100vh;
    height: auto;
    // background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/bg.png?111) no-repeat;
    // background-size: 100% 100%;
    background: #f8f8fa;
    position: relative;
    .tip {
      text-align: center;
      padding-top: 19px;
      width: 303px;
      // height: 80px;
      padding-left: 17px;
      padding-right: 17px;
      background: #ffffff;
      border-radius: 20px;
      border: 1px solid #eb6d12;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3b3b3b;
      line-height: 21px;

      .btn {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 184px;
        height: 34px;
        background: #eb6d11;
        border-radius: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
      }
    }
    .logo {
      position: absolute;
      bottom: 78px;
      width: 116px;
      height: 51px;
      background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/logo.png?1111) no-repeat;
      background-size: 100% 100%;
    }
  }

  .bg-1 {
    width: 375px;
    height: 1621px;
    background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/bg_1.png?11211) no-repeat;
    background-size: 100% 100%;
    position: relative;

    .rule-btn {
      position: absolute;
      right: 8px;
      top: 168px;
      width: 93px;
      height: 29px;
      background: url(https://oss-kiddo.manqu88.com/h5/anniversary/ruleBtn.png) no-repeat;
      background-size: 100% 100%;
    }

    .item-view {
      position: absolute;
      top: 570px;
      left: 18px;
      width: 335px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .item-1 {
        width: 99px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_1.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-1-selected {
        width: 100px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_1_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .item-2 {
        width: 111px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_2.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-2-selected {
        width: 105px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_2_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .item-3 {
        width: 111px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_3.png) no-repeat;
        background-size: 100% 100%;
      }
      .item-3-selected {
        width: 112px;
        height: 126px;
        background: url(https://oss-kiddo.manqu88.com/h5/nationalDay/item_3_selected.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .num-1 {
      position: absolute;
      left: 118px;
      top: 767px;
      width: 53px;
      height: 23px;
      font-size: 19px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #e86c11;
      line-height: 23px;
      text-align: center;
    }
    .num-2 {
      position: absolute;
      right: 32px;
      top: 767px;
      width: 53px;
      height: 23px;
      font-size: 19px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #e86c11;
      line-height: 23px;
      text-align: center;
    }

    .progress {
      position: absolute;
      left: 24px;
      top: 711px;
      width: 326px;
      height: 6px;
      border-radius: 13px;
      border: 1px #e86c11 solid;
      display: flex;
      align-items: center;

      .progress-in {
        position: absolute;
        left: 0;
        background: #e86c11;
        border-radius: 13px;
        width: 326px;
        height: 6px;
      }

      .dot-1 {
        position: absolute;
        left: 10%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-1-selected {
        position: absolute;
        left: 10%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .dot-2 {
        position: absolute;
        left: 45%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-2-selected {
        position: absolute;
        left: 45%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }

      .dot-3 {
        position: absolute;
        left: 80%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot.png) no-repeat;
        background-size: 100% 100%;
      }
      .dot-3-selected {
        position: absolute;
        left: 80%;
        width: 22px;
        height: 22px;
        background: url(https://oss-kiddo.manqu88.com/h5/anniversary/dot_selected.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    padding-top: 42px;
    box-sizing: border-box;
    width: 340px;
    height: 458px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: left;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: -30px;
    }

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      line-height: 25px;
      letter-spacing: 1px;
    }

    .border {
      margin: 2px auto 0;
      width: 133px;
      height: 3px;
      background: #f9e46e;
    }

    .form {
      padding: 0 55px;
      box-sizing: border-box;

      .label {
        margin-top: 15px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        line-height: 18px;
      }

      input {
        margin-top: 7px;
      }

      .save {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        height: 39px;
        background: #f9550a;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
}
.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}
</style>
