<template>
  <div class="wrap">
    <div class="tips">
      2023年1月、2月团队奖励将会在2023年3月13日24:00进行汇总结算与调整。总收益会根据累计达到的最高门槛合并计算1月、2月奖励，并显示至2月份团队业绩中。奖励经财务审核完成将合并1笔发放至团队奖励绑定银行账户。
      <!-- <span class="tips-click" @click="goTo">查看详情说明</span> -->
    </div>
    <div class="line"></div>
    <div v-if="dataList.length > 0" class="time">
      <img src="../assets/shijian.png" style="width: 13px; height: 13px; margin-right: 7px" />列表数据更新于 {{
        formatDate(dataList[0].finalTime) }}
    </div>

    <div v-for="(item, index) in dataList" :key="index" class="card-view">
      <div v-if="index === 0 ||
        (index > 0 &&
          getYear(item.statisticsMonth) !== getYear(dataList[index - 1].statisticsMonth))
        " class="year">
        <span>{{ getYear(item.statisticsMonth) }}</span>
        <div class="year-line"></div>
      </div>
      <div class="card">
        <div class="card-title" :style="{
          background:
            (Number(item.status) === 2 || Number(item.status) === 1) &&
              item.rewardActual !== undefined
              ? titleColor[2]
              : titleColor[Number(item.status)]
        }">
          <div style="margin-left: 16px">{{ getMonth(item.statisticsMonth) }}月销售业绩奖励</div>
          <div :style="{
            'margin-right': '16px',
            color:
              (Number(item.status) === 2 || Number(item.status) === 1) &&
                item.rewardActual !== undefined
                ? statusColor[2]
                : statusColor[Number(item.status)]
          }">
            {{
              (Number(item.status) === 2 || Number(item.status) === 1) &&
              item.rewardActual !== undefined
              ? status[2]
              : status[Number(item.status)]
            }}
          </div>
        </div>

        <div class="price-all">
          {{
            (Number(item.status) === 2 || Number(item.status) === 1) &&
            item.rewardActual !== undefined
            ? ""
            : "预计"
          }}¥
          {{
            formatAll(
              (Number(item.status) === 2 || Number(item.status) === 1) &&
                item.rewardActual !== undefined
                ? item.rewardActual
                : item.rewardPayable
            )
          }}
        </div>

        <!-- <div class="little-card">
          <div class="txt">
            <span class="left">下单直属社群总额：</span>
            <span class="right">¥{{ format(item.expectCommunityAmount) }}</span>
          </div>
          <div class="delNum">
            -含团购商品业绩：<span class="price">¥{{ format(item.expectCommunityDistGrouponAmount) }}</span>
          </div>
          <div class="delNum">
            -已减运费：<span class="price">¥{{ format(item.expectCommunityFreight) }}</span
            >，已减特殊商品：<span class="price"
              >¥{{ format(item.expectCommunityExcludeAmount) }}</span
            >
          </div>
        </div>
        <div class="little-card">
          <div class="txt">
            <span class="left">已确认直属社群总额：</span>
            <span class="right">¥{{ format(item.completedCommunityAmount) }}</span>
          </div>
          <div class="delNum">
            -含团购商品业绩：<span class="price">¥{{ format(item.completedCommunityDistGrouponAmount) }}</span>
          </div>
          <div class="delNum">
            -已减运费：<span class="price">¥{{ format(item.completedCommunityFreight) }}</span
            >，已减特殊商品：<span class="price"
              >¥{{ format(item.confirmCommunityExcludeAmount) }}</span
            >
          </div>
        </div> -->

        <div class="little-card">
          <div class="txt">
            <span class="left">下单团队总额：</span>
            <span class="right">¥{{ format(item.expectTeamAmount) }}</span>
          </div>
          <div class="delNum delTitle">
            -本月业绩：<span class="price"> ¥{{ format(item.expectThisMonthAmount) }}</span>，未结算业绩：<span class="price">¥{{
              format(item.expectWaitAmount) }}</span>
          </div>
          <div class="delNum">
            -含50%团购业绩：<span class="price">¥{{ format(item.expectTeamDistGrouponAmount) }}</span>
          </div>
          <div class="delNum">
            -已减运费：<span class="price"> ¥{{ format(item.expectTeamFreight) }}</span>，已减特殊商品：<span class="price">¥{{
              format(item.expectExcludeAmount) }}</span>
          </div>
        </div>

        <div class="little-card">
          <div class="txt">
            <span class="left">已确认团队总额：</span>
            <span class="right">¥{{ format(item.completedTeamAmount) }} </span>
          </div>
          <div class="delNum delTitle">
            -本月业绩：<span class="price"> ¥{{ format(item.completedThisMonthAmount) }}</span>，未结算业绩：<span class="price">¥{{
              format(item.completedWaitAmount) }}</span>
          </div>
          <div class="delNum">
            -含50%团购业绩：<span class="price">¥{{ format(item.completedTeamDistGrouponAmount) }}</span>
          </div>
          <div class="delNum">
            -已减运费：<span class="price">¥{{ format(item.completedTeamFreight) }}</span>，已减特殊商品：<span class="price">¥{{
              format(item.confirmExcludeAmount) }}</span>
          </div>
        </div>

        <div style="height: 5px"></div>
      </div>
    </div>

    <div style="height: 25px"></div>
    <div v-if="isShowUI" class="help_icon" @click="handleClickHelp">
      <img src="../assets/help_icon.png" alt="">
    </div>
    <div v-if="isShowUI" class="card_icon" @click="handleClickBankCard">
      <img src="./../assets/bandcard_icon.png" alt="">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { formatPrice } from "../utils/money";
import { formatDate } from "../utils/time";

export default {
  name: "Pay",
  components: {},
  data() {
    return {
      money: "",
      dataList: [],
      status: ["统计中", "待结算", "已结算"],
      titleColor: ["#EAEAF9", "#F5F0EB", "#EDEDED"],
      statusColor: ["#6B74B6", "#B6856B", "#666666"],
      token: "",
      // 显示隐藏部分UI （兼容app）
      isShowUI: false,
      isClickBankCard: false
    };
  },
  created() {
    document.title = "平台奖励";
    console.log("token", this.$route.query);
    this.token = this.$route.query.token;
  },
  mounted() {
    this.getList();
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        if (res.miniprogram && res.miniprogram === true) {
          this.isShowUI = true
          this.checkIsBindBankCard();
        } else {
          this.isShowUI = false
        }
      } else {
        this.isShowUI = false
      }
    });
  },
  methods: {
    formatDate,
    getYear(date) {
      return date.split("-")[0];
    },
    getMonth(date) {
      var ret = date.split("-")[1];
      if (Number(ret) < 10) {
        ret = ret.replace("0", "");
      }
      return ret;
    },
    formatAll(price) {
      return formatPrice(price);
    },
    format(price) {
      var ret = price;
      if (price >= 100000000000) {
        for (let i = 0; i < 5; i++) {
          ret = formatPrice(Number(ret));
        }
        return ret + "亿";
      } else if (price >= 10000000) {
        for (let i = 0; i < 3; i++) {
          ret = formatPrice(Number(ret));
        }
        return ret + "万";
      } else {
        return formatPrice(price);
      }
    },
    goTo() {
      this.$router.push("/monthRewardDes");
    },
    getList() {
      this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/teamReward/queryTeamRewardDetailNewByUserId"
      }).then(res => {
        console.log("res", res);
        if (res.data.success) {
          this.dataList = res.data.data ? res.data.data : [];
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 查询是否绑定奖励银行卡
    async checkIsBindBankCard() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/bankcard/queryTeamRewardBankCardByUserId",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          if (this.isClickBankCard) {
            this.$router.push(`/addol/rewardBankCard?token=${this.token}`);
          }
        } else {
          this.$dialog.alert({
            title: '奖励发放设置',
            message: '请先设置奖励发放银行卡,以免影响奖励发放',
            confirmButtonText: '选择银行卡',
            confirmButtonColor: '#232323',
            closeOnClickOverlay: true
          }).then(() => {
            // type：1 （绑定奖励银行卡）
            this.$router.push(`/addol/bankCardList?token=${this.token}&type=1`);
          });
        }
      } else {
        this.$toast(data.message)
      }
    },
    handleClickHelp() {
      this.$router.push("/monthRewardDes");
    },
    handleClickBankCard() {
      this.isClickBankCard = true
      this.checkIsBindBankCard()
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  height: auto;
  width: 100%;
  min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.tips {
  display: -webkit-box;
  margin: 13px auto;
  width: 348px;
  // height: 49px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.tips-click {
  color: #323cb3;
}

.line {
  margin: 0 auto;
  width: 347px;
  border: 1px solid #f5f5f5;
}

.time {
  margin-top: 10px;
  margin-bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
}

.year {
  width: 353px;
  height: 25px;
  margin: 0px auto;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 25px;

  .year-line {
    width: 294px;
    border: 1px solid #f5f5f5;
  }
}

.card {
  margin: 25px auto;
  margin-bottom: 0;
  width: 355px;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;

  .card-title {
    align-items: center;
    width: 355px;
    height: 36px;
    background: #eaeaf9;
    display: flex;
    justify-content: space-between;
  }

  .price-all {
    height: 26px;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 1px;
    margin-left: 16px;
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #232323;
    line-height: 26px;
  }

  .little-card {
    margin: 7px auto;
    width: 323px;
    background: #fcfcfc;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 17px;

    .txt {
      width: 305px;
      margin: 3px auto;
      margin-bottom: 5px;
      font-size: 12px;
      display: -webkit-box;
      word-break: break-all;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 17px;
      }

      .right {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 20px;
      }
    }

    .delNum {
      width: 305px;
      margin: 0px auto;
      margin-bottom: 7px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      display: -webkit-box;
      word-break: break-all;
      display: flex;
      align-items: center;
    }

    .delTitle {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
    }
  }
}

.price {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #232323;
  line-height: 14px;
}

.card_icon {
  position: fixed;
  top: 100px;
  right: 10px;
  width: 30px;
  height: 30px;
  text-align: center;

  img {
    width: 20px;
    top: 50%;
    transform: translateY(25%);
  }
}

.help_icon {
  position: fixed;
  top: 58px;
  right: 10px;
  width: 30px;
  height: 30px;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(25%);
  }
}
</style>
