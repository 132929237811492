<template>
  <div class="wrap" v-if="isRequest">
    <div class="top"></div>
    <block v-if="pageData.activityKa">
      <div class="time" v-if="pageData.endTime > now">考核倒计时：{{ countDown(pageData.endTime) }}</div>
      <div class="time" v-else>考核已结束</div>
    </block>
    <div class="fuli1">
      <img src="https://oss-kiddo.manqu88.com/activity/chongfen/fuli1.png" />
      <div class="btn fafang" v-if="pageData.kaSendStatus"></div>
      <div class="btn zhiyin" v-else @click="toZhiYin"></div>
    </div>
    <div class="fuli2">
      <img src="https://oss-kiddo.manqu88.com/activity/chongfen/fuli2.png" />
    </div>
    <div class="result">
      <div class="amount">10000</div>
      <div class="progress">
        <div class="bar" :style="{ width: `${ 315 * (pageData.lowerCompleteAmount / 1000000) }px`}"></div>
        <div class="dot" v-if="pageData.lowerCompleteAmount"></div>
      </div>
      <div class="total" v-if="pageData.lowerStatus">任务完成奖励已发放</div>
      <div class="total" v-if="!pageData.lowerStatus && pageData.lowerCompleteAmount">
        已完成金额：{{ pageData.lowerCompleteAmount | formatPrice }}
      </div>
    </div>
    <div class="fuli3">
      <img src="https://oss-kiddo.manqu88.com/activity/chongfen/fuli3.png" />
    </div>
    <div class="result">
      <div class="amount">20</div>
      <div class="progress">
        <div class="bar" :style="{ width: `${ 315 * (pageData.validDistCount / 20) }px`}"></div>
        <div class="dot" v-if="pageData.validDistCount"></div>
      </div>
      <div class="total" v-if="pageData.inviteStatus">任务完成奖励已发放</div>
      <div class="total" v-if="!pageData.inviteStatus && pageData.validDistCount">
        已邀请人数：{{ pageData.validDistCount }}
      </div>
    </div>
    <div class="fuli4">
      <img src="https://oss-kiddo.manqu88.com/activity/chongfen/fuli4.png" />
    </div>
    <div class="result">
      <div class="amount">3</div>
      <div class="progress">
        <div class="bar" :style="{ width: `${ 315 * (pageData.validKaCount / 3) }px`}"></div>
        <div class="dot" v-if="pageData.validKaCount"></div>
      </div>
      <div class="total" v-if="pageData.inviteKaStatus">任务完成奖励已发放</div>
      <div class="total" v-if="!pageData.inviteKaStatus && pageData.validKaCount">
        永久KA人数：{{ pageData.validKaCount }}
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
import moment from "moment";
import browser from "@/utils/browser";
import { formatPrice } from "../utils/money";
import { formatDate } from "../utils/time";
import { countDown } from "../utils/count-down";

export default {
  data() {
    return {
      isRequest: false,
      token: '',
      now: moment(),
      pageData: {
        activityKa: false,                //类型：Boolean  必有字段  备注：是否活动KA
        kaSendStatus: false,                //类型：Boolean  必有字段  备注：福利一发放状态
        lowerCompleteAmount: 0,                //类型：Number  必有字段  备注：福利二进度
        lowerStatus: false,                //类型：Boolean  必有字段  备注：福利二发放状态
        validDistCount: 0,                //类型：Number  必有字段  备注：福利三进度
        inviteStatus: false,                //类型：Boolean  必有字段  备注：福利三发放状态
        validKaCount: 0,                //类型：Boolean  必有字段  备注：福利四进度
        inviteKaStatus: false,                //类型：Boolean  必有字段  备注：福利四发放状态
        beginTime: 0,                //类型：Number  必有字段  备注：开始时间
        endTime: 0,                //类型：Number  必有字段  备注：结束时间
        nowTime: 0                //类型：Number  必有字段  备注：当前时间
      }
    }
  },
  filters: {
    formatPrice,
    formatDate
  },
  mounted() {
    document.title = "新升宠粉季";
    setTimeout(async () => {
      uni.webView.getEnv(async (res) => {
        console.log("当前环境1111111111：", res, JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          this.token = this.$route.query.token;
          this.getProgress();
        } else {
          if (browser().versions.isKiddolClient || browser().versions.isAddolClient) {
            this.$bridge.call("getToken", {}).then((e) => {
              console.log("getToken客户端返回: ", e, JSON.stringify(e));
              this.token = e;
              if (!e || JSON.stringify(e) == '{}') {
                this.$bridge.call("openPage", { type: 26 });
                return;
              }
              this.getProgress();
            }).catch((error) => {
              console.log(error);
            });
          } else {

          }
        }
      });
      this.isRequest = true;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.now = this.now + 1000;
      }, 1000);
    }, 500);
  },
  computed: {
    countDown() {
      return (end) => {
        return countDown(this.now, end, 'day');
      };
    },
  },
  methods: {
    async getProgress() {
      const { data: { data, success, message } } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
          // clientSource: 2,
          // Authorization: '52123918de85732bb28c43a3a9c32ab1',
        },
        url: "/api/activityDistUser/kaSendGoldProgress",
      });
      if (success && data) {
        this.pageData = { ...data };
        this.now = data.nowTime;
      } else {
        this.$toast(message);
      }
    },
    toZhiYin() {
      this.$router.push("/kaZhiYin");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100vw;
  min-height: 100vh;
  background-color: #e5e5e5;
  background-image: url("https://oss-kiddo.manqu88.com/activity/recruit/bg1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;

  .top {
    width: 100%;
    height: 151px;
    background: url("https://oss-kiddo.manqu88.com/activity/chongfen/title.png") no-repeat;
    background-size: 100% 100%;
  }

  .time {
    width: 239px;
    height: 39px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #404040;
    line-height: 40px;
    background-image: url('https://oss-kiddo.manqu88.com/activity/chongfen/btn_time.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 3px auto;
    text-align: center;
  }

  .fuli1 {
    margin-top: 32px;
    width: 100%;
    padding: 0 28px 0 23px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    img {
      width: 147px;
      height: 50px;
    }

    .btn {
      width: 80px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;

      &.fafang {
        background-image: url('https://oss-kiddo.manqu88.com/activity/chongfen/btn_fafang.png');
      }

      &.zhiyin {
        background-image: url('https://oss-kiddo.manqu88.com/activity/chongfen/btn_zhiyin.png');
      }
    }
  }

  .fuli2 {
    margin-top: 22px;
    width: 100%;
    padding: 0 23px;
    box-sizing: border-box;

    img {
      width: 330px;
      height: 65px;
    }
  }

  .fuli3 {
    margin-top: 22px;
    width: 100%;
    padding: 0 23px;
    box-sizing: border-box;

    img {
      width: 330px;
      height: 67px;
    }
  }

  .fuli4 {
    margin-top: 22px;
    width: 100%;
    padding: 0 23px;
    box-sizing: border-box;

    img {
      width: 330px;
      height: 66px;
    }
  }

  .result {
    width: 100%;
    text-align: right;

    .amount {
      width: 318px;
      height: 17px;
      font-size: 12px;
      margin: 0 auto;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
    }

    .progress {
      width: 318px;
      height: 10px;
      margin: 3px auto 0;
      background: url("https://oss-kiddo.manqu88.com/activity/chongfen/progress-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;

      .bar {
        // width: 67px;
        max-width: 315px;
        height: 10px;
        background: linear-gradient(180deg, #FFFFFF 0%, #544DE8 54%, #FFFFFF 100%);
        border-radius: 5px;
        opacity: 0.75;
      }

      .dot {
        margin-left: -9px;
        margin-top: -1px;
        width: 12px;
        height: 15px;
        background: url("https://oss-kiddo.manqu88.com/activity/chongfen/dot.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
      }
    }

    .total {
      margin: 7px auto 0;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      text-align: center;
    }
  }

  .bottom {
    width: 332px;
    height: 477px;
    margin: 44px auto;
    background: url("https://oss-kiddo.manqu88.com/activity/chongfen/rule.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>