<template>
  <div class="about">
    <div v-for="(item, index) in config" :key="index" class="display">
      <div>{{ index + 1 + "、" + item.title }}:</div>
      <div :v-if="config[index].methodKey">
        <div
          v-for="(items, indexs) in config[index].methodKey"
          :key="indexs"
          style="
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
            display: flex;
          "
        >
          {{ items }}:
          <input type="text" v-model="config[index].methodValue[indexs]" />
        </div>
      </div>
      <button @click="method(index)">Go</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import browser from "@/utils/browser";
export default {
  name: "appMethod",
  components: {},
  created() {
    document.title = "appMethod";
  },
  data() {
    return {
      config: [
        {
          title: "跳转首页",
          methodName: "jumpToMain",
        },
        {
          title: "跳转商品详情",
          methodName: "jumpToGoodDetail",
          methodKey: ["id"],
          methodValue: [""],
        },
        {
          title: "跳转订单详情",
          methodName: "jumpToOrderDetail",
          methodKey: ["orderId"],
          methodValue: [""],
        },
        {
          title: "跳转订单列表",
          methodName: "jumpToOrder",
          methodKey: ["index"],
          methodValue: [""],
        },
        {
          title: "获取token",
          methodName: "getToken",
        },
        {
          title: "获取版本号",
          methodName: "getVersion",
        },
        {
          title: "获取App系统",
          methodName: "getSystem",
        },
        {
          title: "获取用户手机号",
          methodName: "getUserPhone",
        },
        {
          title: "获取用户id",
          methodName: "getUserId",
        },
        {
          title: "跳转装修页",
          methodName: "jumpToDynamic",
          methodKey: ["id"],
          methodValue: [""],
        },
        {
          title: "唤起支付",
          methodName: "jumpToPay",
          methodKey: ["orderId", "payPrice"],
          methodValue: ["", ""],
        },
        {
          title: "跳转消息中心",
          methodName: "jumpToMessage",
        },
        {
          title: "跳转优惠卷",
          methodName: "jumpToCoupon",
        },
        {
          title: "跳转客服",
          methodName: "jumpToService",
        },
        {
          title: "跳转我的客户（B端）",
          methodName: "jumpToMyClient",
        },
        {
          title: "跳转账户资金（B端）",
          methodName: "jumpToMyFund",
        },
        {
          title: "跳转我的意向用户（B端）",
          methodName: "jumpToMyIntentUser",
        },
        {
          title: "跳转平台奖励（B端） ",
          methodName: "jumpToReward",
        },
        {
          title: "跳转新品页",
          methodName: "jumpToNewGood",
        },
        {
          title: "跳转热卖排行",
          methodName: "jumpToRankGood",
        },
        {
          title: "跳转主题活动",
          methodName: "jumpToThemeGood",
        },
        {
          title: "跳转品牌商品列表",
          methodName: "jumpToBrandGood",
          methodKey: ["brandId", "title"],
          methodValue: ["", ""],
        },
        {
          title: "跳转分类商品列表",
          methodName: "jumpToCategoryGood",
          methodKey: ["title", "frontCategoryId"],
          methodValue: ["", ""],
        },
        {
          title: "跳转指定页面",
          methodName: "openPage",
          methodKey: ["type", "param"],
          methodValue: ["", ""],
        },
      ],
    };
  },
  methods: {
    getMsg(e) {
      console.log("eeeeee");
    },
    method(index) {
      let name = this.config[index].methodName;
      let data = {};
      if (this.config[index].methodKey != undefined) {
        for (let i = 0; i < this.config[index].methodKey.length; i++) {
          data[this.config[index].methodKey[i]] =
            this.config[index].methodKey[i] === "type"
              ? Number(this.config[index].methodValue[i])
              : this.config[index].methodValue[i];
        }
      }
      console.log(this.config[index], data, name);
      uni.webView.getEnv((res) => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
        } else {
          this.$bridge.call(name, { ...data }).then((e) => {
            console.log(name + "客户端返回: ", e);
          });
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.font {
  display: block;
  // color: #fff;
}
.display {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
</style>
