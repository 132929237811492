var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-kiddolRank-wrap"},[_c('div',{staticClass:"top-box"}),_c('div',{staticClass:"scroll-view"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"tweet-li"},[_c('div',{staticClass:"tweet-li-left"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-info-head",style:('background: url(' + item.headIcon + ') no-repeat center')}),_c('div',{staticClass:"user-info-nice"},[_c('div',{staticClass:"user-info-name"},[_vm._v(_vm._s(item.nickName))]),_c('div',{staticClass:"user-info-like"},[_c('span',[_vm._v("点赞: ")]),_c('span',{staticStyle:{"color":"#c31b1b"}},[_vm._v(_vm._s(item.likeNumberValue))])])]),_c('img',{staticClass:"rank-icon",style:({
              display: index === 0 ? 'block' : 'none',
              width: '20px',
              height: '19px',
              marginTop: '-8px',
              marginLeft: '-4px',
            }),attrs:{"src":"https://kiddo-bucket.manqu88.com/kiddol-rank1.png","alt":""}}),_c('img',{staticClass:"rank-icon",style:({
              display: index === 1 ? 'block' : 'none',
              width: '22px',
              height: '19px',
              marginTop: '-8px',
              marginLeft: '-7px',
            }),attrs:{"src":"https://kiddo-bucket.manqu88.com/kiddol-rank2.png","alt":""}}),_c('img',{staticClass:"rank-icon",style:({
              display: index === 2 ? 'block' : 'none',
              width: '20px',
              height: '18px',
              marginTop: '-8px',
              marginLeft: '-4px',
            }),attrs:{"src":"https://kiddo-bucket.manqu88.com/kiddol-rank3.png","alt":""}})]),_c('div',{staticClass:"user-content"},[_c('div',{staticClass:"user-content-box"},[_c('div',{staticClass:"user-content-text"},[_vm._v(_vm._s(item.content))])])])]),_c('div',{staticClass:"tweet-li-right",style:('background: url(' + item.coverImg + ') no-repeat center')})])}),(!_vm.loading && !_vm.list.length)?_c('div',{staticClass:"empty-tweet-box"},[_vm._v("暂无推文数据～")]):_vm._e()],2),_c('img',{staticClass:"bottom-bg",attrs:{"src":"https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddol-rank-bottom-bg-1.png","alt":""}}),_c('img',{staticClass:"activity-rule-entry",attrs:{"src":"https://kiddo-bucket.manqu88.com/kiddol-rank-rule.png","alt":""},on:{"click":_vm.handleToRule}}),_c('img',{staticClass:"activity-rule-tweet",attrs:{"src":"https://kiddo-bucket.manqu88.com/kiddol-rank-tweet.png","alt":""},on:{"click":_vm.handleToTweet}})])}
var staticRenderFns = []

export { render, staticRenderFns }