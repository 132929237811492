<template>
  <div class="card_detail_container">
    <van-list
      v-if="!isShowEmpty"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="onLoad"
    >
      <div
        v-for="item in detailListData"
        :key="item.id"
        class="universal-list"
        @click="handleGoToOrderDetail(item.orderId)"
      >
        <div class="universal-list-left">
          <p class="universal-list-title van-ellipsis">
            {{ item.title }}
          </p>
          <p class="universal-list-number">交易编号 {{ item.flowId }}</p>
          <p class="universal-list-time">
            交易时间
            {{ item.gmtCreate && moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
        </div>
        <div class="universal-list-right">
          <p class="universal-list-money">{{ item.amountStr }}</p>
          <div v-show="item.orderId" class="universal-list-detail">
            查看详情
          </div>
        </div>
        <div class="universal-list-line"></div>
      </div>
    </van-list>
    <van-empty v-if="isShowEmpty" description="暂无数据" />
  </div>
</template>

<script>
import moment from "moment";

export default {
  created() {
    document.title = "明细";
  },
  data() {
    return {
      token: "",
      detailListData: [],
      isShowEmpty: false,
      loading: false,
      finished: false,
      currentPage: 2,
      cardNo: this.$route.query.cardNo
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getDetailListData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetailListData();
        });
      }
    });
  },
  methods: {
    moment,
    async getDetailListData() {
      const params = { currentPage: 1, cardNo: this.cardNo, pageSize: 20 };
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardFlowList",
          data: params
        });
        if (data.success) {
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.isShowEmpty = true;
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.isShowEmpty = false;
            this.detailListData = [...data.data];
          }
        } else {
          this.finished = true;
          this.loading = false;
          this.isShowEmpty = true;
          this.$toast(data.message);
        }
      } catch (error) {
        this.finished = true;
        this.loading = false;
        this.isShowEmpty = true;
      }
    },
    async onLoad() {
      const params = { currentPage: this.currentPage, cardNo: this.cardNo, pageSize: 20 };
      try {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardFlowList",
          data: params
        });
        if (data.success) {
          this.currentPage++;
          this.loading = false;
          if (data.data === null || data.data.length === 0) {
            this.finished = true;
          } else {
            if (data.data.length < 20) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.detailListData = [...this.detailListData, ...data.data];
          }
        } else {
          this.finished = true;
          this.loading = false;
          this.$toast(data.message);
        }
      } catch (error) {
        this.$toast(error.message);
        this.finished = true;
        this.loading = false;
      }
    },
    handleGoToOrderDetail(orderId) {
      if (orderId && orderId.length > 0) {
        this.$router.push(`/addol/orderDetail?token=${this.token}&orderId=${orderId}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card_detail_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;
  /deep/ .van-empty {
    width: 100vw;
    height: 90vh;
  }
  .universal-list {
    box-sizing: border-box;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    background-color: white;
    position: relative;

    .universal-list-left {
      flex: 1;
      width: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;

      .universal-list-title {
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
      }

      .universal-list-number {
        padding: 0;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .universal-list-time {
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }

    .universal-list-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 73px;

      .universal-list-money {
        padding: 0;
        margin: 0;
        font-size: 11px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #de7d36;
        text-align: right;
      }

      .universal-list-detail {
        margin-top: 15px;
        width: 73px;
        height: 25px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #b0b0b0;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        text-align: center;
        line-height: 25px;
      }
    }

    .universal-list-line {
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 17px;
      width: 317px;
      height: 1px;
      background-color: #f5f5f5;
      display: block;
    }
  }

  .universal-list:nth-last-child(1) {
    .universal-list-line {
      display: none;
    }
  }
}
</style>
