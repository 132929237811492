<template>
  <div class="ck-wheel">
    <div class="whiteBack"></div>
    <div class="showRules" @click="goRules">查看助力金规则 ></div>
    <div class="warn"></div>
    <div class="noList" v-if="noList"></div>

    <div
      v-for="(item, index) in assistList"
      :key="index"
      class="card"
      :style="{
        backgroundImage: `url(${'https://oss-kiddo.manqu88.com/h5/assist/listBg_' +
          (item.status === 0 ? 1 : item.status === 3 ? 3 : 2) +
          '.png?' +
          Date.now()})`
      }"
      @click="goDetailPage(item.id)"
    >
      <div
        class="title"
        :style="{
          color: item.status === 0 ? '#D2013D' : '#838383'
        }"
      >
        {{ item.name }}
      </div>
      <div class="date">
        {{ moment(item.useStartTime).format("YYYY.MM.DD HH:mm:ss") }}-{{
          moment(item.useEndTime).format("YYYY.MM.DD HH:mm:ss")
        }}
      </div>
      <!--  -->
      <div
        class="goldView"
        :style="{
          color: item.status === 0 ? '#D2013D' : '#838383'
        }"
      >
        <div class="unit">¥</div>
        <div class="int">{{ formatPrice(item.finalPrice).split(".")[0] }}</div>
        <div class="dot">.{{ formatPrice(item.finalPrice).split(".")[1] }}</div>
      </div>
    </div>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      assistList: [],
      noList: false
    };
  },
  created() {
    document.title = "我的助力金";
  },
  mounted() {
    // this.getList(); //test
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.getList();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getList();
          });
        }
      });
    }, 1000);
    this.$setAssistGoldData({
      type: 2 //1:详情页 0:活动页 2:列表页
    });
  },

  methods: {
    formatPrice,
    moment,
    async getList() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/assist/queryAssistList",
        data: {
          pageSize: 100,
          currentPage: 1,
          assistListType: 1 //0：活动页面；1：我的助力金页面
        }
      });
      if (data.success) {
        this.assistList = data.data || [];
        this.jumpPage();
      } else {
        this.$toast(data.message);
      }
      this.noList = this.assistList.length === 0;
    },
    jumpPage() {
      if (this.assistList.length > 0) {
        let isGoDetail = this.moment(
          this.moment(this.assistList[0].gmtCreate).format("YYYY-MM-DD")
        ).isAfter("2022-12-01");
        if (isGoDetail) {
          this.$router.push(
            `/assistDetail?id=${this.assistList[0].id}&token=${this.token}&time=${Date.now()}`
          );
        } else {
          this.$router.replace(`/assist?token=${this.token}&time=${Date.now()}`);
        }
      } else {
        this.$router.replace(`/assist?token=${this.token}&time=${Date.now()}`);
      }
    },
    goDetailPage(id) {
      this.$router.push(`/assistDetail?id=${id}&token=${this.token}&time=${Date.now()}`);
    },
    goRules() {
      this.$router.push(`/assistRules?time=${Date.now()}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  .whiteBack {
    position: fixed;
    bottom: 0;
    width: 375px;
    height: 50px;
    background: #ffffff;
    z-index: 10000;
  }
  .showRules {
    position: fixed;
    bottom: 15px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    line-height: 20px;
    z-index: 10000;
  }
  .noList {
    margin-top: 199px;
    width: 100px;
    height: 133px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
    background-size: 100% 100%;
  }
  .warn {
    margin-top: 13px;
    margin-bottom: 10px;
    width: 357px;
    height: 58px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/warn.png) no-repeat;
    background-size: 100% 100%;
  }
  .card {
    position: relative;
    width: 357px;
    height: 104px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/listBg_1.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    .goldView {
      position: absolute;
      left: 0px;
      top: 22px;
      width: 107px;
      height: 26px;
      display: flex;
      justify-content: center;

      .unit {
        font-size: 15px;
        font-family: KeepCalm-Medium, KeepCalm;
        font-weight: 500;
        // color: #df6027;
        line-height: 34px;
      }

      .int {
        font-size: 26px;
        font-family: KeepCalm-Medium, KeepCalm;
        font-weight: 500;
        // color: #df6027;
        line-height: 26px;
      }

      .dot {
        font-size: 14px;
        font-family: KeepCalm-Medium, KeepCalm;
        font-weight: 500;
        // color: #df6027;
        line-height: 34px;
      }
    }
    .title {
      position: absolute;
      left: 107px;
      top: 8px;
      width: 210px;
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      /* 作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
      -webkit-box-orient: vertical;
      /* 显示的行 */
      -webkit-line-clamp: 1;
    }
    .date {
      position: absolute;
      left: 107px;
      top: 29px;
      height: 13px;
      font-size: 9px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8b8b8b;
      line-height: 13px;
    }
  }
}
</style>
