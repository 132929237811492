<template>
    <div class="ck-wheel">
        <div class="itemImg" :style="{ backgroundImage: `url(${activityData.coverImage})` }">
            <div class="btn-share" @click="share"></div>
            <div class="btn-rule" @click="goRule(2)"></div>
            <div class="phaseNum">
                <div>第</div>
                <div style="width:1px"></div>
                <div>{{ activityData.phase && activityData.phase.toString().padStart(2, '0') }}</div>
                <div style="width:1px"></div>
                <div>期</div>
            </div>
        </div>

        <div class="itemCard">
            <div class="item-title">{{ activityData.name }}</div>
            <div class="item-cost">
                <div class="score-cost">{{ activityData.lessCodeNum }}</div>
                <div class="unit">金豆抽奖</div>
                <div class="price">￥{{ formatPrice(activityData.prizePrice) }}</div>
            </div>
            <div class="line"></div>

            <div class="title-view">
                <div class="title">抽奖进度</div>
                <div class="pastRecord" @click="goPhaseList(activityData.activityId)">查看往期抽奖
                    <div class="arrow"></div>
                </div>
            </div>

            <div class="progress-view">
                <div class="progress">
                    <div class="progress-inner" :style="{
                        width: `${100 - (activityData.surplusCodeNum <= 0 ? 0 : (100 * activityData.surplusCodeNum / activityData.needCodeNum))}%`
                    }"></div>
                </div>
                <div class="progress-num" v-if="activityData.surplusCodeNum">{{ 100 - (activityData.surplusCodeNum <= 0 ? 0
                    : (100 * activityData.surplusCodeNum / activityData.needCodeNum).toFixed(0)) }}%</div>
                </div>

                <div class="des">
                    <div class="des-num">{{ activityData.needCodeNum }}</div>
                    个抽奖码参与即可开奖 ，还差
                    <div class="des-num">{{ activityData.surplusCodeNum }}</div>
                    个抽奖码
                </div>
            </div>

            <div class="code-card">
                <div class="title-view">
                    <div class="title">我的抽奖码</div>
                    <div class="code-join">
                        您共参与抽奖：
                        <div class="code-num">{{ (activityData.userDrawCodeList && activityData.userDrawCodeList.length) ||
                            0
                        }}</div>
                        个
                    </div>
                </div>

                <div class="code-display" v-if="activityData.userDrawCodeList && activityData.userDrawCodeList.length > 0">
                    <div class="code-bg" v-for="(item, index ) in activityData.userDrawCodeList" :key="index">{{ item }}
                    </div>
                </div>

            </div>


            <div class="bottomCard">
                <div class="textDisplay share" @click="share">邀请好友</div>
                <div class="textDisplay draw" @click="showDraw = true" v-if="activityData.status === 2">立即抽奖</div>
                <div class="textDisplay countDown" v-if="activityData.status === 1">
                    距开始还剩：
                    <van-count-down :time="activityData.phaseStartTime - Date.now()" class="time" :millisecond="true"
                        format="DD天HH时mm分ss秒" @finish="getActivityData" />
                </div>
                <div class="textDisplay over" v-if="activityData.status === 3">已结束</div>

            </div>


            <div class="mask" v-if="showDraw" @click.stop="showDraw = false">
                <div class="draw-card" @click.stop="">
                    <div class="draw-title"></div>
                    <div class="draw-des">
                        <div class="draw-icon"></div>
                        <div>
                            <div>单次至少投入{{ activityData.lessCodeNum }}个金豆 </div>
                            <div>1金豆兑换1抽奖码 </div>
                            <div>单个宝贝最多可兑换{{ activityData.mostCodeNumber }}个抽奖码</div>
                        </div>
                    </div>
                    <div class="draw-line"></div>
                    <van-stepper v-model="goldNum" step="1" :min="activityData.lessCodeNum"
                        :max="goldData.useGoldNum >= activityData.mostCodeNumber ? activityData.mostCodeNumber : goldData.useGoldNum" input-width="60" button-size="32"
                        decimal-length="0" :integer="true" />
                    <div class="draw-score">您当前账号剩余：{{ goldData.useGoldNum }}个金豆</div>
                    <div class="draw-btn" @click="userJoin">确定抽奖</div>
                </div>
            </div>

            <div class="mask" v-if="showPop">
                <div class="pop" @click="goHomePage">
                    <div class="btn-close" @click.stop="showPop = false"></div>
                </div>
            </div>

            <!-- 金豆不足 -->
            <div class="mask" v-if="noBean">
                <div class="noBean">
                    <div class="btn-noBean-close" @click.stop="noBean = false"></div>
                    <div class="invite" @click="share"></div>
                    <div class="homePage" @click="goHomePage"></div>
                </div>
            </div>

            <!-- wx消息订阅 -->
            <!-- <div v-if="showSub">
                <wx-open-subscribe template="9WVi2BY6V1BoVxzdGywSjXFf9ciOQ9kLNL6hCaHjXa0" id="subscribe-btn" @success="onSubscribe" @error="onSubscribe">
                    <script type="text/wxtag-template" slot="style">
                        <style>
                                .subscribe-btn {
                                  color: #fff;
                                  background-color: #07c160;
                                }
                        </style>
                    </script>
                    <script type="text/wxtag-template">
                        <button class="subscribe-btn">
                                一次性模版消息订阅              
                              </button>
                    </script>
                </wx-open-subscribe>
            </div> -->
        </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import { init, wx_weapp_launch, wx_app_launch } from "@/utils/wx-launch";
import vConsole from 'vconsole'
export default {
    name: "assist",
    components: {},
    data() {
        return {
            id: "",
            itemId: "",
            token: this.$route.query.token,
            assistList: [],
            isWx: false,
            token: this.$route.query.token,
            activityId: this.$route.query.activityId,
            phaseId: this.$route.query.phaseId,
            activityData: {},
            goldNum: 1,
            goldData: {
                userId: '',
                useTakeGoldNum: '',
                useGoldNum: '',
            },
            showDraw: false,
            templateId: ['9WVi2BY6V1BoVxzdGywSjXFf9ciOQ9kLNL6hCaHjXa0', 'XNCXGpZvmb0hS_oFxP2x1pNKT5i0tzbZ-vTBF1R02e4'],
            showSub: false,
            showPop: false,
            noBean: false,
        };
    },
    created() {
        document.title = "夺宝详情";
        // this.initWxConfig();
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        // var vconsole = new vConsole();
        this.token = this.$route.query.token;
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                    this.getActivityData();
                    this.isWx = true;
                } else {
                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                        this.getActivityData();
                    });
                }
            });
        }, 0);
    },

    methods: {
        formatPrice,
        async getActivityData() {
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token
                },
                url: "/api/treasureActivity/queryTreasureDetail",
                data: {
                    activityId: this.activityId
                }
            });
            if (data.success) {
                this.activityData = data.data;
                this.phaseId = data.data.phaseId;
            } else {
                this.$toast(data.message);
            }
            this.getGoldData();
        },
        async getGoldData() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/userGold/queryUserGoldData",
                    data: {
                    }
                });
                if (data.success) {
                    this.goldData = data.data;
                } else {
                    this.$toast(data.message);
                }
            }
        },

        async userJoin() {
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac",
                    clientSource: this.$clientSource(),
                    Authorization: this.token
                },
                url: "/api/treasure/userJoin",
                data: {
                    activityId: this.activityId,
                    phaseId: this.phaseId,
                    goldNum: this.goldNum,
                }
            });
            if (data.success) {
                // this.activityData = data.data;
                this.showPop = true;
            }
            else if (data.code === 600000004) {
                this.noBean = true;
            }
            else {
                this.$toast(data.message);
            }
            this.getActivityData();
            this.showDraw = false;
        },

        onSubscribe() { },


        async initWxConfig() {
            wx.ready((e) => {
                console.log(e, "rr222");
                this.$toast('ready');
                this.showSub = true;
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            });
            wx.error(function (e) {
                console.log(e, "error");
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
            });
            const { data } = await this.$axios({
                method: "post",
                headers: {
                    mac: "mac"
                },
                url: "/api/wechat/js/getSignature",
                data: {
                    url: window.location.href
                }
            });
            if (data.success) {
                init(data.data);
            } else {
                this.$toast(data.message);
            }
        },
        // 跳转应用主页
        goHomePage() {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram) {
                    uni.webView.switchTab({
                        url: "/pages/index/index"
                    });
                } else {
                    this.$bridge.call("jumpToMain", {}).then(e => {
                        console.log("jumpToMain客户端返回: ", e);
                    });
                }
            });
        },

        share() {
            uni.webView.getEnv(res => {
                let des = `0元抽奖，${this.activityData.name}带回家！马上参与，开启你的幸运之旅！`;
                if (res.miniprogram) {
                    uni.webView.navigateTo({
                        url: `/otherPackage/db/shareInfo?activityId=${this.activityId}&phaseId=${this.phaseId}&coverImg=${this.activityData.coverImage}&des=${des}&title=${this.activityData.name}` //shareType    分享类型  1- 活动分享   2-助力页分享   assistId    // 助力金id  shareType=2时，必填
                    });

                } else {
                    let param = {
                        type: 1,
                        param: {
                            activityId: this.activityId.toString(),
                            phaseId: this.phaseId.toString(),
                            title: this.activityData.name,
                            des: des,
                            image: this.activityData.coverImage
                        }
                    }
                    this.$bridge
                        .call("openPage", {
                            type: 49,
                            param: JSON.stringify(param)
                        })
                        .then(e => {
                            console.log("openPage27客户端返回: ", e);
                        });
                }
            });
        },
        goPhaseList(activityId) {
            this.$router.push(`/treasure/phaseList?token=${this.token}&activityId=${activityId}`);
        },
        goRule(type) {
            this.$router.push(`/treasure/rule?type=${type}`);
        },

    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "mcFont";
    src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
    width: 375px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F5F5;
    padding-bottom: 100px;

    .itemImg {
        position: relative;
        width: 375px;
        height: 375px;
        background: #D8D8D8;
        background-size: 100% 100%;

        .btn-share {
            position: absolute;
            top: 64px;
            right: 7px;
            width: 32px;
            height: 32px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_share.png?5) no-repeat;
            background-size: 100% 100%;
        }

        .btn-rule {
            position: absolute;
            top: 22px;
            right: 7px;
            width: 32px;
            height: 32px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_rule.png?5) no-repeat;
            background-size: 100% 100%;
        }

        .phaseNum {
            position: absolute;
            left: 10px;
            top: 12px;
            width: 64px;
            height: 26px;
            background: #FFE800;
            border-radius: 13px;
            line-height: 26px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #010100;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .itemCard {
        margin-top: 10px;
        width: 355px;
        height: 183px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;


        .item-title {
            margin-left: 14px;
            margin-top: 14px;
            width: 323px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #232323;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 1;
        }

        .item-cost {
            display: flex;
            height: 22px;
            align-items: center;
            margin-left: 14px;
            margin-top: 8px;


            .score-cost {
                font-size: 22px;
                font-family: Barlow-SemiBold, Barlow;
                font-weight: 600;
                color: #FF5A29;
                line-height: 32px;
            }

            .unit {
                margin-left: 4px;
                margin-right: 4px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FF5A27;
                line-height: 16px;
            }

            .price {
                font-size: 14px;
                font-family: Barlow-SemiBold, Barlow;
                font-weight: 400;
                color: #888888;
                line-height: 14px;
                text-decoration: line-through;
            }
        }


        .line {
            margin-top: 16px;
            width: 356px;
            height: 1px;
            border-bottom: 1px solid #F6F6F6;
            margin-bottom: 11px;
        }

        .progress-view {
            margin-top: 12px;
            width: 100%;
            height: 18px;
            display: flex;
            align-items: center;

            .progress {
                position: relative;
                margin-left: 14px;
                margin-top: 12px;
                width: 282px;
                height: 18px;
                background: #F2F2F2;
                border-radius: 8px;

                .progress-inner {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0px;
                    height: 18px;
                    background: #FF5A26;
                    border-radius: 8px;
                }
            }

            .progress-num {
                margin-left: 12px;
                height: 17px;
                font-size: 14px;
                font-family: Barlow-SemiBold, Barlow;
                font-weight: 600;
                color: #FF5A29;
                line-height: 28px;
            }
        }

        .des {
            margin-left: 14px;
            margin-top: 12px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: Barlow-SemiBold, Barlow;
            font-weight: 600;
            color: #666666;

            .des-num {
                color: #FF5A29;
            }
        }


    }

    .code-card {
        padding-top: 11px;
        margin-top: 10px;
        width: 355px;
        min-height: 31px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .code-display {
            margin-top: 7px;
            margin-bottom: 4px;
            width: 348px;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .code-bg {
                margin-left: 4px;
                margin-right: 4px;
                margin-top: 7px;
                margin-bottom: 7px;
                width: 76px;
                height: 21px;
                background: #FBFBFB;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
            }

        }
    }


    .share {
        width: 50%;
        height: 100px;
    }


    .title-view {
        width: 355px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            margin-left: 14px;
            font-weight: 500;
        }

        .pastRecord {
            margin-right: 14px;
            display: flex;
            align-items: center;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            line-height: 20px;

            .arrow {
                margin-left: 5px;
                width: 5px;
                height: 8px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/arrow.png?2) no-repeat;
                background-size: 100% 100%;
            }
        }

        .code-join {
            margin-right: 14px;
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #131313;
            line-height: 20px;
            display: flex;
            align-items: center;

            .code-num {
                font-size: 16px;
                margin-left: 2px;
                margin-right: 2px;
                line-height: 32px;
                color: #FF5A29;
            }
        }
    }

    .bottomCard {
        position: fixed;
        bottom: 0;
        width: 375px;
        height: 76px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        display: flex;
        align-items: center;

        .textDisplay {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }

        .share {
            margin-left: 13px;
            width: 112px;
            height: 40px;
            background: linear-gradient(90deg, #FDAA36 0%, #F59946 100%);
            border-radius: 4px;
        }

        .draw {
            margin-left: 8px;
            width: 230px;
            height: 40px;
            background: linear-gradient(270deg, #FC7223 0%, #F9550A 100%);
            border-radius: 4px;
        }

        .countDown {
            margin-left: 8px;
            width: 230px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #F0F0F0;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FF5A27;

            .time {
                margin-top: 1px;
                font-size: 14px;
                color: #FF5A27;
            }
        }


        .over {
            margin-left: 8px;
            width: 230px;
            height: 40px;
            background: #D3D3D3;
            border-radius: 4px;
        }

    }


    .mask {
        width: 375px;
        height: 100vh;
        background: rgba($color: #000000, $alpha: 0.5);
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .noBean {
            position: relative;
            width: 290px;
            height: 264px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/noBean.png?5) no-repeat;
            background-size: 100% 100%;

            .btn-noBean-close {
                position: absolute;
                right: -18px;
                top: 12px;
                width: 36px;
                height: 36px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_close.png?5) no-repeat;
                background-size: 100% 100%;
            }

            .invite {
                position: absolute;
                left: 5px;
                bottom: 15px;
                width: 138px;
                height: 55px;
            }

            .homePage {
                position: absolute;
                right: 5px;
                bottom: 15px;
                width: 138px;
                height: 55px;
            }
        }

        .pop {
            position: relative;
            width: 290px;
            height: 372px;
            background: url(https://oss-kiddo.manqu88.com/h5/duobao/joinSuccess.png?5) no-repeat;
            background-size: 100% 100%;

            .btn-close {
                position: absolute;
                right: -18px;
                top: -18px;
                width: 36px;
                height: 36px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/btn_close.png?5) no-repeat;
                background-size: 100% 100%;
            }
        }

        .draw-card {
            position: absolute;
            bottom: 0;
            width: 375px;
            height: 362px;
            background: #FFFFFF;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .draw-title {
                margin-top: 24px;
                font-size: 22px;
                font-family: OPPOSans-H, OPPOSans;
                font-weight: normal;
                color: #FF5A27;
                line-height: 35px;
                letter-spacing: 1px;
                width: 82px;
                height: 20px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/drawTitle.png?5) no-repeat;
                background-size: 100% 100%;
            }

            .draw-des {
                margin-top: 12px;

                height: 78px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #404040;
                line-height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;

                .draw-icon {
                    margin-right: 15px;
                    width: 76px;
                    height: 79px;
                    background: url(https://oss-kiddo.manqu88.com/h5/duobao/draw_icon.png?5) no-repeat;
                    background-size: 100% 100%;
                }

                .draw-msg {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .draw-line {
                margin-bottom: 22px;
                margin-top: 14px;
                width: 375px;
                height: 2px;
                background: #FBFBFB;
            }

            .draw-score {
                margin-top: 18px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #3A3A3A;
                line-height: 20px;
            }

            .draw-btn {
                margin-top: 34px;
                width: 316px;
                height: 40px;
                background: linear-gradient(270deg, #FC7223 0%, #F9550A 100%);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
            }
        }
    }
}
</style>
  