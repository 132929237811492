<template>
	<div>
		<div :class="['rule-' + type]"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			timeStamp: 0,
			type: this.$route.query.type,
		}
	},
	mounted() {
		document.title = type == 3 ? '金豆说明' : '活动规则';
	}
}
</script>

<style lang="scss" scoped >
.kiddol-rule-bg {
	display: block;
	width: 100%;
}

.rule-1 {
	width: 375px;
	height: 1013px;
	background: url(https://oss-kiddo.manqu88.com/h5/duobao/rule_1.png?2) no-repeat;
	background-size: 100% 100%;
}

.rule-2 {
	width: 375px;
	height: 802px;
	background: url(https://oss-kiddo.manqu88.com/h5/duobao/rule_2.png?2) no-repeat;
	background-size: 100% 100%;
}

.rule-3 {
	width: 375px;
	height: 295px;
	background: url(https://oss-kiddo.manqu88.com/h5/duobao/rule_goldBean.png?2) no-repeat;
	background-size: 100% 100%;
}
</style>