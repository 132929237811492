<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div class="ck-wheel">
      <div class="recordRules" v-if="type == 7"></div>
      <div class="noRecord" v-if="isShow && recordList.length == 0"></div>
      <div
        @click="toUse(item.status !== 2)"
        v-for="(item, index) in recordList"
        :key="index"
        :class="[
          type == 7
            ? item.status === 2
              ? 'card_2_out'
              : 'card_2'
            : item.status === 2
            ? 'card_1_out'
            : 'card_1',
          'card'
        ]"
      >
        <div class="gold">{{ item.turntableDTO.prizeName }}</div>
        <div class="time">获得时间：{{ formatDate(item.gmtCreate) }}</div>
        <div class="time" v-if="type == 7">
          可用时间：{{
            formatDate(
              item.gmtCreate +
                Number(item.turntableDTO.extension.FREEZE_DAYS) * 3600 * 24 * 1000
            )
          }}
        </div>
      </div>
      <div style="height:20px;"></div>
    </div>

    <!-- 地址 -->
    <div class="popup" v-show="showModal">
      <div class="mask"></div>
      <div class="modal">
        <img
          class="close"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/icon-close.png"
          @click="close"
        />
        <div class="title">填写收货信息</div>
        <div class="border"></div>
        <div class="form">
          <div class="label">收货人姓名</div>
          <van-field
            v-model="receiverName"
            placeholder="请输入收货人姓名"
            maxlength="16"
            :border="false"
          />
          <div class="label">收货人手机号</div>
          <van-field
            v-model="receiverPhone"
            type="tel"
            placeholder="请输入收货人手机号"
            maxlength="11"
            :border="false"
          />
          <div class="label">收货地址</div>
          <van-field
            v-model="address"
            readonly
            placeholder="请选择省-市-区"
            :border="false"
            @click="onShowArea"
          />
          <van-field
            class="detail"
            v-model="detail"
            rows="2"
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="100"
            :border="false"
            @click="handleScrollNormal('detail')"
          />
          <div class="save" @click="onSave">保存</div>
        </div>
      </div>
    </div>
    <div v-show="showArea" class="mask" @click="cancel"></div>
    <van-area
      class="area"
      :class="{ showArea: showArea }"
      title="标题"
      :area-list="areaList"
      @confirm="confirm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import browser from "@/utils/browser";
import { formatDate } from "@/utils/time";
import { formatPrice } from "@/utils/money";
export default {
  name: "turnTableRecord",
  components: {},
  created() {
    document.title = "中奖记录";
  },
  mounted() {
    this.getRecordData();
    // this.$refs.tabs.scrollTo(this.prizeType);
  },
  data() {
    return {
      prizeType: 4, //1：优惠券；2：实物商品奖品，3：积分 4:金币
      token: this.$route.query.token,
      type: this.$route.query.type,
      isShow: false,
      recordList: [],
      showModal: false,
      areaList,
      showArea: false,
      receiverName: "",
      receiverPhone: "",
      address: "",
      provinceName: "",
      cityName: "",
      areaName: "",
      detail: ""
    };
  },
  methods: {
    formatPrice,
    formatDate,

    async getRecordData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/prizeRecord/queryList",
        data: {
          type: this.type
          // prizeType: this.prizeType
        }
      });
      this.isShow = true;
      if (data.success) {
        this.recordList = data.data;
      } else {
        this.$toast(data.message);
      }
    },
    toUse(canclick) {
      if (!canclick) {
        return;
      }
      let isJumpToMain = this.type == 6;
      if (!isJumpToMain) {
        this.$router.push(`/goldDetail?token=${this.token}&time=${Date.now()}`);
        return;
      }
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          if (isJumpToMain) {
            uni.webView.switchTab({
              url: "/pages/index/index"
            });
          } else {
            uni.webView.navigateTo({
              url: `/pagesA/detail/index?id=${id}`
            });
          }
        } else {
          if (isJumpToMain) {
            this.$bridge.call("jumpToMain", {}).then(e => {
              console.log("jumpToMain客户端返回: ", e);
            });
          } else {
            this.$bridge
              .call("openPage", {
                type: 4,
                param: id
              })
              .then(e => {
                console.log("jumpToDetail客户端返回: ", e);
              });
          }
        }
      });
    },
    showAdress(id) {
      // this.showModal = true;
      this.$router.push("/turnTableAddress?id=" + id + "&token=" + this.token);
    },

    showGoods(id) {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${id}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: id
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },

    close() {
      this.showModal = false;
    },
    onShowArea() {
      this.showArea = true;
    },
    confirm(value) {
      this.showArea = false;
      console.log(value);
      this.address = `${value[0].name}-${value[1].name}-${value[2].name}`;
      this.provinceName = value[0].name;
      this.cityName = value[1].name;
      this.areaName = value[2].name;
    },
    cancel() {
      this.showArea = false;
    },
    onSave() {
      const {
        receiverName,
        receiverPhone,
        provinceName,
        cityName,
        areaName,
        detail,
        address
      } = this;

      if (!receiverName) {
        Toast("请填写收货人姓名");
        return;
      }
      if (!receiverPhone) {
        Toast("请填写收货人手机号");
        return;
      }
      const reg = new RegExp(/^1\d{10}$/);
      if (!reg.test(receiverPhone)) {
        Toast("请填写正确的手机号");
        return;
      }
      if (!address) {
        Toast("请选择地区");
        return;
      }
      if (!detail) {
        Toast("请填写详细地址");
        return;
      }

      //area
      this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.clientSource,
          Authorization: this.token
        },
        url: "/api/reward/addUserAddress",
        data: {
          receiverName,
          receiverPhone,
          provinceName,
          cityName,
          areaName,
          detail
        }
      })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            Toast("保存成功！");
            this.showModal = false;
            this.hasAddress = true;
          } else {
            Toast(data.message);
          }
        })
        .catch(err => {
          console.log(err);
          Toast("保存失败，请重试！");
        });
    },
    handleScrollNormal(param) {
      setTimeout(function() {
        document.getElementsByClassName(param)[0].scrollIntoViewIfNeeded();
      }, 500);
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/bg.png) no-repeat;
  background-size: 100% 100%;
  .card {
    position: relative;
    margin-top: 12px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    .gold {
      margin-top: 19px;
      height: 30px;
      font-size: 25px;
      font-family: JDZhengHT-Regular, JDZhengHT;
      font-weight: 400;
      color: #f45500;
      line-height: 30px;
    }
    .time {
      margin-top: 2px;
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
    }
  }
  .card_1 {
    width: 341px;
    height: 88px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/recordBg_1.png) no-repeat;
    background-size: 100% 100%;
  }
  .card_1_out {
    width: 341px;
    height: 105px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/outTimeBg_1.png) no-repeat;
    background-size: 100% 100%;
  }
  .card_2 {
    width: 341px;
    height: 105px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/recordBg_2.png) no-repeat;
    background-size: 100% 100%;
  }
  .card_2_out {
    width: 341px;
    height: 105px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/outTimeBg_2.png) no-repeat;
    background-size: 100% 100%;
  }
  .recordRules {
    margin-top: 15px;
    width: 340px;
    height: 150px;
    background: url(https://oss-kiddo.manqu88.com/h5/618turnTable/recordRules.png) no-repeat;
    background-size: 100% 100%;
  }
  .noRecord {
    margin-top: 123px;
    width: 100px;
    height: 133px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
    background-size: 100% 100%;
  }
}

.noRecordTitle {
  position: fixed;
  left: 155px;
  top: 305px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
.logo {
  position: fixed;
  bottom: 48px;
  width: 160px;
  height: 45px;
  background: url(https://oss-kiddo.manqu88.com/h5/kiddolLogo.png) no-repeat;
  background-size: 100% 100%;
}

.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    padding-top: 42px;
    box-sizing: border-box;
    width: 340px;
    height: 458px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: left;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: -30px;
    }

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      line-height: 25px;
      letter-spacing: 1px;
    }

    .border {
      margin: 2px auto 0;
      width: 133px;
      height: 3px;
      background: #f9e46e;
    }

    .form {
      padding: 0 55px;
      box-sizing: border-box;

      .label {
        margin-top: 15px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        line-height: 18px;
      }

      input {
        margin-top: 7px;
      }

      .save {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        height: 39px;
        background: #f9550a;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
  .van-field {
    padding: 0 11px;
    width: 230px;
    // height: 32px;
    line-height: 32px;
    border: 1px solid #efefef;
    margin-top: 7px;
  }

  .van-field--min-height {
    line-height: 22px;
  }
}
.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
</style>
