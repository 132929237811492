var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ck-wheel"},[_c('div',{staticClass:"whiteBack"}),_c('div',{staticClass:"showRules",on:{"click":_vm.goRules}},[_vm._v("查看助力金规则 >")]),_c('div',{staticClass:"warn"}),(_vm.noList)?_c('div',{staticClass:"noList"}):_vm._e(),_vm._l((_vm.assistList),function(item,index){return _c('div',{key:index,staticClass:"card",style:({
      backgroundImage: ("url(" + ('https://oss-kiddo.manqu88.com/h5/assist/listBg_' +
        (item.status === 0 ? 1 : item.status === 3 ? 3 : 2) +
        '.png?' +
        Date.now()) + ")")
    }),on:{"click":function($event){return _vm.goDetailPage(item.id)}}},[_c('div',{staticClass:"title",style:({
        color: item.status === 0 ? '#D2013D' : '#838383'
      })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.moment(item.useStartTime).format("YYYY.MM.DD HH:mm:ss"))+"-"+_vm._s(_vm.moment(item.useEndTime).format("YYYY.MM.DD HH:mm:ss"))+" ")]),_c('div',{staticClass:"goldView",style:({
        color: item.status === 0 ? '#D2013D' : '#838383'
      })},[_c('div',{staticClass:"unit"},[_vm._v("¥")]),_c('div',{staticClass:"int"},[_vm._v(_vm._s(_vm.formatPrice(item.finalPrice).split(".")[0]))]),_c('div',{staticClass:"dot"},[_vm._v("."+_vm._s(_vm.formatPrice(item.finalPrice).split(".")[1]))])])])}),_c('div',{staticStyle:{"height":"50px"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }