<template>
  <div class="">
    <div :class="['ck-wheel']">
      <div class="logo-1"></div>
      <div class="logo-2"></div>
      <div class="btn" @click="onBtn">{{ title }}</div>
    </div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img src="@/assets/arrow.png" class="arrow" />
      <div class="txt">点击右上角，用浏览器打开</div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
export default {
  name: "download",
  components: {},
  created() {
    document.title = "APP下载";
    console.log(browser());
  },
  mounted() {
    if (browser().versions.android == true) {
      this.title = "下载App";
      this.systemType = 1;
      return;
    }
    if (browser().versions.ios == true) {
      this.title = "下载App";
      this.systemType = 2;
    }
  },
  data() {
    return {
      title: "复制App下载地址",
      systemType: 1,
      showShadow: false,
    };
  },
  methods: {
    onBtn() {
      if (this.$isWx()) {
        this.showShadow = true;
        return;
      }
      if (this.systemType !== 2) {
        window.location.href = "https://oss-kiddo.manqu88.com/kiddol.apk";

        // var copyDom = document.createElement("div");
        // copyDom.innerText = "https://oss-kiddo.manqu88.com/kiddol.apk";
        // copyDom.style.position = "absolute";
        // copyDom.style.top = "0px";
        // copyDom.style.right = "-9999px";
        // document.body.appendChild(copyDom);
        // //创建选中范围
        // var range = document.createRange();
        // range.selectNode(copyDom);
        // //移除剪切板中内容
        // window.getSelection().removeAllRanges();
        // //添加新的内容到剪切板
        // window.getSelection().addRange(range);
        // //复制
        // var successful = document.execCommand("copy");
        // copyDom.parentNode.removeChild(copyDom);
        // if (successful) {
        //   this.$toast("复制成功");
        // } else {
        //   this.$toast("复制失败");
        // }
      } else {
        var src = "https://apps.apple.com/cn/app/kiddol/id1573521612";
        window.location.href = src;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.logo-1 {
  margin-top: 126px;
  width: 100px;
  height: 100px;
  background: url(../assets/kiddol_logo_1.png) no-repeat;
  background-size: 100% 100%;
}
.logo-2 {
  margin-top: 29px;
  width: 156px;
  height: 70px;
  background: url(../assets/kiddol_logo_2.png) no-repeat;
  background-size: 100% 100%;
}
.btn {
  position: absolute;
  bottom: 151px;
  width: 300px;
  height: 42px;
  background: #323cb0;
  border-radius: 20px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  .arrow {
    position: absolute;
    right: 27px;
    top: 15px;
    width: 20px;
    height: 24px;
  }
  .txt {
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 18px;
    position: absolute;
    right: 24px;
    top: 51px;
  }
}
</style>
