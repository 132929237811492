import { render, staticRenderFns } from "./invitationRules.vue?vue&type=template&id=41b5696d&scoped=true&"
import script from "./invitationRules.vue?vue&type=script&lang=js&"
export * from "./invitationRules.vue?vue&type=script&lang=js&"
import style0 from "./invitationRules.vue?vue&type=style&index=0&id=41b5696d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41b5696d",
  null
  
)

export default component.exports