<template>
  <div class="main">
    <div class="head">
      <div style="margin-top:-20px">可用积分</div>
      <div>{{ freeScore }}</div>
      <div class="title">积分明细</div>
    </div>

    <van-tabs
      v-model="currSegmentIdx"
      @click="clickSegment"
      color="#F9550A"
      line-width="28px"
      line-height="2px"
      title-active-color="#F9550A"
      title-inactive-color="#898989"
      style="margin-top: 24px; width: 100%; font-size: 15px;"
      sticky
    >
      <van-tab
        v-for="(items, indexs) in titleList"
        :key="indexs"
        :title="items.title"
        :name="items.prizeType"
        :disabled="loading"
      >
        <van-list
          class="list"
          v-model="loading"
          :finished="finished"
          :finished-text="noList ? '' : '没有更多了'"
          @load="loadData"
        >
          <div class="noList" v-if="noList && dataArray.length === 0"></div>
          <div class="ls-item" v-for="(item, index) in dataArray" :key="index">
            <div class="left">
              <div>{{ item.describe }}</div>
              <div>{{ moment(item.changeTime).format("YYYY.MM.DD HH:mm:ss") }}</div>
            </div>
            <div class="right">
              <div>
                {{ item.changeFreeScore > 0 ? "+" + item.changeFreeScore : item.changeFreeScore }}
              </div>
              <div
                @click="orderAction(item)"
                v-bind:class="[
                  'order',
                  item.changeType == 4 || item.changeType == 7 || item.changeType == 6 ? '' : 'hide'
                ]"
              >
                <div>查看订单</div>
                <img class="arrow" src="https://oss-kiddo.manqu88.com/h5/rightArrow.png" />
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      token: this.$route.query.token,
      isMini: true,
      currSegmentIdx: 0,
      titleList: [
        {
          title: "全部",
          prizeType: 0
        },
        {
          title: "已获取",
          prizeType: 1
        },
        {
          title: "已消耗",
          prizeType: 2
        }
      ],
      noList: false,
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 20,
      freeScore: 0,
      dataArray: []
    };
  },
  created() {
    document.title = "积分明细";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境1：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.isMini = true;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getUserData();
      } else {
        this.isMini = false;
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getUserData();
          this.loadData();
        });
      }
    });
  },
  methods: {
    moment,
    clickSegment(name, title) {
      this.noList = false;
      this.dataArray = [];
      this.loading = true;
      this.finished = false;
      this.currentPage = 0;
      this.loadData();
      console.log(name, title, this.currSegmentIdx);
    },
    async getUserData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/userScore/queryByUserId",
        data: {}
      });
      if (data.success) {
        this.freeScore = data.data.freeScore;
      } else {
        this.$toast(data.message);
      }
    },
    loadData() {
      if (!this.token) {
        return;
      }
      this.currentPage++;
      console.log("加载数据～", this.currentPage);
      this.getListData();
    },
    async getListData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/userScore/pageQueryFlow",
        data: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          positiveNegativeType: this.currSegmentIdx
        }
      }).catch(error => {
        this.dataArray = [];
        this.loading = false;
        this.finished = true;
        this.noList = this.currentPage === 1;
        this.currentPage--;
      });
      if (data.success) {
        this.dataArray = [...this.dataArray, ...data.data];
        this.loading = false;
        this.noList = this.currentPage === 1 && data.data.length == 0;
        this.finished = data.data.length < this.pageSize;
      } else {
        this.$toast(data.message);
        this.loading = false;
        this.finished = true;
        this.noList = this.currentPage === 1;
        this.currentPage--;
      }
    },
    orderAction(item) {
      // console.log('订单详情页=', item.serialNumber);
      if (this.isMini) {
        uni.webView.navigateTo({
          url: `/pagesA/order/detail?id=${item.serialNumber}`
        });
      } else {
        this.$bridge.call("openPage", { type: 21, param: item.serialNumber });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
}
.head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 163px;
  background: url(https://oss-kiddo.manqu88.com/h5/score/scoreBg.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 24px;
  margin-bottom: -10px;
  position: relative;

  :first-child {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  :nth-child(2) {
    font-size: 38px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: #f9550a;
    padding-bottom: 20px;
  }
  .title {
    position: absolute;
    height: 45px;
    background-color: white;
    border-radius: 30px 30px 0px 0px;
    top: 118px;
    left: 0px;
    right: 0px;
    padding: 28px 0px 0px 24px;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
  }
}
.noList {
  margin: auto;
  margin-top: 129px;
  width: 100px;
  height: 133px;
  background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
  background-size: 100% 100%;
}
.list {
  background-color: white;
  margin: 0px 24px;
  flex: 1;
}
.ls-item {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  // border-bottom: 1px solid #e5e5e5;
  .left {
    width: 80%;
    padding-top: 10px;
  }
  .left div:nth-child(1) {
    color: #232323;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 2px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .left div:nth-child(2) {
    color: #898989;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 17px;
    margin-top: 2px;
  }
  .right {
    text-align: right;
    width: 20%;
    padding-top: 8px;
    // background-color: pink;
  }
  .right div:nth-child(1) {
    color: #f9550a;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    // background-color: red;
  }
  .order {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -2px;
    div:first-child {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #898989;
    }
    :last-child {
      width: 12px;
      height: 24px;
    }
  }
}
.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}
</style>
