<template>
  <div class="ck-wheel">
    <div class="num-1">{{ datas.sellNum }}</div>
    <div class="num-2">{{ datas.activateNum }}</div>
    <div class="num-3">{{ formatPrice(datas.expectCommission) }}</div>
    <div class="num-4">{{ formatPrice(datas.sentCommission) }}</div>


    <div class="isActive" v-if="isActive"></div>
    <div class="btn-go" v-else @click="itemDetail(4040116)"></div>

    <div class="list-view">
      <div class="list">
        <div class="noList" v-if="dataList.length === 0"></div>
        <div class="item" v-for="(item, index) in dataList" :key="index">
          <div class="head" :style="{ backgroundImage: `url(${item.headUrl})` }"></div>
          <div class="name">{{ item.nickname }}</div>
          <div class="price">{{ item.purchaseNum }}</div>
          <div class="num">{{ item.activateNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: this.$route.query.id || 4,
      token: this.$route.query.token,
      isActive: false,
      datas: {},
      dataList: []
    };
  },
  created() {
    document.title = "尊享卡推广奖励";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;

        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }

          this.getDetail();
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    async getDetail() {

      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardCommissionStatistics",
          data: {
            id: this.id,
          }
        });
        if (data.success) {
          this.datas = data.data;
          this.isActive = data.data.isActivate === 1;
        } else {
          this.$toast(data.message);
        }
      }
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/data/cycleCardCommissionFlowStatistics",
          data: {
            id: this.id,
            currentPage: 1,
            pageSize: 10000,
          }
        });
        if (data.success) {
          this.dataList = data.data;
        } else {
          this.$toast(data.message);
        }
      }
    },

    itemDetail(itemId) {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${itemId}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: itemId
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  height: 1568px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/tg_BG_abk.png?1") no-repeat;
  background-size: 100% 100%;
  position: relative;

  font-size: 16px;
  font-family: Barlow-Medium, Barlow;
  font-weight: 500;
  color: #000000;

  .btn-go {
    width: 293px;
    height: 35px;
    position: absolute;
    left: 42px;
    top: 525px;
  }

  .isActive {
    width: 309px;
    height: 60px;
    position: absolute;
    left: 33px;
    top: 500px;
    background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/isActive.png?") no-repeat;
    background-size: 100% 100%;
  }

  .num-1 {
    width: 18px;
    height: 19px;
    position: absolute;
    left: 145px;
    top: 433px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-2 {
    width: 18px;
    height: 19px;
    position: absolute;
    right: 47px;
    top: 433px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-3 {
    width: 18px;
    height: 19px;
    position: absolute;
    left: 145px;
    top: 462px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-4 {
    width: 18px;
    height: 19px;
    position: absolute;
    right: 47px;
    top: 462px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .list-view {
    width: 364px;
    height: 368px;
    position: absolute;
    left: 6px;
    top: 699px;
    background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/tg_list.png?") no-repeat;
    background-size: 100% 100%;

    .list {
      margin: auto;
      margin-top: 50px;
      width: 338px;
      height: 310px;
      overflow: scroll;

      .noList {
        margin: auto;
        margin-top: 113px;
        width: 224px;
        height: 54px;
        background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/noList_2.png?") no-repeat;
        background-size: 100% 100%;
      }

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #E6E6E6;
        position: relative;

        .head {
          margin-left: 13px;
          width: 37px;
          height: 37px;
          background: #D8D8D8;
          border: 1px solid #F8DCB9;
          border-radius: 50%;
          background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/tg_list.png?") no-repeat;
          background-size: 100% 100%;
        }

        .name {
          margin-left: 12px;
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        .price {
          position: absolute;
          right: 130px;
          width: 39px;
          height: 19px;
          font-size: 16px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #000000;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .num {
          position: absolute;
          right: 45px;
          width: 18px;
          height: 19px;
          font-size: 16px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

}
</style>
