<template>
  <div class="ck-wheel">
    <div class="list" @scroll="onScroll">
      <div class="noList" v-if="noList && list.length === 0"></div>
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <div class="des">{{ item.flowDesc }}</div>
          <div class="time">{{ moment(item.gmtModified).format("YYYY.MM.DD HH:mm:ss") }}</div>
        </div>
        <div class="add">
          {{ item.type === 2 ? "-" : "+" }}{{ parseGold(item.goldAmount, true, false) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      assistGoldId: this.$route.query.id,
      token: this.$route.query.token,
      currentPage: 1,
      pageSize: 20,
      canGetList: true,
      loading: false,
      finished: false,
      goldData: {},
      noList: false,
      list: []
    };
  },
  created() {
    document.title = "收益明细";
  },
  destroyed() {},
  mounted() {
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          this.getList();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getList();
          });
        }
        //
      });
    }, 1000);
  },

  methods: {
    formatPrice,
    moment,
    parseGold(gold) {
      let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
      let arr = ret.split(".");
      console.log(ret, arr);
      if (arr[1] === "00") {
        return arr[0];
      } else {
        return ret;
      }
    },
    async getList() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/goldAccount/queryInviteFlow",
        data: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          typeList: [1, 2]
        }
      });
      if (data.success) {
        this.list = [...this.list, ...data.data];
        if (data.data.length < this.pageSize) {
          this.canGetList = false;
        }
      } else {
        this.$toast(data.message);
      }
      this.noList = true;
    },
    goRules() {
      this.$router.push(`/goldDetailRules?time=${Date.now()}`);
    },

    onScroll(e) {
      console.log(
        e,
        e.target.scrollHeight,
        e.target.scrollTop,
        e.target.clientHeight,
        e.target.scrollTop + e.target.clientHeight
      );
      if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
        //触发加载
        if (this.canGetList) {
          this.currentPage++;
          this.getList();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mFont";
  src: url("../../../assets/fonts/poxel-font.ttf");
}
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .list {
    width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;

    .noList {
      margin: auto;
      margin-top: 199px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }
    .item {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      position: relative;

      .left {
        display: flex;
        flex-direction: column;
        width: 247px;
        margin-left: 23px;
        .des {
          width: 247px;
          height: 36px;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 2;
        }
        .time {
          margin-top: 5px;
          width: 247px;
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }
    }

    .add {
      position: absolute;
      text-align: right;
      top: 14px;
      right: 23px;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f6550a;
      line-height: 24px;
    }
  }
}
</style>
