<template>
  <div class="main">
    <van-tabs v-model="currSegmentIdx" @click="clickSegment" color="#1029AE" line-width="28px" line-height="2px"
      title-active-color="#141414 " title-inactive-color="#B0B0B0" style="width: 100%; font-size: 15px;"
      sticky>
      <van-tab v-for="(items, indexs) in titleList" :key="indexs" :title="items.title" :name="items.prizeType"
        :disabled="loading">
        <div class="noList" v-if="dataList.length === 0"></div>
        <div class="ls-item" v-for="(item, index) in dataList" :key="index">
          <div class="card">

            <div :class="['top', currSegmentIdx === 1 ? 'top-finish' : '']">
              <div class="head" :style="{
                backgroundImage: `url(${item.headPicUrl})`
              }"></div>
              <div class="user">
                <div class="name">{{ item.nickName }}</div>
                <div class="mobile">{{ item.mobile }}</div>
              </div>
              <div :class="['status', 'status-' + (currSegmentIdx + 1)]">{{ currSegmentIdx === 0 ? '考核中' : '已完成' }}</div>
            </div>

            <div class="msg">平移时间：{{ moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss") }}</div>
            <div class="msg">考核到期时间：{{ moment(item.deadlineTime).format("YYYY-MM-DD HH:mm:ss") }}</div>
            <div class="msg">销售金额：{{ formatPrice(item.teamSaleAmount) }}元</div>

            <div class="title">已确认订单金额</div>
            <div class="progress">
              <div class="left">
                <div class="inside" :style="{
                  width: `${(item.teamFinishAmount * 100 / item.needTeamFinishAmount) >= 100 ? 100 : (item.teamFinishAmount * 100 / item.needTeamFinishAmount)}%`
                }"></div>
              </div>
              <div class="right">
                {{ item.teamFinishAmount === 0 ? 0 : formatPrice(item.teamFinishAmount) }}/{{
                  Number(formatPrice(item.needTeamFinishAmount)).toFixed(0) }}
              </div>
            </div>

            <div class="title">直邀用户数</div>
            <div class="progress">
              <div class="left">
                <div class="inside" :style="{
                  width: `${(item.inviteForeverNum * 100 / item.needInviteForeverNum) >= 100 ? 100 : (item.inviteForeverNum * 100 / item.needInviteForeverNum)}%`
                }"></div>
              </div>
              <div class="right">{{ item.inviteForeverNum }}/{{ item.needInviteForeverNum }}</div>
            </div>


          </div>

        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "@/utils/money";
export default {
  data() {
    return {
      token: this.$route.query.token,
      isMini: true,
      currSegmentIdx: 0,
      titleList: [
        {
          title: "考核中",
          prizeType: 0
        },
        {
          title: "考核完成",
          prizeType: 1
        }
      ],
      noList: false,
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 20,
      freeScore: 0,
      dataList: [],
      datas: {}
    };
  },
  created() {
    document.title = "实习店主";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境1：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.isMini = true;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getUserData();
      } else {
        this.isMini = false;
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getUserData();
          // this.loadData();
        });
      }
    });
  },
  methods: {
    formatPrice,
    moment,
    clickSegment(name, title) {
      // this.noList = false;
      this.dataList = this.currSegmentIdx === 0 ? this.datas.runningList : this.datas.finishList;
      console.log(name, title, this.currSegmentIdx);
    },
    async getUserData() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/recruitPracticeKb/getPracticeKbList",
          data: {

          }
        });
        if (data.success) {
          this.datas = data.data;
          this.isActive = data.data.isActivate === 1;
          this.dataList = this.datas.runningList;
        } else {
          this.$toast(data.message);
        }
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
}

.noList {
  margin: auto;
  margin-top: 129px;
  width: 100px;
  height: 133px;
  background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
  background-size: 100% 100%;
}



.ls-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  // border-bottom: 1px solid #e5e5e5;

  .card {
    width: 355px;
    height: 250px;
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;
    margin-top: 12px;



    .top {
      width: 353px;
      height: 72px;
      background: #FFFAF5;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 2px;

      .head {
        margin-left: 15px;
        width: 49px;
        height: 48px;
        border-radius: 50%;
        background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/zm_BG.png?6") no-repeat;
        background-size: 100% 100%;
      }

      .user {
        margin-left: 11px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          max-width: 160px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #404040;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 1;
        }

        .mobile {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }

      .status {
        position: absolute;
        right: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .status-1 {
        width: 64px;
        height: 28px;
        background: #FA6400;
        border-radius: 14px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 18px;
      }

      .status-2 {
        width: 64px;
        height: 28px;
        background: #FFF4E2;
        border-radius: 14px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FA6400;
        line-height: 18px;
      }
    }

    .top-finish {
      background: #FAFAFA;
    }

    .msg {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #404040;
      line-height: 17px;
      margin-top: 8px;
      margin-left: 16px;
    }

    .title {
      margin-top: 8px;
      margin-left: 16px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #404040;
      line-height: 17px;
      margin-bottom: 6px;
    }

    .progress {
      margin-bottom: 2px;
      display: flex;
      align-items: center;

      .left {
        position: relative;
        margin-left: 16px;
        width: 250px;
        height: 11px;
        background: #F1F1F1;
        border-radius: 6px;

        .inside {
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 11px;
          background: #FFE4B8;
          border-radius: 6px;
        }
      }

      .right {
        margin-left: 5px;
        height: 15px;
        font-size: 12px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: #404040;
        line-height: 12px;
      }
    }
  }
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}
</style>
