<template>
  <div class="">
    <div :class="['ck-wheel', 'background']">
      <div class="btn-address" @click="showModal = true"></div>
      <div class="pay-money">￥{{ formatPrice(amount) }}</div>
      <div class="next-money">￥{{ formatPrice(nextAmount) }}</div>
      <div class="can-get">{{ curLevel >= 0 ? giftList[curLevel].itemName : "无" }}</div>
      <!-- gifts -->
      <div class="gift-1" @click="goItemDeatil(0)">
        <div :class="[curLevel == 0 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-2" @click="goItemDeatil(1)">
        <div :class="[curLevel == 1 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-3" @click="goItemDeatil(2)">
        <div :class="[curLevel == 2 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-4" @click="goItemDeatil(3)">
        <div :class="[curLevel == 3 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-5" @click="goItemDeatil(4)">
        <div :class="[curLevel == 4 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-6" @click="goItemDeatil(5)">
        <div :class="[curLevel == 5 ? 'label-can-get' : '']"></div>
      </div>
      <div class="gift-7" @click="goItemDeatil(6)">
        <div :class="[curLevel == 6 ? 'label-can-get' : '']"></div>
      </div>

      <div class="progressBg">
        <div class="progress" :style="{ width: progressWidth + 'px' }"></div>

        <div class="giftView">
          <div :class="[curLevel == 0 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 3%"></div>
          <div :class="[curLevel == 1 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 18%"></div>
          <div :class="[curLevel == 2 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 33.5%"></div>
          <div :class="[curLevel == 3 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 49%"></div>
          <div :class="[curLevel == 4 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 64%"></div>
          <div :class="[curLevel == 5 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 79.5%"></div>
          <div :class="[curLevel == 6 ? 'giftIcon-2' : 'giftIcon-1']" style="left: 95%"></div>
        </div>
      </div>
    </div>

    <!-- address -->
    <div class="popup" v-show="showModal">
      <div class="mask"></div>
      <div class="modal">
        <img
          class="close"
          src="https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/icon-close.png"
          @click="close"
        />
        <div class="title">填写收货信息</div>
        <div class="border"></div>
        <div class="form">
          <div class="label">收货人姓名</div>
          <van-field
            v-model="receiverName"
            placeholder="请输入收货人姓名"
            maxlength="16"
            :border="false"
          />
          <div class="label">收货人手机号</div>
          <van-field
            v-model="receiverPhone"
            type="tel"
            placeholder="请输入收货人手机号"
            maxlength="11"
            :border="false"
          />
          <div class="label">收货地址</div>
          <van-field
            v-model="address"
            readonly
            placeholder="请选择省-市-区"
            :border="false"
            @click="onShowArea"
          />
          <van-field
            class="detail"
            v-model="detail"
            rows="2"
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="100"
            :border="false"
            @click="handleScrollNormal('detail')"
          />
          <div class="save" @click="onSave">保存</div>
        </div>
      </div>
    </div>
    <div v-show="showArea" class="mask" @click="cancel"></div>
    <van-area
      class="area"
      :class="{ showArea: showArea }"
      title="标题"
      :area-list="areaList"
      @confirm="confirm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import { formatPrice } from "../utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      type: 1,
      progressWidth: 0,
      amount: 0,
      nextAmount: 0,
      moneyLevel: [69900, 129900, 299900, 499900, 699900, 999900, 2999900],
      percentList: [5.5, 21, 36.5, 52, 67, 82.5, 98],
      giftList: [
        { itemName: "kipkep口罩2盒", itemId: 3921176 },
        { itemName: "kipkep洗脸巾1箱", itemId: 3281020 },
        { itemName: "康巴赫悦厨老铁锅", itemId: 3921181 },
        { itemName: "周六福葫芦项链", itemId: 3883999 },
        { itemName: "Bruno破壁机", itemId: 3743452 },
        { itemName: "大宇多功能料理锅", itemId: 2981150 },
        { itemName: "戴森吹风机", itemId: 3041725 }
      ],
      curLevel: -1,

      giftIcon: [{}, {}, {}, {}, {}, {}],

      //   address
      canSave: true,
      addressId: "",
      hasAddress: false,
      showModal: false,
      areaList,
      showArea: false,
      receiverName: "",
      receiverPhone: "",
      address: "",
      provinceName: "",
      cityName: "",
      areaName: "",
      detail: ""
    };
  },
  beforeCreate() {
    document.title = "惠购年货豪礼加赠";
  },
  created() {
    console.log("token", this.$route.query);
    // this.token = "f663a3ede49e327adcf2760c52250089";
  },
  mounted() {
    // this.getAddressInfo();
    // this.getConsumeAmount();
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          if (!this.token || this.token == "") {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
            return;
          }
          this.getAddressInfo();
          this.getConsumeAmount();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getAddressInfo();
            this.getConsumeAmount();
          });
        }
      });
    }, 1000);
  },
  methods: {
    formatPrice,
    rules() {
      this.$router.push(
        "/giftRules?token=" + this.token + "&type=2" + "&timeStamp=" + Date.now(),
        () => {
          console.log(window.location.href);
        }
      );
    },
    async getConsumeAmount() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/order/queryConsumeAmount",
        data: {
          type: this.type
        }
      });
      let width = (318 / 375) * document.documentElement.clientWidth;
      if (data.success) {
        this.amount = data.data;
        // this.amount = 2999000;
        let level = -1;
        for (let i = 0; i < this.moneyLevel.length; i++) {
          if (this.amount >= this.moneyLevel[i]) {
            level = i;
          }
        }
        this.nextAmount = this.moneyLevel[level + 1] - this.amount;
        this.curLevel = level;
        if (this.amount < 69900) {
          this.progressWidth =
            (((this.amount * this.percentList[0]) / this.moneyLevel[0]) * width) / 100;
        } else if (this.amount < 2999900) {
          this.progressWidth =
            ((this.percentList[this.curLevel] +
              ((this.amount - this.moneyLevel[this.curLevel]) *
                (this.percentList[this.curLevel + 1] - this.percentList[this.curLevel])) /
                (this.moneyLevel[this.curLevel + 1] - this.moneyLevel[this.curLevel])) *
              width) /
            100;
        } else {
          this.progressWidth = width;
        }
      } else {
        this.$toast(data.message);
      }
    },

    goItemDeatil(index) {
      let itemId = this.giftList[index].itemId;
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${itemId}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: itemId
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },

    //address
    async getAddressInfo() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "api/reward/getUserAddress",
        data: {
          type: this.type
        }
      });
      if (data.success) {
        if (data.data) {
          this.hasAddress = true;
          let datas = data.data;
          this.addressId = datas.id;
          this.address = `${datas.provinceName}-${datas.cityName}-${datas.areaName}`;
          this.provinceName = datas.provinceName;
          this.cityName = datas.cityName;
          this.areaName = datas.areaName;
          this.receiverName = datas.receiverName;
          this.receiverPhone = datas.receiverPhone;
          this.detail = datas.detail;
        } else {
          this.hasAddress = false;
        }
      } else {
        this.$toast(data.message);
      }
    },
    close() {
      this.showModal = false;
    },
    onShowArea() {
      this.showArea = true;
    },
    confirm(value) {
      this.showArea = false;
      console.log(value);
      this.address = `${value[0].name}-${value[1].name}-${value[2].name}`;
      this.provinceName = value[0].name;
      this.cityName = value[1].name;
      this.areaName = value[2].name;
    },
    cancel() {
      this.showArea = false;
    },
    onSave() {
      const {
        addressId,
        receiverName,
        receiverPhone,
        provinceName,
        cityName,
        areaName,
        detail,
        address
      } = this;

      if (!this.canSave) {
        return;
      }
      if (!receiverName) {
        Toast("请填写收货人姓名");
        return;
      }
      if (!receiverPhone) {
        Toast("请填写收货人手机号");
        return;
      }
      const reg = new RegExp(/^1\d{10}$/);
      if (!reg.test(receiverPhone)) {
        Toast("请填写正确的手机号");
        return;
      }
      if (!address) {
        Toast("请选择地区");
        return;
      }
      if (!detail) {
        Toast("请填写详细地址");
        return;
      }
      this.canSave = false;
      setTimeout(() => {
        this.canSave = true;
      }, 1000);
      this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: this.hasAddress ? "api/reward/updateUserAddress" : "/api/reward/addUserAddress",
        data: {
          id: addressId,
          receiverName,
          receiverPhone,
          provinceName,
          cityName,
          areaName,
          detail
        }
      })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            Toast("保存成功！");
            this.showModal = false;
            this.hasAddress = true;
            if (this.addressId == "") {
              this.getAddressInfo();
            }
          } else {
            Toast(data.message);
          }
        })
        .catch(err => {
          console.log(err);
          Toast("保存失败，请重试！");
        });
    },
    handleScrollNormal(param) {
      setTimeout(function() {
        document.getElementsByClassName(param)[0].scrollIntoViewIfNeeded();
      }, 500);
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  max-width: 100vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background {
  width: 375px;
  min-height: 1480px;
  background: url(https://oss-kiddo.manqu88.com/giftH5/bg.png?121) no-repeat;
  background-size: 100% 100%;
}

.rulesBtn {
  position: absolute;
  top: 33px;
  right: 0;
  width: 28px;
  height: 77px;
  background: url(https://oss-kiddo.manqu88.com/giftH5/rulesBtn.png?1) no-repeat;
  background-size: 100% 100%;
}
.btn-address {
  position: absolute;
  right: 27px;
  top: 547px;
  width: 90px;
  height: 25px;
}
.pay-money {
  position: absolute;
  left: 29px;
  top: 495px;
  width: 90px;
  height: 20px;
  font-size: 17px;
  font-family: Barlow-Bold, Barlow;
  font-weight: bold;
  color: #b80a04;
  line-height: 20px;
  text-decoration: underline;
}
.next-money {
  position: absolute;
  left: 200px;
  top: 495px;
  width: 90px;
  height: 20px;
  font-size: 17px;
  font-family: Barlow-Bold, Barlow;
  font-weight: bold;
  color: #b80a04;
  line-height: 20px;
  text-decoration: underline;
}
.can-get {
  position: absolute;
  left: 113px;
  top: 551px;
  width: 132px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b80a04;
  line-height: 17px;
  text-overflow: ellipsis;
}
.gift-1 {
  position: absolute;
  left: 29px;
  top: 592px;
  width: 75px;
  height: 126px;
}
.gift-2 {
  position: absolute;
  left: 110px;
  top: 592px;
  width: 75px;
  height: 126px;
}
.gift-3 {
  position: absolute;
  left: 191px;
  top: 592px;
  width: 75px;
  height: 126px;
}
.gift-4 {
  position: absolute;
  left: 272px;
  top: 592px;
  width: 75px;
  height: 126px;
}

.gift-5 {
  position: absolute;
  left: 29px;
  top: 728px;
  width: 102px;
  height: 149px;
}
.gift-6 {
  position: absolute;
  left: 136px;
  top: 728px;
  width: 102px;
  height: 149px;
}
.gift-7 {
  position: absolute;
  left: 245px;
  top: 728px;
  width: 102px;
  height: 149px;
}
.label-can-get {
  margin: auto;
  margin-top: 0;
  width: 75px;
  height: 22px;
  background: url(https://oss-kiddo.manqu88.com/giftH5/canGet.png) no-repeat;
  background-size: 100% 100%;
}
.progressBg {
  position: relative;
  margin-top: 410px;
  width: 318px;
  height: 22px;
  background: #e2e3e3;
  display: flex;
  align-items: center;

  .progess-left {
    margin-left: 1.5px;
    width: 5.5px;
    height: 12px;
    background: url(https://oss-kiddo.manqu88.com/giftH5/progress_left.png) no-repeat;
    background-size: 100% 100%;
  }
  .progress {
    // margin-left: 15px;
    width: 117.5px;
    height: 22px;
    background: #b80a04;
  }
  .progess-right {
    position: relative;
    left: -3px;
    width: 19px;
    height: 19px;
    background: url(https://oss-kiddo.manqu88.com/giftH5/progress_right.png) no-repeat;
    background-size: 100% 100%;
  }

  .giftView {
    position: absolute;
    top: -66px;
    width: 318px;
    height: 58px;
    display: flex;
    align-items: flex-end;

    .giftIcon-1 {
      position: absolute;
      width: 17px;
      height: 17px;
      // background: url(https://oss-kiddo.manqu88.com/giftH5/gift_1.png?11) no-repeat;
      // background-size: 100% 100%;
    }

    .giftIcon-2 {
      position: absolute;
      width: 17px;
      height: 17px;
      background: url(https://oss-kiddo.manqu88.com/giftH5/gift_1.png?11) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    padding-top: 42px;
    box-sizing: border-box;
    width: 340px;
    height: 458px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    background: url("https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/kiddo-activity/modal-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: left;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: -30px;
    }

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      line-height: 25px;
      letter-spacing: 1px;
    }

    .border {
      margin: 2px auto 0;
      width: 133px;
      height: 3px;
      background: #f9e46e;
    }

    .form {
      padding: 0 55px;
      box-sizing: border-box;

      .label {
        margin-top: 15px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        line-height: 18px;
      }

      input {
        margin-top: 7px;
      }

      .save {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        height: 39px;
        background: #f9550a;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
}
.van-field {
  padding: 0 11px;
  width: 230px;
  // height: 32px;
  line-height: 32px;
  border: 1px solid #efefef;
  margin-top: 7px;
}

.van-field--min-height {
  line-height: 22px;
}

.area {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -600px;
  transition: all 0.3s;

  &.showArea {
    bottom: 0;
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
</style>
