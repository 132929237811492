<template>
  <div class="back">
    <div class="card-top">
      Kiddol
      <div class="btn-openApp" @click="goToDownload">
        <div>打开APP</div>
        <div :id="'goApp_0'" class="goods-weapp"></div>
      </div>
    </div>

    <div class="swiper-div">
      <van-swipe :autoplay="0" :show-indicators="false" @change="onSwiperChange">
        <van-swipe-item
          class="swiper-item"
          v-for="(image, index) in resouceData"
          :key="index"
        >
          <img
            v-if="resourceType === 1"
            class="swiper-item"
            :src="`${resouceData[index].path}?x-oss-process=image/watermark,image_MTQ2MTYzOTM4NDI5NjgzNS5wbmc,g_sw,y_18,x_18`"
          />
          <video
            controls
            v-if="resourceType === 2 && resouceData[index].videoDTOS"
            :src="resouceData[index].videoDTOS[0].fileUrl"
            :poster="resouceData[index].coverImage"
            class="swiper-item"
          ></video>
        </van-swipe-item>
      </van-swipe>
      <div class="index">{{ curSwiperPage + "/" + resouceData.length }}</div>
    </div>

    <div class="detail-view">
      <div class="head-view" v-if="mediumData.userDTO">
        <img :src="mediumData.userDTO.headPicUrl" class="head" />
        <div class="name">{{ mediumData.userDTO.nickName }}</div>
        <div class="btn-gz" @click="goToDownload">
          <div>关注</div>
          <div :id="'goApp_1'" class="goods-weapp"></div>
        </div>
      </div>

      <pre class="cotent">{{ mediumData.content }}</pre>

      <div class="group-topic-view" @click="goToDownload">
        <div class="group" v-for="(item, index) in mediumData.circleDTOS" :key="index">
          <img :src="item.icon" class="group-icon" />
          <div>{{ item.name }}</div>
        </div>

        <div
          class="topic"
          v-for="(item, index) in mediumData.topicList"
          :key="index"
          @click="goToDownload"
        >
          <img src="@/assets/topic-icon.png" class="topic-icon" />
          <pre class="topic-txt">{{ item }}</pre>
        </div>
        <div :id="'goApp_2'" class="goods-weapp"></div>
      </div>

      <div class="goods-view">
        <div
          class="goods"
          v-for="(item, index) in mediumData.itemDTOS"
          :key="index"
          @click="goToWeApp(item.id)"
        >
          <img :src="item.resourceDTOS[0].path" class="goods-icon" />
          <div class="goods-right">
            <div class="goods-title">
              {{ item.itemName }}
            </div>
            <div class="price">￥{{ formatPrice(item.retailPrice) }}</div>
          </div>
          <div :id="'goods_' + index" class="goods-weapp"></div>
        </div>
      </div>

      <div style="height: 66px"></div>
    </div>

    <div class="card-bottom">
      <div class="line"></div>
      <div
        style="
          height: 66px;
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
        "
      >
        <div style="display: flex; align-items: center; position: relative">
          <img
            @click="goToDownload"
            class="bottom-icon"
            style="margin-left: 36px"
            src="@/assets/save-icon.png"
          />
          <div>
            {{ mediumData.favorNumberValue }}
          </div>
          <div :id="'goApp_5'" class="goods-weapp"></div>
        </div>
        <div style="display: flex; align-items: center; position: relative">
          <img
            @click="goToDownload"
            class="bottom-icon"
            style="margin-left: 36px"
            src="@/assets/like-icon.png"
          />
          <div>
            {{ mediumData.likeNumberValue }}
          </div>
          <div :id="'goApp_4'" class="goods-weapp"></div>
        </div>
        <div class="btn-goApp" @click="goToDownload">
          <div>APP内打开</div>
          <div :id="'goApp_3'" class="goods-weapp"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import { init, wx_weapp_launch, wx_app_launch } from "@/utils/wx-launch";
export default {
  name: "kiddolCircle",
  components: {},
  created() {
    // init({ dddd: window.location.href });
    document.title = "test";

    // if (this.$isWx()) {
    //   this.canClick = false;
    // }
    // this.initWxConfig();
  },
  mounted() {
    this.initWxConfig();
    // uni.webView.getEnv((res) => {
    //   console.log("当前环境1：" + JSON.stringify(res));
    //   if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
    //     this.token = this.$route.query.token;
    //     if (!this.token || this.token == "") {
    //       uni.webView.reLaunch({
    //         url: "/pagesA/login/index",
    //       });
    //       return;
    //     }
    //     this.initWxConfig();
    //   } else {
    //     this.$bridge.call("getToken", {}).then((e) => {
    //       console.log("getToken客户端返回: ", e);
    //       this.token = e;
    //       if (!e || JSON.stringify(e) == "{}") {
    //         this.$bridge.call("openPage", { type: 26 });
    //         return;
    //       }
    //       this.initWxConfig();
    //     });
    //   }
    // });
  },
  data() {
    return {
      mediumid: this.$route.query.mediumid,
      invitecode: this.$route.query.invitecode,
      mediumData: {},
      resourceType: 1, //1-pic 2-video
      resouceData: {},
      type: 1,
      curSwiperPage: 1,
      isReady: false,
      canClick: true,
      userList: [],
    };
  },
  methods: {
    formatPrice,
    async initWxConfig() {
      wx.ready(function (e) {
        console.log(e, "rr222");
        this.canClick = true;
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
      wx.error(function (e) {
        console.log(e, "error");
        this.canClick = true;
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
      });
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
        },
        url: "/api/wechat/js/getSignature",
        data: {
          url: window.location.href,
        },
      });
      if (data.success) {
        init(data.data);
        this.queryById();
      } else {
        this.$toast(data.message);
      }
    },
    async queryById() {
      let height = ["26px", "23px", "26px", "66px", "66px", "66px"];
      setTimeout(() => {
        for (let i = 0; i < 1; i++) {
            // console.log(document, document.getElementById("goods_" + i));
            let params = {
              eleId: "goApp_" + i, // 元素ID
              appid: "wx56d0192d5b52ba6d", // 小程序id号 gh_****
              height: height[i],
              url: `pages/index/index.html`, // 跳转小程序的页面路径地址 例: pages/home/home.html - (后面必须带上.html后缀 否则IOS跳转时出现小程序页面未配置)
              method: () => {
                this.goToDownload();
              },
              //   content: `<style>.btn { padding: 12px; opacity: 0;width:100%;height: 58px;}</style>
              //       <div class="btn"></div>`, // html字符串 例: "<button>点我</button>"
              content: `<style>.btns {-webkit-tap-highlight-color:rgba(0,0,0,0);padding: 12px; opacity:0;width:100%;height:${height[i]};background:null}</style>
                  <div class="btns" id="btn_go_app"></div>`, // html字符串 例: "<button>点我</button>"
            };
            wx_weapp_launch(params);
          }
        // if (this.mediumData.itemDTOS) {
          
        // }

        // for (let i = 0; i < 6; i++) {
        //   let params = {
        //     eleId: "goApp_" + i, // 元素ID
        //     appid: "wx539cd2d2a4e07251",
        //     height: height[i],
        //     extinfo: JSON.stringify({
        //       mediumId: this.mediumid,
        //       inviteCode: this.invitecode,
        //     }),
        //     method: () => {
        //       this.goToDownload();
        //     },
        //     content: `<style>.btns {-webkit-tap-highlight-color:rgba(0,0,0,0);padding: 12px; opacity:0;width:100%;height:${height[i]};background:null}</style>
        //           <div class="btns" id="btn_go_app"></div>`, // html字符串 例: "<button>点我</button>"
        //   };
        //   wx_app_launch(params);
        // }
      }, 1000);
    },
    onSwiperChange(e) {
      this.curSwiperPage = e + 1;
    },
    goToDownload() {
      if (this.canClick) {
        this.$router.push("/downloadApp?timeStamp=" + Date.now());
      }
    },
    goToWeApp(itemId) {
      console.log(itemId);
    },
    transContentToClient(str) {
      console.log("transContentToClient", str);
      if (!str) {
        return;
      }
      let matchRet = str.match(/<kiddol_user>([\s\S]*?)<\/kiddol_user>/);
      while (matchRet) {
        str = str.replace(
          `<kiddol_user>${matchRet[1]}</kiddol_user>`,
          `@${
            this.userList.filter((item) => item.userId == Number(matchRet[1]))[0].nickName
          } `
        );
        matchRet = str.match(/<kiddol_user>([\s\S]*?)<\/kiddol_user>/);
      }

      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ck-wheel {
  text-align: center;
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.back {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  background-color: #ffffff;
  text-align: center;

  .card-top {
    background-color: #ffffff;
    top: 0;
    width: 100vw;
    height: 48px;
    display: flex;
    align-items: center;
    position: fixed;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 20px;
    z-index: 1000;

    .btn-openApp {
      overflow: hidden;
      align-items: center;
      position: absolute;
      right: 10px;
      width: 66px;
      height: 26px;
      background: #f6550a;
      border-radius: 13px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 26px;
    }
  }

  .swiper-div {
    margin-top: 48px;
    width: 100vw;
    height: 100vw;
    max-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;

    .swiper-item {
      width: 100vw;
      height: 100vw;
      max-height: 500px;
      object-fit: scale-down;
    }
    .index {
      width: 56px;
      height: 26px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 13px;
      font-size: 16px;
      font-family: JDZhengHT-Regular, JDZhengHT;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: 1px;
      margin: auto;
      margin-top: -26px;
      margin-bottom: 0px;
      position: relative;
      bottom: 12px;
    }
  }

  .detail-view {
    margin-top: 26px;
    width: 100%;
    .head-view {
      width: 100%;
      height: 43px;
      display: flex;
      align-items: center;
      position: relative;
      .head {
        margin-left: 18px;
        margin-right: 10px;
        width: 43px;
        height: 43px;
        border-radius: 45px;
      }
      .name {
        width: 112px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 20px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
        -webkit-box-orient: vertical;
        /* 显示的行 */
        -webkit-line-clamp: 1;
      }
      .btn-gz {
        width: 54px;
        height: 23px;
        background: #fff5f0;
        border-radius: 12px;
        font-size: 11px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f6550a;
        line-height: 23px;
        position: absolute;
        right: 18px;
        top: 10px;
      }
    }

    .cotent {
      width: 340px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 25px;
      margin-top: 7px;
      text-align: left;
      margin-left: 18px;
      word-break: break-all;
      white-space: pre-wrap;
    }

    .group-topic-view {
      word-break: keep-all;
      width: 340px;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 26px;
      margin-top: 9px;
      margin-left: 18px;
      margin-bottom: 13px;

      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #131313;
      line-height: 26px;
      display: flex;
      position: relative;

      .group {
        word-break: keep-all;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 10px;
        .group-icon {
          margin-right: 5px;
          width: 26px;
          height: 26px;
          border-radius: 45px;
        }
      }

      .topic {
        display: flex;
        align-items: center;
        height: 100%;
        background: #fafafa;
        border-radius: 12px;
        margin-right: 10px;
        .topic-icon {
          margin-left: 8px;
          margin-right: 3px;
          width: 9px;
          height: 9px;
        }
        .topic-txt {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #131313;
          line-height: 26px;
          text-align: left;
          word-break: keep-all;
          margin-right: 8px;
        }
      }
    }

    .goods-view {
      word-break: keep-all;
      width: 340px;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 58px;
      margin-top: 14px;
      margin-left: 18px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;

      .goods {
        position: relative;
        display: flex;
        width: 240px;
        height: 58px;
        background: #fafafa;
        border-radius: 4px;
        margin-right: 10px;
        .goods-icon {
          width: 43px;
          height: 43px;
          margin-left: 9px;
          margin-top: 8px;
          margin-right: 13px;
          border-radius: 12px;
        }
        .goods-right {
          height: 100%;
          width: 157px;
          margin-right: 18px;
          display: flex;
          flex-direction: column;
          .goods-title {
            text-align: left;
            margin-top: 10px;
            width: 100%;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #404040;
            line-height: 17px;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 1;
          }
          .price {
            margin-top: 3px;
            height: 18px;
            font-size: 12px;
            font-family: JDZhengHT-Bold, JDZhengHT;
            font-weight: bold;
            color: #f6550a;
            line-height: 18px;
            text-align: left;
          }
        }
        .goods-weapp {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .goods-weapp {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .card-bottom {
    width: 100%;
    height: 66px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;

    font-size: 18px;
    font-family: JDZhengHT-Regular, JDZhengHT;
    font-weight: 400;
    color: #404040;
    line-height: 22px;

    .line {
      width: 340px;
      height: 1px;
      border: 1px solid #f5f5f5;
    }
    .bottom-icon {
      width: 28px;
      height: 28px;
      margin-right: 7px;
    }
    .btn-goApp {
      position: absolute;
      right: 17px;
      width: 100px;
      height: 36px;
      background: #323cb3;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 36px;
    }
  }
}
</style>
