<template>
  <div class="">
    <div :class="['ck-wheel', 'background-1']">
      <div class="share" @click="onShare"></div>
      <!-- 通知栏 -->
      <div class="msgView">
        <van-notice-bar class="notice" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :duration="1000"
            :show-indicators="false"
            :touchable="false"
          >
            <van-swipe-item v-for="(item, index) in prizeTurnTableDTO" :key="index">{{
              item
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>

      <!-- 标题 -->
      <div class="title"></div>

      <!-- 转盘 -->
      <div class="tableBg"></div>

      <div class="wheel">
        <canvas
          class="item"
          ref="wheelCanvas"
          id="wheelCanvas"
          width="1368px"
          height="1368px"
        ></canvas>
        <div class="choosed"></div>
        <div class="pointer"></div>
      </div>

      <!-- 按钮 -->
      <div class="btn-draw" @click="onClick"></div>
      <div class="btn-goLucky" @click="goPage(2)"></div>
      <div class="btn-goDuihuan" @click="goPage(5)"></div>

      <!-- 中奖弹窗 -->
      <div v-if="showModal" class="mask" @click="stopClick">
        <div
          class="bgGet"
          :style="{
            backgroundImage: `url(${
              popType === 1
                ? 'https://oss-kiddo.manqu88.com/h5/11turnTable/noScore.png'
                : popType === 0
                ? 'https://oss-kiddo.manqu88.com/h5/11turnTable/noCounts.png'
                : 'https://oss-kiddo.manqu88.com/h5/11turnTable/super_getBg.png'
            })`
          }"
        >
          <div
            v-if="popType === 2"
            class="prize"
            :style="{
              backgroundImage: `url(${'https://oss-kiddo.manqu88.com/h5/11turnTable/super_prize_' +
                stopIndex +
                '.png?' +
                Date.now()})`
            }"
          ></div>
          <div class="btn-close" @click="showModal = false"></div>
          <div class="btn-goRecord" @click="jumpToMain()"></div>
        </div>
      </div>
    </div>

    <!-- 下方 -->
    <div class="bg-3">
      <div
        v-for="(item, index) in goodsList"
        :key="index"
        :class="['goods-' + (index + 1)]"
        @click="goodsDetail(item)"
      ></div>
    </div>

    <div class="bg-1">
      <div class="btn-left" @click="showDuihuan(306, 170)"></div>
      <div class="btn-right" @click="showDuihuan(307, 320)"></div>
      <div
        v-for="(item, index) in duihuanList"
        :key="index"
        :class="['item-' + (index + 1)]"
        @click="goodsDetail(item)"
      ></div>
      <div
        v-for="(item, index) in duihuanList"
        :key="index"
        :class="['duihuan-' + (index + 1)]"
        @click="showDuihuan(item.lotteryPrizeId, 0)"
      ></div>
    </div>

    <div class="bg-2"></div>

    <!-- 二次确认框 -->
    <div class="shadow" v-if="showConfirmFrame">
      <div class="card">
        <div class="btn-close" @click="showConfirmFrame = false"></div>
        <div class="card-title">确认兑换该奖品吗？</div>
        <div class="btn-view">
          <div class="btn-cancel" @click="showConfirmFrame = false">取消</div>
          <div class="btn-confirm" @click="duihuan">确定兑换</div>
        </div>
      </div>
    </div>

    <div class="label label-1" @click="goPage(1)"></div>
    <div class="label label-2" @click="goPage(0)"></div>

    <div class="label-3" @click="goPage(3)">
      <div style="margin-left:8px">我的活力值:</div>
      <div class="num">{{ score }}</div>
    </div>
    <div class="label-4" @click="goPage(4)"></div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice, parseGold } from "@/utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      type: this.$route.query.type || 10, //活动类型（6：618幸运大转盘；7:618超级大转盘）
      turnTableScore: 2299,

      score: 0, //活力值
      showConfirmFrame: false,
      lotteryPrizeId: 0,

      lotteryTimesData: {
        score: 0,
        timesPlayedToday: 0,
        lotteryConsumeScore: 50
      },
      prizeType: 2,
      curItemUrl: "",
      curPrizeName: "",
      drwaData: {},
      token: this.$route.query.token,
      pointerStatus: 0,
      progressWidth: 0,
      showModal: false,
      popType: 2, //0:今日次数不足 1:抽奖次数不足 2:中奖
      prizeTurnTableDTO: [],
      useNum: 0, //剩余抽奖次数
      totalUseNum: 0, //累计抽奖次数
      totalAmount: 0, //类型：Number  必有字段  备注：累计消费金额
      thresholdAmount: 0, //类型：Number  必有字段  备注：门槛金额
      scheduleAmount: 0, //类型：String  必有字段  备注：进度金额
      canClick: false,
      img_bg: "",
      turnTime: 30,
      turnAngle: 15,
      turnLoop: 4,
      curIntervalId: 0,
      stopIndex: 1,
      prizeRecordId: 0,
      rewardList: [
        //逆时针
        {
          "【超级大转盘】一等奖兑换券": { index: 1 },
          "【超级大转盘】八等奖兑换券": { index: 2 },
          "【超级大转盘】七等奖兑换券": { index: 3 },
          "【超级大转盘】六等奖兑换券": { index: 4 },
          "【超级大转盘】五等奖兑换券": { index: 5 },
          "【超级大转盘】四等奖兑换券": { index: 6 },
          "【超级大转盘】三等奖兑换券": { index: 7 },
          "【超级大转盘】二等奖兑换券": { index: 8 }
        }
      ],
      duihuanList: [
        {
          lotteryPrizeId: 308,
          itemId: 3722460
        },
        {
          lotteryPrizeId: 309,
          itemId: 3722470
        },
        {
          lotteryPrizeId: 310,
          itemId: 3722530
        },
        {
          lotteryPrizeId: 311,
          itemId: 3742730
        },
        {
          lotteryPrizeId: 312,
          itemId: 3722540
        },
        {
          lotteryPrizeId: 313,
          itemId: 3722560
        },
        {
          lotteryPrizeId: 314,
          itemId: 3742770
        },
        {
          lotteryPrizeId: 315,
          itemId: 3722570
        },
        {
          lotteryPrizeId: 339,
          itemId: 3742800
        },
        {
          lotteryPrizeId: 317,
          itemId: 3722590
        },
        {
          lotteryPrizeId: 318,
          itemId: 3722600
        },
        {
          lotteryPrizeId: 319,
          itemId: 3742820
        }
      ],
      goodsList: [
        { itemId: 3722610 },
        { itemId: 3722620 },
        { itemId: 3742850 },
        { itemId: 3742860 },
        { itemId: 3722630 },
        { itemId: 3722650 },
        { itemId: 3742950 },
        { itemId: 3722760 },
        { itemId: 3742770 },
        { itemId: 3722570 },
        { itemId: 3742800 },
        { itemId: 3722590 },
        { itemId: 3722600 },
        { itemId: 3722710 },
        { itemId: 3722750 }
      ]
    };
  },
  created() {
    console.log("type", this.type);
    if (this.type == 1) {
      this.type = 9;
    } else if (this.type == 2) {
      window.location.href =
        process.env.VUE_APP_ENV === "production"
          ? `https://activity.manqu88.com/#/turnTable2?token=${
              this.token
            }&type=2&time=${Date.now()}`
          : process.env.VUE_APP_ENV == "development"
          ? `http://localhost:8080/#/turnTable2?token=${this.token}&type=2&time=${Date.now()}`
          : `https://activity-test.manqu88.com/#/turnTable2?token=${
              this.token
            }&type=2&time=${Date.now()}`;
      return;
    }
    document.title = "Kiddol双11下单“转”好礼，好货“兑”不停";
  },
  mounted() {
    if (this.type == 2) {
      return;
    }
    document.documentElement.scrollTop = 0;
    this.drawWheelCanvas();

    // this.getTurnTableDate(); //test
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        // this.canClick = true;
        this.token = this.$route.query.token;
        console.log("token1", this.token);
        if (!this.token || this.token == "") {
          console.log("token2", this.token);
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getTurnTableDate();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          // this.canClick = true;
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getTurnTableDate();
        });
      }
    });
  },

  methods: {
    formatPrice,
    parseGold,
    async showDuihuan(lotteryPrizeId, score) {
      this.lotteryPrizeId = lotteryPrizeId;
      this.needScore = score;
      this.showConfirmFrame = true;
    },
    async duihuan() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/luckyDraw",
        data: {
          type: 11,
          lotteryPrizeId: 107
        }
      });
      if (data.success) {
        this.$toast("兑换成功！");
        this.showConfirmFrame = false;
        this.getDrawCounts();
      } else {
        this.$toast(data.message);
      }
    },
    async getDrawCounts() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/lottery/queryExchangePrizeList",
          data: {
            type: 11
          }
        });
      }

      // 活力值
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/temp/score/query",
        data: {
          type: 50
        }
      });
      if (data.success) {
        this.score = formatPrice(data.data.score);
      } else {
        this.$toast(data.message);
      }
    },
    async getTurnTableDate() {
      //获取抽奖次数
      this.getDrawCounts();

      // //累计金额
      // if (this.type == 2) {
      //   const { data } = await this.$axios({
      //     method: "post",
      //     headers: {
      //       mac: "mac",
      //       clientSource: this.$clientSource(),
      //       Authorization: this.token
      //     },
      //     url: "/api/dist/data/queryActivityAmount",
      //     data: {}
      //   });
      //   if (data.success) {
      //     this.totalAmount = data.data.totalAmount;
      //     this.thresholdAmount = data.data.thresholdAmount;
      //     this.scheduleAmount = data.data.scheduleAmount;
      //     this.progressWidth = (this.scheduleAmount / this.thresholdAmount) * 312;
      //     console.log(data);
      //   } else {
      //     this.$toast(data.message);
      //   }
      // }

      //中奖记录播报
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/prizeRecord/queryLimit",
          data: {
            type: this.type
          }
        });
        if (data.success) {
          this.prizeTurnTableDTO = [];
          if (data.data && data.data.length > 0) {
            data.data.map((item, index) => {
              this.prizeTurnTableDTO.push(
                `恭喜${this.noPassByName(
                  item.extension.userName ? item.extension.userName : ""
                )}获得${item.turntableDTO.prizeName}`
              );
            });
          }
        } else {
          this.$toast(data.message);
        }
      }
    },
    stopClick() {},
    noPassByName(str) {
      if (null != str && str != undefined) {
        if (str.length <= 3) {
          return "*" + str.substring(1, str.length);
        } else if (str.length > 3 && str.length <= 6) {
          return "**" + str.substring(2, str.length);
        } else if (str.length > 6) {
          return str.substring(0, 2) + "****" + str.substring(6, str.length);
        }
      } else {
        return "";
      }
    },

    hasToken() {
      if (!this.canClick) return false;
      if (!this.token || this.token == "undefined") {
        this.$router.push("/downloadApp?timeStamp=" + Date.now());
        return false;
      }
      clearInterval(this.curIntervalId);
      return true;
    },
    toAddress(id) {
      this.$router.push(`/turnTableAddress?id=${id}&token=${this.token}&timeStamp=${Date.now()}`);
    },

    onShare(){
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: "/otherPackage/h5Game/share?type=superTurntable"
          });
        } else {
          
        }
      });
    },

    goPage(index, prizeType = null) {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      if (index == 0) {
        window.scrollTo(0, document.body.scrollHeight - 880);
      }
      if (index == 1) {
        var path = ["/turnTableRules", "/turnTableRecord"];
        this.$router.push(
          path[index] + "?token=" + this.token + "&type=" + this.type + "&timeStamp=" + Date.now()
        );
      }
      if (index == 2) {
        this.$router.replace(`/turnTable?token=${this.token}&time=${Date.now()}`);
      }
      if (index == 4) {
        this.$router.push(`/scoreDetail?token=${this.token}&time=${Date.now()}`);
      }
      if (index == 5) {
        window.scrollTo(0, document.body.scrollHeight - 1840);
      }
    },
    goTo() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.$router.push("/turnTable2?token=" + this.token + "&timeStamp=" + Date.now(), () => {
        console.log(window.location.href);
      });
    },
    getMoreChance() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      this.jumpToMain();
    },
    onBtn(popType) {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      if (popType === 2) {
        this.$router.push(`/goldDetail?token=${this.token}&time=${Date.now()}`);
      } else {
        this.jumpToMain();
      }
    },
    jumpToMain() {
      if (this.popType === 2) {
        this.goPage(1);
        return;
      }
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    },
    goodsDetail(item) {
      console.log(this.canClick);
      if (!this.canClick) {
        return;
      }
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${item.itemId}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: item.itemId
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },

    // 抽取按钮按钮点击触发事件
    async onClick() {
      if (!this.hasToken() || !this.canClick) {
        return;
      }
      if (this.score < this.turnTableScore) {
        // this.$toast("活力值不足！");
        // return;
      }
      this.canClick = false;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/luckyDraw",
        data: {
          type: this.type
        }
      });

      if (data.success) {
        this.resetTable();
        this.drwaData = data.data;
        // this.priceValue = data.data.extension.priceValue;
        this.turnAngle = 15;
        this.turnLoop = 3;
        this.prizeRecordId = data.data.prizeRecordId;
        this.prizeType = data.data.prizeType;
        this.curPrizeName = data.data.prizeName;
        this.curItemUrl = "";
        this.popType = 2;
        if (this.prizeType == 2) {
          this.curItemUrl = data.data.extension.ITEM_URL;
        } else if (this.drwaData.extension && this.drwaData.extension.COUPON_ITEM_URL) {
          this.curItemUrl = this.drwaData.extension.COUPON_ITEM_URL;
        }
        setTimeout(() => {
          // this.stopIndex = 1;
          this.stopIndex = this.rewardList[0][data.data.prizeName].index;
          this.action();
        }, 10);
        this.getTurnTableDate();
      } else {
        // test//////////////////////////////////////////////
        // setTimeout(() => {
        //   this.turnAngle = 15;
        //   this.turnLoop = 3;
        //   this.stopIndex = 1;
        //   this.action();
        //   console.log("dddd");
        // }, 10);
        /////////////////////////////////////////////////////
        this.canClick = true;
        this.pointerStatus = 0;
        if (data.code == 60000036) {
          //当日抽奖次数已用尽
          this.popType = 0;
          this.showModal = true;
        } else if (data.code == 60000035) {
          //无抽奖次数
          this.showModal = true;
          this.popType = 1;
        } else {
          this.$toast(data.message);
        }
      }
    },
    resetTable() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      ctx.clearRect(-canvasW / 2, -canvasH / 2, canvasW, canvasH); //去掉背景默认的黑色
      ctx.rotate(-(45 * (this.stopIndex - 1) * Math.PI) / 180);
      ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
    },
    action() {
      this.pointerStatus = 1;
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度

      var allAngle = 0;
      this.curIntervalId = setInterval(() => {
        ctx.beginPath();
        ctx.clearRect(-canvasW / 2, -canvasH / 2, canvasW, canvasH); //去掉背景默认的黑色
        ctx.closePath();
        ctx.rotate((this.turnAngle * Math.PI) / 180);
        ctx.drawImage(this.img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);

        allAngle += this.turnAngle;
        if (allAngle == (this.turnLoop * 360 == 0 ? 360 : this.turnLoop * 360)) {
          clearInterval(this.curIntervalId);
          this.turnAngle -= 5;
          if (this.turnAngle > 5) {
            this.turnLoop = 2;
            this.action();
          } else if (this.turnAngle == 5) {
            this.turnLoop = (45 * (this.stopIndex - 1)) / 360;

            console.log("this.turnLoop", this.turnLoop, this.stopIndex);
            this.action();
          } else {
            this.getDrawCounts();
            setTimeout(() => {
              this.canClick = true;
              this.showModal = true;
              this.pointerStatus = 0;
            }, 300);
          }
        }
      }, this.turnTime);
    },
    drawWheelCanvas() {
      var canvas = document.getElementById("wheelCanvas");
      var ctx = canvas.getContext("2d");
      var canvasW = canvas.width; // 画板的高度
      var canvasH = canvas.height; // 画板的宽度
      console.log(canvasW);
      // ctx.beginPath();
      ctx.fillStyle = "#fff000";
      ctx.clearRect(-canvasW / 2, -canvasH / 2, canvasW, canvasH); //去掉背景默认的黑色

      console.log(canvasW);
      ctx.strokeStyle = "#199301"; //线的颜色
      ctx.font = "26px Microsoft YaHei";

      //画转盘
      ctx.translate(canvasW / 2, canvasH / 2); //设置旋转锚点
      var img_bg = new Image(canvasW + "px", canvasH + "px");
      img_bg.src =
        "https://oss-kiddo.manqu88.com/h5/11turnTable/super_turntable.png?time=" + Date.now();
      // +"&x-oss-process=image/format,webp";
      img_bg.onload = () => {
        console.log("onload", this.stopIndex);
        ctx.rotate((45 * (this.stopIndex - 1) * Math.PI) / 180);
        ctx.drawImage(img_bg, -canvasW / 2, -canvasH / 2, canvasW, canvasH);
        // ctx.closePath();
        this.canClick = true;
      };
      this.img_bg = img_bg;
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  max-width: 100vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .share {
    position: absolute;
    top: 60px;
    right: 13px;
    width: 32px;
    height: 47px;
    background: url(https://oss-kiddo.manqu88.com/h5/share_turntable.png) no-repeat;
    background-size: 100% 100%;
  }
}
.background-1 {
  height: 966px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_bg_1.png?11) no-repeat;
  background-size: 100% 100%;
}

.btn-draw {
  position: absolute;
  top: 533px;
  left: 88px;
  width: 200px;
  height: 44px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_draw.png) no-repeat;
  background-size: 100% 100%;
}
.btn-goLucky {
  position: absolute;
  top: 672px;
  left: 25px;
  width: 176px;
  height: 38px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_goSuper.png?11) no-repeat;
  background-size: 100% 100%;
}
.btn-goDuihuan {
  position: absolute;
  top: 672px;
  left: 211px;
  width: 141px;
  height: 38px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_goDuihuan.png?11) no-repeat;
  background-size: 100% 100%;
}
.label {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0px;
  width: 71px;
  height: 27px;
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 17px 0 0 17px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}
.label-1 {
  top: 139px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/label_1.png?1) no-repeat;
  background-size: 100% 100%;
}
.label-2 {
  top: 173px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/label_2.png?1) no-repeat;
  background-size: 100% 100%;
}
.label-3 {
  display: flex;
  align-items: center;
  position: absolute;
  // justify-content: center;
  left: 0;
  top: 139px;
  width: auto;
  height: 29px;
  background: #b80106;
  border-radius: 0px 13px 13px 0px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 17px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/label_3.png?1) no-repeat;
  background-size: 100% 100%;

  .num {
    margin-left: 6px;
    margin-right: 6px;
    width: auto;
    height: 26px;
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.label-4 {
  position: absolute;
  left: 0;
  top: 173px;
  width: 104px;
  height: 24px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/label_4.png?1) no-repeat;
  background-size: 100% 100%;
}

.msgView {
  position: relative;
  margin-bottom: 22px;
  margin-top: 20px;
  width: 330px;
  height: 27px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/msgBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .notice {
    width: 270px;
    height: 24px;
    position: absolute;
    bottom: 0px;
    left: 20px;
    font-size: 12px;
    color: #404040;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }
  /deep/ .van-notice-bar {
    background-color: rgba($color: #ffffff, $alpha: 0) !important;
  }
}

.title {
  width: 240px;
  height: 68px;
  position: relative;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 0;
  margin-bottom: 48px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/title_2.png?1) no-repeat;
  background-size: 100% 100%;
}

.tableBg {
  position: absolute;
  width: 375px;
  height: 283px;
  top: 320px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_turntableBg.png) no-repeat;
  background-size: 100% 100%;
}
.wheel {
  display: block;
  width: 310px;
  height: 310px;
  position: relative;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 0;
}
.choosed {
  position: absolute;
  left: 113px;
  top: 9px;
  width: 125px;
  height: 152px;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/choosed.png) no-repeat;
  background-size: 100% 100%;
}
.pointer-up {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 123px;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btnDrawGif.gif) no-repeat;
  background-size: 100% 100%;
}
.pointer {
  position: absolute;
  width: 34px;
  height: 143px;
  left: 138px;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/point.png) no-repeat;
  background-size: 100% 100%;

  .drawtitle {
    margin-top: 37px;
    width: 58px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
  }
  .needScore {
    height: 20px;
    font-size: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 14px;
  }
}

#wheelCanvas {
  width: 310px;
  height: 310px;
}

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card {
    position: relative;
    width: 226px;
    height: 136px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 38px;
      margin-bottom: 26px;
      width: 100%;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }
    .btn-close {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_close.png) no-repeat;
      background-size: 100% 100%;
    }
    .btn-view {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid #cccccc;
        margin-right: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1c1c1c;
        line-height: 20px;
      }
      .btn-confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 32px;
        background: #c90e09;
        border-radius: 8px;
        margin-left: 4px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
}

.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  .bgGet {
    position: relative;
    width: 250px;
    height: 296px;
    background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_getBg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .prize {
      position: absolute;
      top: 64px;
      width: 250px;
      height: 135px;
      background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_prize_2.png) no-repeat;
      background-size: 100% 100%;
    }

    .btn-close {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_close.png) no-repeat;
      background-size: 100% 100%;
    }

    .btn-goRecord {
      position: absolute;
      top: 213px;
      width: 250px;
      height: 135px;
    }
  }
}
.bg-3 {
  position: relative;
  width: 100vw;
  height: 1366px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_bg_2.png?11) no-repeat;
  background-size: 100% 100%;

  .goods-1 {
    position: absolute;
    width: 164px;
    height: 138px;
    left: 20px;
    top: 129px;
    //border: 1px solid #57ff78;
  }
  .goods-2 {
    position: absolute;
    width: 164px;
    height: 138px;
    left: 191px;
    top: 129px;
    //border: 1px solid #57ff78;
  }

  .goods-3 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 371px;
    //border: 1px solid #57ff78;
  }
  .goods-4 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 371px;
    //border: 1px solid #57ff78;
  }
  .goods-5 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 371px;
    //border: 1px solid #57ff78;
  }

  .goods-6 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 633px;
    //border: 1px solid #57ff78;
  }
  .goods-7 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 633px;
    //border: 1px solid #57ff78;
  }
  .goods-8 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 633px;
    //border: 1px solid #57ff78;
  }

  .goods-9 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 895px;
    //border: 1px solid #57ff78;
  }
  .goods-10 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 895px;
    //border: 1px solid #57ff78;
  }
  .goods-11 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 895px;
    //border: 1px solid #57ff78;
  }

  .goods-12 {
    position: absolute;
    width: 79px;
    height: 114px;
    left: 19px;
    top: 1156px;
    //border: 1px solid #57ff78;
  }
  .goods-13 {
    position: absolute;
    width: 79px;
    height: 114px;
    left: 105px;
    top: 1156px;
    //border: 1px solid #57ff78;
  }
  .goods-14 {
    position: absolute;
    width: 79px;
    height: 114px;
    left: 191px;
    top: 1156px;
    //border: 1px solid #57ff78;
  }
  .goods-15 {
    position: absolute;
    width: 79px;
    height: 114px;
    left: 276px;
    top: 1156px;
    //border: 1px solid #57ff78;
  }
}

.bg-1 {
  position: relative;
  width: 100vw;
  height: 1298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/super_bg_3.png?11) no-repeat;
  background-size: 100% 100%;

  .btn-left {
    position: absolute;
    top: 257px;
    left: 27px;
    width: 150px;
    height: 30px;
    background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_duihuan.png) no-repeat;
    background-size: 100% 100%;
  }

  .btn-right {
    position: absolute;
    top: 257px;
    right: 27px;
    width: 150px;
    height: 30px;
    background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/btn_duihuan.png) no-repeat;
    background-size: 100% 100%;
  }

  .item-1 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 398px;
    //border: 1px solid #57ff79;
  }
  .item-2 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 398px;
    //border: 1px solid #57ff79;
  }
  .item-3 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 398px;
    //border: 1px solid #57ff79;
  }

  .item-4 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 619px;
    //border: 1px solid #57ff79;
  }
  .item-5 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 619px;
    //border: 1px solid #57ff79;
  }
  .item-6 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 619px;
    //border: 1px solid #57ff79;
  }

  .item-7 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 841px;
    //border: 1px solid #57ff79;
  }
  .item-8 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 841px;
    //border: 1px solid #57ff79;
  }
  .item-9 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 841px;
    //border: 1px solid #57ff79;
  }

  .item-10 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 20px;
    top: 1063px;
    //border: 1px solid #57ff79;
  }
  .item-11 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 134px;
    top: 1063px;
    //border: 1px solid #57ff79;
  }
  .item-12 {
    position: absolute;
    width: 108px;
    height: 138px;
    left: 248px;
    top: 1063px;
    //border: 1px solid #57ff79;
  }

  .duihuan-1 {
    position: absolute;
    left: 20px;
    top: 536px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-2 {
    position: absolute;
    left: 134px;
    top: 536px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-3 {
    position: absolute;
    left: 248px;
    top: 536px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-4 {
    position: absolute;
    left: 20px;
    top: 757px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-5 {
    position: absolute;
    left: 134px;
    top: 757px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-6 {
    position: absolute;
    left: 248px;
    top: 757px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-7 {
    position: absolute;
    left: 20px;
    top: 979px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-8 {
    position: absolute;
    left: 134px;
    top: 979px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-9 {
    position: absolute;
    left: 248px;
    top: 979px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-10 {
    position: absolute;
    left: 20px;
    top: 1201px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-11 {
    position: absolute;
    left: 134px;
    top: 1201px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
  .duihuan-12 {
    position: absolute;
    left: 248px;
    top: 1201px;
    width: 108px;
    height: 74px;
    //border: 1px solid #57ff79;
  }
}
.bg-2 {
  position: relative;
  width: 100vw;
  height: 858px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(https://oss-kiddo.manqu88.com/h5/11turnTable/lucky_bg_3.png?1) no-repeat;
  background-size: 100% 100%;
}
</style>
