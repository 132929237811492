<template>
  <div class="wrap">
    <div class="Section1" style="layout-grid:15.6pt">
      <p class="MsoNormal">
        <b
          ><span style="font-family:宋体;color:black">一、第三方</span
          ><span lang="EN-US" style="color:black">SDK</span></b
        ><b
          ><span
            style="font-family:宋体;
color:black"
            >列表</span
          ></b
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">为保障</span
        ><span lang="EN-US" style="color:black">Kiddol</span
        ><span
          style="font-family:宋体;
color:black"
          >相关功能的实现和应用的安全稳定运行，我们会接入由第三方提供的软件开发包（</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span style="font-family:宋体;color:black">）实现该目的。</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">我们会对第三方</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span
          style="font-family:宋体;
color:black"
          >进行严格的安全检测，并要求合作伙伴采取严格的措施来保护您的个人数据。在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span
          style="font-family:宋体;
color:black"
          >，并及时在本说明中向您公开说明接入第三方</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span style="font-family:宋体;color:black">的最新情况。请注意，第三方</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span style="font-family:宋体;color:black"
          >可能因为版本升级、策略调整等原因导致数据类型存在一些变化，附其官方网站链接或相关隐私保护说明链接供参考，具体请以其公示的官方说明为准。</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">安卓操作系统第三方</span
        ><span lang="EN-US" style="color:black">SDK</span
        ><span
          style="font-family:宋体;
color:black"
          >列表</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">Android:</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">1.</span
        ><span style="font-family:宋体;color:black">阿里云一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：手机号码、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black"
          >地址、设备生产商、设备型号、操作系统类型、操作系统版本、运营商类型、网络类型、</span
        ><span lang="EN-US" style="color:black">SIM</span
        ><span
          style="font-family:宋体;
color:black"
          >数量、屏幕尺寸、屏幕分辨率信息、软件列表</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">2.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国移动</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：中移互联网有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：手机号码、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black"
          >地址、设备生产商、设备型号、操作系统类型、操作系统版本、运营商类型、网络类型、</span
        ><span lang="EN-US" style="color:black">SIM</span
        ><span
          style="font-family:宋体;
color:black"
          >数量、屏幕尺寸、屏幕分辨率信息</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://wap.cmpassport.com/resources/html/contract.html</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">3.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国联通</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：中国联合网络通信有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：手机号码、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black"
          >地址、设备生产商、设备型号、操作系统类型、操作系统版本、运营商类型、网络类型、</span
        ><span lang="EN-US" style="color:black">SIM</span
        ><span
          style="font-family:宋体;
color:black"
          >数量、屏幕尺寸、屏幕分辨率信息</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">4.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国电信</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：世纪龙信息网络有限责任公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：手机号码、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black"
          >地址、设备生产商、设备型号、操作系统类型、操作系统版本、运营商类型、网络类型、</span
        ><span lang="EN-US" style="color:black">SIM</span
        ><span
          style="font-family:宋体;
color:black"
          >数量、屏幕尺寸、屏幕分辨率信息</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://e.189.cn/sdk/agreement/content.do?type=main&amp;appKey=&amp;hidetop=true&amp;returnUrl=</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">5.</span
        ><span style="font-family:宋体;color:black">腾讯</span
        ><span lang="EN-US" style="color:black">bugly</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：深圳市腾讯计算机系统有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >崩溃堆栈信息、操作系统名称、操作系统版本</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：记录应用运行崩溃错误信息，用于</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >稳定正常运行</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：使用过程中出现程序崩溃时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://privacy.qq.com/document/priview/fbd2c3f898df4c1c869925dd49d57827</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">6.</span
        ><span style="font-family:宋体;color:black">腾讯</span
        ><span lang="EN-US" style="color:black">X5</span
        ><span style="font-family:宋体;color:black">浏览器</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：深圳市腾讯计算机系统有限公司</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">收集的个人信息：无</span></p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >浏览网页</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >商品详情页、订单页、购物车页面、辅食育儿百科内容信息展示场景</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">https://x5.tencent.com/docs/privacy.html</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">7.</span
        ><span style="font-family:宋体;color:black">微信</span
        ><span lang="EN-US" style="color:black">Open SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：深圳市腾讯计算机系统有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：订单信息、账号信息</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black"
          >功能：微信账号授权登录，分享到微信，订单微信支付</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：登录为</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">微信登录】场景；分享到微信为</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">【商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">内容页</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black"
          >右上角分享】场景；微信支付为购买商品或服务订单结算支付场景；</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/ONwPihxKd82RAkIJ</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">8.</span
        ><span style="font-family:宋体;color:black">支付宝</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：支付宝（中国）网络技术有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：</span
        ><span lang="EN-US" style="color:black">WLAN</span
        ><span
          style="font-family:宋体;
color:black"
          >接入点、订单信息</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：订单支付宝支付</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >具体使用场景：购买商品或服务订单结算支付场景；</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">https://opendocs.alipay.com/open/54/01g6qm</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">9.</span
        ><span style="font-family:宋体;color:black">网易七鱼客服</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：杭州朗和科技有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：软件列表、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black">地址、网络类型</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >使用的敏感权限：存储权限、麦克风权限、相机权限</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：客服服务，用于用户了解询问商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务信息、查询订单信息、查询配送信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【商品详情页</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">底部客服按钮】场景；</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">【我的</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">在线客服】</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">http://gb.corp.163.com/gb/legal.html</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">10.</span
        ><span style="font-family:宋体;color:black">阿里云移动推送</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：</span
        ><span lang="EN-US" style="color:black">WLAN</span
        ><span
          style="font-family:宋体;
color:black"
          >接入点、软件列表</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用的敏感权限：存储权限、无</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于向用户推送通知消息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >开启消息推送通知并使用</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">过程中</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US"
          ><a
            href="http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC"
            ><span style="color:windowtext;text-decoration:none"
              >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
            ></a
          ></span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal" style="text-indent:0cm">
        <span lang="EN-US" style="color:black"
          >11.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span
        ><span style="font-family:宋体;color:black">友盟</span
        ><span lang="EN-US" style="color:black">SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >所属机构：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：埋点统计，用于</span
        ><span lang="EN-US" style="color:black">App</span
        ><span
          style="font-family:宋体;
color:black"
          >数据分析</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">App</span
        ><span
          style="font-family:宋体;
color:black"
          >运行过程中</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">https://www.umeng.com/page/policy</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">12.</span
        ><span style="font-family:宋体;color:black">小米推送</span
        ><span lang="EN-US" style="color:black">SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：小米移动软件有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：软件列表</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于向小米设备用户推送通知消息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >开启消息推送通知并使用</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">过程中</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://dev.mi.com/console/doc/detail?pId=1822</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">13.VIVO</span
        ><span style="font-family:宋体;color:black">推送</span
        ><span lang="EN-US" style="color:black">SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：维沃移动通信有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：软件列表</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于向</span
        ><span lang="EN-US" style="color:black">VIVO</span
        ><span
          style="font-family:宋体;
color:black"
          >设备用户推送通知消息</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >开启消息推送通知并使用</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">过程中</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">14.</span
        ><span style="font-family:宋体;color:black">华为推送</span
        ><span lang="EN-US" style="color:black">SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：华为终端有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：软件列表</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于向华为设备用户推送通知消息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >开启消息推送通知并使用</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">过程中</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">15.</span
        ><span style="font-family:宋体;color:black">阿里云上传文件</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：文件数据信息、软件列表、</span
        ><span lang="EN-US" style="color:black">IP</span
        ><span style="font-family:宋体;color:black">地址</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用的敏感权限：存储权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于视频文件上传</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【</span
        ><span lang="EN-US" style="color:black">Kiddol </span
        ><span style="font-family:宋体;color:black">圈</span
        ><span lang="EN-US" style="color:black">--</span
        ><span style="font-family:宋体;color:black">发布】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">iOS:</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">1.</span
        ><span style="font-family:宋体;color:black">阿里云一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：手机号码</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">2.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国移动</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：中移互联网有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：当前手机号的掩码</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://wap.cmpassport.com/resources/html/contract.html</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">3.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国联通</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：中国联合网络通信有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：当前手机号的掩码</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">4.</span
        ><span style="font-family:宋体;color:black">一键登录</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">中国电信</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：世纪龙信息网络有限责任公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：当前手机号的掩码</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于手机号码一键登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">一键登录】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://e.189.cn/sdk/agreement/content.do?type=main&amp;appKey=&amp;hidetop=true&amp;returnUrl=</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">5.</span
        ><span style="font-family:宋体;color:black">腾讯</span
        ><span lang="EN-US" style="color:black">bugly</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：深圳市腾讯计算机系统有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：记录应用运行崩溃错误信息，用于</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >稳定正常运行</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：使用过程中出现程序崩溃时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://privacy.qq.com/document/priview/fbd2c3f898df4c1c869925dd49d57827</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">6.</span
        ><span style="font-family:宋体;color:black">微信</span
        ><span lang="EN-US" style="color:black">Open SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：深圳市腾讯计算机系统有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >收集的个人信息：用户昵称、用户头像、订单信息</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black"
          >功能：微信账号授权登录，分享到微信，订单微信支付</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：登录为</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【登录页面</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">微信登录】场景；分享到微信为</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">【商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">内容页</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black"
          >右上角分享】场景；微信支付为购买商品或服务订单结算支付场景；</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/ONwPihxKd82RAkIJ</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">7.</span
        ><span style="font-family:宋体;color:black">支付宝</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：支付宝（中国）网络技术有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >收集的个人信息：订单信息、设备型号、系统版本</span
        >
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：订单支付宝支付</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >具体使用场景：购买商品或服务订单结算支付场景；</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">https://opendocs.alipay.com/open/54/01g6qm</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">8.</span
        ><span style="font-family:宋体;color:black">网易七鱼客服</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：杭州朗和科技有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用的敏感权限：使用麦克风、读取相册</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：客服服务，用于用户了解询问商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务信息、查询订单信息、查询配送信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【商品详情页</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">底部客服按钮】场景；</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">【我的</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">在线客服】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">http://gb.corp.163.com/gb/legal.html</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">9.</span
        ><span style="font-family:宋体;color:black">阿里云移动推送</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于向用户推送通知消息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >开启消息推送通知并使用</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span style="font-family:宋体;color:black">过程中</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">10.</span
        ><span style="font-family:宋体;color:black">阿里云上传文件</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：文件数据信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用的敏感权限：读取相册权限</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于视频文件上传</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【</span
        ><span lang="EN-US" style="color:black">Kiddol </span
        ><span style="font-family:宋体;color:black">圈</span
        ><span lang="EN-US" style="color:black">--</span
        ><span style="font-family:宋体;color:black">发布】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">11.</span
        ><span style="font-family:宋体;color:black">阿里云播放器</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：阿里云计算有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于视频播放</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【首页</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">带视频的内容</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">视频播放】场景；</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【</span
        ><span lang="EN-US" style="color:black">kiddol</span
        ><span style="font-family:宋体;color:black">圈</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">带视频辅食内容详情</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">视频播放】场景；</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【商品详情</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">商品主图</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">视频播放】场景</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          ><a
            href="http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC"
            ><span style="color:black"
              >http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.89.14752d62sXCNhC</span
            ></a
          ></span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">12.</span
        ><span style="font-family:宋体;color:black">高德地图</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">所属机构：高德软件有限公司</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集的个人信息：位置信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用的敏感权限：读取位置</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于详细地址定位</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >【收货地址</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">添加收货地址</span
        ><span lang="EN-US" style="color:black">-</span
        ><span style="font-family:宋体;color:black">定位】</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black"
          ><a href="http://cache.amap.com/h5/h5/publish/238/index.html"
            ><span style="color:black">http://cache.amap.com/h5/h5/publish/238/index.html</span></a
          ></span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">13.</span
        ><span style="font-family:宋体;color:black">友盟</span
        ><span lang="EN-US" style="color:black">SDK</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >所属机构：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">收集个人信息：设备型号、系统版本</span>
      </p>

      <p class="MsoNormal"><span style="font-family:宋体;color:black">使用的敏感权限：无</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：埋点统计，用于</span
        ><span lang="EN-US" style="color:black">App</span
        ><span
          style="font-family:宋体;
color:black"
          >数据分析</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">具体使用场景：</span
        ><span lang="EN-US" style="color:black">App</span
        ><span
          style="font-family:宋体;
color:black"
          >运行过程中</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">隐私政策链接：</span
        ><span lang="EN-US" style="color:black">https://www.umeng.com/page/policy</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <b><span style="font-family:宋体;color:black">二、与关联方共享个人信息</span></b>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">为使您能够便捷通过</span
        ><span lang="EN-US" style="color:black">Kiddol APP</span
        ><span
          style="font-family:宋体;
color:black"
          >浏览商品信息以及购物，您的个人信息可能会在共享给关联方，我们将根据法律法规要求并参照行业最佳实践，保障您个人信息的安全。我们严格遵循隐私权政策的约定，一旦涉及共享您的个人敏感信息或新增共享方，我们将再次征求您的授权同意。</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <b><span style="font-family:宋体;color:black">三、与合作伙伴共享个人信息</span></b>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">在我们向您提供产品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black"
          >服务时，我们会采取严格的安全措施，同时由于部分产品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black"
          >服务是年糕妈妈无法单独完成的，因此我们可能会与其他合作商等第三方共享或委托处理您的部分个人信息，以保障产品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务的顺利完成。</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">(</span
        ><span style="font-family:宋体;color:black">一</span
        ><span lang="EN-US" style="color:black">) </span
        ><span style="font-family:宋体;color:black">支付服务</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用场景：商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务支付</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：保障交易安全、完成商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务的支付</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">个人信息功能类型：订单信息</span
        ><span lang="EN-US" style="color:black">(</span
        ><span style="font-family:宋体;color:black">账号信息、订单号、订单金额</span
        ><span lang="EN-US" style="color:black">) </span
        ><span style="font-family:宋体;color:black">和网络信息（</span
        ><span lang="EN-US" style="color:black">WLAN</span
        ><span
          style="font-family:宋体;
color:black"
          >接入点）</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >接收方功能类型：提供支付服务的合作方，如财付通支付科技有限公司、支付宝（中国）网络技术有限公司</span
        ><span lang="EN-US" style="color:black">.</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color:black">(2) </span
        ><span style="font-family:宋体;color:black">配送服务</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用场景：商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务购买后的交付</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：实现购买商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务配送的需要及配送信息查询的售后服务</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >个人信息功能类型：订单信息（订单号）和配送信息（姓名、地址、电话）</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >接收方功能类型：商品提供商，配送服务提供商，配送信息查询提供商</span
        >
      </p>

      <p class="MsoNormal"><span lang="EN-US" style="color:black">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">（</span
        ><span lang="EN-US" style="color:black">3</span
        ><span style="font-family:宋体;color:black">）客服服务</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用场景：用户与</span
        ><span lang="EN-US" style="color:black">APP</span
        ><span
          style="font-family:宋体;
color:black"
          >客服沟通</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black">使用目的</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">功能：用于用户了解询问商品</span
        ><span lang="EN-US" style="color:black">/</span
        ><span style="font-family:宋体;color:black">服务信息、查询订单信息、查询配送信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >个人信息功能类型：个人账号信息、订单信息（订单号，订单名称）和配送信息（姓名、地址、电话）</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family:宋体;color:black"
          >接收方功能类型：提供客服消息服务的合<a name="_GoBack"></a
          >作方（如杭州朗和科技有限公司）</span
        >
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "illustrate",
  components: {},

  data() {
    return {};
  },
  created() {
    // window.location.href = 'https://static.manqu88.com/kiddo-privacy.html'
    document.title = "第三方共享个人信息清单";
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 0;
  // min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  text-align: left;
}
p {
  margin: 5px 15px;
}
span {
  word-break: break-all;
  white-space: pre-wrap;
}
.card {
  margin: 0px auto;
  width: 355px;
  height: auto;
  background: #ffffff;
}
.title {
  margin: 14px auto;
  margin-bottom: -9px;
  width: 326px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 21px;
}
.detail {
  margin: 14px auto;
  width: 326px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
</style>
