<template>
  <div class="ck-wheel">
    <div class="bg"></div>
    <div class="bg-bottom"></div>
    <div class="time"></div>
    <div class="btn" @click="goMiniPage"></div>

    <div class="mask" v-if="showQrCode">
      <div class="tip">
        <!-- <div class="download" @click="downLoadImg"></div> -->
        <div class="close" @click="showQrCode = false"></div>
        <div class="copyLink" @click="copyLink"></div>
      </div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
export default {
  components: {},
  created() {
    document.title = "礼包卡位战";
  },
  data() {
    return {
      browser: "",
      config: { ios: 0, android: 0 },
      platform: "",
      isWx: false,
      shortLink: "#小程序://Kiddol/礼包卡位战/i3UtieIhnqTFV1x",
      qrCode: "https://oss-kiddo.manqu88.com/h5/VTN/qrCode.png",
      showQrCode: false,
    };
  },
  mounted() {
    const {
      versions: { ios, isQQ, isWeiXin },
    } = browser();
    this.platform = ios ? "ios" : "android";

    this.getConfig();

    uni.webView.getEnv((res) => {
      if (res.miniprogram) {
        this.isWx = true;
      } else {
        this.isWx = false;
      }
    });
  },
  methods: {
    async getConfig() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
        },
        url: "/api/common/storage/getJsonObj",
        data: {
          key: "VTN_CONFIG",
        },
      });
      if (data.success) {
        this.config = data.data;
        console.log(this.config);
      }
    },
    goMiniPage() {
      uni.webView.getEnv((res) => {
        // console.log("当前环境：" + JSON.stringify(res), browser().versions);
        console.log({ res });
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/otherPackage/vtn/index`,
          });
        } else {
          if (this.platform === "ios") {
            if (this.config.ios === 0) {
              this.showQrCodeMask();
            } else {
              this.appGoWeapp();
            }
          } else {
            if (this.config.android === 0) {
              this.showQrCodeMask();
            } else {
              this.appGoWeapp();
            }
          }
          //   window.location.href =
          //     "weixin://dl/business/?appid=wxe10051dd6c494797&path=otherPackage/vtn/index";

          //   this.downLoadImg();
        }
      });
    },

    showQrCodeMask() {
      this.showQrCode = true;
    },

    appGoWeapp() {
      this.$bridge
        .call("openPage", {
          type: 50,
          param: "otherPackage/vtn/index",
        })
        .then((e) => {
          console.log("jumpToDetail客户端返回: ", e);
        });

      setTimeout(() => {
        this.showQrCodeMask();
      }, 1000);
    },

    downLoadImg(imgsrc, name) {
      imgsrc = this.qrCode;
      let image = new Image();
      image.setAttribute("crossorigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); //生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "礼包卡位战二维码"; // 设置图片名称没有设置则为默认
        a.href = url; //将生成的URL设置为a.href属性
        a.dispatchEvent(event); //触发a的单击事件
      };
      image.src = imgsrc;
    },

    copyLink() {
      this.$copyText(this.shortLink)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch(() => {
          this.$toast("复制失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .tip {
      position: relative;
      width: 250px;
      height: 347px;
      background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/tips.png?3");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 70px;

      .close {
        position: absolute;
        right: 0;
        top: -36px;
        width: 30px;
        height: 30px;
        background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/close.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .download {
        position: absolute;
        left: 12px;
        bottom: 15px;
        width: 111px;
        height: 30px;
      }

      .copyLink {
        position: absolute;
        right: 69px;
        bottom: 15px;
        width: 111px;
        height: 30px;
      }
    }
  }

  .bg {
    width: 100vw;
    height: 731px;
    background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .bg-bottom {
    width: 100vw;
    height: 100vh;
    background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/bg_bottom.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -2px;
  }

  .time {
    position: absolute;
    left: 52px;
    bottom: 125px;
    width: 271px;
    height: 28px;
    background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/time.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .btn {
    position: absolute;
    left: 112px;
    bottom: 68px;
    width: 151px;
    height: 37px;
    background-image: url("https://oss-kiddo.manqu88.com/h5/VTN/btn.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
