<template>
    <div class="ck-wheel">


        <div class="card">
            <div class="phaseNum">
                <div>第</div>
                <div style="width:1px"></div>
                <div>{{ detailData.phase && detailData.phase.toString().padStart(2, '0') }}</div>
                <div style="width:1px"></div>
                <div>期</div>
            </div>
            <div class="item-frame">
                <div class="item-img" :style="{ backgroundImage: `url(${detailData.coverImage})` }"></div>
            </div>

            <div class="reward-msg">
                <div class="title">{{ detailData.name }}</div>
                <div class="des">总参与抽奖码数：{{ detailData.codeNum }}个</div>
                <div class="des des-sub" v-if="detailData.isPublish === 1">中奖者：{{ detailData.nickName }}</div>
                <div class="des des-sub" v-if="detailData.isPublish === 1">中奖码：{{ detailData.winningCode }}</div>
                <div class="wait" v-if="detailData.isPublish !== 1">待开奖</div>
                <!-- <div class="arrow"></div> -->
            </div>
        </div>


        <div class="code-card">
            <div class="title-view">
                <div class="title">我的抽奖码</div>
                <div class="code-join">
                    您共参与抽奖：
                    <div class="code-num">{{ (detailData.userDrawCodeList && detailData.userDrawCodeList.length) ||
                        0
                    }}</div>
                    个
                </div>
            </div>

            <div class="code-display" v-if="detailData.userDrawCodeList && detailData.userDrawCodeList.length > 0">
                <div class="code-bg" v-for="(item, index ) in detailData.userDrawCodeList" :key="index">{{ item }}
                </div>
            </div>

        </div>


        <div class="user-card">
            <div class="card-title">参与详情</div>
            <div class="user-line" v-for="(item, index) in userList" :key="index">
                <div class="user-head" :style="{ backgroundImage: `url(${item.headUrl})` }"></div>
                <div class="user-name">{{ item.nickName }}</div>
                <div class="user-code">{{ item.drawCode }}</div>
            </div>

            <div class="getMore" v-if="!finished" @click="getMore">
                点击查看更多
                <div class="arrow-more"></div>
            </div>
        </div>


    </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
export default {
    name: "assist",
    components: {},
    data() {
        return {
            activityId: this.$route.query.activityId,
            phaseId: this.$route.query.phaseId,
            token: this.$route.query.token,
            isWx: false,

            userList: [],

            detailData: {},
            canLoad: false,
            currentPage: 1,
            pageSize: 8,
            loading: false,
            finished: false,
        };
    },
    created() {
        document.title = "往期详情";
    },
    mounted() {
        this.token = this.$route.query.token;
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                    this.getList();
                    this.isWx = true;
                } else {
                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                        this.getList();
                    });
                }
            });
        }, 0);
    },

    methods: {
        formatPrice,
        async getList() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/treasureActivity/queryPhaseDetail",
                    data: {
                        activityId: this.activityId,
                        phaseId: this.phaseId,
                    }
                });
                if (data.success) {
                    this.detailData = data.data;

                } else {
                    this.$toast(data.message);
                }

                // 加载状态结束
                this.loading = false;
                this.canLoad = true;

                this.getUsers();
            }
        },

        async getUsers() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/treasure/queryPageTreasureCode",
                    data: {
                        activityId: this.activityId,
                        phaseId: this.phaseId,
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                    }
                });
                if (data.success) {
                    this.userList = [...this.userList, ...data.data];
                    if (data.data.length < this.pageSize) {
                        this.finished = true;
                    }

                } else {
                    this.$toast(data.message);
                }

                // 加载状态结束
                this.loading = false;
                this.canLoad = true;
            }
        },



        async getMore() {
            if (!this.canLoad) {
                return;
            }
            this.currentPage++;
            this.getUsers();
        },





        goDetail(activityId, phaseId) {

            this.$router.push(`/treasure/queryPhaseDetail?token=${this.token}&activityId=${activityId}&phaseId=${phaseId}`);
            // window.open(`/assistDetail?token=${token}&id=${id}`);
        },


    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "mcFont";
    src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F5F5;



    .card {
        margin-top: 10px;
        position: relative;
        width: 355px;
        height: 118px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        align-items: center;

        .phaseNum {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 64px;
            height: 26px;
            background: #FFE800;
            border-radius: 13px;
            line-height: 26px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #010100;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .item-frame {
            margin-right: 10px;
            margin-left: 8px;
            width: 102px;
            height: 102px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #DFDFDF;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-img {
                width: 83px;
                height: 83px;
                background-size: 100% 100%;
            }
        }

        .reward-msg {
            height: 102px;
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                margin-top: 4px;
                width: 222px;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #232323;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* 作为弹性伸缩盒子模型显示 */
                display: -webkit-box;
                /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
                -webkit-box-orient: vertical;
                /* 显示的行 */
                -webkit-line-clamp: 1;
            }

            .des {
                margin-top: 4px;
                margin-bottom: 4px;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
            }

            .des-sub {
                width: 132px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 17px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* 作为弹性伸缩盒子模型显示 */
                display: -webkit-box;
                /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
                -webkit-box-orient: vertical;
                /* 显示的行 */
                -webkit-line-clamp: 1;
            }

            .wait {
                position: absolute;
                left: 0;
                bottom: 4px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #09C34E;
                line-height: 20px;
            }

            .arrow {
                position: absolute;
                right: 0;
                bottom: 6px;
                margin-left: 5px;
                width: 9px;
                height: 13px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/arrow_color.png?2) no-repeat;
                background-size: 100% 100%;
            }
        }
    }




    .code-card {
        padding-top: 11px;
        margin-top: 10px;
        width: 355px;
        min-height: 31px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .code-display {
            margin-top: 7px;
            margin-bottom: 4px;
            width: 348px;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .code-bg {
                margin-left: 4px;
                margin-right: 4px;
                margin-top: 7px;
                margin-bottom: 7px;
                width: 76px;
                height: 21px;
                background: #FBFBFB;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
            }

        }
    }

    .title-view {
        width: 355px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            margin-left: 14px;
        }

        .pastRecord {
            margin-right: 14px;
            display: flex;
            align-items: center;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            line-height: 20px;

            .arrow {
                margin-left: 5px;
                width: 5px;
                height: 8px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/arrow.png?2) no-repeat;
                background-size: 100% 100%;
            }
        }

        .code-join {
            margin-right: 14px;
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #131313;
            line-height: 17px;
            display: flex;
            align-items: center;

            .code-num {
                margin-left: 2px;
                margin-right: 2px;
                color: #FF5A29;
            }
        }
    }


    .user-card {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 355px;
        height: auto;
        background: #FFFFFF;
        border-radius: 4px;
        padding-bottom: 12px;

        .card-title {
            margin-top: 11px;
            margin-left: 14px;
            margin-bottom: 4px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 20px;
        }

        .user-line {
            margin-top: 10px;
            margin-bottom: 10px;
            height: 36px;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .user-head {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-size: 100% 100%;
                margin-left: 14px;
                margin-right: 10px;
            }

            .user-name {
                width: 182px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #404040;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* 作为弹性伸缩盒子模型显示 */
                display: -webkit-box;
                /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
                -webkit-box-orient: vertical;
                /* 显示的行 */
                -webkit-line-clamp: 1;
            }

            .user-code {
                position: absolute;
                right: 25px;
                height: 17px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 17px;
            }


        }

        .getMore {
            margin-top: 3px;
            margin-left: 130px;
            position: relative;
            display: flex;
            align-items: center;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 17px;

            .arrow-more {
                margin-top: -2px;
                margin-left: 8px;
                width: 5px;
                height: 8px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/arrow.png?2) no-repeat;
                background-size: 100% 100%;
                transform: rotate(90deg);
            }

        }
    }
}
</style>
  