<template>
  <div class="funds_detail_container">
    <div class="detail_head">
      <img v-if="type === '0'" class="detail_head_help" src="../../../assets/manage_head_question.png" alt=""
        @click="handleClickTip" />
      <div class="detail_head_mask">
        <div class="un_money_info">
          <p class="title">{{ type === "0" ? "待结算余额" : "冻结货款" }}</p>
          <p class="value">
            ¥{{
              type === "0"
              ? formatPrice(fundsData.unsettlementAmount)
              : formatPrice(fundsData.freezePaymentAmount)
            }}
          </p>
        </div>
        <div class="money_info">
          <p class="title">{{ type === "0" ? "可提现余额" : "可提现货款" }}</p>
          <p class="value">
            ¥{{
              type === "0"
              ? formatPrice(fundsData.settledAmount)
              : formatPrice(fundsData.freedPaymentAmount)
            }}
          </p>
        </div>
      </div>
      <div class="withdraw_button" @click="handleClickWithdraw()">提现</div>
      <p class="tip_info">
        {{
          type === "0"
          ? "余额提现需在每月13日23:59分前发起"
          : "货款提现需在每月4/14/24日23:59前发起"
        }}
      </p>
    </div>
    <p class="detail_title_tip">{{ type === "0" ? "余额明细" : "货款明细" }}</p>
    <div v-if="!isShowEmpty" class="mask">
      <div v-for="item in detailListData" :key="item.div" class="universal-list"
        @click="handleGoToOrderDetail(item.orderId)">
        <div class="universal-list-left">
          <p class="universal-list-title van-ellipsis">
            {{ item.title }}
          </p>
          <p class="universal-list-number">交易编号 {{ item.flowId }}</p>
          <p class="universal-list-time">
            交易时间 {{ item.gmtCreate && moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
        </div>
        <div class="universal-list-right">
          <p class="universal-list-money">{{ item.amountStr }}</p>
          <div v-show="item.orderId" class="universal-list-detail">
            查看详情
          </div>
        </div>
        <div class="universal-list-line"></div>
      </div>
    </div>
    <div v-if="!isShowEmpty" class="look_button" @click="handleClickAll">查看全部</div>
    <van-empty v-if="isShowEmpty" description="暂无明细" />
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "../../../utils/money";

export default {
  created() {
    if (this.$route.query.type === "0") {
      document.title = "我的余额";
    } else {
      document.title = "我的货款";
    }
  },
  data() {
    return {
      token: "",
      type: "",
      fundsData: {},
      detailListData: [],
      isShowEmpty: false
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.type = this.$route.query.type;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getCurrentFundsData();
        this.getDetailListData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getCurrentFundsData();
          this.getDetailListData();
        });
      }
    });
  },
  methods: {
    formatPrice,
    moment,
    async getCurrentFundsData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryDistAccountByDistUserId",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.fundsData = { ...data.data };
        }
      } else {
        this.$toast(data.message);
      }
    },
    async getDetailListData() {
      let params = { currentPage: 1, pageSize: 5 };
      if (this.type === "0") {
        params.accountType = 1;
      } else {
        params.accountType = 2;
      }
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/queryDisAccountFlowCondition",
        data: params
      });
      if (data.success) {
        if (data.data === null || data.data.length === 0) {
          this.isShowEmpty = true;
        } else {
          this.isShowEmpty = false;
          this.detailListData = [...data.data];
        }
      } else {
        this.isShowEmpty = true;
        this.$toast(data.message);
      }
    },
    handleClickTip() {
      this.$dialog.alert({
        title: "温馨提示",
        message:
          "可用余额为当前账户已结算收益的余额，若当前账户存在待结算收益订单时，可用余额会小于账户总资金。",
        confirmButtonText: "确定",
        confirmButtonColor: "#232323"
      });
    },
    handleGoToOrderDetail(orderId) {
      if (orderId && orderId.length > 0) {
        this.$router.push(`/addol/orderDetail?token=${this.token}&orderId=${orderId}`);
      }
    },
    handleClickAll() {
      // 0:余额  1:货款
      this.$router.push(
        `/addol/fundsSpecificDetail?type=${this.$route.query.type}&token=${this.token}`
      );
    },
    handleClickWithdraw() {
      // 0:余额  1:货款
      let withdrawMoney = "";
      if (this.type === "0") {
        withdrawMoney = formatPrice(this.fundsData.settledAmount);
      } else {
        withdrawMoney = formatPrice(this.fundsData.freedPaymentAmount);
      }
      this.$router.push(
        `/addol/withdraw?type=${this.$route.query.type}&withdrawMoney=${withdrawMoney}&token=${this.token}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.funds_detail_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  .detail_head {
    margin-top: 10px;
    width: 355px;
    height: 184px;
    background: #fffbf2;
    box-shadow: 0px 0px 4px 1px rgba(229, 229, 229, 0.5);
    border-radius: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .detail_head_help {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 15px;
      right: 15px;
      z-index: 999;
      display: block;
    }

    .detail_head_mask {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 40px;
      width: 100%;

      .un_money_info {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
          padding: 0;
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
        }

        .value {
          padding: O;
          margin: 0;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #a08849;
        }
      }

      .money_info {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
          padding: 0;
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
        }

        .value {
          padding: O;
          margin: 0;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #a08849;
        }
      }
    }

    .withdraw_button {
      width: 154px;
      height: 35px;
      background: #232323;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 35px;
      text-align: center;
      margin-top: 20px;
    }

    .tip_info {
      padding: 0;
      margin: 0;
      margin-top: 10px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }
  }

  .detail_title_tip {
    padding: 0;
    margin: 10px 0px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .mask {
    width: 100%;

    .universal-list {
      box-sizing: border-box;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      background-color: white;
      position: relative;

      .universal-list-left {
        flex: 1;
        width: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;

        .universal-list-title {
          padding: 0;
          margin: 0;
          text-align: left;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
        }

        .universal-list-number {
          padding: 0;
          margin: 0;
          margin-top: 5px;
          margin-bottom: 5px;
          text-align: left;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .universal-list-time {
          padding: 0;
          margin: 0;
          text-align: left;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }

      .universal-list-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 73px;

        .universal-list-money {
          padding: 0;
          margin: 0;
          font-size: 11px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #de7d36;
          text-align: right;
        }

        .universal-list-detail {
          margin-top: 15px;
          width: 73px;
          height: 25px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #b0b0b0;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
          text-align: center;
          line-height: 25px;
        }
      }

      .universal-list-line {
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 17px;
        width: 317px;
        height: 1px;
        background-color: #f5f5f5;
        display: block;
      }
    }

    .universal-list:nth-last-child(1) {
      .universal-list-line {
        display: none;
      }
    }
  }

  .look_button {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 135px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #0e0e0e;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #141414;
    line-height: 40px;
    text-align: center;
  }
}
</style>
