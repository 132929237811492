export function init(data) {
  console.log(data, wx);
  if (!is_weixn()) {
    return false
  }
  wx.config({
    debug: true,
    appId: 'wx241516f7d4d8336d', // 和获取Ticke的必须一样------必填，公众号的唯一标识 wxe10051dd6c494797 wx241516f7d4d8336d
    timestamp: data.timestamp, // 必填，生成签名的时间戳
    nonceStr: data.noncestr, // 必填，生成签名的随机串
    signature: data.signature, // 必填，签名
    //需要微信权限列表
    jsApiList: [
      "checkJsApi" //判断当前客户端版本是否支持指定JS接口
    ],
    openTagList: [
      'wx-open-launch-weapp',
      'wx-open-launch-app',
      'wx-open-subscribe'
    ] // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
  });
  wx.ready(function (e) {
    console.log(e, 'ready')
    alert('ready0');
    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
  });
  wx.error(function (e) {
    console.log(e, 'error')
    alert('error0');
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
  });
}

// 动态生成标签
// info参数
/* 
let params={
  eleId:"", // 元素ID
  appid:"", // 小程序id号 gh_****
  url:"", // 跳转小程序的页面路径地址 例: pages/home/home.html - (后面必须带上.html后缀 否则IOS跳转时出现小程序页面未配置)
  content:"" // html字符串 例: "<button>点我</button>"
}
*/
export function wx_weapp_launch(info) {
  if (!is_weixn()) {
    return
  }
  //   is_version();
  if (true) {
    var btn = document.getElementById(info.eleId); //获取元素
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content // 自定义的html字符串内容
    btn.style = `height:${info.height};`;
    // console.log(script.outerHTML)
    //style="opacity: 0;width:100%;height: 58px;display:block;position: absolute;left: 0;top: 0;"
    let html =
      style =
      `<wx-open-launch-weapp id="weapp_${info.eleId}" style="-webkit-tap-highlight-color:rgba(0,0,0,0);opacity:0;background:null;width:100%;height:${info.height};display:block;position:absolute;top:0;left:0;"  username="${info.username}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`; //${script.outerHTML}
    btn.innerHTML = html; // html字符串赋值

    // 点击按钮 正常跳转触发
    var cao = document.getElementById(`weapp_${info.eleId}`);
    cao.addEventListener("launch", function (e) {
      console.log("launch success");
      alert(`launch success`)
    });
    cao.addEventListener("click", function (e) {
      console.log("click success");
      alert(`click success`)
    });
    // 点击跳转 抛出异常
    cao.addEventListener("error", function (e) {
      info.method && info.method();
      console.log("fail", e.detail);
      alert(`跳转异常 - ${e.detail}`)
    });

  } else {
    alert("您的版本号不支持")
  }
}

// info参数
/* 
let params={
  eleId:"", // 元素ID
  appid:"", // 所需跳转的移动应用的AppID
  extinfo:"", // extinfo
  content:"" // html字符串 例: "<button>点我</button>"
}
*/
export function wx_app_launch(info) {
  console.log("wx_app_launch:", info)
  if (!is_weixn()) {
    return
  }
  // is_version();
  if (true) {
    var btn = document.getElementById(info.eleId); //获取元素
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content // 自定义的html字符串内容
    btn.style = `height:${info.height};`;
    // script.style = 'width:100%;height:100%';
    let html =
      `<wx-open-launch-app id="app_${info.eleId}" style="-webkit-tap-highlight-color:rgba(0,0,0,0);opacity:0;background:null;width:100%;height:${info.height};display:block;position:absolute;top:0;left:0;" appid="${info.appid}" extinfo='${info.extinfo}'>${script.outerHTML}</wx-open-launch-app>`;
    btn.innerHTML = html; // html字符串赋值
    console.log("info.extinfo11:", info.extinfo, html)
    // 点击按钮 正常跳转触发
    var cao = document.getElementById(`app_${info.eleId}`);
    cao.addEventListener("launch", (e) => {
      console.log("app_launch success1", e);
    });
    cao.addEventListener("click", function (e) {
      console.log("app_click success", e);
    });
    cao.addEventListener("ready", (e) => {
      console.log("app_ready success", e);
    });
    // 点击跳转 抛出异常
    cao.addEventListener("error", (e) => {
      info.method && info.method();
      console.log("fail", e.detail, e);
      alert(`跳转异常 - ${e.detail}`)
    });
    // btn.launch();
  } else {
    alert("您的版本号不支持")
  }
}
// 判断是否微信环境
function is_weixn() {
  let ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  };
};

// 判断当前微信版本号是否支持
function is_version() {
  let client = false; // 当前版本号是否支持 (默认不支持)
  let wxInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i); // 微信浏览器信息
  // 微信版本号 wxInfo[1] = "7.0.18.1740" (示例)
  //进行split转成数组进行判断 [7,0,18,1740] (示例)
  console.log(wxInfo)
  let version = wxInfo[1].split(".");
  // 判断版本在7.0.12及以上的版本
  if (version[0] >= 7) {
    if (version[1] >= 0) {
      if (version[2] >= 12) {
        client = true; // 当前版本支持
      }
    }
  }
  return client;
}
