<template>
  <div class="funds_container">
    <div v-for="(item, index) in titles" :key="index" class="list" @click="handleClickItem(index)">
      <div class="content">
        <p class="content_title">{{ item }}</p>
        <img class="content_icon" src="../../../assets/right_arrow_icon.png" alt="" />
      </div>
      <van-divider />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "我的资金";
  },
  data() {
    return {
      titles: ["余额", "货款"],
      token: ""
    };
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
        });
      }
    });
  },
  methods: {
    handleClickItem(index) {
      // 0:余额  1:货款
      this.$router.push({
        path: `/addol/fundsDeatil`,
        query: {
          type: index,
          token: this.$route.query.token
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.funds_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;
  .list {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .content_title {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
      }
      .content_icon {
        height: 11px;
      }
    }
    /deep/ .van-divider {
      margin: 0;
    }
  }
}
</style>
