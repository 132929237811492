<template>
  <div class="reward_container">
    <div v-if="!isShowEmpty" class="list" @click="handleClickCurrentBankCard(bankCardInfo)">
      <img class="list_logo" :src="require(`../../../assets/BranCardLogo/${bankCardInfo.bankCode}.png`)" alt="" />
      <p class="list_name van-ellipsis">
        {{ bankCardInfo.bankName }}({{ bankCardInfo.cardNumber && bankCardInfo.cardNumber.slice(-4) }})
      </p>
      <div class="list_button">更换</div>
      <img v-if="false" class="list_arrow" src="../../../assets/right_arrow_black_icon.png" alt="" />
    </div>
    <van-empty v-if="isShowEmpty" description="暂无数据" />
  </div>
</template>

<script>
export default {
  created() {
    document.title = '奖励发放银行卡'
  },
  data() {
    return {
      token: '',
      isShowEmpty: false,
      bankCardInfo: {}
    }
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
        this.getBankCardListData();
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getBankCardListData();
        });
      }
    });
  },
  methods: {
    async getBankCardListData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/bankcard/queryTeamRewardBankCardByUserId",
        data: {}
      });
      if (data.success) {
        if (data.data && Object.keys(data.data).length > 0) {
          this.isShowEmpty = false
          this.bankCardInfo = { ...data.data }
        } else {
          this.isShowEmpty = true
        }
      } else {
        this.isShowEmpty = true
        this.$toast(data.message)
      }
    },
    handleClickCurrentBankCard(item) {
      // type：1 （绑定奖励银行卡）
      this.$router.push(`/addol/bankCardList?token=${this.token}&type=1`);
    }
  },
}
</script>

<style lang="scss" scoped>
.reward_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  /deep/ .van-empty {
    width: 100vw;
    height: 90vh;
  }

  .list {
    margin-top: 10px;
    width: 355px;
    height: 52px;
    background: #ffffff;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .list_logo {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 4px;
    }

    .list_name {
      flex: 1;
      width: 0;
      padding: 0;
      margin: 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
    }

    .list_button {
      width: 52px;
      height: 25px;
      /* border-radius: 13px; */
      border: 1px solid #404040;
      font-size: 13px;
      font-family: PingFangSC;
      color: #404040;
      line-height: 25px;
      text-align: center;
      margin-left: 10px;
      margin-right: 7px;
    }

    .list_arrow {
      height: 12px;
      margin-right: 10px;
    }
  }
}
</style>