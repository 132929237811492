export const downloadIamge = (imgsrc, name) => {
  let image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function() {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    let url = canvas.toDataURL("image/png");  //得到图片的base64编码数据
    let a = document.createElement("a");  //生成一个a元素
    let event = new MouseEvent("click");  //创建一个单击事件
    a.download = name || "海报";  //设置图片名称没有设置则为默认
    a.href = url;  //将生成的URL设置为a.href属性
    a.dispatchEvent(event);  //触发a的单击事件
  };
  image.src = imgsrc;
};