<template>
  <div class="m-crm">
    <!-- <button type="button" class="btn btn-primary btn-lg btn-block" @click="url">
      URL接入方式
    </button> -->
    <!-- <button
      type="button"
      class="btn btn-default btn-lg btn-block"
      @click="openSdk"
    >
      Open接入方式
    </button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSdkReady: false,
    };
  },
  mounted() {
    document.title = "在线客服";
    const { cardInfo, userInfo } = this.$route.query;
    console.log(cardInfo);
    ysf("onready", () => {
       this.isSdkReady = true;
       const user = JSON.parse(decodeURIComponent(userInfo));
			
		let params = {};
		// 用户等级
		if(user && user.qiYuVipLevel == 1) {
			params['level'] = 1;
		}
		ysf('config', {
        uid: user.uid || '',
        name: user.name || '',
        mobile: user.mobile || '',
		...params,
        success: () => {
          if (cardInfo) {
            const card = JSON.parse(decodeURIComponent(cardInfo));
            ysf("product", {
              ...card,
              // template: 'pictureLink'
            });
          } else {
    
          }
          window.location.replace(ysf("url"));
        },
        error: (error) => {
          this.$toast(`客服sdk加载失败，请重试! ${error}`);
        }
      })
    });
  },
  methods: {
    // url() {
    //   const { isSdkReady } = this;
    //   if (isSdkReady) {
    //     location.href = ysf("url");
    //   } else {
    //     alert("客服sdk尚未加载成功，请稍后再试");
    //   }
    // },
    // openSdk() {
    //   const { isSdkReady } = this;
    //   if (isSdkReady) {
    //     ysf("open");
    //   } else {
    //     alert("客服sdk尚未加载成功，请稍后再试");
    //   }
    // },
  },
};
</script>


<style scoped>
.m-crm {
  max-width: 400px;
  margin: 2rem auto 2rem;
}
</style>