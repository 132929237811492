<template>
  <div class="ck-wheel">
    <div class="time">
      <img src="@/assets/shijian.png" style="width: 13px; height: 13px; margin-right: 7px" />列表数据更新于{{
        lists.length > 0 && lists[0].gmtModified
        ? moment(lists[0].gmtModified).format("YYYY.MM.DD HH:mm:ss")
        : moment(Date.now()).format("YYYY.MM.DD HH:mm:ss")
      }}
    </div>

    <div class="card" v-for="(item, index) in lists" :key="index" @click="goDetail(item.id)">
      <div class="title-view">
        <div class="title">{{ moment(item.beginTime).format("YYYY年MM") }}月超享团商品销售业绩</div>
        <div class="detail">查看明细 ></div>
      </div>

      <div class="detail-view">
        <div class="detail-left">销售总件数</div>
        <div class="detail-right">{{ item.totalOrderPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">已退款件数</div>
        <div class="detail-right">{{ item.refundPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">未确认件数</div>
        <div class="detail-right">{{ item.orderPieceNum }}</div>
      </div>
      <div class="detail-view">
        <div class="detail-left">已确认件数</div>
        <div class="detail-right">{{ item.orderCompletePieceNum }}</div>
      </div>

      <div class="line"></div>

      <div class="detail-view">
        <div class="detail-left">预计佣金收益</div>
        <div class="detail-right">￥{{ formatPrice(item.predictCommissionAward) }}</div>
      </div>
      <div class="detail-view" v-if="item.predictLadderAward && item.predictLadderAward > 0">
        <div class="detail-left">预计阶梯奖励</div>
        <div class="detail-right">￥{{ formatPrice(item.predictLadderAward) }}</div>
      </div>
    </div>

    <div class="noData" style="margin-top:30px" v-if="lists.length === 0">暂无数据</div>
    <div class="noData" v-if="lists.length === 0">当日数据将在次日更新</div>
  </div>
</template>

<script>
import moment from "moment";
import { formatPrice } from "@/utils/money";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      lists: []
    };
  },
  created() {
    document.title = "超享团个人销售业绩";
  },
  mounted() {
    this.token = this.$route.query.token;

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getData();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getData();
        });
      }
    });
  },

  methods: {
    moment,
    formatPrice,
    async getData() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/dist/data/distGrouponPerformanceList",
        data: {}
      });
      if (data.success) {
        this.lists = data.data || [];
      } else {
        this.$toast(data.message);
      }
    },
    goDetail(id) {
      this.$router.push(
        `/superGruopBuy/gruopSelfRewardsDetail?token=${this.token}&id=${id || null}`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "mcFont";
  src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;

  .time {
    margin-top: 15px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }

  .card {
    margin-top: 15px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    width: 355px;
   //height: 220px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-view {
      width: 355px;
      height: 40px;
      background: #fff2e9;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 21px;
        margin-left: 16px;
      }

      .detail {
        margin-right: 16px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 18px;
      }
    }

    .line {
      margin-top: 8px;
      width: 325px;
      height: 0px;
      border-bottom: 1px solid #f5f5f5;
    }

    .detail-view {
      margin-top: 8px;
      width: 355px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .detail-left {
        margin-left: 16px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 18px;
      }

      .detail-right {
        margin-right: 16px;
        height: 16px;
        font-size: 13px;
        font-family: JDZhengHT-Regular, JDZhengHT;
        font-weight: 400;
        color: #130000;
        line-height: 16px;
      }
    }
  }

  .noData {
    width: 375px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
}</style>
