<template>
  <div class="wrap">
    <div class="Section1" style="layout-grid: 15.6pt">
      <p class="MsoNormal">
        <span style="font-family: 宋体; color: black">营业执照：</span
        ><span lang="EN-US" style="color: black"
          >91330521MA2JJMW99G<a name="_GoBack"></a
        ></span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体; color: black"
          >增值电信业务经营许可证：合字</span
        ><span lang="EN-US" style="font-size: 12pt; color: black"
          >B2-20210215</span
        ><span lang="EN-US" style="color: black"><br /> </span
        ><span style="font-family: 宋体; color: black">浙</span
        ><span lang="EN-US" style="color: black">ICP</span
        ><span style="font-family: 宋体; color: black">备</span
        ><span lang="EN-US" style="font-size: 12pt; color: black">19049778</span
        ><span style="font-family: 宋体; color: black">号</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体; color: black">联网备案号：</span
        ><span lang="EN-US" style="color: black"
          >浙公网安备33011002016293号<a name="_GoBack"></a
        ></span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color: black">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color: black">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color: black">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color: black">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span lang="EN-US" style="color: black"
          ><img
            class="img-1"
            :src="'https://oss-kiddo.manqu88.com/h5/license.jpg?'+Date.now()"
        /></span>
      </p>
      <p class="MsoNormal">
        <span lang="EN-US" style="color: black"
          ><img
            class="img-1"
            :src="'https://oss-kiddo.manqu88.com/h5/license_2.jpg?'+Date.now()"
        /></span>
      </p>
      <p class="MsoNormal">
        <span lang="EN-US" style="color: black"
          ><img
            class="img-1"
            :src="'https://oss-kiddo.manqu88.com/licence-img-1.png?'+Date.now()"
        /></span>
      </p>
      <p class="MsoNormal">
        <span lang="EN-US" style="color: black"
          ><img
            class="img-1"
            :src="'https://oss-kiddo.manqu88.com/h5/license_4.jpg?'+Date.now()"
        /></span>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "illustrate",
  components: {},

  data() {
    return {};
  },
  created() {
    // window.location.href = 'https://static.manqu88.com/kiddo-privacy.html'
    document.title = "证照信息";
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 0;
  // min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  text-align: left;
}
p {
  margin: 5px 15px;
}
.img-1{
  width: 100%;
}
.card {
  margin: 0px auto;
  width: 355px;
  height: auto;
  background: #ffffff;
}
.title {
  margin: 14px auto;
  margin-bottom: -9px;
  width: 326px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 21px;
}
.detail {
  margin: 14px auto;
  width: 326px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
</style>
