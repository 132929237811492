<template>
  <div class="back">
    <div class="title">注销后，您将放弃以下权益：</div>
    <div class="des">
      1.您的交易记录将被清空，请确保所有交易已完成且无纠纷，账号注销后因历史交易可能产生的退换货、维权相关的资金退回等权益将视作自动放弃
    </div>
    <div class="des">2.账号中的未结清资产等权益将视作自动放</div>
    <div class="des">3.账号中的积分、优惠券、金币将被全部清空且无法恢复</div>
    <div class="des">4.账号的会员身份(首席推荐官、推荐官、实习推荐官、会员)将自动放弃且无法恢复</div>
    <div class="des">5.账号注销后，您的账号在Kiddol平台、Addol平台均不能继续使用</div>

    <div class="title" style="margin-top:14px">请确认注销账号是否满足以下条件：</div>
    <div class="title" style="margin-top:6px">1.账号近期不存在交易</div>
    <div class="des">您的账号无未完成订单、无已完成未满15天的订单</div>
    <div class="title">2.账号不存在进行中的售后单</div>
    <div class="des">您的账号无未完成的售后单</div>
    <div class="title">3.账号不存在进行中的违规记录</div>
    <div class="des">您的账号，不存在进行中的违规记录，包括但不限于冻结账号、暂停提现等</div>
    <div class="title">4.账号相关财产权益已结清</div>
    <div class="des">
      您的Kiddol平台账号、Addol平台账号不存在未结清资产，包括余额（待结算余额、可提现余额）、货款（冻结货款、可提现货款）
    </div>
  </div>
</template>

<script>
import { formatList } from "@/utils/utils";
export default {
  name: "Help",
  components: {},
  created() {
    document.title = "注销账号";
  },
  data() {
    return {
      list: formatList(),
      finished: true
    };
  },
  methods: {
    goDetail(index1, index2) {
      this.$router.push("/helpDetail?index1=" + index1 + "&index2=" + index2);
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  width: 100vw;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  .title {
    width: 335px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #404040;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .des {
    width: 335px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 19px;
    margin-bottom: 8px;
  }
}
</style>
