<template>
  <div class="ck-wheel">

    <div class="progress">
      <div class="progress-inside" :style="{
        width: `${datas.finishNum >= 100 ? 100 : datas.finishNum}%`
      }"></div>
    </div>

    <div class="num-1">{{ datas.totalNum || 0 }}</div>
    <div class="num-2">{{ datas.finishNum || 0 }}</div>
    <div class="num-3">{{ formatPrice(datas.currentRewardAmount) }}</div>
    <div class="num-4">{{ (datas.nextRewardNeedNum) }}</div>

    <div class="white" v-if="datas.finishNum >= 100"></div>


    <div :class="['btn-1', curTab == 0 ? 'btn-choosed' : '']" @click="changeTab(0)">考核中</div>
    <div :class="['btn-2', curTab == 1 ? 'btn-choosed' : '']" @click="changeTab(1)">已完成</div>

    <div class="list-view">
      <div class="list">
        <div class="noList" v-if="dataList.length === 0"></div>
        <div class="item" v-for="(item, index) in dataList" :key="index">
          <div class="head" :style="{ backgroundImage: `url(${item.headPicUrl})` }"></div>
          <div class="name">{{ item.nickName }}</div>
          <div class="price">{{ formatPrice(item.teamFinishAmount) }}</div>
          <div class="num">{{ item.inviteForeverNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      id: this.$route.query.id || 9,
      token: this.$route.query.token,

      curTab: 0,

      isActive: false,
      datas: {},
      dataList: [

      ]

    };
  },
  created() {
    document.title = "店主招募计划";
  },
  mounted() {
    this.token = this.$route.query.token;
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;

        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }

          this.getDetail();
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,
    async getDetail() {

      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/dist/recruitPracticeKb/teamPracticeKbStatistics",
          data: {

          }
        });
        if (data.success) {
          this.datas = data.data;
          this.isActive = data.data.isActivate === 1;
          this.dataList = this.datas.runningList;
        } else {
          this.$toast(data.message);
        }
      }
    },

    itemDetail(itemId) {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${itemId}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: itemId
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },

    changeTab(index) {
      this.curTab = index;
      this.dataList = index === 0 ? this.datas.runningList : this.datas.finishList;
    },
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  height: 1969px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/zm_BG.png?6") no-repeat;
  background-size: 100% 100%;
  position: relative;

  font-size: 16px;
  font-family: Barlow-Medium, Barlow;
  font-weight: 500;
  color: #000000;

  .btn-go {
    width: 293px;
    height: 35px;
    position: absolute;
    left: 42px;
    top: 525px;
  }

  .isActive {
    width: 309px;
    height: 60px;
    position: absolute;
    left: 33px;
    top: 500px;
    background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/isActive.png?") no-repeat;
    background-size: 100% 100%;
  }

  .progress {
    position: absolute;
    left: 33px;
    top: 337px;
    width: 310px;
    height: 10px;
    background: #E6E6E6;
    border: 1px solid #9D9D9D;

    .progress-inside {
      width: auto;
      height: 10px;
      background: #131313;
    }
  }

  .num-1 {
    width: 18px;
    height: 19px;
    position: absolute;
    left: 137px;
    top: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-2 {
    width: 18px;
    height: 19px;
    position: absolute;
    right: 48px;
    top: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-3 {
    width: 18px;
    height: 19px;
    position: absolute;
    left: 137px;
    top: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num-4 {
    width: 18px;
    height: 19px;
    position: absolute;
    right: 47px;
    top: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .white {
    position: absolute;
    right: 30px;
    top: 423px;
    width: 167px;
    height: 22px;
    background: #FFFFFF;
  }

  .btn-1 {
    position: absolute;
    left: 6px;
    top: 960px;
    width: 180px;
    height: 30px;
    background: #E5E5E5;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .btn-2 {
    position: absolute;
    right: 6px;
    top: 960px;
    width: 180px;
    height: 30px;
    background: #E5E5E5;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-choosed {
    background: #EB7B7B;
    color: #FFFFFF;
  }


  .list-view {
    width: 364px;
    height: 368px;
    position: absolute;
    left: 6px;
    top: 1001px;
    background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/zm_list.png?1") no-repeat;
    background-size: 100% 100%;

    .noList {
      margin: auto;
      margin-top: 113px;
      width: 222px;
      height: 54px;
      background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/noList_1.png?") no-repeat;
      background-size: 100% 100%;
    }

    .list {
      margin: auto;
      margin-top: 50px;
      width: 338px;
      height: 310px;
      overflow: scroll;

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #E6E6E6;
        position: relative;

        .head {
          margin-left: 13px;
          width: 37px;
          height: 37px;
          background: #D8D8D8;
          border: 1px solid #F8DCB9;
          border-radius: 50%;
          background: url("https://oss-kiddo.manqu88.com/h5/cardActivity/zmlist.png?") no-repeat;
          background-size: 100% 100%;
        }

        .name {
          margin-left: 12px;
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        .price {
          position: absolute;
          right: 128px;
          width: 39px;
          height: 19px;
          font-size: 16px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #000000;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .num {
          position: absolute;
          right: 42px;
          width: 18px;
          height: 19px;
          font-size: 16px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

}
</style>
