<template>
  <div>
    <img src="@/assets/rule.png" alt="" style="width:100%">
  </div>
</template>
<script>
export default {
  mounted(){
    document.title = '直升KB会员礼包专区'
  }
}
</script>
