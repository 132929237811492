<template>
  <div class="ck-wheel">
    <div class="top" id="top">
      <div class="helpIcon" @click="goRules"></div>
      <div class="topTips"></div>
      <div class="goldTitle">可用金豆</div>
      <div class="goldView">
        <div class="goldIcon"></div>
        {{ goldData.useGoldNum }}
      </div>
    </div>

    <van-tabs color="#323CB0" style="width:100%" :sticky="true" @click="changeTab">
      <van-tab id="tab" v-for="(items, indexs) in tabList" :key="indexs" :title="items">
        <div class="list" :style="{
          maxHeight: listHeight + 'px'
        }" @scroll="onScroll">
          <div class="noList" v-if="noList && list.length === 0"></div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="left">
              <div class="des">{{ item.recordDescribe }}</div>
              <div class="time">{{ moment(item.gmtModified).format("YYYY-MM-DD HH:mm:ss") }}</div>
            </div>
            <div :class="['add', item.score < 0 ? 'del' : '']">
              {{ item.score >= 0 ? '+' : '' }}{{ item.score }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "assist",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      tabList: ["全部", "收入", "支出"],
      currentPage: 1,
      pageSize: 20,
      canGetList: true,
      curTab: 0,
      loading: false,
      finished: false,
      popData: {},
      noList: false,
      list: [],
      showShadow: true,
      modalStep: 1,
      listHeight: 0,
      goldData: {
        userId: '',
        useTakeGoldNum: '',
        useGoldNum: '',
      },
      pageSize: 50,
      currentPage: 1,
    };
  },
  created() {
    document.title = "我的金豆";
  },
  destroyed() { },
  mounted() {
    console.log(
      "121212",
      document.getElementById("top").clientHeight,
      "121212",
      document.getElementById("tab").clientHeight,
      "121212",
      screen.height,
      document.body.clientHeight
    );
    this.listHeight = document.body.clientHeight - document.getElementById("top").clientHeight - 56 + 44;
    setTimeout(() => {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
          this.token = this.$route.query.token;
          if (!this.token || this.token == "") {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
            return;
          }
          this.getGoldData();
        } else {
          console.log("当前环境：" + JSON.stringify(res));
          this.$bridge.call("getToken", {}).then(e => {
            console.log("getToken客户端返回: ", e);
            this.token = e;
            if (!e || JSON.stringify(e) == "{}") {
              this.$bridge.call("openPage", { type: 26 });
              return;
            }
            this.getGoldData();
          });
        }
        //
      });
    }, 0);
  },

  methods: {
    formatPrice,
    moment,
    parseGold(gold) {
      let ret = Number(formatPrice(gold, true, false)).toFixed(2) + "";
      let arr = ret.split(".");
      console.log(ret, arr);
      if (arr[1] === "00") {
        return arr[0];
      } else {
        return ret;
      }
    },
    async getGoldData() {
      if (this.token) {
        const { data } = await this.$axios({
          method: "post",
          headers: {
            mac: "mac",
            clientSource: this.$clientSource(),
            Authorization: this.token
          },
          url: "/api/userGold/queryUserGoldData",
          data: {
          }
        });
        if (data.success) {
          this.goldData = data.data;
        } else {
          this.$toast(data.message);
        }
        this.getData();
      }
    },
    async onRefuse() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/user/info/refuseConvert",
        data: {}
      });
      if (data.success) {
        this.popData.isPopup = false;
      } else {
        this.$toast(data.message);
      }
    },

    async getData() {
      const curPage = this.currentPage;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/temp/score/queryDetailList",
        data: {
          type: 52,
          changeType: this.curTab == 0 ? null : this.curTab,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      });
      if (data.success) {
        console.log("111", JSON.stringify(data.data));
        if (JSON.stringify(data.data)) {
          if (curPage === 1) {
            this.list = [];
          }
          // this.list = data.data;
          this.list = [...this.list, ...data.data];
          if (data.data.length < this.pageSize) {
            this.canGetList = false;
          }
        }
      } else {
        this.$toast(data.message);
      }
    },
    goRules() {
      this.$router.push(`/treasure/rule?type=${3}`);
    },

    changeTab(e) {
      console.log(e);
      if (e === this.curTab) {
        return;
      }
      this.curTab = e;
      this.list = [];
      this.currentPage = 1;
      this.noList = false;
      this.canGetList = true;
      this.getData();
    },
    onScroll(e) {
      console.log(
        e,
        e.target.scrollHeight,
        e.target.scrollTop,
        e.target.clientHeight,
        e.target.scrollTop + e.target.clientHeight
      );
      if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
        //触发加载
        if (this.canGetList) {
          this.currentPage++;
          this.getData();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .top {
    position: relative;
    width: 375px;
    height: 188px;
    margin-bottom: -44px;
    background: url(https://oss-kiddo.manqu88.com/h5/duobao/goldBeanBg.png?1) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .helpIcon {
      position: absolute;
      right: 12px;
      top: 14px;
      width: 21px;
      height: 21px;
      background: url(https://oss-kiddo.manqu88.com/h5/gold/helpIcon.png?124) no-repeat;
      background-size: 100% 100%;
    }

    .topTips {
      position: absolute;
      right: 41px;
      top: 17px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }

    .goldTitle {
      margin-top: 31px;
      height: 22px;
      width: 327px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 22px;
    }

    .goldView {
      margin-top: 12px;
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      height: 42px;
      font-size: 35px;
      font-family: JDZhengHT-Regular, JDZhengHT;
      font-weight: 400;
      color: #000000;
      line-height: 42px;

      .goldIcon {
        margin-left: 23px;
        width: 28px;
        height: 26px;
        background: url(https://oss-kiddo.manqu88.com/h5/duobao/goldBean.png?123) no-repeat;
        background-size: 100% 100%;
      }
    }

    .goldTips {
      position: relative;
      width: 124px;
      height: 35px;
      background: url(https://oss-kiddo.manqu88.com/h5/gold/goldTips.png?123) no-repeat;
      background-size: 100% 100%;

      .tips {
        width: 100%;
        height: 35px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3px;
      }
    }

    .warn {
      display: flex;
      margin-top: 12px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }

    .goldColor {
      color: #f9570c;
    }
  }

  .list {
    padding-bottom: 10px;
    width: 100vw;
    max-height: 539px;
    overflow: hidden;
    overflow-y: scroll;

    .noList {
      margin: auto;
      margin-top: 129px;
      width: 100px;
      height: 133px;
      background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png) no-repeat;
      background-size: 100% 100%;
    }

    .item {
      margin-top: 20px;
      width: 100%;
      display: flex;
      position: relative;

      .left {
        display: flex;
        flex-direction: column;
        width: 247px;
        margin-left: 23px;

        .des {
          width: 247px;
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          line-height: 18px;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 2;
        }

        .time {
          margin-top: 5px;
          width: 247px;
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }
    }

    .add {
      position: absolute;
      text-align: right;
      top: 15px;
      right: 23px;
      height: 22px;
      font-size: 18px;
      font-family: Barlow-SemiBold, Barlow;
      font-weight: 600;
      color: #FF5A27;
      line-height: 22px;
    }

    .del {
      color: #404040;
    }
  }

  .shadow {
    z-index: 10000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal {
      position: absolute;
      // top: 168px;
      width: 292px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .close {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 34px;
        height: 34px;
        background: url(https://oss-kiddo.manqu88.com/h5/gold/closeBtn.png?123) no-repeat;
        background-size: 100% 100%;
      }

      .title {
        margin-top: 38px;
        margin-bottom: 13px;
        height: 24px;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }

      .str {
        margin-bottom: 13px;
        width: 240px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #848484;
        line-height: 28px;

        .goldStr {
          color: #f6550a;
        }
      }

      .btnView {
        margin: 23px 0 33px 0;
        width: 100%;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        .cancel {
          margin: 0 10px 0 10px;
          width: 122px;
          height: 40px;
          border-radius: 23px;
          border: 1px solid #bebebe;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #404040;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .confirm {
          margin: 0 10px 0 10px;
          width: 122px;
          height: 40px;
          background: linear-gradient(270deg, #fc7223 0%, #f9550a 100%);
          border-radius: 23px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
