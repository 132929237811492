import { render, staticRenderFns } from "./topicRankRules.vue?vue&type=template&id=749fa440&scoped=true&"
import script from "./topicRankRules.vue?vue&type=script&lang=js&"
export * from "./topicRankRules.vue?vue&type=script&lang=js&"
import style0 from "./topicRankRules.vue?vue&type=style&index=0&id=749fa440&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749fa440",
  null
  
)

export default component.exports