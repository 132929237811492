<template>
  <div class="main">
    <div class="topBg"></div>
    <div v-if="!showPoster" style="position:relative;z-index:0;">


      <div class="user-view">
        <div class="user-head" :style="{
          backgroundImage: `url(${userInfo.headPicUrl})`
        }"></div>

        <div class="user-msg">
          <div class="user-name">{{ userInfo.nickName }}</div>
          <div class="user-active" v-if="userInfo.gradeCode < 3">活跃值：{{ upGradeInfo.teamActiveValue }}</div>
        </div>

        <div class="user-inviteCode">
          邀请码：{{ userInfo.invitationCode }}
          <div class="copy" @click="copy(userInfo.invitationCode)">复制</div>
        </div>

      </div>

      <div class="tusted_box">
        <div class="swiper-container tust_swp">
          <div class="swiper-wrapper">
            <div class="swiper-slide wid">
              <div class="trus_img wid">
                <img src="https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_1.png?111" alt="" class="wid" />
                <div class="check-upGrade" v-if="userInfo.gradeCode < 2" @click="jumpZX(3006)"></div>
                <div class="get" v-else></div>
                <div class="curLevel" v-if="userInfo.gradeCode < 2"></div>
                <div class="des" v-if="userInfo.gradeCode < 2">
                  <div>我的直邀用户：{{ upGradeInfo.memberNum }}</div>
                  <div>距下个等级还差{{ 100 - upGradeInfo.memberNum }}用户</div>
                </div>
              </div>
            </div>
            <div class="swiper-slide wid" v-if="userInfo.gradeCode === 2 && isJoin">
              <div class="trus_img wid">
                <img src="https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_2.png?111" alt="" class="wid" />
                <div class="check-upGrade" @click="jumpZX(3006)"
                  :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/check-upGrade-1.png)` }"
                  v-if="userInfo.gradeCode < 3"></div>
                <div class="get"
                  :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/get-1.png)` }" v-else>
                </div>
                <div class="curLevel" v-if="userInfo.gradeCode == 2"></div>
                <div class="des" v-if="userInfo.gradeCode === 2">
                  <div>考核到期时间：{{ moment(practiceKbStatistics.deadlineTime).format('YYYY.MM.DD HH:mm:ss') }}</div>
                </div>
              </div>
            </div>
            <div class="swiper-slide wid" v-else>
              <div class="trus_img wid">
                <img src="https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_3.png?111" alt="" class="wid" />
                <div class="check-upGrade" @click="jumpZX(3006)"
                  :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/check-upGrade-1.png)` }"
                  v-if="userInfo.gradeCode < 3"></div>
                <div class="get"
                  :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/get-1.png)` }" v-else>
                </div>
                <div class="curLevel" v-if="userInfo.gradeCode == 2"></div>
                <div class="des" v-if="userInfo.gradeCode < 2">
                  <div>直邀用户≥100可达该等级</div>
                </div>
                <div class="des" v-if="userInfo.gradeCode === 2">
                  <div>距下等级还差：</div>
                  <div>{{ 100000 - upGradeInfo.teamActiveValue }}活跃值</div>
                  <div>{{ `${upGradeInfo.memberNum <= 400 ? 400 - upGradeInfo.memberNum : 0}直邀用户` }}</div>
                      <div>即可升级首席，享最高权益</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide wid">
                <div class="trus_img wid">
                  <img src="https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_4.png?111" alt="" class="wid" />
                  <div class="check-upGrade" @click="jumpZX(3006)"
                    :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/check-upGrade-2.png)` }"
                    v-if="userInfo.gradeCode < 3"></div>
                  <div class="get"
                    :style="{ backgroundImage: `url(https://oss-kiddo.manqu88.com/h5/memberCenter4/get-2.png)` }" v-else>
                  </div>
                  <div class="curLevel" v-if="userInfo.gradeCode == 3"></div>
                  <div class="des" v-if="userInfo.gradeCode < 3">
                    <div>活跃值≥100000 且</div>
                    <div>直邀用户≥400 可达该等级</div>
                  </div>
                  <div class="des" v-if="userInfo.gradeCode === 3">
                    <div>你已达到最高等级</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 会员权益 -->
        <div class="title" style="color: #FFFFFF;">会员权益
          <!-- <div class="right-title">
          权益说明
          <div class="arrow-2"></div>
        </div> -->
        </div>
        <div :class="['qy-' + (activeIndex + 1)]"></div>



        <!-- 升级攻略 -->
        <div v-if="userInfo.gradeCode < 3">
          <div class="title">升级攻略</div>
          <div class="card">
            <div class="title-view" v-if="activeIndex > 0 && !(userInfo.gradeCode < 2 && activeIndex === 1)">
              <div class="left-title">
                <div class="main-title">
                  获取活跃值
                </div>
                <div class="sub-title">
                  购买/推广平台商品
                </div>
              </div>
              <div class="btn" @click="goHomePage">
                去购买
              </div>
            </div>

            <div class="title-view">
              <div class="left-title">
                <div class="main-title">
                  邀请用户注册
                </div>
                <div class="sub-title">
                  邀请用户注册满足升级条件
                </div>
              </div>
              <div class="btn" @click="goInvite">
                去邀请
              </div>
            </div>
          </div>
        </div>

        <!-- 考核进度 -->
        <div v-if="userInfo.gradeCode === 2 && isJoin && activeIndex === 1">
          <div class="title">考核进度
            <div class="title-sub">（条件一或二任意满足一个）</div>
            <div class="right-title" style="color: #404040;" @click="jumpZX(3007)">规则说明
              <div class="arrow-1"></div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 5px;">
            <div class="title-view">
              <div>条件一：</div>
            </div>

            <div class="subTitle">已确认销售业绩达到{{ formatPrice(practiceKbStatistics.teamFinishAmount) }}（已销售业绩：{{
              formatPrice(practiceKbStatistics.teamSaleAmount) }}元）</div>
            <div class="progress-view">
              <div class="progress-bg">
                <div class="progress"
                  :style="{ width: (100 * practiceKbStatistics.teamFinishAmount / practiceKbStatistics.needTeamFinishAmount) + '%' }">
                </div>
              </div>
              <div class="progress-detail">{{ formatPrice(practiceKbStatistics.teamFinishAmount) }}/{{
                formatPrice(practiceKbStatistics.needTeamFinishAmount) }}</div>
            </div>
            <div class="subTitle">数据每日0点~2点更新</div>

          </div>

          <div class="card" style="margin-bottom: 5px;">
            <div class="title-view">
              <div>条件二：</div>
            </div>

            <div class="subTitle">邀请50名会员</div>
            <div class="progress-view">
              <div class="progress-bg">
                <div class="progress"
                  :style="{ width: (100 * practiceKbStatistics.inviteForeverNum / practiceKbStatistics.needInviteForeverNum) + '%' }">
                </div>
              </div>
              <div class="progress-detail">{{ practiceKbStatistics.inviteForeverNum
              }}/{{ practiceKbStatistics.needInviteForeverNum }}</div>
            </div>
          </div>
        </div>

        <!-- 赚积分/佣金 -->
        <div class="title">{{ userInfo.gradeCode >= 2 ? '赚积分/佣金' : '赚积分' }}
          <div class="title-tip" @click="showPop = true"></div>
        </div>
        <div class="tab-view" v-if="userInfo.gradeCode >= 2">
          <div :class="['tab', curTab === 0 ? 'tab-choosed' : '']" @click="onChangeTab(0)">赚积分</div>
          <div :class="['tab', curTab === 1 ? 'tab-choosed' : '']" @click="onChangeTab(1)">
            赚佣金
          </div>
        </div>
        <!-- 赚积分 -->
        <div class="card" v-if="curTab === 0">
          <div class="title-view" v-for="(item, index) in taskList " :key="index">
            <div class="left-title">
              <div class="main-title">
                <div class="num-title"
                  :style="{ backgroundImage: `url('https://oss-kiddo.manqu88.com/h5/memberCenter4/num_${index + 1}.png')` }">
                </div>
                {{ item.taskTemplateDTO.name }}
              </div>
              <div class="sub-title" style="margin-left:18px;">
                {{ item.taskTemplateDTO.describe + `(${item.taskRuleFinished}/${item.taskRuleNeed})` }}
              </div>
            </div>
            <div :class="['btn', item.taskCompleteStatus === 1 ? 'btn-disabled' : '']"
              @click="taskJump(item.taskCompleteStatus === 1, item.jumpType)">
              {{ item.taskCompleteStatus === 1 ? '已完成' : '去完成' }}
            </div>
          </div>
          <div class="bottom-des">更多积分任务敬请期待</div>
        </div>

        <!-- 赚佣金 -->
        <div class="card" v-if="curTab === 1">
          <div class="card-title">
            <div class="card-left-title">
              <div class="card-main-title">高佣专区</div>
            </div>
          </div>

          <div class="item-view-column" v-for="(item, index) in commissionItems" :key="index"
            @click="itemDetail(item.id)">
            <div class="item-column">
              <div class="item-column-img" :style="{ backgroundImage: `url(${item.resourceDTOS[0].path})` }"></div>
              <div class="item-column-msg">
                <div class="item-column-msg-title">{{ item.itemName }}</div>
                <div class="item-column-msg-bottom">
                  <div class="item-left">
                    <div class="item-left-unit">¥</div>
                    <div class="item-left-price">{{ formatPrice(item.retailPrice, true, false) }}</div>
                  </div>
                  <div class="item-right" @click.stop="onShare(item.id)">
                    <div class="item-right-bill">
                      <!-- <div class="bill">赚{{ formatPrice(item.maxCommission, true, false) }}</div> -->
                    </div>
                    <div class="item-right-share">去分享</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-des" @click="jumpZX(3037)">查看更多商品 ></div>
        </div>



        <!-- 积分商城 -->
        <div class="title">积分商城</div>
        <div class="card">
          <div class="card-title">
            <div class="card-left-title">
              <div class="card-main-title">积分换礼</div>
              <div class="card-sub-title">积分兑换低至0元</div>
            </div>
            <div class="card-right-title" @click="jumpZX(3036)">查看更多
              <div class="arrow-1"></div>
            </div>
          </div>

          <div class="item-view">
            <div class="item-card" v-for="(item, index) in creditItems" :key="index" @click="itemDetail(item.id)">
              <div class="item-img" :style="{ backgroundImage: `url(${item.resourceDTOS[0].path})` }"></div>
              <div class="item-name">{{ item.itemName }}</div>
              <div class="item-price">
                {{ `${item.minIntegral}积分` }}{{ item.minIntegralPrice ? `+${formatPrice(item.minIntegralPrice,
                  true, false)}元` : '' }}</div>
              <div class="item-buy">立即兑换</div>
            </div>
          </div>
        </div>



      </div>
      <div v-else class="poster-member"></div>

      <div class="shadow" v-if="showPop">
        <div class="pop">
          <div class="rule"></div>
          <div class="close" @click="showPop = false"></div>
        </div>
      </div>
    </div>
</template>
<script>
import { Toast } from "vant";
import Swiper from "swiper";
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  data() {
    return {
      token: this.$route.query.token,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination"
        }
        // Some Swiper option/callback...
      },
      userInfo: {},
      activeIndex: 0,
      swiper: {},
      intermediateGradeAmount: 0,

      sub: 0,
      myprogress: 0,

      forEverKB: 0,
      forEverKC: 0,
      forEverDistUser: 0,
      teamAmount: null,

      type: this.$route.query.type,
      gmtModified: 0,
      qualified: 1,
      proportion: 0,
      forEverKBWidth: 0,
      teamAmountWidth: 0,
      showBtn: false,
      isJoin: false,
      showPoster: false,
      canClick: false,

      practiceKbStatistics: {},
      upGradeInfo: {},
      creditItems: [],

      // 积分佣金
      curTab: 0,

      // 高佣商品ids
      commissionItemIds: [3322260, 3240780, 3240450, 3960108, 3840253, 3881130, 3440186, 3420970, 3941470, 3922640, 3982960],
      commissionItems: [],
      // 积分任务
      taskList: [],

      // 积分规则
      showPop: false,

      timeId: '',
    };
  },
  created() {
    document.title = "会员中心";
    console.log(this.isBanner);
    // this.showPoster = false;
  },
  mounted() {
    this.swiper = new Swiper(".tust_swp", {
      slidesPerView: "auto",
      centeredSlides: true,
      pagination: {
        el: ".tusted_box .swiper-pagination",
        clickable: true
      },
      on: {
        slideChangeTransitionEnd: e => {
          console.log("end", this.swiper); //切换结束时，告诉我现在是第几个slide
          this.activeIndex = this.swiper.activeIndex;
          this.showBtn = false;
          if (this.userInfo.gradeCode < 2) {
            this.showBtn = true;
          } else if (this.userInfo.gradeCode === 2 && this.activeIndex === 2) {
            this.showBtn = true;
          }
        }
      }
    });
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getDatas();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDatas();
        });
      }
    });
  },
  destroyed() {
    clearInterval(this.timeId);
  },
  methods: {
    moment,
    formatPrice,
    // checkTime
    checkTime() {
      const limitTime = new Date("2022/09/19 10:00:00").getTime();
      if (Date.now() >= limitTime) {
        return true;
      } else {
        return false;
      }
    },

    //获取数据
    async getDatas() {
      this.getUserInfo();
      this.nextLevel();
      this.getItemDatas();


      this.getTask();
      this.timeId = setInterval(() => {
        this.getTask();
      }, 1000 * 10)
    },
    //基本信息
    async getUserInfo() {
      if (true) {
        const { data } = await this.$axios({
          method: "post",
          headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
          url: "/api/dist/recruitPracticeKb/getPracticeKbStatistics",
          data: {}
        });
        if (data.success) {
          this.practiceKbStatistics = data.data;
          this.isJoin = data.data.isJoin === 1 && data.data.status === 1;
        }
        console.log("isJoin", this.isJoin);
      }

      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/dist/user/getDistUserInfo",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.userInfo = data.data;
        // this.userInfo.gradeCode = 2;
        let num = 0;
        if (this.userInfo.gradeCode == 2) {
          num = 1;
        }
        if (this.userInfo.gradeCode == 3) {
          num = 2;
        }
        if (this.type == 1) {
          this.swiper.slideTo(1);
        } else {
          this.swiper.slideTo(num);
        }
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },

    // 获取积分任务
    async getTask() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/taskTemplate/queryList",
        data: {
          status: 1,
          type: 2,
          pageSize: 10000,
          currentPage: 1
        }
      });

      if (data.code === 200) {
        this.taskList = data.data;
      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    //查看邀请消费数据
    async nextLevel() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token },
        url: "/api/member/queryNewUpgradeProgress",
        data: {}
      });
      console.log(data);
      if (data.code === 200) {
        this.upGradeInfo = data.data;
        this.upGradeInfo.teamActiveValue = formatPrice(this.upGradeInfo.teamActiveValue);


      }
      if (data.code !== 200) {
        Toast(data.message);
      }
    },
    // 积分商品
    async getItemDatas() {
      const { data } = await this.$axios({
        method: "post",
        headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token, version: 'mini_1.2.4', },
        url: "/api/item/queryItemPage",
        data: {
          pageSize: 3,
          currentPage: 1,
          itemType: 5,//积分商品
          condition: {
            needResources: true
          }
        }
      });
      console.log(data);
      if (data.code === 200) {
        this.creditItems = data.data;
      }
      if (data.code !== 200) {
        Toast(data.message);
      }

      if (true) {//高佣商品
        const { data } = await this.$axios({
          method: "post",
          headers: { mac: "mac", clientSource: this.$clientSource(), Authorization: this.token, version: 'mini_1.2.4', },
          url: "/api/item/queryItemPage",
          data: {
            pageSize: 100,
            currentPage: 1,
            itemIdList: this.commissionItemIds,
            condition: {
              needResources: true
            }
          }
        });
        console.log(data);
        if (data.code === 200) {
          this.commissionItems = data.data;
        }
        if (data.code !== 200) {
          Toast(data.message);
        }
      }
    },




    // 跳转应用主页
    goHomePage() {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    },





    jumpZX(id) {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pages/index/sub-page?id=${id}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 0,
              param: id
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },

    goInvite() {
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          uni.webView.reLaunch({
            url: `/pages/mine/index?onShare=1`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 47,
              param: ''
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },

    itemDetail(itemId) {
      uni.webView.getEnv(res => {
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${itemId}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              type: 4,
              param: itemId
            })
            .then(e => {
              console.log("jumpToDetail客户端返回: ", e);
            });
        }
      });
    },

    onShare(itemId) {
      uni.webView.getEnv(res => {
        console.log("当前环境onShare：" + JSON.stringify(res));
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pagesA/detail/index?id=${itemId}&onShare=1`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 43,
              param: itemId
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },

    taskJump(disabled, jumpType) {
      if (disabled) {
        return;
      }
      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res));
        if (res.miniprogram) {
          const typeEnum = {
            'KIDDOL_CIRCLE': "/pages/kiddol/index",
            'HOMEPAGE': "/pages/index/index"
          }
          uni.webView.switchTab({
            url: typeEnum[jumpType]
          });
        } else {
          const typeEnum = {
            'KIDDOL_CIRCLE': 2,
            'HOMEPAGE': 0
          }
          this.$bridge
            .call("openPage", {
              //跳转主页
              type: 23,
              param: typeEnum[jumpType]
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },


    copy(value) {
      let input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', value);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.$toast('复制成功');
    },

    // 佣金
    onChangeTab(index) {
      this.curTab = index;
    },
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-color: #ffffff;
  // padding-top: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  .poster-member {
    width: 375px;
    height: 780px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter/memberPoster.png?111") no-repeat;
    background-size: 100% 100%;
  }

  .topBg {
    width: 375px;
    height: 528px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/topBg.png?") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .user-view {
    margin-top: 65px;
    margin-bottom: 23px;
    position: relative;
    width: 375px;
    height: 45px;
    display: flex;
    z-index: 1;

    .user-head {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      margin-left: 20px;
      margin-right: 8px;
      background-size: 100% 100%;
    }

    .user-msg {
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .user-name {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .user-active {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        margin-top: 2px;
      }
    }

    .user-inviteCode {
      position: absolute;
      height: 14px;
      bottom: 4px;
      right: 19px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;

      .copy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 17px;
        border-radius: 9px;
        border: 1px solid #FFFFFF;
        margin-left: 4px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .title {
    position: relative;
    margin-left: 15px;
    width: 360px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    display: flex;
    align-items: center;

    .title-sub {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;

    }

    .title-tip {
      margin-left: 4px;
      width: 18px;
      height: 18px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/tip.png?") no-repeat;
      background-size: 100% 100%;
    }

    .right-title {
      position: absolute;
      right: 18px;
      bottom: 0;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17px;
      display: flex;
      align-items: center;

      .arrow-1 {
        margin-left: 5px;
        margin-bottom: 1px;
        width: 7px;
        height: 11px;
        background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/arrow_1.png?111") no-repeat;
        background-size: 100% 100%;
      }

      .arrow-2 {
        margin-left: 5px;
        margin-bottom: 1px;
        width: 7px;
        height: 11px;
        background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/arrow_2.png?") no-repeat;
        background-size: 100% 100%;
      }

    }
  }

  .tab-view {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 375px;
    height: 32px;

    .tab {
      margin-left: 5px;
      margin-right: 5px;
      width: 168px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #EDEDED;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
    }

    .tab-choosed {
      background: linear-gradient(270deg, #FFE1B2 0%, #FFF1D9 100%);
      color: #131313;

    }
  }

  .btn-progress {
    margin: auto;
    margin-top: 18px;
    margin-bottom: -5px;
    width: 240px;
    height: 36px;
    background: linear-gradient(309deg, #ffcb75 0%, #ffe160 100%);
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #333333;
    line-height: 18px;
  }




  .qy-1 {
    margin: auto;
    margin-top: 23px;
    margin-bottom: 48px;
    width: 312px;
    height: 105px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/qy-0.png?111") no-repeat;
    background-size: 100% 100%;
  }

  .qy-2 {
    margin: auto;
    margin-top: 23px;
    margin-bottom: 48px;
    width: 312px;
    height: 105px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/qy-1.png?12") no-repeat;
    background-size: 100% 100%;
  }

  .qy-3 {
    margin: auto;
    margin-top: 23px;
    margin-bottom: 48px;
    width: 312px;
    height: 105px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/qy-2.png?1122") no-repeat;
    background-size: 100% 100%;
  }



  .card {
    width: 345px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

    .title-view {
      width: 315px;
      height: 26px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      display: flex;
      align-items: center;
      justify-content: space-between;


      .left-title {
        height: 26px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .main-title {
          height: 17px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #232323;
          line-height: 20px;
          display: flex;
          align-items: center;

          .num-title {
            margin-right: 4px;
            width: 14px;
            height: 14px;
            background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/num_1.png?") no-repeat;
            background-size: 100% 100%;
          }
        }

        .sub-title {
          height: 12px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
        }
      }

      .btn {
        width: 80px;
        height: 26px;
        background: linear-gradient(90deg, #FFF1D9 0%, #FFE1B2 100%);
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #131313;
        line-height: 17px;
        margin-top: 6px;
      }

      .btn-disabled {
        background: #F0F0F0;
        color: #131313;
      }


    }

    .bottom-des {
      margin-top: 13px;
      padding-top: 4px;
      width: 315px;
      height: 34px;
      border-top: 1px solid #F4F4F4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #131313;
    }


    //积分商城
    .card-title {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-left-title {
        height: 20px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        .card-main-title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .card-sub-title {
          margin-left: 6px;
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .card-right-title {
        margin-right: 15px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;

        .arrow-1 {
          margin-left: 5px;
          margin-top: 1px;
          width: 7px;
          height: 11px;
          background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/arrow_1.png?") no-repeat;
          background-size: 100% 100%;
        }
      }


    }

    //高佣专区
    .item-view-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      width: 345px;


      .item-column {
        width: 345px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-column-img {
          width: 84px;
          height: 84px;
          //background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_1.png?") no-repeat;
          background-size: 100% 100%;
          margin-left: 16px;
          margin-right: 10px;
        }

        .item-column-msg {
          width: 220px;
          height: 84px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 16px;

          .item-column-msg-title {
            width: 220px;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #404040;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 2;
          }

          .item-column-msg-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 25px;

            .item-left {
              display: flex;
              align-items: center;
              font-family: Barlow-Bold, Barlow;
              font-weight: bold;
              color: #000000;
              height: 25px;

              .item-left-unit {
                height: 25px;
                font-size: 12px;
                line-height: 24px;
              }

              .item-left-price {
                height: 25px;
                font-size: 17px;
              }
            }

            .item-right {
              position: relative;
              height: 25px;

              .item-right-bill {
                position: absolute;
                right: 28px;
                bottom: 0;
                min-width: 88px;
                width: auto;
                height: 25px;
                border-radius: 13px;
                //border: 1px solid #EEEEEE;




                .bill {
                  position: absolute;
                  left: 7px;
                  min-width: 52px;
                  width: auto;
                  height: 25px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #131313;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                }

              }

              .item-right-share {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 56px;
                height: 27px;
                background: linear-gradient(270deg, #FFE1B2 0%, #FFF1D9 100%);
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #131313;
              }
            }
          }
        }
      }

    }


    //积分商城
    .item-view {
      margin-top: 14px;
      margin-bottom: 4px;
      width: 325px;
      height: 180px;
      display: flex;
      justify-content: space-around;


      .item-card {
        width: 104px;
        height: 180px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F1F1F1;
        display: flex;
        flex-direction: column;
        align-items: center;


        .item-img {
          margin-top: 4px;
          width: 96px;
          height: 96px;
          //background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/levelBg_1.png?") no-repeat;
          background-size: 100% 100%;
        }

        .item-name {
          margin-top: 2px;
          width: 96px;
          height: 32px;
          font-size: 11px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
          -webkit-box-orient: vertical;
          /* 显示的行 */
          -webkit-line-clamp: 2;
        }

        .item-price {
          width: 96px;
          height: 18px;
          font-size: 12px;
          font-family: Barlow-Bold, Barlow;
          font-weight: bold;
          color: #F9550A;
          line-height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .item-buy {
          margin-top: 3px;
          width: 96px;
          height: 19px;
          background: linear-gradient(270deg, #FC7223 0%, #F9550A 100%);
          border-radius: 4px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }




    .subTitle {
      margin-top: 0px;
      margin-bottom: 6px;
      width: 315px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .progress-view {
      margin-bottom: 4px;
      height: 20px;
      width: 315px;
      display: flex;
      align-items: center;

      .progress-bg {
        position: relative;
        width: 232px;
        height: 14px;
        background: #f1f1f1;
        border-radius: 12px;

        .progress {
          position: absolute;
          left: 0;
          width: 50%;
          height: 14px;
          background: #FFE4B8;
          border-radius: 12px;
        }
      }

      .progress-detail {
        margin-left: 8px;
        position: relative;
        height: 16px;
        font-size: 13px;
        font-family: JDZhengHT-Regular, JDZhengHT;
        font-weight: 400;
        color: #404040;
        line-height: 16px;

        .subDetail {
          word-break: keep-all;
          position: absolute;
          left: 0;
          bottom: -18px;
          height: 13px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a5a5a5;
          line-height: 13px;
        }
      }
    }
  }
}

.tusted_box {
  margin-bottom: 25px;

  .swiper-slide {
    position: relative;
    font-size: 18px;
    // background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    // box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;

    .levelname {
      position: absolute;
      text-align: left;
      left: 26px;
      top: 20px;

      .tit {
        color: #404040;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 22px;
        position: relative;
        top: -25px;
      }

      .name {
        color: #747474;
        font-family: ArialMT;
        font-size: 65px;
        position: relative;

        img {
          width: 82px;
          // height: 47px;
        }
      }

      .op {
        opacity: 0.2;
      }
    }
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
  }

  .trus_img {
    position: relative;

    img {
      // height: 150px;
      border-radius: 4px;
      box-shadow: 0px 0px 12px 0px rgba(221, 221, 221, 0.5);
    }
  }

  .swiper-slide-prev {
    right: -8px;
  }

  .swiper-slide-next {
    left: -8px;
  }

  .swiper-pagination {
    text-align: center;
    width: 100%;
    bottom: -50px;
  }

  .tusted_box {
    position: relative;
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 1px solid #4a9a83;
    border-radius: 50%;
    background: transparent;
    margin: 0 15px;
    vertical-align: middle;
    opacity: 1;
    outline: none;
  }

  .swiper-pagination-bullet-active {
    background: #4a9a83;
    width: 20px;
    height: 20px;
  }
}

.wid {
  // width: 270px;
  // height: 153px;
  width: 328px;
  height: 184px;

  //margin-left: 7px;
  //margin-right: 7px;
  .curLevel {
    position: absolute;
    left: 0;
    top: 0;
    width: 74px;
    height: 17px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/curLevel.png?111") no-repeat;
    background-size: 100% 100%;
  }

  .des {
    position: absolute;
    left: 18px;
    bottom: 19px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 18px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .check-upGrade {
    position: absolute;
    right: 18px;
    bottom: 19px;
    width: 101px;
    height: 32px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/check-upGrade-0.png?") no-repeat;
    background-size: 100% 100%;
  }

  .get {
    position: absolute;
    right: 18px;
    bottom: 19px;
    width: 60px;
    height: 32px;
    background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/get-0.png?") no-repeat;
    background-size: 100% 100%;
  }
}

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .pop {
    width: 330px;
    height: 610px;
    background: #FFFFFF;
    position: relative;

    .rule {
      position: absolute;
      left: 18px;
      top: 16px;
      width: 295px;
      height: 586px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/rule.png?") no-repeat;
      background-size: 100% 100%;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 15px;
      height: 15px;
      background: url("https://oss-kiddo.manqu88.com/h5/memberCenter4/close.png?") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
