<template>
  <div class="wrap">
    <div class="Section1" style="layout-grid: 15.6pt">
      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >为提供相应的产品或服务，并保障系统安全稳定运行，我们可能会收集您的以下个人信息：</span
        >
      </p>

      <p class="MsoNormal">
        <span lang="EN-US">1</span><span style="font-family: 宋体">、</span>
        <span style="font-family: 宋体">用户基本信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：昵称</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：完善账号信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景：用户补充账号信息时；用户注册、登录时</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：个人资料</span
        ><a name="_GoBack"></a>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：性别、生日</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：完善账号信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户补充账号信息时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：个人资料</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：手机号码</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：创建账号；身份验证；联系用户交付商品或服务</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景：用户首次注册时；使用手机号登录、验证身份时；用户下单时</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span>
        <span style="font-family: 宋体">账号安全</span
        ><span lang="EN-US">-</span
        ><span style="font-family: 宋体">修改手机号码</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：收货地址</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：完成收货</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户每次下单时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：我的收货地址</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：姓名</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：配送联系</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：填写收货联系人时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：我的收货地址</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：第三方账号信息</span>
        <span style="font-family: 宋体">（头像、昵称）</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：联合登录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景：用户使用第三方账号登录时</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：账号关联</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">2</span><span style="font-family: 宋体">、</span>
        <span style="font-family: 宋体">用户使用过程信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：加购信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：提供购物车服务；个性化推荐</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户操作购物车时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：我的购物车</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：订单记录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：客服与售后服务及争议处理；提供用户所购买的商品或服务；个性化推荐</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户下单时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：我的订单</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：搜索记录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：展示搜索记录；个性化推荐</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户使用搜索服务时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息详情：搜索历史</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：收藏记录</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用目的：展示收藏的商品</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用场景：用户收藏、关注商品或店铺时</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息详情：收藏夹</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">3</span><span style="font-family: 宋体">、</span>
        <span style="font-family: 宋体">位置信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >信息名称：设备所在位置相关信息（包括您授权的</span
        ><span lang="EN-US">GPS</span
        ><span style="font-family: 宋体">位置信息以及</span
        ><span lang="EN-US">WLAN</span
        ><span style="font-family: 宋体">接入点、蓝牙和基站传感器信息）</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：填写收货地址时，可直接定位出当前地址</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：填写收货地址时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息详情：查询位置</span
        ><span lang="EN-US">&nbsp; </span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span lang="EN-US">4</span><span style="font-family: 宋体">、</span>
        <span style="font-family: 宋体">设备信息</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：设备型号</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：对相应的商品或者服务内容进行排序以适应您的设备界面；保障交易安全</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户启动</span
        ><span lang="EN-US">APP</span
        ><span style="font-family: 宋体">时；用户下单时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span
        ><span lang="EN-US">iPhone11,2</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：操作系统</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：对相应的商品或者服务内容进行排序以适应您的设备界面；保障交易安全</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户启动</span
        ><span lang="EN-US">APP</span
        ><span style="font-family: 宋体">时；下单时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span
        ><span lang="EN-US">iOS</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：应用程序版本</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：对相应的商品或者服务内容进行排序以适应您的设备界面</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户启动</span
        ><span lang="EN-US">APP</span><span style="font-family: 宋体">时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span
        ><span lang="EN-US">10.4.2</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：分辨率</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：对相应的商品或者服务内容进行排序以适应您的设备界面</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户启动</span
        ><span lang="EN-US">APP</span><span style="font-family: 宋体">时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">收集情况：收集</span
        ><span lang="EN-US">1</span><span style="font-family: 宋体">次</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span
        ><span lang="EN-US">375*812</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息名称：浏览器类型</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体"
          >使用目的：对相应的商品或者服务内容进行排序以适应您的设备界面</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">使用场景：用户启动</span
        ><span lang="EN-US">APP</span><span style="font-family: 宋体">时</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 宋体">信息内容：</span
        ><span lang="EN-US">chrome</span>
      </p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "illustrate",
  components: {},

  data() {
    return {};
  },
  created() {
    // window.location.href = 'https://static.manqu88.com/kiddo-privacy.html'
    document.title = "个人信息收集清单";
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 0;
  // min-height: 750px;
  background-color: #ffffff;
  background-size: 100% 100%;
  text-align: left;
}
p{
  margin: 5px 15px;
}
.cotent {
  width: 100%;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 25px;
  margin-top: 0px;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
