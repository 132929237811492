<template>
  <div class="ck-wheel">
    <!-- <div
      style="
        width: 100%;
        height: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <van-icon
        name="arrow-left"
        style="margin-left: 20px"
        size="20"
        @click="back"
      />
      <div>客户详情</div>
      <van-icon
        name="search"
        style="margin-right: 20px; visibility: hidden"
        size="24"
      />
    </div> -->

    <div class="contain">
      <div style="display: flex" v-if="item">
        <van-image round width="48" height="48" :src="item.headUrl">
        </van-image>
        <div
          style="
            display: flex;
            flex-direction: column;
            margin-left: 7px;
            justify-content: space-around;
          "
        >
          <div style="display: flex">
            <span class="nick">{{ item.nickname }}</span>
            <span class="name" style="margin-left: 10px">{{
              item.realName
            }}</span>
            <!-- @touchstart="startTimer"
              @touchend="endTimer" -->
            <span class="name" style="margin-left: 10px" v-if="item.mobile">{{
              item.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            }}</span>
          </div>
          <div style="display: flex">
            <div class="agent">{{ item.agentName }}</div>
            <div class="zhishu">直属</div>
          </div>
        </div>
      </div>
      <div class="data-display" v-if="item">
        <div class="data-show">
          <div class="data-title">首席</div>
          <div class="data-num">
            {{ item.inviteInfo.seniorCount }}
          </div>
        </div>
        <div class="data-show">
          <div class="data-title">店主</div>
          <div class="data-num">
            {{ item.inviteInfo.intermediateCount }}
          </div>
        </div>
        <div class="data-show">
          <div class="data-title">会员</div>
          <div class="data-num">
            {{ item.inviteInfo.primaryCount }}
          </div>
        </div>
      </div>

      <div class="buy-display" v-if="item">
        <div class="container">
          <span class="top"> {{ item.allQuantity }}</span>
          <span class="bottom">购买件数</span>
        </div>
        <div class="container">
          <span class="top">
            {{ `￥${formatPrice(item.allConsumeAmount)}` }}</span
          >
          <span class="bottom">购买总额</span>
        </div>
        <div class="container">
          <span class="top"> {{ item.lastConsumeTime }}</span>
          <span class="bottom">最近消费时间</span>
        </div>
      </div>

      <div class="tips" v-if="myShow">
        本人
        <div class="line"></div>
      </div>
      <div class="list-contain" v-if="myShow" @click="jumpOrder()">
        <div class="left">
          <div v-for="(item, index) in myListLeft" :key="index" class="data">
            <div class="name">{{ item.name }}</div>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.unit }}</div>
          </div>
        </div>
        <div class="right">
          <div v-for="(item, index) in myListRight" :key="index" class="data">
            <div class="name">{{ item.name }}</div>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.unit }}</div>
          </div>
        </div>
      </div>

      <div class="tips" v-if="teamShow">
        团队
        <div class="team">（不包含本人销售业绩）</div>
        <div class="line"></div>
      </div>
      <div class="list-contain" v-if="teamShow" @click="jumpOrder()">
        <div class="left">
          <div v-for="(item, index) in teamListLeft" :key="index" class="data">
            <div class="name">{{ item.name }}</div>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.unit }}</div>
          </div>
        </div>
        <div class="right">
          <div v-for="(item, index) in teamListRight" :key="index" class="data">
            <div class="name">{{ item.name }}</div>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.unit }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "clientdetail",
  components: {},
  data() {
    return {
      token: this.$route.query.token,
      timer: null,
      item: undefined,
      distUserId: undefined,
      myListLeft: [],
      myListRight: [],
      teamListLeft: [],
      teamListRight: [],
      myShow: true,
      teamShow: true,
    };
  },
  created() {
    document.title = "客户详情";
  },

  activated() {
    this.token = this.$route.query.token;

    if (this.$route.params.item && this.$route.params.item.distUserId) {
      this.item = this.$route.params.item;
      this.distUserId = this.$route.params.item.distUserId;
      this.getDetail(0);
      this.getDetail(1);
    }
  },

  mounted() {
    this.token = this.$route.query.token;
    this.item = this.$route.params.item;
    this.distUserId = this.$route.params.item.distUserId;

    uni.webView.getEnv((res) => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.getDetail(0);
        this.getDetail(1);
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetail(0);
          this.getDetail(1);
        });
      }
    });
  },

  methods: {
    formatPrice,
    moment,

    startTimer() {
      this.timer = setTimeout(() => {
        this.$copyText(this.item.mobile)
          .then(() => {
            this.$toast("复制成功");
          })
          .catch(() => {
            this.$toast("复制失败");
          });
      }, 1000);
    },

    endTimer() {
      clearTimeout(this.timer);
    },

    jumpOrder() {
      this.$router.push(
        `/addol/orderList?token=${this.token}&buyerId=${this.distUserId}`
      );
    },

    back() {
      this.$router.back();
    },

    async getDetail(value) {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/data/salesPerformance",
        data: {
          distUserId: this.distUserId,
          saleType: "0",
          type: value,
        },
      });

      if (data.success) {
        if (value == 0) {
          this.myListLeft = data.data.salesDataToday;
          this.myListRight = data.data.salesDataMonth;
          this.myShow = data.data.show;
        } else {
          this.teamListLeft = data.data.salesDataToday;
          this.teamListRight = data.data.salesDataMonth;
          this.teamShow = data.data.show;
        }
      } else {
        this.$toast(data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;

  .contain {
    background: #ffffff;
    width: 349px;
    box-shadow: 0px 0px 4px 0px rgba(236, 236, 236, 0.3);
    border-radius: 4px;
    margin-top: 13px;
    margin-left: 13px;
    margin-right: 13px;
    box-sizing: border-box;
    display: flex;
    padding: 13px;
    flex-direction: column;
  }

  .nick {
    display: inline-block;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .name {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
  }

  .agent {
    min-width: 57px;
    height: 18px;
    background: #020202;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }

  .zhishu {
    width: 57px;
    height: 18px;
    background: #ececec;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
    line-height: 18px;
    margin-left: 7px;
    text-align: center;
  }

  .data-display {
    display: flex;

    .data-show {
      width: 33.3%;
      margin-top: 20px;
      margin-left: 8px;
      margin-right: 8px;
      display: flex;

      .data-title {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        margin-right: 5px;
      }

      .data-num {
        margin-top: 1px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a08849;
      }

      .data-unit {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #666666;
      }
    }
  }

  .buy-display {
    display: flex;
    background: #ececec70;
    border-radius: 2px;
    margin-top: 10px;
    justify-content: space-between;
    padding: 10px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .top {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a08849;
      }

      .bottom {
        margin-top: 3px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: normal;
        color: #333333;
      }
    }
  }

  .tips {
    margin-top: 20px;
    font-size: 13px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;

    .line {
      flex: 1;
      height: 2px;
      background: #dcdbdb48;
    }

    .team {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .list-contain {
    display: flex;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .data {
      display: flex;
      margin-top: 10px;
      align-items: end;

      .name {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .value {
        margin-right: 1px;
        margin-left: 4px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #131313;
      }
    }
  }
}
</style>
  