<template>
  <div class="success_container">
    <p class="tip">提现成功</p>
    <div class="sure" @click="handleClickSure">确认</div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = '提现结果'
  },
  data() {
    return {
      token: '',
      type: '',
      timer: null
    }
  },
  mounted() {
    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (
        res.miniprogram ||
        process.env.VUE_APP_ENV == "development" ||
        process.env.VUE_APP_ENV == "test"
      ) {
        this.token = this.$route.query.token;
        this.type = this.$route.query.type;
        if (!this.token || this.token == "") {
          uni.webView.reLaunch({
            url: "/pagesA/login/index"
          });
          return;
        }
      } else {
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
        });
      }
    });
    this.timer = setTimeout(() => {
      this.$dialog.alert({
        title: "温馨提示",
        message:
          "已成功收到您的提现需求，具体到账时间，详见平台提现说明",
        confirmButtonText: "确认",
        confirmButtonColor: "#232323"
      });
    }, 500);
  },
  methods: {
    handleClickSure() {
      this.$router.go(-2)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
}
</script>

<style lang="scss" scoped>
.success_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  background-color: #f9f9f9;

  .tip {
    width: 100%;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #131313;
    text-align: center;
  }

  .sure {
    position: fixed;
    left: 0;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
    background-color: #0E0E0E;
    height: 40px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: white;
    line-height: 40px;
    text-align: center;
  }
}
</style>