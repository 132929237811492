<template>
    <div class="ck-wheel">

        <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
            <div class="noList" v-if="lists.length === 0"></div>
            <div class="card" v-for="(item, index) in lists" @click="goDetail(item.activityId, item.phaseId)">
                <div class="phaseNum">
                    <div>第</div>
                    <div style="width:1px"></div>
                    <div>{{ item.phase.toString().padStart(2, '0') }}</div>
                    <div style="width:1px"></div>
                    <div>期</div>
                </div>
                <div class="item-frame">
                    <div class="item-img" :style="{ backgroundImage: `url(${item.coverImage})` }"></div>
                </div>

                <div class="reward-msg">
                    <div class="title">{{ item.name }}</div>
                    <div class="des">总参与抽奖码数：{{ item.codeNum }}个</div>
                    <div class="des des-sub" v-if="item.isPublish === 1">中奖者：{{ item.nickName }}</div>
                    <div class="des des-sub" v-if="item.isPublish === 1">中奖码：{{ item.winningCode }}</div>
                    <div class="wait" v-if="item.isPublish !== 1">待开奖</div>
                    <div class="arrow"></div>
                </div>
            </div>
        </van-list>


    </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
export default {
    name: "assist",
    components: {},
    data() {
        return {
            activityId: this.$route.query.activityId,
            token: this.$route.query.token,
            isWx: false,

            lists: [],
            canLoad: false,
            currentPage: 1,
            loading: false,
            finished: false,
        };
    },
    created() {
        document.title = "往期抽奖";
    },
    mounted() {
        this.token = this.$route.query.token;
        setTimeout(() => {
            uni.webView.getEnv(res => {
                console.log("当前环境：" + JSON.stringify(res));
                if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
                    this.token = this.$route.query.token;
                    if (!this.token || this.token == "") {
                        uni.webView.reLaunch({
                            url: "/pagesA/login/index"
                        });
                        return;
                    }
                    this.getList();
                    this.isWx = true;
                } else {
                    console.log("当前环境：" + JSON.stringify(res));
                    this.$bridge.call("getToken", {}).then(e => {
                        console.log("getToken客户端返回: ", e);
                        this.token = e;
                        if (!e || JSON.stringify(e) == "{}") {
                            this.$bridge.call("openPage", { type: 26 });
                            return;
                        }
                        this.getList();
                    });
                }
            });
        }, 0);
    },

    methods: {
        formatPrice,
        async getList() {
            if (this.token) {
                const { data } = await this.$axios({
                    method: "post",
                    headers: {
                        mac: "mac",
                        clientSource: this.$clientSource(),
                        Authorization: this.token
                    },
                    url: "/api/treasureActivity/queryPagePhase",
                    data: {
                        pageSize: 20,
                        currentPage: this.currentPage,
                        activityId: this.activityId,
                    }
                });
                if (data.success) {
                    this.lists = [...this.lists, ...data.data];
                    if (data.data.length <= 0) {
                        this.finished = true;
                    }
                } else {
                    this.$toast(data.message);
                }

                // 加载状态结束
                this.loading = false;
                this.canLoad = true;
            }
        },

        async onLoad() {
            if (!this.canLoad) {
                return;
            }
            this.currentPage++;
            this.getList();
        },





        goDetail(activityId, phaseId) {

            this.$router.push(`/treasure/queryPhaseDetail?token=${this.token}&activityId=${activityId}&phaseId=${phaseId}`);
            // window.open(`/assistDetail?token=${token}&id=${id}`);
        },


    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "mcFont";
    src: url("../../../assets/fonts/KEEP-CALM.ttf");
}

.ck-wheel {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F5F5;


    .noList {
        margin: auto;
        margin-top: 129px;
        width: 146px;
        height: 180px;
        background: url(https://oss-kiddo.manqu88.com/h5/duobao/noRecordList.png) no-repeat;
        background-size: 100% 100%;
    }

    .card {
        margin-top: 10px;
        position: relative;
        width: 355px;
        height: 118px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        align-items: center;

        .phaseNum {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 64px;
            height: 26px;
            background: #FFE800;
            border-radius: 13px;
            line-height: 26px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #010100;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .item-frame {
            margin-right: 10px;
            margin-left: 8px;
            width: 102px;
            height: 102px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #DFDFDF;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-img {
                width: 83px;
                height: 83px;
                background-size: 100% 100%;
            }
        }

        .reward-msg {
            height: 102px;
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                margin-top: 4px;
                width: 222px;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #232323;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* 作为弹性伸缩盒子模型显示 */
                display: -webkit-box;
                /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
                -webkit-box-orient: vertical;
                /* 显示的行 */
                -webkit-line-clamp: 1;
            }

            .des {
                margin-top: 4px;
                margin-bottom: 4px;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
            }

            .des-sub {
                margin-top: 2px;
                margin-bottom: 2px;
                width: 132px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 17px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* 作为弹性伸缩盒子模型显示 */
                display: -webkit-box;
                /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
                -webkit-box-orient: vertical;
                /* 显示的行 */
                -webkit-line-clamp: 1;
            }

            .wait {
                position: absolute;
                left: 0;
                bottom: 4px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #09C34E;
                line-height: 20px;
            }

            .arrow {
                position: absolute;
                right: 0;
                bottom: 6px;
                margin-left: 5px;
                width: 9px;
                height: 13px;
                background: url(https://oss-kiddo.manqu88.com/h5/duobao/arrow_color.png?2) no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>
  