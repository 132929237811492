<template>
  <div class="wrap">
    <div class="ani"></div>
    <div class="guodu"></div>
    <!-- <div class="light">
      <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/bg_2.gif" />
    </div> -->

    <div class="shezhi" @click.stop="showSetModal"></div>
    <div class="fenxiang" @click.stop="toShare"></div>
    <div class="guize" @click.stop="showRuleModal"></div>

    <div class="gifts">
      <div class="scoreBg">我的积分：{{ score }}</div>
    </div>

    <!-- <div class="top"></div> -->
    <!-- <div
      v-for="item in threeBgArr"
      :key="item"
      class="three"
      :class="{ show: currentBg === item }"
      :style="{
        backgroundImage: `url(https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/three/three_${item}.png)`,
      }"
    ></div> -->

    <div class="duiwu">
      <div
        :id="`${item.myTeam ? 'myTeam' : ''}`"
        class="item"
        :class="item.pyName"
        v-for="item in teamList"
        :key="item.id"
      >
        <div class="center">
          <div class="title">第{{ rankEnum[item.rank] }}名</div>
          <div class="name">{{ item.name }}</div>
          <div class="desc">
            <span>{{ item.passNum }}</span
            >次通关
          </div>
        </div>
        <div class="here" v-if="item.myTeam"></div>
      </div>
    </div>

    <div class="jiangli" @click.stop="showJiangliModal"></div>
    <div class="scoreMall" @click.stop="jumpZX"></div>
    <div class="weizhi" @click.stop="toLocal"></div>
    <div class="paihangbang" @click.stop="showPaihangbangModal"></div>
    <div v-if="!isJoin" class="zhandui" @click.stop="toJoin"></div>
    <div v-if="isJoin" class="kaishi">
      <div class="btn" @click.stop="toStart"></div>
      <div class="bottom" @click.stop="toShare">
        <div class="left">
          剩余次数：<span>{{ lotteryTimes }}</span>
        </div>
        <div class="right">
          <span>分享得次数</span>
          <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/fenxiang.png" />
        </div>
      </div>
    </div>

    <div class="mask" v-if="showMask" @touchmove.prevent></div>
    <div class="modal set" v-if="showSet" @touchmove.prevent>
      <img
        class="title"
        src="https://oss-kiddo.manqu88.com/kiddo-activity/kedou/title_shezhi.png"
      />
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <div class="row">
        <img
          class="yinxiao"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/yinxiao.png"
        />
        <img
          v-if="yinxiaoOpen"
          class="kai"
          @click.stop="switchYinxiao"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/kai.png"
        />
        <img
          v-else
          class="guan"
          @click.stop="switchYinxiao"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/guan.png"
        />
      </div>
      <div class="row">
        <img
          id="yinyue"
          class="yinyue"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/yinyue.png"
        />
        <img
          v-if="yinyueOpen"
          class="kai"
          @click.stop="switchYinyue"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/kai.png"
        />
        <img
          v-else
          class="guan"
          @click.stop="switchYinyue"
          src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/guan.png"
        />
      </div>
    </div>

    <div class="modal prize" v-if="showJiangli">
      <img
        class="title"
        src="https://oss-kiddo.manqu88.com/kiddo-activity/kedou/title_jiangli.png"
      />
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <block v-if="prizeData && prizeData.length">
        <div class="desc">奖品可在我的优惠券、金币、积分页面查看</div>
        <div class="list">
          <div class="item" v-for="item in prizeData" :key="item">
            <div class="name">
              {{
                item.turntableDTO.prizeType === 5
                  ? item.extension.value + "积分"
                  : item.turntableDTO.prizeName
              }}
            </div>
            <div class="time">{{ formatDate(item.gmtCreate) }}</div>
            <div
              :class="[item.turntableDTO.prizeType === 5 ? 'duihuan' : 'check']"
              @click="goPage(item.turntableDTO.prizeType)"
            ></div>
          </div>
        </div>
      </block>
      <block v-else>
        <img class="empty" src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/empty.png" />
        <div class="text">暂无通关奖励</div>
      </block>
    </div>

    <div class="modal rank" v-if="showPaihangbang">
      <img
        class="title"
        src="https://oss-kiddo.manqu88.com/kiddo-activity/kedou/title_paihangbang.png"
      />
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <img
        v-if="paihangbangType === 1"
        class="typeTitle"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/paihangbang_zhandui.png"
      />
      <img
        v-if="paihangbangType === 2"
        class="typeTitle"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/paihangbang_zong.png"
      />
      <div class="zhanwei left" @click.stop="switchType(1)"></div>
      <div class="zhanwei right" @click.stop="switchType(2)"></div>
      <!-- <div class="desc" v-if="paihangbangType === 1">
        活动结束后，排行前5名可获得IP盲盒4个
      </div>
      <div class="desc" v-if="paihangbangType === 2">
        活动结束后，排行前5名可获得史努比被子1个
      </div> -->
      <div class="th">
        <div class="left">排行</div>
        <div class="center">用户</div>
        <div class="right">通关次数</div>
      </div>
      <div class="list">
        <div v-if="!userRankList.length" class="empty">
          <img
            class="img"
            src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/paihangbang_empty.png"
          />
          <div class="text">暂无排名</div>
        </div>
        <div class="item" v-else v-for="item in userRankList" :key="item.userId">
          <div class="left first" v-if="item.rank === 1">
            <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/first.png" />
          </div>
          <div class="left second" v-else-if="item.rank === 2">
            <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/second.png" />
          </div>
          <div class="left third" v-else-if="item.rank === 3">
            <img src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/third.png" />
          </div>
          <div class="left" v-else>{{ item.rank }}</div>
          <div class="right">
            <img class="head" :src="item.headPic" />
            <div class="nick">{{ item.nickname }}</div>
            <div class="times">
              通关<span class="count">{{ item.passNum }}</span
              >次
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="userRankInfo.userId">
        <div class="left">
          {{
            userRankInfo.rank ? (userRankInfo.rank >= 1000 ? "1000+" : userRankInfo.rank) : "暂无"
          }}
        </div>
        <img class="head" :src="userRankInfo.headPic" />
        <div class="nick">{{ userRankInfo.nickname }}</div>
        <div class="times">
          通关<span class="count">{{ userRankInfo.passNum }}</span
          >次
        </div>
      </div>
    </div>

    <div class="modal shareTip" v-if="showShareTip" @touchmove.prevent>
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <img
        class="btn"
        @click.stop="toShare(true)"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/share_btn.png"
      />
    </div>

    <div class="modal rule" v-if="showRule">
      <img class="title" src="https://oss-kiddo.manqu88.com/kiddo-activity/kedou/title_guize.png" />
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <div class="main">
        <img class="img" src="https://oss-kiddo.manqu88.com/kiddo-activity/kedou/rule.png" />
      </div>
    </div>

    <div class="loading" :class="{ hide: !isLoading }">
      <div class="box">
        <img class="loading_1" src="https://oss-kiddo.manqu88.com/h5/kedou/loading_1.gif" />
        <img class="loading_2" src="https://oss-kiddo.manqu88.com/h5/kedou/loading_2.gif" />
        <img class="loading_3" src="https://oss-kiddo.manqu88.com/h5/kedou/loading_3.gif" />
        <img class="loading_4" src="https://oss-kiddo.manqu88.com/h5/kedou/loading_4.gif" />
      </div>
      <div class="text">loading</div>
    </div>

    <audio controls="controls" loop="loop" id="audios" preload="auto" hidden>
      <source
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/yinyue.mp3"
        type="audio/mp3"
      />
    </audio>

    <div class="modal popup" v-if="showPopup" @touchmove.prevent>
      <img
        class="close"
        @click.stop="close"
        src="https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/close.png"
      />
      <div class="main" @click.stop="toMiniSubPage">
        <img class="img" :src="popupUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import baseUrl from "@/utils/env";
import { formatDate } from "@/utils/time";

export default {
  data() {
    return {
      isLoading: true,
      isMini: true,
      token: this.$route.query.token,
      threeBgArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      currentBg: 1,
      yinxiaoOpen: true,
      yinyueOpen: true,
      isSetting: false,
      showMask: false,
      showSet: false,
      isJoin: false,
      teamData: {
        lotteryId: null,
        teamId: null,
        userId: null
      },
      teamList: [],
      myTeamData: [],
      rankEnum: {
        1: "一",
        2: "二",
        3: "三",
        4: "四"
      },
      showJiangli: false,
      prizeData: [],
      showPaihangbang: false,
      paihangbangType: 1,
      userRankList: [],
      userRankInfo: {
        userId: null,
        rank: null,
        headPic: "",
        nickname: "",
        passNum: null
      },
      lotteryTimes: 0,
      showShareTip: false,
      showRule: false,
      showClientLogin: false,
      showPopup: false,
      popupUrl: "",
      popupArr1: [
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup1_1.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup1_2.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup1_3.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup1_4.png"
      ],
      popupArr2: [
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup2_1.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup2_2.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup2_3.png",
        "https://kiddo-bucket-prod.oss-cn-hangzhou.aliyuncs.com/cp/kelegedou_popup2_4.png"
      ],
      timer1: null,
      timer2: null,
      shareClicked: false,
      score: 0
    };
  },
  async mounted() {
    document.title = "嗑了个豆";
    setInterval(() => {
      // this.getLotteryTimes();
      if (this.currentBg < 20) {
        this.currentBg++;
      } else {
        this.currentBg = 1;
      }
      // if (this.yinyueOpen) {
      //   audio.play();
      // }
    }, 2000);

    this.timer1 = setInterval(() => {
      if (this.lotteryTimes === 0) {
        this.getLotteryTimes();
      } else {
        clearInterval(this.timer1);
      }
    }, 15000);

    this.timer2 = setInterval(() => {
      if (this.shareClicked) {
        this.getLotteryTimes();
        this.shareClicked = false;
      }
    }, 4000);

    uni.webView.getEnv(res => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.isMini = true;
        this.token = this.$route.query.token;
        this.showPopupModal();
        this.getScore();
        this.getTeamList();
        this.checkJoin();
        this.getLotteryTimes();
      } else {
        this.isMini = false;
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then(e => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          this.showPopupModal();
          this.getScore();
          this.getTeamList();
          this.checkJoin();
          this.getLotteryTimes();
        });
      }
    });

    if (localStorage.getItem("yinxiaoOpen") || localStorage.getItem("yinyueOpen")) {
      this.yinxiaoOpen = localStorage.getItem("yinxiaoOpen") === "true";
      this.yinyueOpen = localStorage.getItem("yinyueOpen") === "true";
    } else {
      localStorage.setItem("yinxiaoOpen", true);
      localStorage.setItem("yinyueOpen", true);
    }

    this.showClientLogin = false;

    const audio = document.getElementById("audios");
    if (this.yinyueOpen) {
      audio.play();

      // 音频
      const play = () => {
        audio.play();
        document.removeEventListener("touchstart", play, false);
      };
      audio.play();
      document.addEventListener(
        "WeixinJSBridgeReady",
        function() {
          play();
        },
        false
      );
      document.addEventListener(
        "YixinJSBridgeReady",
        function() {
          play();
        },
        false
      );
      document.addEventListener("touchstart", play, false);

      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          audio.pause();
          console.log("document__________hidden");
        } else {
          console.log(198888888, this.yinyueOpen);
          if (this.yinyueOpen) {
            audio.play();
          } else {
            audio.pause();
          }
        }
      });
    } else {
      audio.pause();
    }
  },
  destroyed() {
    const audio = document.getElementById("audios");
    audio.pause();
    clearInterval();
  },
  methods: {
    formatDate,
    // 唤起客户端登录
    toClientLogin() {
      // if (this.showClientLogin) {
      //   return false;
      // }
      // this.showClientLogin = true;
      if (!this.token || JSON.stringify(this.token) == "{}") {
        this.$bridge.call("openPage", { type: 26 });
        return;
      }
    },
    async toStart() {
      await this.getLotteryTimes();
      if (!this.lotteryTimes) {
        this.showMask = true;
        this.showShareTip = true;
        return;
      }
      window.location.href = `${baseUrl}/klgd?token=${this.token}&v=${Math.random() * 10}`;
    },
    showSetModal() {
      this.showMask = true;
      this.showSet = true;
    },

    jumpZX() {
      uni.webView.getEnv(res => {
        // console.log("当前环境：" + JSON.stringify(res));
        let id = 2962;
        if (res.miniprogram) {
          uni.webView.navigateTo({
            url: `/pages/index/sub-page?id=${id}`
          });
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 0,
              param: id
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    },

    async showJiangliModal() {
      this.showMask = true;
      this.showJiangli = true;
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/prizeRecord/queryList",
        data: {
          type: 8,
          startTime: new Date("2023/01/13 00:00:00").getTime()
          // lotteryId: this.teamData.lotteryId
        }
      });
      if (success) {
        if (data) {
          this.prizeData = [...data];
        } else {
          this.prizeData = [];
        }
      } else {
        this.$toast(message);
        this.prizeData = [];

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }
    },
    showPaihangbangModal() {
      this.getRankData(1);
      this.showMask = true;
      this.showPaihangbang = true;
    },
    showRuleModal() {
      this.showMask = true;
      this.showRule = true;
    },
    showPopupModal() {
      this.popupUrl = "";
      const time = Date.now();
      const time1_start = new Date("2023/01/18 10:00:00").getTime();
      const time1_end = new Date("2023/01/27 23:59:59").getTime();
      const time2_start = new Date("2022/11/06 10:00:00").getTime();
      const time2_end = new Date("2022/11/07 09:59:59").getTime();
      // if (time <= time1_end && time >= time1_start) {
      //   this.popupUrl = this.popupArr1[Math.floor(Math.random() * this.popupArr1.length)];
      // } else if (time <= time2_end && time >= time2_start) {
      //   this.popupUrl = this.popupArr2[Math.floor(Math.random() * this.popupArr2.length)];
      // }
      let popArr = [...this.popupArr1, ...this.popupArr2];
      if (time <= time1_end && time >= time1_start) {
        this.popupUrl = popArr[Math.floor(Math.random() * popArr.length)];
      }

      if (this.popupUrl) {
        this.showMask = true;
        this.showPopup = true;
      }
    },
    close() {
      this.showMask = false;
      this.showSet = false;
      this.showJiangli = false;
      this.showPaihangbang = false;
      this.showShareTip = false;
      this.showRule = false;
      this.showPopup = false;
    },
    goPage(prizeType) {
      if (prizeType === 5) {
        //积分
        this.jumpZX();
      } else if (prizeType === 1) {
        //优惠券
        uni.webView.getEnv(res => {
          if (res.miniprogram) {
            uni.webView.navigateTo({
              url: `/pagesA/coupon/index`
            });
          } else {
            this.$bridge
              .call("openPage", {
                //跳转任意app页面
                type: 5,
                param: 1
              })
              .then(e => {
                console.log("openPage" + "客户端返回: ", e);
              });
          }
        });
      } else if (prizeType === 4) {
        //金币
        this.$router.push("/goldDetail?token=" + this.token);
      }
    },
    toMiniSubPage() {
      // if (this.isMini) {
      //   uni.webView.navigateTo({
      //     url: "/pages/index/sub-page?id=2961"
      //   });
      // } else {
      //   this.$bridge.call("jumpToDynamic", { id: "2961" });
      // }
      uni.webView.getEnv(res => {
        // console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
          uni.webView.switchTab({
            url: "/pages/index/index"
          });
        } else {
          this.$bridge.call("jumpToMain", {}).then(e => {
            console.log("jumpToMain客户端返回: ", e);
          });
        }
      });
    },
    switchYinxiao() {
      if (this.isSetting) {
        return false;
      }
      this.isSetting = true;
      this.yinxiaoOpen = !this.yinxiaoOpen;
      localStorage.setItem("yinxiaoOpen", this.yinxiaoOpen);
      setTimeout(() => {
        this.isSetting = false;
      }, 500);
    },
    switchYinyue() {
      if (this.isSetting) {
        return false;
      }
      this.isSetting = true;
      this.yinyueOpen = !this.yinyueOpen;
      localStorage.setItem("yinyueOpen", this.yinyueOpen);

      const audio = document.getElementById("audios");
      if (this.yinyueOpen) {
        audio.play();
      } else {
        audio.pause();
      }

      setTimeout(() => {
        this.isSetting = false;
      }, 500);
    },

    async checkJoin() {
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/kd/team/checkJoin",
        data: {}
      });
      if (success) {
        if (data) {
          this.isJoin = true;
          this.teamData = { ...data };
        } else {
          this.isJoin = false;
        }
      } else {
        this.$toast(message);
        this.isJoin = false;

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }
    },
    async toJoin() {
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/kd/team/join",
        data: {}
      });
      if (success) {
        if (data) {
          await this.getTeamList();
          this.$toast(`恭喜你已成功加入${this.myTeamData[0].name}`);
          this.isJoin = true;
          this.teamData = { ...data };
          // setTimeout(() => {
          //   this.toStart();
          // }, 1000);
        }
      } else {
        this.$toast(message);
        this.isJoin = false;

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }
    },
    async getScore() {
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/user/info/queryUserAccount",
        data: {}
      });
      if (success) {
        if (data) {
          this.score = data.freeScore;
        }
      } else {
        this.$toast(message);
      }
    },
    async getTeamList() {
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/kd/team/rank",
        data: {}
      });
      if (success) {
        if (data) {
          this.teamList = [...data];
          this.myTeamData = data.filter(ele => ele.myTeam);
        }
      } else {
        this.$toast(message);

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    toLocal() {
      if (!this.isJoin) {
        this.$toast("请先加入战队");
      }
      const currentEle = document.getElementById("myTeam");
      currentEle.scrollIntoView({ block: "center", behavior: "smooth" });
      // const currentHeight = document.getElementById("myTeam").offsetTop;
      // window.scrollTo(0, currentHeight - 300);
    },
    async toShare(isClose) {
      this.shareClicked = true;

      let inviteCode;
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/distGrouponLeader/getInviteCode",
        data: {}
      });
      if (data.success) {
        inviteCode = data.data.userCode;
      } else {
        this.$toast(data.message);
      }

      if (isClose) {
        setTimeout(() => {
          this.close();
        }, 500);
      }

      if (this.isMini) {
        uni.webView.navigateTo({
          url: `/otherPackage/kedou/share?inviteCode=${inviteCode}`
        });
      } else {
        this.$bridge.call("openPage", { type: 36 });
      }
    },
    switchType(type) {
      if (this.isSetting) {
        return false;
      }
      this.isSetting = true;
      this.getRankData(type);
      this.paihangbangType = type;
      setTimeout(() => {
        this.isSetting = false;
      }, 500);
    },
    async getRankData(type) {
      this.paihangbangType = type;
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/kd/team/user/rank",
        data: {
          type
        }
      });
      if (success) {
        if (data) {
          this.userRankList = data.userRankList || [];
          this.userRankInfo = data.userRankInfo || {};
        } else {
          this.userRankList = [];
          this.userRankInfo = {};
        }
      } else {
        this.$toast(message);
        this.userRankList = [];
        this.userRankInfo = {};

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }
    },
    async getLotteryTimes() {
      const {
        data: { data, success, message, code }
      } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token
        },
        url: "/api/lottery/queryLotteryTimes",
        data: {
          type: 8
        }
      });
      if (success) {
        if (data) {
          this.lotteryTimes = data.remainTimes || 0;
        } else {
          this.lotteryTimes = 0;
        }
      } else {
        this.$toast(message);
        this.lotteryTimes = 0;

        if (this.showClientLogin) {
          return false;
        }
        this.showClientLogin = true;

        if (code === 987654322) {
          if (this.isMini) {
            uni.webView.reLaunch({
              url: "/pagesA/login/index"
            });
          } else {
            this.toClientLogin();
          }
        }

        if (code === 100039 && this.isMini) {
          uni.webView.navigateTo({
            url: "/pagesA/login/invitationCode"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100vw;
  min-height: 100vh;
  background: #b9011d url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/bg.png?1") repeat-y
    21px 60px;
  background-size: 331px 551px;
  background-attachment: fixed;
  position: relative;

  .shezhi {
    width: 60px;
    height: 79px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/shezhi.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 8px;
    left: 18px;
  }

  .fenxiang {
    width: 61px;
    height: 72px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/share.png?1") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 8px;
    right: 18px;
  }

  .guize {
    width: 60px;
    height: 79px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/guize.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    left: 18px;
    top: 232px;
  }

  .gifts {
    width: 305px;
    height: 188px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/gifts.png?v=2") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 36px;
    left: 35px;

    .scoreBg {
      position: absolute;
      bottom: -6px;
      left: 88px;
      width: 130px;
      height: 27px;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
  }

  .top {
    width: 305px;
    height: 188px;
    background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/top.png?v=1") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 36px;
    left: 35px;
  }

  .three {
    width: 152px;
    height: 152px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 65px;
    right: 5px;
    opacity: 0;
    transition: all 0.3s ease;

    &.show {
      opacity: 1;
    }
  }

  .duiwu {
    width: 100vw;
    padding: 246px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 34px;
      flex-direction: column;

      .center {
        width: 90px;
        height: 82px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        .title {
          margin-top: 3px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 25px;
          letter-spacing: 1px;
        }

        .name {
          margin-top: 6px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 17px;
        }

        .desc {
          margin-top: -2px;
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 20px;

          span {
            font-size: 14px;
          }
        }
      }

      &.yuanbaobao {
        background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/yuanbaobao_bg.gif?1")
          no-repeat;
        background-size: 100% 100%;

        .center {
          background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/yuanbaobao.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      &.letaotao {
        background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/letaotao_bg.gif?1")
          no-repeat;
        background-size: 100% 100%;

        .center {
          background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/letaotao.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      &.fangmanman {
        background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/fangmanman_bg.gif?1")
          no-repeat;
        background-size: 100% 100%;

        .center {
          background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/fangmanman.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      &.maidengdeng {
        background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/maidengdeng_bg.gif?1")
          no-repeat;
        background-size: 100% 100%;

        .center {
          background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/maidengdeng.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      .here {
        width: 90px;
        height: 22px;
        background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/here.png") no-repeat;
        background-size: 100% 100%;
        margin-top: -12px;
      }
    }
  }

  .jiangli {
    width: 60px;
    height: 72px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/jiangli.png?11") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 232px;
    right: 18px;
  }

  .scoreMall {
    width: 60px;
    height: 65px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/scoreMall.png?1") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 323px;
    right: 18px;
  }

  .weizhi {
    width: 60px;
    height: 78px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/weizhi.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 395px;
    left: 18px;
  }

  .paihangbang {
    width: 60px;
    height: 76px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/paihangbang.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    top: 395px;
    right: 18px;
  }

  .zhandui {
    width: 280px;
    height: 73px;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/zhandui.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    z-index: 10;
    // top: 497px;
    bottom: 20px;
    left: 48px;
  }

  .kaishi {
    width: 280px;
    position: fixed;
    z-index: 10;
    // top: 576px;
    bottom: 20px;
    left: 48px;

    .btn {
      width: 300px;
      height: 78px;
      background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/kaishi.png") no-repeat;
      background-size: 100% 100%;
    }

    .bottom {
      width: 280px;
      height: 58px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 8px;
      border: 2px solid #b0532e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 23px 15px 2px;
      box-sizing: border-box;
      margin-top: -23px;
      position: relative;
      z-index: -1;

      .left {
        display: flex;
        align-items: center;

        span {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .right {
        display: flex;
        align-items: center;

        img {
          width: 27px;
          height: 27px;
          margin-left: 6px;
        }
      }
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #ffffff;
    box-shadow: inset 0px 0px 0px 4px rgba(217, 50, 30, 1);
    border-radius: 8px;
    border: 2px solid #a00018;

    .title {
      width: 112px;
      height: 42px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -21px;
    }

    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: -15px;
      top: -15px;
    }

    &.set {
      width: 229px;
      height: 218px;
      padding: 59px 35px 54px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .yinxiao,
        .yinyue {
          width: 86px;
          height: 40px;
        }

        .kai,
        .guan {
          width: 56px;
          height: 27px;
        }
      }
    }

    &.prize {
      width: 285px;
      height: 375px;
      padding: 33px 15px;
      box-sizing: border-box;

      .desc {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #252525;
        line-height: 17px;
        text-align: center;
        margin-bottom: 14px;
      }

      .list {
        width: 255px;
        height: 280px;
        overflow-y: scroll;

        overscroll-behavior: contain;
        &::-webkit-scrollbar {
          display: none;
        }

        .item {
          width: 255px;
          height: 70px;
          background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/jiangli_bg.png")
            no-repeat;
          background-size: 100% 100%;
          margin-bottom: 10px;
          padding-top: 15px;
          padding-left: 17px;
          box-sizing: border-box;
          position: relative;

          .name {
            width: 159px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #131313;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .time {
            margin-top: 6px;
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 14px;
          }

          .check {
            width: 52px;
            height: 23px;
            position: absolute;
            right: 8px;
            top: 23px;
            background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/btn_check.png")
              no-repeat;
            background-size: 100% 100%;
          }
          .duihuan {
            width: 52px;
            height: 23px;
            position: absolute;
            right: 8px;
            top: 23px;
            background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/btn_duihuan.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .empty {
        display: block;
        width: 100px;
        height: 77px;
        margin: 69px auto 31px;
      }

      .text {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #404040;
        line-height: 20px;
        text-align: center;
      }
    }

    &.rule {
      width: 285px;
      height: 375px;
      padding: 28px 18px 11px;
      box-sizing: border-box;

      .main {
        overflow-y: scroll;
        width: 100%;
        height: 100%;

        overscroll-behavior: contain;
        &::-webkit-scrollbar {
          display: none;
        }

        .img {
          width: 100%;
        }
      }
    }

    &.popup {
      width: 275px;
      height: 325px;

      box-shadow: inset 0px 0px 0px 0px #ffffff;
      border-radius: 0;
      border: 0;

      .main {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
        }
      }
    }

    &.rank {
      width: 285px;
      height: 419px;
      padding: 28px 22px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .typeTitle {
        width: 223px;
        height: 37px;
      }

      .zhanwei {
        width: 111px;
        height: 37px;
        position: absolute;
      }

      .left {
        left: 29px;
      }

      .right {
        right: 29px;
      }

      .desc {
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fa6400;
        line-height: 14px;
        margin-top: 6px;
      }

      .th {
        width: 100%;
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        line-height: 14px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;

        .left {
          width: 40px;
        }
        .center {
        }
        .right {
          width: 60px;
        }
      }

      .list {
        width: 100%;
        height: 263px;
        overflow-y: scroll;
        margin-top: 5px;

        overscroll-behavior: contain;
        &::-webkit-scrollbar {
          display: none;
        }

        .empty {
          .img {
            width: 105px;
            height: 105px;
            display: block;
            margin: 45px auto 5px;
          }

          .text {
            width: 100%;
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            text-align: center;
          }
        }

        .item {
          position: relative;
          display: flex;
          margin-bottom: 10px;

          .left {
            width: 186px;
            height: 38px;
            border-radius: 8px;
            border: 1px solid #404040;
            display: flex;
            padding-left: 10px;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2f2f2f;

            img {
              height: 100%;
            }

            &.first {
              background: #fff5c1;
            }

            &.second {
              background: #ededed;
            }

            &.third {
              background: #ffead5;
            }
          }

          .right {
            width: 190px;
            height: 38px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #404040;
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            padding-left: 10px;

            .head {
              width: 22px;
              height: 22px;
              border-radius: 50%;
            }

            .nick {
              margin-left: 6px;
              width: 84px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2f2f2f;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .times {
              margin-left: 18px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2f2f2f;

              .count {
                font-size: 14px;
                color: #fa6400;
              }
            }
          }
        }
      }

      .bottom {
        width: 100%;
        height: 42px;
        border-top: 1px solid #dfdfdf;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 12px;
        box-sizing: border-box;

        .left {
          width: 41px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2f2f2f;
          text-align: center;
        }

        .head {
          margin-left: 10px;
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }

        .nick {
          margin-left: 8px;
          width: 84px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #131313;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .times {
          margin-left: 38px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2f2f2f;

          .count {
            font-size: 14px;
            color: #fa6400;
          }
        }
      }
    }

    &.shareTip {
      width: 267px;
      height: 192px;
      padding: 115px 29px 26px;
      box-sizing: border-box;
      background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/shareTip.png")
        no-repeat;
      background-size: 100% 100%;

      .btn {
        width: 100%;
        height: 100%;
      }
    }
  }

  .loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    opacity: 1;
    padding-bottom: 16px;
    box-sizing: border-box;

    &.hide {
      opacity: 0;
      z-index: -10;
    }

    .box {
      width: 198px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      img {
        position: absolute;
      }
    }

    &_1 {
      width: 68px;
      height: 68px;
      left: 0;
      top: 6px;
    }

    &_2 {
      width: 70px;
      height: 70px;
      left: 43px;
      top: 2px;
    }

    &_3 {
      width: 70px;
      height: 70px;
      left: 85px;
      top: 0;
    }

    &_4 {
      width: 65px;
      height: 65px;
      left: 133px;
      top: 3px;
    }

    .text {
      margin-top: 10px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 28px;
    }
  }

  .ani {
    width: 375px;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
    background: url("https://oss-kiddo.manqu88.com/kiddo-activity/kedou/ani.gif?v=1") no-repeat 0 -10px;
    background-size: 100% 100%;
    z-index: 0;
  }
  .guodu {
    position: absolute;
    top: 164px;
    left: 0;
    width: 375px;
    height: 40px;
    background: linear-gradient(180deg, #cf1722 0%, #b9011d 100%);
  }

  .light {
    width: 375px;
    height: 314px;
    position: absolute;
    top: 0;
    left: 0;
    background: url("https://kiddo-bucket.manqu88.com/kiddo-activity/kedou/bg_1.gif?v=1") no-repeat
      0 -10px;
    background-size: 100% 100%;
    z-index: 0;

    img {
      width: 375px;
      height: 328px;
    }
  }
}
</style>
