<template>
  <div class="ck-wheel">
    <van-sticky style="width: 100%; display: flex; flex-direction: column">
      <div
        style="
          width: 100%;
          height: 50px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <van-icon
          v-if="false"
          name="arrow-left"
          style="margin-left: 20px"
          size="20"
          @click="back"
        />
        <van-search
          v-model="aaa"
          placeholder="请输入搜索关键词"
          style="flex: 1; height: 34px"
          @click="search"
        />
        <div style="margin-right: 20px; font-size: 14px" @click="search">
          搜索
        </div>
      </div>
    </van-sticky>

    <div class="refresh-con" ref="wrapper">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        class="scroll"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="list.length != 0 ? '没有更多了' : ''"
          @load="onLoad"
        >
          <div class="noList" v-if="list.length === 0"></div>
          <div class="card" v-for="(item, index) in list" :key="index">
            <van-image round width="66" height="66" :src="item.headPicUrl">
            </van-image>
            <div class="display">
              <div class="name">{{ item.nickname }}</div>
              <div :class="item.mobile ? 'mobile' : 'mobile-2'">
                {{
                  item.mobile
                    ? item.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                    : " 111 "
                }}
              </div>
              <div class="time">
                注册时间：{{
                  moment(item.gmtCreate).format("YYYY.MM.DD HH:mm")
                }}
              </div>
            </div>
            <div class="order" v-if="item.hadBuy">已下单</div>
          </div>
        </van-list></van-pull-refresh
      >
    </div>
  </div>
</template>
  
<script>
import { formatPrice } from "@/utils/money";
import moment from "moment";
export default {
  name: "myintention",
  components: {},
  data() {
    return {
      aaa: "",
      scroll: 0,
      token: this.$route.query.token,
      pid: this.$route.query.pid,

      loading: false,
      finished: false,
      currentPage: 1,
      canLoad: false,
      dataVisible: true,
      gradeCode: "",
      list: [],
      refreshing: false,
      time: null,
      copyText: "",
    };
  },
  activated() {
    this.$refs.wrapper.scrollTop = this.scroll;
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.wrapper.scrollTop;
    next();
  },
  created() {
    document.title = "我的意向客户";
  },
  mounted() {
    this.token = this.$route.query.token;
    this.pid = this.$route.query.pid;
    // this.token = "978bb7aedaa6edad360f89656baa246b";
    uni.webView.getEnv((res) => {
      console.log("当前环境：" + JSON.stringify(res));
      if (res.miniprogram || process.env.VUE_APP_ENV == "development") {
        this.token = this.$route.query.token;
        this.pid = this.$route.query.pid;
        // this.token = "978bb7aedaa6edad360f89656baa246b";
        this.getDetail();
      } else {
        console.log("当前环境：" + JSON.stringify(res));
        this.$bridge.call("getToken", {}).then((e) => {
          console.log("getToken客户端返回: ", e);
          this.token = e;
          // this.token = "978bb7aedaa6edad360f89656baa246b";
          if (!e || JSON.stringify(e) == "{}") {
            this.$bridge.call("openPage", { type: 26 });
            return;
          }
          this.getDetail();
        });
      }
    });
  },

  methods: {
    gtouchstart(val) {
      this.copyText = val;
      // 设置定时器，500毫秒触发长按事件
      this.time = setTimeout(() => {
        this.$copyText(this.copyText)
          .then(() => {
            this.$toast("复制成功");
          })
          .catch(() => {
            this.$toast("复制失败");
          });
      }, 1000);
      return false;
    },
    // 触摸屏幕离开
    cleartouchend() {
      clearTimeout(this.time);
      this.copyText = "";
    },
    // 手指在屏幕上移动
    gtouchmove() {
      clearTimeout(this.time);
      this.copyText = "";
    },

    formatPrice,
    moment,
    back() {
      this.$router.go(-1);
    },
    search() {
      this.$router.push(
        `/addol/intentionsearch?token=${this.token}&pid=${this.pid}`
      );
    },

    onRefresh() {
      this.currentPage = 1;
      this.finished = false;
      this.list = [];
      this.loading = true;
      this.getDetail();
    },
    async onLoad() {
      if (!this.canLoad) {
        return;
      }
      this.currentPage++;
      this.getDetail();
    },

    async getDetail() {
      this.changeEnable = false;
      console.log("currentPage", this.currentPage);

      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: this.$clientSource(),
          Authorization: this.token,
        },
        url: "/api/dist/data/queryIntentionUserList",
        data: {
          pid: this.pid,
          currentPage: this.currentPage,
          pageSize: 8,
        },
      });
      if (data.success) {
        if (data.data.length === 0) {
          this.finished = true;
        }
        this.list = [...this.list, ...data.data];
      } else {
        this.$toast(data.message);
      }

      // 加载状态结束
      this.loading = false;
      this.canLoad = true;
      this.changeEnable = true;
      this.refreshing = false;
    },

    onItemClick(item) {
      this.$router.push({
        name: "clientdetail",
        query: { token: this.token },
        params: { item: item },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  width: 375px;
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  .noList {
    margin: auto;
    margin-top: 53px;
    width: 100px;
    height: 133px;
    background: url(https://oss-kiddo.manqu88.com/h5/assist/noList.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .refresh-con {
    display: flex;
    flex: 1;

    .scroll {
      width: 375px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .card {
    width: 355px;
    background: #ffffff;
    border-radius: 2px;
    margin-top: 8px;
    padding: 9px 7px;
    box-sizing: border-box;
    display: flex;
    position: relative;

    .user-head {
      width: 66px;
      height: 66px;
    }

    .display {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
      .name {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #6f6f6f;
        margin-top: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
      .mobile {
        margin-top: 1px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6f6f6f;
      }
      .mobile-2 {
        margin-top: 1px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6f6f6f;
        visibility: hidden;
      }
      .time {
        margin-top: 8px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6f6f6f;
      }
    }
    .order {
      width: 40px;
      height: 18px;
      background: #f4f4f4;
      font-size: 11px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #757575;
      line-height: 18px;
      text-align: center;
      position: absolute;
      right: 10px;
    }
  }
}
</style>
  