<template>
  <div class="">
    <div :class="['ck-wheel', 'background-1']">
      <!-- 规则 -->
      <div :class="['label', 'label-1']" @click="goRulePage()">活动规则</div>
      <div class="topImg"></div>
      <div class="card">
        <!-- <div class="title-view">
          <div class="title-left">
            TOP10
            <div class="left-down">用户奖励</div>
          </div>
          <div class="title-right">
            无门槛优惠券1张
            <div class="right-down">（最高可得588元）</div>
          </div>
        </div> -->

        <!-- <div class="line"></div> -->

        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="item-view"
          @click="jumpDetail(item)"
        >
          <div class="img">
            <img
              class="img-head"
              :src="item.headPicUrl"
            />
            <div
              v-if="index < 3"
              class="rank-bg-1"
              :style="{
                backgroundImage: `url(${'https://oss-kiddo.manqu88.com/topicRank/rank_' +
                  index +
                  '.png'})`
              }"
            ></div>

            <div v-else class="rank-bg-2">
              <div class="num">{{ index + 1 }}</div>
            </div>
          </div>

          <div class="item-right">
            <div class="score-view">
              <div class="score-bg">总评分：{{ item.score/10 }}</div>
              <div class="articalNum">已发{{item.mediumNumCount}}篇</div>
            </div>
            <div class="mid-view">
              <div class="detail">点赞：{{ item.likeNumCount }}</div>
              <div class="detail">分享：{{ item.shareNumCount }}</div>
              <div class="detail">收藏：{{ item.favorNumCount }}</div>
            </div>
            <div class="user-view">
              <div class="user-icon"></div>
              <div class="user-name">{{ item.nickName }}</div>

              <div class="at-icon"></div>
              <div class="user-name">官方转载{{ item.releaseAtCount }}篇</div>
            </div>
          </div>
        </div>

        <div style="height: 30px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import browser from "@/utils/browser";
import { formatPrice } from "../utils/money";
export default {
  name: "turnTable",
  components: {},
  data() {
    return {
      token: "",
      dataList: []
    };
  },
  async created() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
    document.title = "kiddol圈营养补充站";

    const { data } = await this.$axios({
      method: "post",
      headers: {
        mac: "mac"
      },
      url: "/api/shortMediumRank/queryMediumRankData", //  /api/shortMedium/rank
      data: {}
    });
    if (data.success) {
      console.log(data);
      this.dataList = data.data;
      // this.dataList=[{nickName:'第三季度撒会计法1111'},{nickName:'撒发大水公司法国'}]
    } else {
      this.$toast(data.message);
    }
  },
  destroyed() {},
  mounted() {},
  methods: {
    formatPrice,
    httpToHttps(path) {
      let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
      path = path.replace(reg, "https://$2$3$4$5$6");
      console.log(path);
      return path;
    },
    goRulePage() {
      this.$router.push("/topicRankRules?timeStamp" + Date.now());
    },
    jumpDetail(item) {
      return
      console.log(item.mediumId);

      uni.webView.getEnv(res => {
        console.log("当前环境：" + JSON.stringify(res), browser().versions);
        if (res.miniprogram) {
        } else {
          this.$bridge
            .call("openPage", {
              //跳转任意app页面
              type: 24, //推文详情
              param: item.mediumId
            })
            .then(e => {
              console.log("openPage" + "客户端返回: ", e);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ck-wheel {
  padding: 0px;
  max-width: 100vw;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-1 {
  height: auto;
  // background: url() no-repeat;
  // background-size: 100% 100%;
}
.topImg {
  width: 100vw;
  height: 363px;
  background: url(https://oss-kiddo.manqu88.com/topicRank/rangBg.png?1) no-repeat;
  background-size: 100% 100%;
}
.card {
  width: 350px;
  height: auto;
  margin-top: 11px;
  margin-bottom: 11px;
  background: #ffffff;
  box-shadow: 0px 0px 13px -2px rgba(216, 216, 216, 0.3),
    inset 0px 0px 4px 5px rgba(238, 238, 238, 0.5);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-view {
    width: 100%;
    height: 47px;
    margin-top: 24px;
    display: flex;
    .title-left {
      position: absolute;
      left: 50px;
      height: 40px;
      font-size: 19px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #161616;
      line-height: 20px;
      text-align: center;
      .left-down {
        font-size: 15px;
      }
    }
    .title-right {
      position: absolute;
      right: 50px;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303030;
      line-height: 21px;
      letter-spacing: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .right-down {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4c4c4c;
        line-height: 17px;
      }
    }
  }
  .line {
    margin-top: 20px;
    width: 316px;
    height: 0px;
    border: 1px solid #f5f5f5;
  }
  .item-view {
    width: 100%;
    height: 102px;
    display: flex;
    margin-bottom: 9px;
    .img {
      position: relative;
      margin-top: 33px;
      margin-left: 32px;
      width: 81px;
      height: 81px;
      // background: no-repeat;
      // background-size: 100% 100%;
      object-fit: scale-down;

      .img-head {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        border-radius: 45px;
        position: absolute;
        top: 0;
        left: 0;
        // object-fit: scale-down;
      }
      .rank-bg-1 {
        position: absolute;
        top: -11px;
        left: -18px;
        width: 33px;
        height: 29px;
        background: no-repeat;
        background-size: 100% 100%;
      }
      .rank-bg-2 {
        position: absolute;
        top: -11px;
        left: -15px;
        width: 25px;
        height: 25px;
        background: url(https://oss-kiddo.manqu88.com/topicRank/rank_bg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .num {
          height: 16px;
          font-size: 13px;
          font-family: JDZhengHT-Regular, JDZhengHT;
          font-weight: 400;
          color: #a8a8a8;
          line-height: 16px;
        }
      }
    }

    .item-right {
      margin-top: 33px;
      margin-left: 11px;
      width: auto;
      height: 81px;
      display: flex;
      flex-direction: column;
      .score-view {
        display: flex;
        align-items: center;
        height: 33px;
        width: auto;
        .score-bg {
          z-index: 10;
          width: 106px;
          height: 33px;
          background: linear-gradient(270deg, #ffffff 0%, #e8eaee 100%);
          box-shadow: 0px 0px 4px 0px rgba(152, 152, 152, 0.5);
          border-radius: 17px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .articalNum {
          z-index: 9;
          position: relative;
          left: -20px;
          width: 122px;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(270deg, #ffffff 0%, #e8eaee 100%);
          border-radius: 17px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 17px;
        }
      }

      .mid-view {
        margin-top: 7px;
        display: flex;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        .detail {
          margin-right: 7px;
        }
      }
      .user-view {
        margin-top: 5px;
        display: flex;
        align-items: center;
        .user-icon {
          width: 17px;
          height: 17px;
          border-radius: 45px;
          background: url(https://oss-kiddo.manqu88.com/topicRank/user_bg.png?1) no-repeat;
          background-size: 100% 100%;
        }
        .user-name {
          margin-left: 5px;
          width: 84px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .at-icon {
          width: 17px;
          height: 17px;
          border-radius: 45px;
          background: url(https://oss-kiddo.manqu88.com/topicRank/atIcon.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
.label {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0px;
  width: 80px;
  height: 30px;
  background: rgba($color: #000000, $alpha: 0.46);
  border-radius: 17px 0 0 17px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}
.label-1 {
  position: absolute;
  top: 70px;
}
</style>
