<template>
  <div :class="['product']">
    <div class="productDetaildetail">
      <van-swipe :autoplay="0" :show-indicators="true">
        <van-swipe-item
          class="bannerImg"
          v-for="(item, index) in detail.resourceDTOS"
          :key="index"
        >
          <img
            v-if="item.tag !== 'ITEM_detail' && item.tag !== 'ITEM_MAIN_VIDEO'"
            class="bannerImg"
            :src="`${item.path}?x-oss-process=image/resize,m_fill,h_300,w_375`"
          />
          <video
            controls
            v-if="item.tag === 'ITEM_MAIN_VIDEO'"
            :src="item.path"
            class="bannerImg"
          ></video>
        </van-swipe-item>
      </van-swipe>

      <div class="price">
        {{
          detail.retailPriceDesc +
          (detail.maxRetailPrice > detail.retailPrice
            ? `${formatPrice(detail.retailPrice)}~${formatPrice(detail.maxRetailPrice)}`
            : formatPrice(detail.retailPrice))
        }}
      </div>
      <div class="title">{{ detail.itemName }}</div>
    </div>
    <div class="servicedetail">
      <!-- <div class="spec">
        <span class="left">选择</span>
        <span class="right">{{ getPageQuery().spec }}</span>
      </div> -->
      <div class="warehouse">
        <span class="left">发货</span>
        <span class="right">{{ detail.warehouse }}</span>
      </div>
      <div class="service">
        <span class="left">服务</span>
        <div class="right">
          <span v-for="(items, ids) in detail.itemServiceDTOS" :key="ids">
            {{
              ids !== detail.itemServiceDTOS.length - 1
                ? `${items.serviceName} | `
                : items.serviceName
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="branddetail" v-if="detail.brandDTO">
      <img class="avatar" :src="detail.brandDTO.pictureUrl" />
      <div class="right">
        <div class="name">{{ detail.brandDTO.name }}</div>
        <div class="country">{{ detail.brandDTO.country }}</div>
      </div>
    </div>
    <div class="detaildetail">
      <div class="title">商品详情</div>
      <div class="description" v-html="content"></div>
    </div>

    <div></div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";
export default {
  name: "Pay",
  components: {},
  created() {
    this.itemId = Number(this.$route.query.itemId);
    document.title = "商品详情";
  },
  async mounted() {
    this.getDetail();
  },
  data() {
    return {
      token: this.$route.query.token,
      detail: {},
      content: "",
    };
  },
  methods: {
    formatPrice,
    async getRich(url) {
      const e = await this.$axios({
        method: "get",
        headers: {
          mac: "mac",
        },
        url: url,
        data: {},
      });

      this.content = e.data;
      this.content = this.content.replaceAll("<video", '<video show-mute-btn="true"');
      this.content = this.content.replaceAll('autoplay=""', "");
      this.$forceUpdate();
    },
    async getDetail() {
      const { data } = await this.$axios({
        method: "post",
        headers: {
          mac: "mac",
          clientSource: 2,
          version: "mini_1.2.4",
        },
        url: "/api/item/queryItemById",
        data: {
          id: this.itemId,
        },
      });
      if (data.success) {
        this.detail = data.data;
        const multipleRes =
          data.data.itemDetailResourceDTO && data.data.itemDetailResourceDTO.path;
        if (multipleRes) {
          await this.getRich(multipleRes);
        }
      } else {
        this.$toast(data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  width: 375px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #f6f6f6;
  box-sizing: border-box;

  .productDetaildetail {
    background: #ffffff;
    width: 100%;
    padding-bottom: 10px;

    .bannerImg {
      width: 100%;
      height: 300px;
    }

    .grade {
      padding: 0 10px;
      margin-top: 10px;

      .gradeName {
        color: rgb(154, 124, 0);
      }

      .memberPrice {
        margin-left: 5px;
        color: red;
        font-size: 16px;
      }
    }

    .price {
      padding: 0 10px;
      font-size: 16px;
      line-height: 30px;
    }

    .oldPrice {
      padding: 0 10px;
      font-size: 14px;
      color: #666;
      line-height: 30px;

      span {
        text-decoration: line-through;
      }
    }

    .title {
      padding: 0 10px;
      font-size: 14px;
      line-height: 32px;
    }

    .tag {
      padding: 0 10px;
      font-size: 12px;
      line-height: 30px;
      color: #f7a92b;
    }

    .isPresale {
      padding: 0 10px;
      display: flex;
      align-items: center;

      .tag {
        padding: 0;
        text-align: center;
        line-height: 18px;
        width: 34px;
        height: 18px;
        background: rgb(255, 96, 96);
        color: #fff;
        margin-right: 5px;
      }
    }
  }

  .servicedetail {
    padding: 10px;
    background: #ffffff;
    margin-top: 10px;
    line-height: 30px;

    .spec {
      display: flex;
      .left {
        font-weight: bold;
        margin-right: 20px;
      }
    }

    .warehouse {
      display: flex;
      .left {
        font-weight: bold;
        margin-right: 20px;
      }
    }

    .service {
      display: flex;
      .left {
        font-weight: bold;
        margin-right: 20px;
      }
    }
  }

  .branddetail {
    padding: 10px;
    background: #ffffff;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      margin-right: 10px;
    }

    .right {
      .name {
        font-weight: bold;
        line-height: 28px;
      }

      .country {
        line-height: 28px;
      }
    }
  }

  .detaildetail {
    margin-top: 10px;
    padding: 10px 0;
    background: #ffffff;
    text-align: center;

    .title {
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
    }

    .description {
      width: 375px;
      margin-top: 10px;
      /deep/ img {
        width: 100% !important;
        object-fit: fill !important;
      }
      /deep/ video {
        width: 100% !important;
      }
    }
  }

  .footerBtn {
    width: 275px;
    height: 40px;
    border-radius: 20px;
    margin-left: 42px;
    position: fixed;
    bottom: 40px;
    background: #f7a92b;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
}
.rules {
  width: 375px;
  min-height: 100px;
  background: url(https://kiddo-bucket.oss-cn-hangzhou.aliyuncs.com/giftH5/rulesBg.png)
    no-repeat;
  background-size: 100% 100%;
}
</style>
